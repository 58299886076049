import React from 'react';

import { ShapeIconProps } from 'editor/src/util/shapePresets';

import Icon from 'editor/src/component/Icon/index';

function IconLinePreset({ className, dashPattern, strokeWidth = 4 }: ShapeIconProps) {
  return (
    <Icon className={className}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 73L73 7"
          stroke="#383838"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={dashPattern && `${dashPattern.dash * strokeWidth},${dashPattern.gap * strokeWidth}`}
        />
      </svg>
    </Icon>
  );
}

export default IconLinePreset;
