import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import copySpreadUtil from '../../util/copySpreadUtil';

const copySpreadReducer = (
  state: DesignState,
  action: PayloadAction<{ copyFromIndex: number; positionAtIndex: number; empty: boolean; mediaFromIndex?: number }>,
) => {
  if (!state.designData) {
    return;
  }

  const { copyFromIndex, positionAtIndex, empty, mediaFromIndex } = action.payload;
  copySpreadUtil(state.designData, copyFromIndex, positionAtIndex, empty, mediaFromIndex);
};

export default copySpreadReducer;
