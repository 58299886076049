import { fabric } from 'fabric';
import React, { useImperativeHandle, useMemo } from 'react';

import FabricLine from 'editor/src/fabric/FabricLine';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

export interface Props extends fabric.ILineOptions {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  zIndex?: number;
  angle?: number;
}

function FabricLineComponent(props: Props, ref: React.Ref<FabricLine>) {
  const fabricCanvas = useFabricCanvas();

  const { ...fabricProps } = props;

  const element = useMemo(() => new FabricLine([]), []);
  useObjectProps(element, fabricProps);
  useImperativeHandle(ref, () => element);
  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricLineComponent));
