import memoize from 'lodash/memoize';

import { DesignData, StructureIndex } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

export function getStructureIndexByElementUuid(
  designData: DesignData | undefined,
  uuid: number,
): StructureIndex | undefined {
  if (!designData) {
    return undefined;
  }

  for (let spreadIndex = 0; spreadIndex < designData.spreads.length; spreadIndex += 1) {
    const pageIndex = 0;
    const { media } = designData.spreads[spreadIndex].pages[pageIndex].groups;
    if (!media) {
      continue;
    }

    for (let elementIndex = 0; elementIndex < media.length; elementIndex += 1) {
      if (media[elementIndex].uuid === uuid) {
        return {
          spreadIndex,
          pageIndex,
          elementIndex,
          uuid,
        };
      }
    }
  }

  return undefined;
}

const getStructureIndexByElementUuidMemoized = memoize(
  (state: RootState, uuid: number) => getStructureIndexByElementUuid(state.design.designData, uuid),
  (state: RootState, uuid: number) => JSON.stringify(getStructureIndexByElementUuid(state.design.designData, uuid)),
);

export default getStructureIndexByElementUuidMemoized;
