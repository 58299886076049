import { fabric } from 'fabric';
import Matrix from 'ml-matrix';

import degrees2Radians from 'editor/src/util/degrees2Radians';

const glsl = (e: TemplateStringsArray) => e;

const m3 = {
  translation: function translation(tx: number, ty: number, ar: number) {
    return [
      [1, 0, 0],
      [0, 1, 0],
      [tx * ar, ty, 1],
    ];
  },

  rotation: function rotation(angleInRadians: number, ar: number) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    return [
      [c / ar, -s, 0],
      [s / ar, c, 0],
      [0, 0, 1],
    ];
  },

  scaling: function scaling(sx: number, sy: number, ar: number) {
    return [
      [sx * ar, 0, 0],
      [0, sy, 0],
      [0, 0, 1],
    ];
  },
};

export type ConstructorOption = {
  x: number;
  y: number;
  width: number;
  height: number;
  angle: number;
  inside?: boolean;
};

interface CropFilter extends fabric.IBaseFilter {}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CropFilter = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  angle: 0,
  inside: true,

  type: 'CropFilter',
  vertexSource: glsl`
    attribute vec2 aPosition;
    uniform mat3 u_transformMatrix;
    uniform int u_inside;
    varying vec2 v_f2TexCoord;

    void main() {
      v_f2TexCoord = (u_transformMatrix * vec3(aPosition, 1.0)).xy;
      if (u_inside == 1) {
        gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);
      } else {
        gl_Position = vec4((u_transformMatrix * vec3(aPosition, 1.0)).xy * 2.0 - 1.0, 0.0, 1.0);
      }
    }
  `,

  fragmentSource: glsl`
    precision highp float;
    varying vec2 v_f2TexCoord;
    uniform sampler2D u_s2Texture;

    void main() {
      gl_FragColor = texture2D(u_s2Texture, v_f2TexCoord);
    }
  `,

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      transformMatrix: gl.getUniformLocation(program, 'u_transformMatrix'),
      inside: gl.getUniformLocation(program, 'u_inside'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    const ar = this.originalWidth / this.originalHeight;
    const matrix = Matrix.identity(3, 3)
      .mmul(new Matrix(m3.scaling(this.width, this.height, ar)))
      .mmul(new Matrix(m3.translation(this.x, this.y, ar)))
      .mmul(new Matrix(m3.rotation(degrees2Radians(this.angle), ar)));
    gl.uniformMatrix3fv(uniformLocations.transformMatrix, false, matrix.to1DArray());
    gl.uniform1i(uniformLocations.inside, this.inside ? 1 : 0);
  },

  applyTo2d() {
    // we need to implement this for browser that don't support webgl
  },

  applyToWebGL(p: { context: WebGLRenderingContext; sourceWidth: number; sourceHeight: number }) {
    this.originalWidth = p.sourceWidth;
    this.originalHeight = p.sourceHeight;

    if (!this.inside) {
      const gl = p.context;
      gl.clearColor(0, 0, 0, 0);
      // eslint-disable-next-line no-bitwise
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    return this.callSuper('applyToWebGL', p);
  },
}) as { new (options: ConstructorOption): CropFilter };

(CropFilter as any).fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).CropFilter = CropFilter;

export default CropFilter;
