import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const resetDigitizedAssetReducer = (state: DesignState, action: PayloadAction<{ elementId: string }>) => {
  const { elementId } = action.payload;
  if (state.digitizedAssets[elementId]) {
    delete state.digitizedAssets[elementId];
  }
};

export default resetDigitizedAssetReducer;
