import { MediaElement } from 'editor/src/store/design/types';
import { LayoutFrame } from 'editor/src/store/editorModules/layouts/types';

import { areNumberClose } from './utils';

function frameTypeToElementType(frameType: LayoutFrame['frameArea']['type']): MediaElement['type'] {
  switch (frameType) {
    case 'generated-text':
    case 'text':
      return 'text';
    case 'grid':
      return 'grid';
    case 'image':
    default:
      return 'image';
  }
}

function findElementFrame(sourceFrames: LayoutFrame[], destFrames: LayoutFrame[], element: MediaElement) {
  if (element.type === 'line') {
    return undefined;
  }

  const frameIndex = sourceFrames.findIndex((frame) => {
    if (element.type === 'text' && element.layoutFrameId === frame.id) {
      return true;
    }

    const frameType = frameTypeToElementType(frame.frameArea.type);
    if (frameType !== element.type) {
      return false;
    }

    return (
      areNumberClose(frame.x, element.x) &&
      areNumberClose(frame.y, element.y) &&
      areNumberClose(frame.width, element.width) &&
      areNumberClose(frame.height, element.height)
    );
  });

  if (frameIndex === -1) {
    return undefined;
  }

  return {
    destFrame: destFrames[frameIndex],
    sourceFrame: sourceFrames[frameIndex],
  };
}

export default findElementFrame;
