import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaRectangle } from 'editor/src/store/design/types';

const INITIAL_DIMENSION_MULTIPLIER = 0.7;

export const defaultRectangleStyles: Required<Pick<MediaRectangle, 'stroke' | 'fill' | 'strokeWidth' | 'height'>> = {
  stroke: '#000',
  fill: '#999999',
  strokeWidth: 1,
  height: 100,
};

export const getNewRectangleDisplaySize = (area: Area) => {
  const dimensionSize = Math.min(area.width, area.height) * INITIAL_DIMENSION_MULTIPLIER;
  return {
    width: dimensionSize,
    height: dimensionSize,
  };
};
