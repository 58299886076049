import { isFlutter } from 'editor/src/store/watchers/useDisplayMode';

import environment, { EnvEnum } from 'editor/src/environment';

import { OutboundPostMessage } from './messages';

function sendPostMessage<A extends keyof OutboundPostMessage>(type: A, data: OutboundPostMessage[A]) {
  if (isFlutter) {
    window.parent.postMessage(JSON.stringify({ type, data }));
  } else {
    window.parent.postMessage({ type, data }, '*');
  }

  if (environment.env === EnvEnum.dev) {
    // eslint-disable-next-line no-console
    console.debug('To the host', { type, data });
  }
}

export default sendPostMessage;
