import cloneDeep from 'lodash/cloneDeep';

import getApplyableSchemaNames from 'editor/src/store/design/operation/getApplyableSchemaNames';
import { DesignData } from 'editor/src/store/design/types';

import { RootState } from 'editor/src/store';
import applyGroupLayoutToSpread from 'editor/src/util/design/applyGroupLayoutToSpread';
import applyLayoutSchemaToSpread from 'editor/src/util/design/applyLayoutSchemaToSpread';

import groupLayouts from './groupLayouts';

import type { i18n } from 'i18next';

const applyLayoutPerProductUid = (
  destinationDesign: DesignData,
  rootState: RootState,
  mutateDesign: boolean,
  i18n: i18n,
  layoutPerProductUids = rootState.variants.layoutPerProductUids,
  groupedSpreadsPerProductUids = rootState.variants.groupedSpreadsPerProductUids,
  forceLayout = rootState.variants.configuration.forceLayout,
) => {
  const schemaNamesToApply = getApplyableSchemaNames(
    destinationDesign,
    layoutPerProductUids,
    rootState.design.autoApplyLayoutSchemaNames,
  );
  if (!schemaNamesToApply) {
    return destinationDesign;
  }

  const spreadGroups = groupedSpreadsPerProductUids[destinationDesign.product_uid];

  const updatedDesignData = mutateDesign ? destinationDesign : cloneDeep(destinationDesign);
  updatedDesignData.spreads.forEach((spread, spreadIndex) => {
    if (spread.layoutSchemaName && !forceLayout) {
      return;
    }

    if (spreadGroups) {
      const spreadGroup = spreadGroups.find((spreadGroup) => spreadGroup.spreadIndexes[0] === spreadIndex);
      const layoutName = schemaNamesToApply[0];
      const layoutGroup =
        spreadGroup &&
        groupLayouts.find((layout) => layout.name === layoutName && layout.tag === spreadGroup.layoutTag);
      if (
        !spreadGroup ||
        !layoutGroup ||
        layoutName === spread.layoutSchemaName ||
        spreadGroup.layoutTag !== layoutGroup.tag
      ) {
        return;
      }
      applyGroupLayoutToSpread(rootState, spreadIndex, updatedDesignData.spreads, spreadGroup, layoutGroup, i18n);
    } else {
      const layoutSchemas = rootState.editorModules.layouts.schemas.filter((schema) =>
        schemaNamesToApply.includes(schema.name),
      );
      const layout = spread.pages.length > 1 && layoutSchemas.length > 1 ? layoutSchemas[1] : layoutSchemas[0];
      if (
        !layout ||
        layout.name === spread.layoutSchemaName ||
        (spread.tags && !spread.tags.some((tag) => !layout.tags || layout.tags.includes(tag)))
      ) {
        return;
      }
      applyLayoutSchemaToSpread(rootState, spread, spreadIndex, layout, i18n);
    }
  });

  return updatedDesignData;
};

export default applyLayoutPerProductUid;
