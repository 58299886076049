// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg fill=%27%236B6B6B%27 height=%2724%27 viewBox=%270 0 24 24%27 width=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M7 10l5 5 5-5z%27/><path d=%27M0 0h24v24H0z%27 fill=%27none%27/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VariantSelectors--DYrNB .label--NdxjP{font-size:14px;line-height:24px;color:#6b6b6b;display:block}.selector--skoZ8+.selector--skoZ8{margin-top:24px}.select--RZc7y{width:100%;padding:8px 8px 8px 12px;border:1px solid #999;cursor:pointer;appearance:none;background:rgba(0,0,0,0);background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position-x:100%;background-position-y:5px;font-size:16px;line-height:20px}.select--RZc7y:hover{background-color:#f7f7f7}.select--RZc7y:focus{border:2px solid #212121;outline:none;box-shadow:none}`, "",{"version":3,"sources":["webpack://./src/component/VariantSelectors/index.module.scss"],"names":[],"mappings":"AACE,uCACE,cAAA,CACA,gBAAA,CACA,aAAA,CACA,aAAA,CAIJ,kCACE,eAAA,CAGF,eACE,UAAA,CACA,wBAAA,CACA,qBAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA,CACA,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,yBAAA,CACA,cAAA,CACA,gBAAA,CAEA,qBACE,wBAAA,CAGF,qBACE,wBAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".VariantSelectors {\n  .label {\n    font-size: 14px;\n    line-height: 24px;\n    color: #6b6b6b;\n    display: block;\n  }\n}\n\n.selector + .selector {\n  margin-top: 24px;\n}\n\n.select {\n  width: 100%;\n  padding: 8px 8px 8px 12px;\n  border: 1px solid #999;\n  cursor: pointer;\n  appearance: none;\n  background: transparent;\n  background-image: url(\"data:image/svg+xml;utf8,<svg fill='%236B6B6B' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>\");\n  background-repeat: no-repeat;\n  background-position-x: 100%;\n  background-position-y: 5px;\n  font-size: 16px;\n  line-height: 20px;\n\n  &:hover {\n    background-color: #f7f7f7;\n  }\n\n  &:focus {\n    border: 2px solid #212121;\n    outline: none;\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VariantSelectors": `VariantSelectors--DYrNB`,
	"label": `label--NdxjP`,
	"selector": `selector--skoZ8`,
	"select": `select--RZc7y`
};
export default ___CSS_LOADER_EXPORT___;
