import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, MediaElement } from 'editor/src/store/design/types';

const addMediaElementReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    pageIndex: number;
    element: MediaElement;
    elementIndex?: number;
  }>,
) => {
  if (!state.designData) {
    return;
  }

  const { spreadIndex, pageIndex, element, elementIndex } = action.payload;
  if (!state.designData.spreads[spreadIndex].pages[pageIndex].groups.media) {
    state.designData.spreads[spreadIndex].pages[pageIndex].groups.media = [];
  }

  const { media } = state.designData.spreads[spreadIndex].pages[pageIndex].groups;
  if (!media) {
    return;
  }

  if (elementIndex !== undefined) {
    media.splice(elementIndex, 0, element);
  } else {
    media.push(element);
  }
};

export default addMediaElementReducer;
