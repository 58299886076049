import removeImageManipulationBeforeImageRemoveOperation from './operation/imageManipulation/removeImageManipulationBeforeImageRemoveOperation';
import updateImageManipulationBeforeImageUpdateOperation from './operation/imageManipulation/updateImageManipulationBeforeImageUpdateOperation';
import { PluginEventName, PluginOperationEventsConfig } from './types';

const operationEventsConfig: [
  PluginOperationEventsConfig<PluginEventName.BeforeElementAssetChanged>,
  PluginOperationEventsConfig<PluginEventName.BeforeElementAssetRemoved>,
] = [
  {
    event: PluginEventName.BeforeElementAssetChanged,
    operations: [updateImageManipulationBeforeImageUpdateOperation],
  },
  {
    event: PluginEventName.BeforeElementAssetRemoved,
    operations: [removeImageManipulationBeforeImageRemoveOperation],
  },
];

export default operationEventsConfig;
