import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setAllowPersonalizationCalendarEventsFlagReducer = (state: DesignState, action: PayloadAction<boolean>) => {
  if (!state.designData) {
    return;
  }

  if (!state.designData.calendar) {
    state.designData.calendar = {};
  }

  state.designData.calendar.allowPersonalization = action.payload;
};

export default setAllowPersonalizationCalendarEventsFlagReducer;
