import cn from 'classnames';
import React from 'react';

import { CollectionAsset } from 'editor/src/store/collections/types';

import styles from './index.module.scss';

interface Props {
  asset: CollectionAsset;
  isActive: boolean;
  onSelect: (assetId: string, url: string) => void;
}

function ColorThumbnail({ asset, isActive, onSelect }: Props) {
  return (
    <div
      className={cn(styles.ColorThumbnail, { [styles.active]: isActive })}
      style={{ backgroundColor: asset.color || 'white' }}
      onClick={() => onSelect(asset.id, asset.url)}
    />
  );
}

export default React.memo(ColorThumbnail);
