import cn from 'classnames';
import React from 'react';

import { ConditionOption } from 'editor/src/store/design/types';

import styles from './index.module.scss';

interface Props {
  conditionId: string;
  option: ConditionOption;
  isActive: boolean;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptionButton({ conditionId, option, isActive, onSelectOption }: Props) {
  return (
    <button
      type="button"
      className={cn(styles.option, 'g-selector-button', {
        [styles.active]: isActive,
        'g-selector-button-active': isActive,
      })}
      onClick={() => onSelectOption(conditionId, option.id)}
    >
      {option.label}
    </button>
  );
}

export default React.memo(ConditionOptionButton);
