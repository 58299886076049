import cn from 'classnames';
import React from 'react';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import getPopupData from 'editor/src/store/app/module/popup/selector/getPopupData';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import Section from 'editor/src/component/Popup/AbstractPopup/Section';
import { PopupName } from 'editor/src/component/Popup/popups';

import styles from 'editor/src/component/Popup/ConfirmationPopup/index.module.scss';

export interface ConfirmationOption {
  title: string;
  onConfirm?: () => void;
}

export interface LocalConfirmationPopupData {
  popupTitle: string;
  textLines: string[];
  options: ConfirmationOption[];
  onCancel?: () => void;
  hideTitleIcon?: boolean;
}

interface Props {
  className?: string;
  fadeMaskClassName?: string;
}

function LocalConfirmationPopup({ className, fadeMaskClassName }: Props) {
  const dispatch = useDispatch();
  const popupData = useSelector((state) => getPopupData(state, PopupName.LOCAL_CONFIRMATION_POPUP));
  if (!popupData) {
    return null;
  }

  const handleClickOutside = () => {
    if (popupData.onCancel) {
      popupData.onCancel();
    }
    dispatch(closePopupOperation());
  };

  const handleOptionClick = (option: ConfirmationOption) => {
    if (option.onConfirm) {
      option.onConfirm();
    }
    dispatch(closePopupOperation());
  };

  const { popupTitle, textLines, options, hideTitleIcon } = popupData;

  return (
    <AbstractPopup
      onClickOutside={handleClickOutside}
      name={PopupName.LOCAL_CONFIRMATION_POPUP}
      type={PopupType.INFO}
      title={popupTitle}
      className={cn(className, 'cy-popup-confirmation')}
      fadeMaskClassName={fadeMaskClassName}
      hideTitleIcon={hideTitleIcon}
      buttons={
        <div className={styles.confirmationButtons}>
          {options.map((option, index) => (
            <Button
              key={index}
              secondary={index < options.length - 1}
              primary={index === options.length - 1}
              onClick={() => handleOptionClick(option)}
              className={`cy-popup-option-${index + 1}`}
            >
              {option.title}
            </Button>
          ))}
        </div>
      }
    >
      <Section>
        {textLines.map((text, i) => (
          <p className={styles.confirmationText} key={i}>
            {text}
          </p>
        ))}
      </Section>
    </AbstractPopup>
  );
}

export default LocalConfirmationPopup;
