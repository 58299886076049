function getTextFromTemplate<P extends string>(
  templateString: string,
  params: { [key in P]: string },
): string | undefined {
  let text = templateString;
  const expectedParams = templateString
    .match(/\{\{(.+?)\}\}/g)
    ?.map<P>((param) => param.replace('{{', '').replace('}}', '') as P);

  if (!expectedParams?.length) {
    return undefined;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of expectedParams) {
    if (!params[key]) {
      // eslint-disable-next-line no-console
      console.warn(`"${key}" parameter is not provided to the string template`);
      return undefined;
    }

    text = text.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
  }

  return text;
}

export default getTextFromTemplate;
