import { MediaRectangle } from 'editor/src/store/design/types';

import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';

import { validateCornerRounding, validateStrokeWidth } from './utils';

const rectangleElementDataToFabricProps = (
  elementData: MediaRectangle,
  elementIndex: number,
  mm2px: (n: number) => number,
) => {
  const cornerRounding = mm2px(validateCornerRounding(elementData));

  // multiply strokeWidth as half of it is clipped
  const strokeWidth = validateStrokeWidth({
    ...elementData,
    strokeWidth: elementData.strokeWidth * 2,
  });
  return {
    zIndex: zIndex.MEDIA + elementIndex,
    strokeWidth: mm2px(strokeWidth),
    stroke: elementData.stroke,
    fill: elementData.fill,
    paintFirst: 'fill',
    rx: cornerRounding,
    ry: cornerRounding,
    strokeDashArray: elementData.dashPattern
      ? [
          mm2px(elementData.dashPattern.dash * elementData.strokeWidth),
          mm2px(elementData.dashPattern.gap * elementData.strokeWidth),
        ]
      : undefined,
    objectCaching: false,
  };
};

export default rectangleElementDataToFabricProps;
