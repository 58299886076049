const getPointPositionRotatedOnPoint = (
  pointX: number,
  pointY: number,
  rotationPointX: number,
  rotationPointY: number,
  rotation: number,
): [number, number] => {
  const radianAngle = (rotation * Math.PI) / 180;

  const x =
    (pointX - rotationPointX) * Math.cos(radianAngle) -
    (pointY - rotationPointY) * Math.sin(radianAngle) +
    rotationPointX;
  const y =
    (pointX - rotationPointX) * Math.sin(radianAngle) +
    (pointY - rotationPointY) * Math.cos(radianAngle) +
    rotationPointY;

  return [x, y];
};
export default getPointPositionRotatedOnPoint;
