import { VariantsState } from 'editor/src/store/variants/types';

const linkAllVariantsReducer = (state: VariantsState) => {
  state.variationGroups.forEach((group) => {
    if (!group.linked) {
      group.linked = true;
    }
  });
};

export default linkAllVariantsReducer;
