import {
  ProductVariation,
  MultiOptions,
  ProductControlMulti,
  ProductControlSingle,
} from 'editor/src/store/variants/types';

const extractOptionsFromVariations = (
  options: MultiOptions,
  variations: ProductVariation[],
  multiControls: ProductControlMulti[],
  singleControls: ProductControlSingle[],
  singleMode: boolean,
) => {
  variations.forEach((variation) => {
    multiControls.forEach(({ key, dependsOnSingleControls }) => {
      const value = variation[key];
      // hide when the value is undefined
      if (
        !options[key].find(
          (option) =>
            option.value === value &&
            (singleMode || option.dependedOptions.every((depOption) => depOption.value === variation[depOption.key])),
        )
      ) {
        options[key].push({
          value,
          dependedOptions:
            !singleMode && dependsOnSingleControls
              ? singleControls.map((singleControl) => ({
                  key: singleControl.key,
                  value: variation[singleControl.key],
                }))
              : [],
        });
      }
    });
  });
};

export default extractOptionsFromVariations;
