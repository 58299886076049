// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoomInIconBlock--ZtaE7{display:flex;width:100%;height:100%;justify-content:end;align-items:end}.zoomInIconBlock--ZtaE7 .zoomInIcon--B4vy7{display:flex;background-color:rgba(255,255,255,.8);height:36px;width:36px;padding:8px}.zoomInIconBlock--ZtaE7 .zoomInIcon--B4vy7 svg{height:25px;margin-top:-2px}`, "",{"version":3,"sources":["webpack://./src/component/ZoomInCartItemPreviewButton/index.module.scss"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CACA,eAAA,CAEA,2CACE,YAAA,CACA,qCAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CAEA,+CACE,WAAA,CACA,eAAA","sourcesContent":["@import 'editor/src/comonStyles/index';\n\n.zoomInIconBlock {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: end;\n  align-items: end;\n\n  .zoomInIcon {\n    display: flex;\n    background-color: rgba($white, 0.8);\n    height: 36px;\n    width: 36px;\n    padding: 8px;\n\n    svg {\n      height: 25px;\n      margin-top: -2px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zoomInIconBlock": `zoomInIconBlock--ZtaE7`,
	"zoomInIcon": `zoomInIcon--B4vy7`
};
export default ___CSS_LOADER_EXPORT___;
