import { GridDesign } from '@gelatoas/design-editor-calendar';

import { Spread } from 'editor/src/store/design/types';
import isMediaGrid from 'editor/src/store/design/util/isMediaGrid';

const getCurrentGridDesign = (gridDesigns: GridDesign[], spread: Spread | undefined) => {
  const gridElement = spread?.pages[0].groups.media?.find(isMediaGrid);
  const designName = gridElement?.grid.designName;
  if (designName) {
    const design = gridDesigns.find((grid) => grid.name === designName);
    if (design) {
      return design;
    }
  }

  return gridDesigns[0];
};

export default getCurrentGridDesign;
