import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaImage, MediaAddon, ElementAddress } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import { getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import PersonalisationCollection from './PersonalisationCollection';
import PersonalizationSimpleImage from './PersonalizationSimpleImage';

interface Props {
  elementId: number;
  uploadImage: (address: ElementAddress) => void;
  editImageCrop: (address: ElementAddress) => void;
  trackUserInteraction: boolean | undefined;
}

function PersonalizationImage({ elementId, editImageCrop, uploadImage, trackUserInteraction }: Props) {
  const { element, address } = useSelector(
    (state) => ({
      isSelected: state.editor.selectedElementUuids.includes(elementId),
      element: getMediaElementByUuid(state, elementId) as MediaImage | MediaAddon | undefined,
      address: getStructureIndexByElementUuidMemoized(state, elementId),
    }),
    shallowEqual,
  );

  const onEditImageCrop = useCallback(() => {
    if (address) {
      editImageCrop(address);
    }
  }, [address]);

  const onUploadImage = useCallback(() => {
    if (address) {
      uploadImage(address);
    }
  }, [address]);

  if (!element || !address || element.personalizationLocked) {
    return null;
  }

  const collectionInfo = getAdvPersoDataFromElement(element);
  if (collectionInfo && element.type === 'image') {
    return (
      <PersonalisationCollection
        image={element}
        address={address}
        collectionInfo={collectionInfo}
        trackUserInteraction={trackUserInteraction}
      />
    );
  }

  return <PersonalizationSimpleImage element={element} editImageCrop={onEditImageCrop} uploadImage={onUploadImage} />;
}

export default React.memo(PersonalizationImage);
