import cn from 'classnames';
import React from 'react';

import IconCriticalWarning from 'editor/src/component/Icon/IconCriticalWarning';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import IconWarning from 'editor/src/component/Icon/IconWarning';
import { PopupType } from 'editor/src/component/Popup/AbstractPopup/index';

import styles from './index.module.scss';

interface Props {
  type: PopupType;
  className?: string;
}

function PopupIcon({ type, className }: Props) {
  switch (type) {
    case PopupType.ERROR:
      return <IconCriticalWarning className={cn(styles.icon, className)} />;
    case PopupType.WARNING:
      return <IconWarning className={cn(styles.icon, className)} />;
    case PopupType.INFO:
      return <IconInfo className={cn(styles.icon, className)} />;
    case PopupType.SUCCESS:
      return <IconInfo className={cn(styles.icon, className)} />;
    default:
      return null;
  }
}

export default PopupIcon;
