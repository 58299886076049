import { PayloadAction } from '@reduxjs/toolkit';

import { Addon, AddonsState } from 'editor/src/store/editorModules/addons/types';

const addAddonInUseReducer = (state: AddonsState, action: PayloadAction<Addon>) => {
  if (!state.inUse.find((addon) => addon.id === action.payload.id)) {
    state.inUse.push(action.payload);
  }
};

export default addAddonInUseReducer;
