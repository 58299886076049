import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaImage } from 'editor/src/store/design/types';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import getScaleAndCenterImageUpdates from './getScaleAndCenterImageUpdates';

const applyFillImageToSpread = (element: MediaImage, area: Area): Partial<MediaImage> => {
  // we rotate the area instead of the element, get its bbox, and then fit the element in it
  const areaBbox = getRectangleBoundingBox(0, 0, area.width, area.height, element.r);
  const elementBbox = getRectangleBoundingBox(element.x, element.y, element.width, element.height, 0);
  const scale = Math.max(areaBbox.width / elementBbox.width, areaBbox.height / elementBbox.height);

  return getScaleAndCenterImageUpdates(element, scale, area);
};

export default applyFillImageToSpread;
