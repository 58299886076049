import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

import type { UploaderConfig } from 'shared/uploader';

const setUploaderConfigReducer = (state: EditorState, action: PayloadAction<UploaderConfig>) => {
  state.uploaderConfig = action.payload;
};

export default setUploaderConfigReducer;
