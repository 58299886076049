import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultButtonText: DefaultCtaButtonText;
}

// NOTE!!!: DefaultCtaButtonText type must be up to date the DefaultButtonText variable in the
// personalize.button.liquid file in the product-personalizer-shopify-app
export type DefaultCtaButtonText = 'Personalize design' | 'Back to shopping' | 'Add to Cart';

function CtaButtonLabel({ defaultButtonText }: Props) {
  const { t } = useTranslation();

  return <span>{t(defaultButtonText)}</span>;
}

export default React.memo(CtaButtonLabel);
