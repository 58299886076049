import { PayloadAction } from '@reduxjs/toolkit';

import { AddonsState } from 'editor/src/store/editorModules/addons/types';

const setRequestedVersionReducer = (state: AddonsState, action: PayloadAction<number>) => {
  if (state.data) {
    state.requestedVersion = action.payload;
  }
};

export default setRequestedVersionReducer;
