import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState, Settings } from 'editor/src/store/editor/types';

const setSettingsValueReducer = <K extends keyof Settings>(
  state: EditorState,
  action: PayloadAction<{ property: K; value: Settings[K] }>,
) => {
  const { property, value } = action.payload;
  state.settings[property] = value;
};

export default setSettingsValueReducer;
