// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonalizationView--yPYi6{width:100%;font-family:Roboto,Arial,sans-serif}.PersonalizationView--yPYi6 *{box-sizing:border-box}`, "",{"version":3,"sources":["webpack://./src/component/PersonalizationView/index.module.scss"],"names":[],"mappings":"AAEA,4BACE,UAAA,CACA,mCAAA,CAEA,8BACE,qBAAA","sourcesContent":["@import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';\n\n.PersonalizationView {\n  width: 100%;\n  font-family: Roboto, Arial, sans-serif;\n\n  * {\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PersonalizationView": `PersonalizationView--yPYi6`
};
export default ___CSS_LOADER_EXPORT___;
