import { FontStyles, TextAlign, TextDirection } from 'editor/src/store/fonts/types';

import {
  CANVAS_TO_SVG_BLUR,
  CANVAS_TO_SVG_OFFSET,
} from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/MediaShadow/ShadowControls/utils';
import { CANVAS_TO_SVG_STROKE } from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/TextOutline/TextOutlineControls/utils';

export function getDefaultFontPerLocale(locale: string) {
  switch (locale) {
    case 'ja_JP':
      return 'NotoSansJP-Regular';
    default:
      return 'OpenSans-Regular';
  }
}

const defaultFontStyles: FontStyles = {
  underline: false,
  linethrough: false,
  fontFamily: 'OpenSans-Regular',
  fontSize: 24,
  color: '#000000',
  lineHeight: 1,
  textAlign: TextAlign.center,
  direction: TextDirection.ltr,
  angle: 0,
  letterSpacing: 0,
  stroke: { width: 2 / CANVAS_TO_SVG_STROKE, color: '#FF0000' },
  shadow: {
    type: 'drop-shadow',
    blur: 20 * CANVAS_TO_SVG_BLUR,
    color: '#999999',
    // offset values is calculated dynamically by shadow controls. Be careful editing them
    offsetX: 22 / CANVAS_TO_SVG_OFFSET,
    offsetY: 22 / CANVAS_TO_SVG_OFFSET,
    scale: 0,
  },
  curve: 200,
  wrapping: undefined,
};

export default defaultFontStyles;
