import { fabric } from 'fabric';
import polygonClipping, { Polygon } from 'polygon-clipping';

import {
  arePolygonsEqual,
  polygonToFabricPolygon,
  rotatedRectToPolygon,
} from 'editor/src/component/EditorArea/utils/polygonsUtils';

import { ObjectRect } from './Image/types';

function getClipPath(
  eltBBox: ObjectRect,
  contentClipPolygons: Polygon[],
  forceClipping: boolean,
  extraClipPath?: fabric.Object,
) {
  const bboxPolygon = [rotatedRectToPolygon(eltBBox)];
  let clipPathPolygon = bboxPolygon;
  if (contentClipPolygons.length) {
    try {
      clipPathPolygon = polygonClipping.intersection(clipPathPolygon, contentClipPolygons);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('getClipPath: cannot do polygon intersection');
    }
  }

  // check if the elt bbox is the same after removing the visible area polygons. if it's the same, them we do not need any clipping
  if (!forceClipping && arePolygonsEqual(bboxPolygon, clipPathPolygon) && !extraClipPath) {
    return extraClipPath;
  }

  if (clipPathPolygon.length === 1 && !extraClipPath) {
    const polygon = polygonToFabricPolygon(clipPathPolygon[0], {
      absolutePositioned: true,
    });
    polygon.clipPath = extraClipPath;
    return polygon;
  }

  const group = new fabric.Group(
    clipPathPolygon.map((polygon) => polygonToFabricPolygon(polygon)),
    { absolutePositioned: true },
  );
  group.clipPath = extraClipPath;
  return group;
}

export default getClipPath;
