import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryState } from 'editor/src/store/gallery/types';

const removeImagesReducer = (state: GalleryState, action: PayloadAction<string[]>) => {
  action.payload.forEach((imageIdToRemove) => {
    const imageIndex = state.images.findIndex((image) => image.id === imageIdToRemove);
    if (imageIndex !== -1) {
      state.images.splice(imageIndex, 1);
    }
  });
};

export default removeImagesReducer;
