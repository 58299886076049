import { PayloadAction } from '@reduxjs/toolkit';

import { ProductSizeOption, VariantsState } from 'editor/src/store/variants/types';

const updateControlOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{
    controlKey: string;
    index: number;
    option: ProductSizeOption;
  }>,
) => {
  const { controlKey, index, option } = action.payload;

  const control = state.product.externalProductControls.find((control) => control.key === controlKey);
  if (control) {
    control.options[index] = option;
  }
};

export default updateControlOptionReducer;
