import rotatePointOnPoint, { Point } from 'editor/src/util/rotatePointOnPoint';

import type { PathCommand } from 'opentype.js';

export function addRectToPathCommands(x: number, y: number, width: number, height: number, commands: PathCommand[]) {
  commands.push(
    { type: 'M', x, y: y + height },
    { type: 'L', x, y },
    { type: 'L', x: x + width, y },
    { type: 'L', x: x + width, y: y + height },
    { type: 'Z' },
  );
}

export function getCommandWithRotation(cmd: PathCommand, angle: number, center: Point): PathCommand {
  let rotatedPoint: Point;
  let rotatedPoint1: Point;
  let rotatedPoint2: Point;
  switch (cmd.type) {
    case 'M':
    case 'L':
      rotatedPoint = rotatePointOnPoint(cmd, center, angle);
      return {
        type: cmd.type,
        x: rotatedPoint.x,
        y: rotatedPoint.y,
      };
    case 'C':
      rotatedPoint = rotatePointOnPoint({ x: cmd.x, y: cmd.y }, center, angle);
      rotatedPoint1 = rotatePointOnPoint({ x: cmd.x1, y: cmd.y1 }, center, angle);
      rotatedPoint2 = rotatePointOnPoint({ x: cmd.x2, y: cmd.y2 }, center, angle);
      return {
        type: cmd.type,
        x: rotatedPoint.x,
        y: rotatedPoint.y,
        x1: rotatedPoint1.x,
        y1: rotatedPoint1.y,
        x2: rotatedPoint2.x,
        y2: rotatedPoint2.y,
      };
    case 'Q':
      rotatedPoint = rotatePointOnPoint({ x: cmd.x, y: cmd.y }, center, angle);
      rotatedPoint1 = rotatePointOnPoint({ x: cmd.x1, y: cmd.y1 }, center, angle);
      return {
        type: cmd.type,
        x: rotatedPoint.x,
        y: rotatedPoint.y,
        x1: rotatedPoint1.x,
        y1: rotatedPoint1.y,
      };
    default:
      return cmd;
  }
}

export function getCommandWithOffsetAndRotation(
  cmd: PathCommand,
  offsetX: number,
  offsetY: number,
  angle: number,
  center?: Point,
): PathCommand {
  let rotatedCmd = cmd;
  if (angle && center) {
    rotatedCmd = getCommandWithRotation(cmd, angle, center);
  }

  switch (rotatedCmd.type) {
    case 'M':
    case 'L':
      return {
        type: rotatedCmd.type,
        x: rotatedCmd.x + offsetX,
        y: rotatedCmd.y + offsetY,
      };
    case 'C':
      return {
        type: rotatedCmd.type,
        x: rotatedCmd.x + offsetX,
        y: rotatedCmd.y + offsetY,
        x1: rotatedCmd.x1 + offsetX,
        y1: rotatedCmd.y1 + offsetY,
        x2: rotatedCmd.x2 + offsetX,
        y2: rotatedCmd.y2 + offsetY,
      };
    case 'Q':
      return {
        type: rotatedCmd.type,
        x: rotatedCmd.x + offsetX,
        y: rotatedCmd.y + offsetY,
        x1: rotatedCmd.x1 + offsetX,
        y1: rotatedCmd.y1 + offsetY,
      };
    default:
      return rotatedCmd;
  }
}

// see opentype.js Path.prototype.draw
export function drawCommands(ctx: CanvasRenderingContext2D, commands: PathCommand[], offsetX: number, offsetY: number) {
  for (let i = 0; i < commands.length; i += 1) {
    const cmd = commands[i];
    switch (cmd.type) {
      case 'M':
        ctx.moveTo(cmd.x + offsetX, cmd.y + offsetY);
        break;
      case 'L':
        ctx.lineTo(cmd.x + offsetX, cmd.y + offsetY);
        break;
      case 'C':
        ctx.bezierCurveTo(
          cmd.x1 + offsetX,
          cmd.y1 + offsetY,
          cmd.x2 + offsetX,
          cmd.y2 + offsetY,
          cmd.x + offsetX,
          cmd.y + offsetY,
        );
        break;
      case 'Q':
        ctx.quadraticCurveTo(cmd.x1 + offsetX, cmd.y1 + offsetY, cmd.x + offsetX, cmd.y + offsetY);
        break;
      case 'Z':
        ctx.closePath();
        break;
      default:
        break;
    }
  }
}
