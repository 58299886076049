import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setShowCalendarEventsFlagReducer = (state: DesignState, action: PayloadAction<boolean>) => {
  if (!state.designData) {
    return;
  }

  if (!state.designData.calendar) {
    state.designData.calendar = {};
  }

  state.designData.calendar.showEvents = action.payload;

  // turn off the personalization if the calendar events should be shown at all
  if (!action.payload) {
    state.designData.calendar.allowPersonalization = false;
    state.designData.calendar.autoApplyPublicHolidays = false;
  }
};

export default setShowCalendarEventsFlagReducer;
