import { Content, Rect } from 'editor/src/store/design/types';

import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';

import { Area } from './getNewElementDisplay';

function getFocusArea(contentElements: Rect[], content?: Content | undefined): Area {
  if (content) {
    return {
      left: content.x,
      top: content.y,
      width: content.width,
      height: content.height,
    };
  }

  const bbox = getBoxesMinMax(contentElements);

  return {
    left: bbox.left,
    top: bbox.top,
    width: bbox.width,
    height: bbox.height,
  };
}

export default getFocusArea;
