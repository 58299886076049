import { RootState } from 'editor/src/store/index';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import getPlugin from './getPlugin';

const isPluginEnabled = (state: RootState, pluginName: PluginName) => {
  const plugin = getPlugin(state, pluginName);
  return !!plugin && plugin.state === PluginState.ENABLED;
};

export default isPluginEnabled;
