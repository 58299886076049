import { ControlVisibility } from 'editor/src/component/EditorArea/fabricComponents/useControlVisibility';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';

export const GHOST_CONTROL_VISIBILITY: ControlVisibility = {
  mt: false,
  mr: false,
  mb: false,
  ml: false,
};

export const LOCKED_CONTROL_VISIBILITY: ControlVisibility = {
  mtr: false,
  tl: false,
  mt: false,
  tr: false,
  mr: false,
  br: false,
  mb: false,
  bl: false,
  ml: false,
};

export const UNLOCKED_CONTROL_VISIBILITY: ControlVisibility = {
  mtr: true,
  tl: true,
  mt: true,
  tr: true,
  mr: true,
  br: true,
  mb: true,
  bl: true,
  ml: true,
};

export const GHOST_PROPS: Partial<fabric.IObjectOptions> = {
  opacity: 0.4,
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,
  borderColor: ELEMENT_FRAME_COLOR,
  cornerColor: ELEMENT_FRAME_COLOR,
  cornerStrokeColor: ELEMENT_FRAME_COLOR,
  borderOpacityWhenMoving: 0.4,
  transparentCorners: false,
};

export const FRAME_PROPS: Partial<fabric.IObjectOptions> = {
  scaleX: 1,
  scaleY: 1,
  fill: '',
  borderColor: ELEMENT_FRAME_COLOR,
  cornerColor: '#FFFFFF',
  cornerStrokeColor: ELEMENT_FRAME_COLOR,
  transparentCorners: false,
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,
  strokeWidth: 0, // important or obj.getScaledWith() is 1px too big
};

export const FRAME_PROPS_MEDIUM_WARNING: Partial<fabric.IObjectOptions> = {
  ...FRAME_PROPS,
  borderColor: '#D48C06',
  cornerStrokeColor: '#D48C06',
};

export const FRAME_PROPS_HIGH_WARNING: Partial<fabric.IObjectOptions> = {
  ...FRAME_PROPS,
  borderColor: '#FD5F5C',
  cornerStrokeColor: '#FD5F5C',
};

export const GHOST_PROPS_MEDIUM_WARNING: Partial<fabric.IObjectOptions> = {
  ...GHOST_PROPS,
  borderColor: '#D48C06',
  cornerStrokeColor: '#D48C06',
  cornerColor: '#D48C06',
};

export const GHOST_PROPS_HIGH_WARNING: Partial<fabric.IObjectOptions> = {
  ...GHOST_PROPS,
  borderColor: '#FD5F5C',
  cornerStrokeColor: '#FD5F5C',
  cornerColor: '#FD5F5C',
};
