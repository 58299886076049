import { Polygon } from 'polygon-clipping';
import React, { Suspense, useMemo } from 'react';

import { Coords, ElementAddress, MediaElement } from 'editor/src/store/design/types';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import { NO_CANVAS_ROTATION } from 'editor/src/component/EditorArea/useCanvasRotation';

const TextElement = React.lazy(retryPromiseFn(() => import('./TextElement')));
const ImageElement = React.lazy(retryPromiseFn(() => import('./ImageElement')));
const RectangleElement = React.lazy(retryPromiseFn(() => import('./RectangleElement')));
const LineElement = React.lazy(retryPromiseFn(() => import('./LineElement')));
const CalendarGridElement = React.lazy(retryPromiseFn(() => import('./CalendarGridElement')));

interface Props {
  element: MediaElement;
  elementIndex: number;
  spreadIndex: number;
  pageIndex: number;
  pageCoords: Coords;
  contentClipPolygons: Polygon[];
  contentClipPath: fabric.Object | undefined;
  areaWidth: number;
}

function MediaElementComponent({
  element,
  spreadIndex,
  pageIndex,
  elementIndex,
  pageCoords,
  contentClipPolygons,
  contentClipPath,
  areaWidth,
}: Props) {
  const elementAddress: ElementAddress = useMemo(
    () => ({ spreadIndex, pageIndex, elementIndex }),
    [spreadIndex, pageIndex, elementIndex],
  );

  switch (element.type) {
    case 'addon':
    case 'image': {
      return (
        <Suspense fallback="">
          <ImageElement
            element={element}
            elementAddress={elementAddress}
            pageCoords={pageCoords}
            contentClipPolygons={contentClipPolygons}
            contentClipPath={contentClipPath}
          />
        </Suspense>
      );
    }
    case 'text':
      return (
        <Suspense fallback="">
          <TextElement
            element={element}
            elementAddress={elementAddress}
            pageCoords={pageCoords}
            contentClipPolygons={contentClipPolygons}
            contentClipPath={contentClipPath}
            areaWidth={areaWidth}
          />
        </Suspense>
      );
    case 'grid':
      return (
        <Suspense fallback="">
          <CalendarGridElement
            element={element}
            elementAddress={elementAddress}
            pageCoords={pageCoords}
            contentClipPolygons={contentClipPolygons}
          />
        </Suspense>
      );
    case 'line':
      return (
        <LineElement
          element={element}
          pageCoords={pageCoords}
          canvasRotation={NO_CANVAS_ROTATION}
          elementAddress={elementAddress}
          contentClipPolygons={contentClipPolygons}
          contentClipPath={contentClipPath}
        />
      );
    case 'rectangle':
      return (
        <RectangleElement
          element={element}
          pageCoords={pageCoords}
          elementAddress={elementAddress}
          contentClipPolygons={contentClipPolygons}
          contentClipPath={contentClipPath}
          canvasRotation={NO_CANVAS_ROTATION}
        />
      );
    default:
      return null;
  }
}

export default MediaElementComponent;
