import { PayloadAction } from '@reduxjs/toolkit';

import {
  MockupState,
  MockupSize,
  MockupCategory,
  SceneCategory,
  MockupProductCategory,
} from 'editor/src/store/mockup/types';

const setScenePlaceholderMockDataReducer = (
  state: MockupState,
  action: PayloadAction<{
    scenesCategories: SceneCategory[];
    sizes: MockupSize[];
    categories: MockupCategory[];
    productCategories: MockupProductCategory[];
  }>,
) => {
  const { scenesCategories, sizes, categories, productCategories } = action.payload;
  state.scenes.categories = scenesCategories;
  state.mockup.sizes = sizes;
  state.mockup.categories = categories;
  state.scenePlaceholder.productCategories = productCategories;
};

export default setScenePlaceholderMockDataReducer;
