import React, { useCallback, useMemo } from 'react';

import { CollectionFolder } from 'editor/src/store/collections/types';
import { MediaImage } from 'editor/src/store/design/types';

import PersonalisationDropDown from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalisationDropDown';

interface Props {
  image: MediaImage;
  folder: CollectionFolder;
  selectAsset: (assetId: string, url: string) => void;
}

function PersonalisationCollectionDropdown({ image, folder, selectAsset }: Props) {
  const activeOptionIndex = folder.assets.findIndex((asset) => asset.id === image.imageId);
  const options = useMemo(
    () =>
      folder.assets.map((asset) => ({
        imageUrl: asset.url,
        label: asset.name,
      })),
    [folder.assets],
  );

  const onSelect = useCallback(
    (index: number) => {
      const asset = folder.assets[index];
      selectAsset(asset.id, asset.url);
    },
    [folder.assets],
  );

  return (
    <PersonalisationDropDown
      className="cy-collection-dropdown"
      options={options}
      activeOptionIndex={activeOptionIndex}
      selectOption={onSelect}
    />
  );
}
export default React.memo(PersonalisationCollectionDropdown);
