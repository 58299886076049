// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon--SSazR{margin-right:10px}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Popup/AbstractPopup/PopupIcon/index.module.scss"],"names":[],"mappings":"AAAA,aACE,iBAAA","sourcesContent":[".icon {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon--SSazR`
};
export default ___CSS_LOADER_EXPORT___;
