import { PayloadAction } from '@reduxjs/toolkit';

import { AddonsState } from 'editor/src/store/editorModules/addons/types';

const setSearchValueReducer = (state: AddonsState, action: PayloadAction<string>) => {
  if (state.data) {
    state.data.search.value = action.payload;
  }
};

export default setSearchValueReducer;
