import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

const removeConditionOptionReducer = (
  state: DesignState,
  action: PayloadAction<{ address: ConditionAddress; optionId: string }>,
) => {
  const { address, optionId } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  if (condition.options.length < 3) {
    // we only allow condition with at least 2 options
    return;
  }

  condition.options = condition.options.filter((option) => option.id !== optionId);
  const removedChildren = conditionGroup.children[getConditionChildrenKey(conditionId, optionId)];
  removedChildren.forEach((child) => {
    const element = state.designData?.spreads[spreadIndex].pages[0].groups.media?.find(
      (element) => element.uuid === child.id,
    );
    if (element && !element.personalizationLocked) {
      conditionGroup.children[conditionGroupChildenKey].push(child);
    }
  });

  delete conditionGroup.children[getConditionChildrenKey(conditionId, optionId)];
  if (condition.activeOptionId === optionId) {
    condition.activeOptionId = condition.options[0].id;
  }
};

export default removeConditionOptionReducer;
