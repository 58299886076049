import { DropShadow } from 'editor/src/store/design/types';

const defaultImageStyles: { shadow: DropShadow } = {
  shadow: {
    type: 'drop-shadow',
    blur: 0.2,
    color: 'rgba(0, 0, 0, 0.4)',
    offsetX: 22,
    offsetY: 22,
    scale: 0,
    absolute_direction: true,
  },
};

export default defaultImageStyles;
