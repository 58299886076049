import { PayloadAction } from '@reduxjs/toolkit';

import { DesignOptionGroup, VariantsState } from 'editor/src/store/variants/types';

import { ColorReplacement } from '../../design/operation/getAssetUrlWithReplacedColors';
import { ColorOverrides, DesignData } from '../../design/types';

function areColorMatching(replacement: ColorReplacement['fill'], overrides: ColorOverrides[]): boolean {
  for (let i = 0; i < replacement.fill.length; i += 1) {
    if (replacement[i]?.from !== overrides[i]?.previous || replacement[i]?.to !== overrides[i]?.new) {
      return false;
    }
  }

  return true;
}

export function updateDesign(designData: DesignData, assetId: string, url: string, colors: ColorReplacement) {
  designData.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      if (
        element.type === 'image' &&
        element.imageId === assetId &&
        areColorMatching(colors.fill, element.colorOverrides?.fill || []) &&
        areColorMatching(colors.stroke, element.colorOverrides?.stroke || [])
      ) {
        element.url = url;
        element.colorOverrides = {
          ...element.colorOverrides,
          url,
          urlTimestamp: Date.now(),
        };
      }
    });
  });
}

const updateRecoloredElementsReducer = (
  state: VariantsState,
  action: PayloadAction<{
    assetId: string;
    url: string;
    colors: ColorReplacement;
    variantKey: string | undefined;
    designOptionKey: string | undefined;
    variationGroupsFromDesignOptions: DesignOptionGroup[] | undefined;
  }>,
) => {
  const { assetId, url, colors, variantKey, designOptionKey, variationGroupsFromDesignOptions } = action.payload;
  const requestedVariationGroup = state.variationGroups.find((variationGroup) => variationGroup.key === variantKey);
  const variationGroups =
    designOptionKey && variationGroupsFromDesignOptions
      ? variationGroupsFromDesignOptions.find((group) => group.key === designOptionKey)?.value ?? []
      : state.variationGroups;
  const filteredVariationGroups = state.variationGroups.filter(
    (variationGroup) => !!variationGroups.find((group) => group.key === variationGroup.key),
  );
  filteredVariationGroups.forEach((variationGroup) => {
    if (
      (!requestedVariationGroup?.linked && requestedVariationGroup?.key === variationGroup.key) ||
      (requestedVariationGroup?.linked && variationGroup.linked)
    ) {
      variationGroup.variationsInfo.forEach((variationInfo) => {
        if (variationInfo.designData) {
          updateDesign(variationInfo.designData, assetId, url, colors);
        }
      });
    }
  });
};

export default updateRecoloredElementsReducer;
