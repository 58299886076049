// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.confirmationPopup--dipol{z-index:1000001}div.confirmationPopupFadeMask--a_shy{display:block;z-index:1000000}`, "",{"version":3,"sources":["webpack://./src/component/PersonalizerConfirmationPopup/index.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CAGF,qCACE,aAAA,CACA,eAAA","sourcesContent":["div.confirmationPopup {\n  z-index: 1000001;\n}\n\ndiv.confirmationPopupFadeMask {\n  display: block;\n  z-index: 1000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationPopup": `confirmationPopup--dipol`,
	"confirmationPopupFadeMask": `confirmationPopupFadeMask--a_shy`
};
export default ___CSS_LOADER_EXPORT___;
