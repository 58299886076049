import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, MediaImage } from 'editor/src/store/design/types';

const clearImageElementsByImageIdReducer = (state: DesignState, action: PayloadAction<string>) => {
  const imageId = action.payload;

  state.designData?.spreads.forEach((spread) => {
    spread.pages.forEach((page) => {
      const element = page.groups.media?.find((element) => element.type === 'image' && element.imageId === imageId) as
        | MediaImage
        | undefined;
      if (element) {
        element.imageId = '';
      }
    });
  });
};

export default clearImageElementsByImageIdReducer;
