import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import removeImagesPerImageIdOperation from 'editor/src/store/design/operation/removeImagesPerImageIdOperation';
import { updateImageAction } from 'editor/src/store/gallery/slice';
import { ImageState } from 'editor/src/store/gallery/types';
import { logUpload } from 'editor/src/store/gallery/utils/uploadLogging';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import resetSessionImageOperation from './resetSessionImageOperation';

const updateImageOnFailedUpload = (uppyId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  logUpload(uppyId, Date.now(), 'processing-failed');

  batch(() => {
    dispatch(updateImageAction({ state: ImageState.FAILED, id: uppyId }));
    const state = getState();
    const uploadingImagesCount = state.gallery.images.filter(
      (image) =>
        state.gallery.sessionImages.includes(image.id) &&
        (image.state === ImageState.LOCAL_PREVIEW || image.state === ImageState.REMOTE_PREVIEW),
    ).length;
    if (uploadingImagesCount === 0) {
      dispatch(resetSessionImageOperation());
    }
    dispatch(removeImagesPerImageIdOperation(uppyId));
  });
};

export default updateImageOnFailedUpload;
