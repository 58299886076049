import { removeImageFromHistoryAction } from 'editor/src/store/editorModules/undoRedo/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import getDeleteFrameWithImage from 'editor/src/store/hostSettings/selector/getDeleteFrameWithImage';
import { RootState } from 'editor/src/store/index';

const removeHistoryImageOperation = (imageId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const deleteFrameWithImage = getDeleteFrameWithImage(state);

  dispatch(removeImageFromHistoryAction({ imageId, deleteFrameWithImage }));
};

export default removeHistoryImageOperation;
