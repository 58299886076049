import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { IMAGE_CORRUPTED, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { Thunk } from 'editor/src/store/hooks';

import applyImageToElementOperation from './applyImageToElementOperation';
import updateMediaElementByUuidOperation from './updateMediaElementByUuidOperation';

const updateImageIdByUuidOperation =
  (uuid: number, imageId: string, saveUndoRedoStep = false): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const element = getMediaElementByUuid(state, uuid) as MediaImage | undefined;
    if (!element) {
      return;
    }

    if (imageId === IMAGE_CORRUPTED) {
      dispatch(updateMediaElementByUuidOperation(uuid, { imageId, shadow: undefined }));
      return;
    }

    const galleryImage = getImageById(state, imageId);
    if (!galleryImage) {
      return;
    }

    if (saveUndoRedoStep) {
      dispatch(saveUndoRedoStateOperation('Replace image in frame'));
    }

    dispatch(
      applyImageToElementOperation(element, imageId, {
        width: galleryImage.width,
        height: galleryImage.height,
      }),
    );
  };

export default updateImageIdByUuidOperation;
