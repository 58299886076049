import React from 'react';
import { useTranslation } from 'react-i18next';

import IconEdit from '../IconEdit';

import styles from './index.module.scss';

function EditCartItem() {
  const { t } = useTranslation();

  return (
    <button type="button" className={styles.button}>
      <IconEdit />
      <span>{t('editor-edit')}</span>
    </button>
  );
}

export default React.memo(EditCartItem);
