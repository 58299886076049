import { createSlice } from '@reduxjs/toolkit';

import setFormatsDataReducer from './reducer/setFormatsDataReducer';
import { FormatsState } from './types';

export const formatsInitialState: FormatsState = {
  selectors: [],
};

export const slice = createSlice({
  name: 'formats',
  initialState: formatsInitialState,
  reducers: {
    setFormatsDataAction: setFormatsDataReducer,
  },
});

export const { setFormatsDataAction } = slice.actions;

const formatsReducer = slice.reducer;

export default formatsReducer;
