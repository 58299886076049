import { PayloadAction } from '@reduxjs/toolkit';

import { MockupState, ProductSceneCategory } from 'editor/src/store/mockup/types';

const setProductScenesReducer = (
  state: MockupState,
  action: PayloadAction<{
    productUid: string;
    categories: ProductSceneCategory[];
  }>,
) => {
  const { categories, productUid } = action.payload;
  const { scenes, sceneCategories } = state.scenePlaceholder;
  categories.forEach((sceneCategory) => {
    sceneCategory.scenes.forEach((scene) => {
      const id = productUid + scene.id;
      scenes[id] = scene;
      state.productPlaceholder.productImages[id] = scene.productImage;
    });
  });

  sceneCategories[productUid] = categories;
};

export default setProductScenesReducer;
