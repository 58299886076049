import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import { ExistingVariant } from 'editor/src/store/variants/types';

function getInitialPageCount(defaultPageCount: number | undefined, existingVariants: ExistingVariant[]) {
  const variant = existingVariants[0];
  if (variant && variant.designData && variant.designData.page_count && variant.designData.page_count_limit) {
    return variant.designData.page_count;
  }

  return defaultPageCount ?? UNSELECTED_PAGE_COUNT;
}

export default getInitialPageCount;
