/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconShippingCar({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M6 20.5C5.16667 20.5 4.45833 20.2083 3.875 19.625C3.29167 19.0417 3 18.3333 3 17.5H2C1.71667 17.5 1.47917 17.4042 1.2875 17.2125C1.09583 17.0208 1 16.7833 1 16.5V6.5C1 5.95 1.19583 5.47917 1.5875 5.0875C1.97917 4.69583 2.45 4.5 3 4.5H15C15.55 4.5 16.0208 4.69583 16.4125 5.0875C16.8042 5.47917 17 5.95 17 6.5V8.5H19C19.3167 8.5 19.6167 8.57083 19.9 8.7125C20.1833 8.85417 20.4167 9.05 20.6 9.3L22.8 12.225C22.8667 12.3083 22.9167 12.4 22.95 12.5C22.9833 12.6 23 12.7083 23 12.825V16.5C23 16.7833 22.9042 17.0208 22.7125 17.2125C22.5208 17.4042 22.2833 17.5 22 17.5H21C21 18.3333 20.7083 19.0417 20.125 19.625C19.5417 20.2083 18.8333 20.5 18 20.5C17.1667 20.5 16.4583 20.2083 15.875 19.625C15.2917 19.0417 15 18.3333 15 17.5H9C9 18.3333 8.70833 19.0417 8.125 19.625C7.54167 20.2083 6.83333 20.5 6 20.5ZM6 18.5C6.28333 18.5 6.52083 18.4042 6.7125 18.2125C6.90417 18.0208 7 17.7833 7 17.5C7 17.2167 6.90417 16.9792 6.7125 16.7875C6.52083 16.5958 6.28333 16.5 6 16.5C5.71667 16.5 5.47917 16.5958 5.2875 16.7875C5.09583 16.9792 5 17.2167 5 17.5C5 17.7833 5.09583 18.0208 5.2875 18.2125C5.47917 18.4042 5.71667 18.5 6 18.5ZM3 15.5H3.8C4.08333 15.2 4.40833 14.9583 4.775 14.775C5.14167 14.5917 5.55 14.5 6 14.5C6.45 14.5 6.85833 14.5917 7.225 14.775C7.59167 14.9583 7.91667 15.2 8.2 15.5H15V6.5H3V15.5ZM18 18.5C18.2833 18.5 18.5208 18.4042 18.7125 18.2125C18.9042 18.0208 19 17.7833 19 17.5C19 17.2167 18.9042 16.9792 18.7125 16.7875C18.5208 16.5958 18.2833 16.5 18 16.5C17.7167 16.5 17.4792 16.5958 17.2875 16.7875C17.0958 16.9792 17 17.2167 17 17.5C17 17.7833 17.0958 18.0208 17.2875 18.2125C17.4792 18.4042 17.7167 18.5 18 18.5ZM17 13.5H21.25L19 10.5H17V13.5Z"
          fill="#383838"
        />
      </svg>
    </Icon>
  );
}

export default IconShippingCar;
