import { captureException } from '@sentry/react';

import { sendUpdateDraftRequest } from 'product-personalizer/src/draftAPI';
import { fetchDesignFromId } from 'product-personalizer/src/utils/loadData';

import type { DraftState } from '../slice';

export const DRAFT_KEY = 'gelato-drafts';

export function getStoredDrafts(): DraftState['drafts'] {
  try {
    const localDrafts = window.localStorage.getItem(DRAFT_KEY);
    if (localDrafts) {
      return JSON.parse(localDrafts);
    }
  } catch (e) {
    captureException(e);
  }
  return [];
}

export function updateStoredDrafts(newDrafts: DraftState['drafts']) {
  window.localStorage.setItem(DRAFT_KEY, JSON.stringify(newDrafts));
}

export function clearStoredDrafts(): void {
  window.localStorage.removeItem(DRAFT_KEY);
}

export async function updateLocalDraftsWithUserId(localDrafts: DraftState['drafts'], customerReferenceId: string) {
  let notMigratedLocalDrafts = localDrafts;
  // eslint-disable-next-line no-restricted-syntax
  for (const localDraft of localDrafts) {
    // eslint-disable-next-line no-await-in-loop
    const design = await fetchDesignFromId(localDraft.designId);
    // eslint-disable-next-line no-await-in-loop
    await sendUpdateDraftRequest(
      localDraft.designId,
      { externalVariantId: localDraft.externalVariantId, variantId: localDraft.variantId },
      customerReferenceId,
      design.structure,
    );

    // remove item from local storage. Don't clear all at once to proceed if user closed the tab and interrupted migration
    notMigratedLocalDrafts = notMigratedLocalDrafts.filter((draftItem) => draftItem.designId !== localDraft.designId);
    updateStoredDrafts(notMigratedLocalDrafts);
  }

  clearStoredDrafts();
}
