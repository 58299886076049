import setCustomerDeviceTypeOperation from 'editor/src/store/app/operation/setCustomerDeviceTypeOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setCanvasSizeAction } from 'editor/src/store/canvas/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';

const setCanvasSizeOperation = (canvasWidth: number, canvasHeight: number) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setCanvasSizeAction({ width: canvasWidth, height: canvasHeight }));
    dispatch(setCustomerDeviceTypeOperation(getCustomerDeviceType()));
  });
};

export default setCanvasSizeOperation;
