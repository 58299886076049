import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData, DesignState } from 'editor/src/store/design/types';

import { PluginName } from '../../plugins/types';
import { personalizationPluginNames } from '../../variants/operation/updateProductPersonalizationOperation';

const setDesignDataReducer = (state: DesignState, action: PayloadAction<DesignData | undefined>) => {
  state.designData = action.payload;
  state.globalPlugins =
    action.payload?.plugins?.filter((plugin) => personalizationPluginNames.has(plugin as PluginName)) || [];
};

export default setDesignDataReducer;
