import { PayloadAction } from '@reduxjs/toolkit';

import { isSystemTab, SidebarProps, SidebarState } from 'editor/src/store/editorModules/sidebar/types';

const setSidebarActiveTabReducer = (state: SidebarState, action: PayloadAction<SidebarProps>) => {
  state.activeTabIndex = action.payload.index;
  state.payload = action.payload.payload ?? undefined;

  const activeTab = state.tabs[state.activeTabIndex];
  if (!isSystemTab(activeTab)) {
    state.lastNonSystemTabIndex = action.payload.index;
  }
};

export default setSidebarActiveTabReducer;
