import { useEffect } from 'react';

import { CollectionFolder } from 'editor/src/store/collections/types';
import { useSelector } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const fetching = new Set<string>();

function useCollectionFolder(folderId: string): CollectionFolder | undefined {
  const folder = useSelector((state) => state.collections.folders[folderId]);

  useEffect(() => {
    if (!folder && !fetching.has(folderId)) {
      fetching.add(folderId);
      sendPostMessage('advancedPersonalisation.getFolderContent', folderId);
    }

    if (folder && fetching.has(folder.id)) {
      fetching.delete(folder.id);
    }
  }, [folder, folderId]);

  return folder;
}

export default useCollectionFolder;
