import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { updateConditionActiveOptionAction } from 'editor/src/store/design/slice';
import { ConditionAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const updateConditionActiveOptionOperation =
  (address: ConditionAddress, optionId: string) => (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(updateConditionActiveOptionAction({ address, optionId }));
      dispatch(updateHiddenElementsOperation());
    });
  };

export default updateConditionActiveOptionOperation;
