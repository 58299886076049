import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { setMobileMenuAction } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { useBottomBarHeight } from '../EditorArea/EditorAreaControls/BottomControls/BottomBarHeightProvider';

import MobileMenu from './MobileMenu';
import { CONTAINER_ID } from './MobileMenuContainer';

const MOBILE_MENU_HEIGHT = 75;

function useMobileMenu(addBackdrop = true, forceHalfScreen?: boolean, flexLayout?: boolean) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const { bottomBarHeight } = useBottomBarHeight();
  const maxHeight = useSelector((state) =>
    Math.round((state.canvas.height + bottomBarHeight + MOBILE_MENU_HEIGHT) * 0.5),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forceHalfScreen) {
      return undefined;
    }

    const handle = window.setTimeout(() => {
      dispatch(setMobileMenuAction({ isOpen }));
    }, 0);

    return () => {
      window.clearTimeout(handle);
    };
  }, [isOpen]);

  const container = document.getElementById(CONTAINER_ID);
  const render = useCallback(
    (children: React.ReactNode) =>
      container &&
      ReactDOM.createPortal(
        <MobileMenu
          isOpen={isOpen}
          close={close}
          addBackdrop={addBackdrop}
          isHidden={isHidden}
          maxHeight={forceHalfScreen ? maxHeight : undefined}
          flexLayout={flexLayout}
        >
          {children}
        </MobileMenu>,
        container,
      ),
    [isOpen, container, isHidden, addBackdrop, flexLayout],
  );

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((value) => !value), []);

  return { render, open, close, toggle, hide: setIsHidden };
}

export default useMobileMenu;
