import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getDigitizedAsset from 'editor/src/store/design/selector/getDigitizedAsset';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { resetDigitizedAssetAction } from 'editor/src/store/design/slice';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import isSampleFlagChangeAllowed from 'editor/src/store/editor/selector/isSampleFlagChangeAllowed';
import getAddonById from 'editor/src/store/editorModules/addons/selector/getAddonById';
import getAddonByIdFromCurrent from 'editor/src/store/editorModules/addons/selector/getAddonByIdFromCurrent';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { Thunk } from 'editor/src/store/hooks';
import { PluginEventName } from 'editor/src/store/plugins/types';
import dispatchPluginOperations from 'editor/src/store/plugins/utils/dispatchPluginOperations';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';
import { canUseFiltersOnImageType } from 'editor/src/util/plugins/canUseFiltersOnImage';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import { isSupportedImageTypeForShadow } from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/MediaShadow/ShadowControls/utils';

import updateMediaElementByUuidOperation from './updateMediaElementByUuidOperation';

const checkToResizeProductOperationModule = retryPromiseFn(
  () => import('editor/src/store/design/operation/checkToResizeProductOperation'),
);

const applyImageToElementOperation =
  (element: MediaImage, imageId: string, dimensions: Dimensions, doNotUpdateLinked = false, isGraphic?: true): Thunk =>
  (dispatch, getState, { i18n }) => {
    const { px, py, pw, ph } = getCenteredImagePosition(element, dimensions);
    const elementUpdate: Partial<MediaImage> = {};
    elementUpdate.px = imageId ? px : 0;
    elementUpdate.py = imageId ? py : 0;
    elementUpdate.pw = imageId ? pw : element.width;
    elementUpdate.ph = imageId ? ph : element.height;
    elementUpdate.pr = 0;
    elementUpdate.imageId = imageId;

    const state = getState();
    if (!isSampleFlagChangeAllowed(state) && element.sample) {
      elementUpdate.sample = undefined;
    }

    let image: GalleryImage | Addon | undefined;
    if (isGraphic) {
      image = getAddonByIdFromCurrent(state, imageId) || getAddonById(state, imageId);
      elementUpdate.url = image?.preview;
      elementUpdate.shadow = undefined;
    } else {
      image = getImageById(state, imageId);
      elementUpdate.url = image?.url;

      if (!canUseFiltersOnImageType(image?.type)) {
        // reset filters if not supported by the new image
        elementUpdate.adjustments = undefined;
        elementUpdate.filters = undefined;
      }

      // reset shadow effect if image was replaced
      if (element.imageId !== imageId && image && !isSupportedImageTypeForShadow(image.type)) {
        elementUpdate.shadow = undefined;
      }
    }

    const previousImage = getImageById(state, element.imageId);
    const digitizedAsset = getDigitizedAsset(state, element);
    // if the image was empty or if the element name was the image name, then we rename it
    if (!previousImage || previousImage.name === element.name) {
      elementUpdate.name = image?.name || `${i18n.t('editor-image')} ${element.uuid}`;
    }

    // this is case when replacing image and we want te be able to get original image url and disable color overrides
    if (element.colorOverrides) {
      elementUpdate.colorOverrides = undefined;
    }

    if (element.perspective_transform && !imageId) {
      elementUpdate.perspective_transform = undefined;
    }

    batch(() => {
      dispatchPluginOperations(PluginEventName.BeforeElementAssetChanged, dispatch, [element.uuid, elementUpdate]);

      if (digitizedAsset && element.digitizing_hash) {
        dispatch(resetDigitizedAssetAction({ elementId: element.digitizing_hash }));
      }
      dispatch(updateMediaElementByUuidOperation(element.uuid, elementUpdate));

      if (element.linkId && !doNotUpdateLinked) {
        getSpread(state, state.editor.currentSpreadIndex)?.pages[0].groups.media?.forEach((media) => {
          if (
            media.type === 'image' &&
            media.linkId === element.linkId &&
            media.uuid !== element.uuid &&
            media.imageId !== imageId
          ) {
            dispatch(applyImageToElementOperation(media, imageId, dimensions, true));
          }
        });
      }

      if (state.hostSettings.resizeProductBasedOnFirstElement) {
        void checkToResizeProductOperationModule().then((checkToResizeProductOperation) => {
          dispatch(checkToResizeProductOperation.default());
        });
      }

      if (!imageId) {
        sendPostMessage('log.removeImageFromFrame', undefined);
        sendPostMessage('log.imageRemoved', element);
      } else if (element.imageId) {
        sendPostMessage('log.replaceImageInFrame', undefined);
        const newImage = getMediaElementByUuid(getState(), element.uuid) as MediaImage;
        sendPostMessage('log.imageReplaced', {
          previous: element,
          next: newImage,
        });
      } else {
        sendPostMessage('log.addImageToFrame', undefined);
        const newImage = getMediaElementByUuid(getState(), element.uuid) as MediaImage;
        sendPostMessage('log.imageAdded', newImage);
      }
    });
  };

export default applyImageToElementOperation;
