import { PayloadAction } from '@reduxjs/toolkit';

import { CustomTabInfo, SidebarState, TAB_NAMES, isNotDefaultTab } from 'editor/src/store/editorModules/sidebar/types';

import { isTabManagedByPlugin } from '../pluginsTabs';

const setBaseTabsReducer = (state: SidebarState, action: PayloadAction<Array<TAB_NAMES | CustomTabInfo>>) => {
  state.requestedBaseTabs = action.payload;
  state.baseTabs = action.payload.filter((tab) => isNotDefaultTab(tab) || !isTabManagedByPlugin(tab));
};

export default setBaseTabsReducer;
