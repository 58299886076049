import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { addMediaElementAction } from 'editor/src/store/design/slice';
import { MediaElement } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import hasPersonalizationPluginEnabled from 'editor/src/store/plugins/selector/hasPersonalizationPluginEnabled';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import isElementWithSpotFinishingType from 'editor/src/util/design/isElementWithSpotFinishingType';

import addElementToConditionGroupOperation from './addElementToConditionGroupOperation';

const addMediaElementOperation =
  (spreadIndex: number, pageIndex: number, element: MediaElement, saveStep = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      if (saveStep) {
        dispatch(saveUndoRedoStateOperation('Element add'));
      }
      let state = getState();
      const { focusedContentAddress } = state.editor;
      if (focusedContentAddress) {
        const { pageIndex, contentIndex } = focusedContentAddress;
        element.belongs_to = { type: 'content', pageIndex, contentIndex };
      }

      if (isEmbedded) {
        element.createdWhileEmbedded = true;
      }

      const media = state.design.designData?.spreads[spreadIndex].pages[pageIndex].groups.media || [];
      const firstElementWithSpotFinishingTypeIndex = media.findIndex(isElementWithSpotFinishingType);
      dispatch(
        addMediaElementAction({
          spreadIndex,
          pageIndex,
          element,
          elementIndex:
            firstElementWithSpotFinishingTypeIndex === -1 ? undefined : firstElementWithSpotFinishingTypeIndex,
        }),
      );

      state = getState();
      if (!element.personalizationLocked && hasPersonalizationPluginEnabled(state)) {
        dispatch(addElementToConditionGroupOperation(element));
      }
    });
  };

export default addMediaElementOperation;
