import { PayloadAction } from '@reduxjs/toolkit';

import { ContentAddress } from 'editor/src/store/design/types';
import { EditorState } from 'editor/src/store/editor/types';

const setFocusedContentReducer = (state: EditorState, { payload }: PayloadAction<ContentAddress | undefined>) => {
  state.focusedContentAddress = payload;
};

export default setFocusedContentReducer;
