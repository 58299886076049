import React from 'react';

import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import SpreadButton from './SpreadButton';
import useSpreadWithPersonalization from './useSpreadWithPersonalization';

import styles from './index.module.scss';

function SpreadControls() {
  const currentSpreadIndex = useSelector(getCurrentSpreadIndex);
  const spreadsWithPersonalization = useSpreadWithPersonalization();

  if (spreadsWithPersonalization.length <= 1) {
    return null;
  }

  return (
    <div className={styles.SpreadControls}>
      <div className={styles.label}>Page:</div>
      <div className={styles.list}>
        {spreadsWithPersonalization.map(({ spread, spreadIndex }) => (
          <SpreadButton
            key={spreadIndex}
            spreadIndex={spreadIndex}
            spreadName={spread.name}
            active={spreadIndex === currentSpreadIndex}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(SpreadControls);
