import * as amplitude from '@amplitude/analytics-browser';

import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import environment from 'editor/src/environment';

import { AmplitudeEvents, ImageSource } from './types';

export { ImageSource };

const logs: Array<{
  event: keyof AmplitudeEvents;
  data: AmplitudeEvents[keyof AmplitudeEvents];
}> = [];
(window as any).logs = { amplitude: logs };

const enabled = !!environment.amplitudeKey;
let initiated = false;
let pendingLogs: Array<{
  event: keyof AmplitudeEvents;
  data: AmplitudeEvents[keyof AmplitudeEvents];
}> = [];

export function init(cb: () => AmplitudeEvents['editor closed']) {
  if (!enabled) {
    return;
  }
  amplitude.init(environment.amplitudeKey, undefined, {
    plan: { version: process.env.REACT_APP_VERSION },
  });

  const identify = new amplitude.Identify();
  identify.set('Embedded Mode', isEmbedded);
  amplitude.identify(identify);

  window.addEventListener('pagehide', () => {
    logEvent('editor closed', cb());
    amplitude.setTransport(amplitude.Types.TransportType.SendBeacon);
    amplitude.flush();
  });

  initiated = true;
  if (pendingLogs.length) {
    pendingLogs.forEach(({ event, data }) => logEvent(event, data));
  }
  pendingLogs = [];
}

export function setUserId(userId: string) {
  amplitude.setUserId(userId);
}

export function setProductUid(productUid: string) {
  const identify = new amplitude.Identify();
  identify.set('Product UID', productUid);
  amplitude.identify(identify);
}

export function logEvent<K extends keyof AmplitudeEvents>(event: K, data: AmplitudeEvents[K]) {
  logs.push({ event, data });
  if (!enabled) {
    return;
  }
  if (!initiated) {
    pendingLogs.push({ event, data });
  } else {
    amplitude.track(event, data);
  }
}
