import { FontDefinition } from './types';

export interface FontInfo {
  obj: opentype.Font;
  definition: FontDefinition;
  localUrl: string;
}

const fontManager: {
  loadedFontMap: { [fontFile: string]: FontInfo };
  fontDefinitions: FontDefinition[];
  fallbackFonts: Set<string>;
} = {
  loadedFontMap: {},
  fontDefinitions: [],
  fallbackFonts: new Set(),
};

export default fontManager;
