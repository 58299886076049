import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const moveMediaElementZindexReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    pageIndex: number;
    prevIndex: number;
    newIndex: number;
  }>,
) => {
  const { spreadIndex, pageIndex, prevIndex, newIndex } = action.payload;
  const media = state.designData?.spreads[spreadIndex].pages[pageIndex].groups.media;

  if (media) {
    media.splice(newIndex, 0, media.splice(prevIndex, 1)[0]);
  }
};

export default moveMediaElementZindexReducer;
