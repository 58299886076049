import { resetSessionImageAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const resetSessionImageOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  dispatch(resetSessionImageAction());
  if (state.gallery.sessionImages.length > 0) {
    sendPostMessage('uploader.uploadComplete', undefined);
  }
};

export default resetSessionImageOperation;
