import cn from 'classnames';
import React, { Suspense } from 'react';

import { useSelector } from 'product-personalizer/src/store/hooks';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import SpreadRenderer from '../../SpreadRenderer';
import use3dPreviewConfig from '../../SpreadRenderer3D/use3dPreviewConfig';

import GalleryItem from './GalleryItem';

import styles from './index.module.scss';

const SpreadRenderer3D = React.lazy(retryPromiseFn(() => import('../../SpreadRenderer3D')));

interface Props {
  images: Array<string | undefined>;
  spreadIndexes: number[];
  selectedImageIndex: number;
  onIndexChanged: (index: number) => void;
  showPreviewDialog: () => void;
  hideImageSelector?: boolean;
  isLoading?: boolean;
  hidePreviewScrollBar?: boolean;
}

function Carousel({
  images,
  spreadIndexes,
  selectedImageIndex,
  onIndexChanged,
  showPreviewDialog,
  isLoading,
  hideImageSelector,
  hidePreviewScrollBar,
}: Props) {
  const divRef = React.useRef<HTMLDivElement>(null);
  const productUid = useSelector((state) => state.design.designData?.product_uid);
  const { config: preview3dConfig, loading: configLoading } = use3dPreviewConfig(productUid);

  return (
    <div className={cn(styles.Carousel, { [styles.noImagesSelector]: hideImageSelector })}>
      {!hideImageSelector && (
        <div className={styles.listContainer}>
          <div className={cn(styles.list, { [styles.hideScrollBar]: hidePreviewScrollBar })} ref={divRef}>
            {images.map((image, index) => (
              <GalleryItem
                key={index}
                src={image}
                index={index}
                onClick={onIndexChanged}
                selected={index === selectedImageIndex}
                className={cn({ [styles.blink]: !image })}
              />
            ))}
          </div>
        </div>
      )}
      {selectedImageIndex < spreadIndexes.length &&
        !configLoading &&
        (preview3dConfig ? (
          <Suspense fallback="">
            <SpreadRenderer3D config={preview3dConfig} className={styles.spreadRenderer3D} />
          </Suspense>
        ) : (
          <SpreadRenderer
            spreadIndex={spreadIndexes[selectedImageIndex]}
            className={styles.spreadRenderer}
            onClick={showPreviewDialog}
            loading={isLoading}
          />
        ))}
      {selectedImageIndex >= spreadIndexes.length && !!images[selectedImageIndex] && (
        <img
          className={cn(styles.selectedImage, 'cy-selected-image')}
          src={images[selectedImageIndex]}
          alt="Preview"
          onClick={showPreviewDialog}
        />
      )}
    </div>
  );
}

export default React.memo(Carousel);
