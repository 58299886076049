import { GridDesign } from '@gelatoas/design-editor-calendar';
import { captureException } from '@sentry/react';
import { fabric } from 'fabric';

import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import {
  CalendarConfig,
  ContentAddress,
  Coords,
  DesignState,
  Rect,
  Spread,
  SpreadGroundImage,
  SpreadGroup,
} from 'editor/src/store/design/types';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import { GalleryImage } from 'editor/src/store/gallery/types';

import orderObjects from 'editor/src/component/EditorArea/orderObjects';
import { getVisibleAreaAsPolygons } from 'editor/src/component/EditorArea/Spread/Page/contentClippingUtils';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import getTrimClipPath from '../EditorArea/Spread/Page/getTrimClipPath';

import addPageElement, { LIGHT_SHADOW, STRONG_SHADOW } from './addPageElement';
import addPages from './addPages';
import addSpreadMediaAndImages from './addSpreadMediaAndImages';
import fabricCanvasToBlob from './fabricCanvasToBlob';
import getThumbnailZoomAndPan from './getThumbnailZoomAndPan';
import { RequestRenderFn } from './useCanvasRendering';

export interface SpreadPreviewBlob {
  type: 'blob';
  blob: Blob | null;
  width: number;
  height: number;
}

export interface SpreadPreviewDataURL {
  type: 'dataURL';
  dataURL: string;
  width: number;
  height: number;
}

export interface SpreadPreviewCanvas {
  type: 'canvas';
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
}

export interface SpreadPreviewOptions<Type extends PreviewOutput = 'dataURL'> {
  quality?: number;
  showEmptyImages?: boolean;
  showBlanks?: boolean;
  showBlankAreaShadowOnly?: boolean;
  zoom?: number | 'spread' | 'auto';
  strongerShadow?: boolean;
  output?: Type;
  format?: 'jpeg' | 'png';
  // for 3d rendering
  showProduct?: boolean;
  noShadow?: boolean;
  noCentering?: boolean;
  offsetX?: number;
  offsetY?: number;
  enableRetinaScaling?: boolean;
  cropContentArea?: boolean;
  backgroundColor?: string;
}

export type SpreadPreview = SpreadPreviewBlob | SpreadPreviewDataURL | SpreadPreviewCanvas;
export type PreviewOutput = SpreadPreview['type'];

const THUMB_SIZE = 250;

export interface SpreadGroupInfo {
  spreadOffsetCoords: Coords;
  backgroundImage: SpreadGroundImage | undefined;
  foregroundImage: SpreadGroundImage | undefined;
  groupSpread: Spread;
  spreadWidth: number;
  spreadHeight: number;
}

export interface DataSource {
  backgroundImage: SpreadGroundImage | undefined;
  foregroundImage: SpreadGroundImage | undefined;
  gridDesigns: GridDesign[];
  images: GalleryImage[];
  addons: Addon[];
}

export type SizeConstraints = {
  dimension: 'width' | 'height' | 'both';
  value: number;
};

function createSpreadPreview<
  Output extends Type extends 'blob'
    ? SpreadPreviewBlob
    : Type extends 'dataURL'
      ? SpreadPreviewDataURL
      : Type extends 'canvas'
        ? SpreadPreviewCanvas
        : never,
  Type extends PreviewOutput = 'dataURL',
>(
  designKey: string,
  spread: Spread,
  dataSources: DataSource,
  spreadIndex: number,
  focusedContentAddress: ContentAddress | undefined,
  sizeContstraint: SizeConstraints,
  requestRender: RequestRenderFn,
  digitizedAssets: DesignState['digitizedAssets'],
  loadFont: (font: string) => Promise<void>,
  isMobile: boolean,
  t: (key: string) => string,
  options?: SpreadPreviewOptions<Type>,
  spreadGroup?: SpreadGroup,
  spreadGroupInfo?: SpreadGroupInfo[],
  calendarConfig?: CalendarConfig,
): Promise<Output | null> {
  const quality = options?.quality || 1;
  const format = options?.format || 'jpeg';
  const showEmptyImages = options?.showEmptyImages ?? false;
  const showBlanks = options?.showBlanks ?? false;
  const showBlankAreaShadowOnly = options?.showBlankAreaShadowOnly ?? false;
  const defaultZoom = options?.zoom ?? 'auto';
  const strongerShadow = options?.strongerShadow ?? false;
  const outputFormat = options?.output ?? 'dataURL';

  const noShadow = options?.noShadow ?? false;
  const showProduct = options?.showProduct ?? true;
  const noCentering = options?.noCentering ?? false;
  const offsetX = options?.offsetX ?? 0;
  const offsetY = options?.offsetY ?? 0;
  const enableRetinaScaling = options?.enableRetinaScaling ?? true;
  const cropContentArea = options?.cropContentArea ?? false;

  const spreadWidth = getSpreadWidthFromSpread(spread);
  const spreadHeight = getSpreadHeightFromSpread(spread);
  let areaWidth = spreadWidth;
  let areaHeight = spreadHeight;

  const contentAreaBox = spread.pages[0].groups.content?.find((area) => area.type === 'area');
  if (cropContentArea && contentAreaBox) {
    areaWidth = contentAreaBox.width;
    areaHeight = contentAreaBox.height;
  }

  const focusedPage = focusedContentAddress && spread.pages[focusedContentAddress.pageIndex];
  const focusedContent =
    focusedContentAddress && focusedPage && focusedPage.groups.content?.[focusedContentAddress.contentIndex];
  const focusedContentRect: Rect | undefined = focusedPage &&
    focusedContent && {
      x: focusedPage.x + focusedContent.x,
      y: focusedPage.x + focusedContent.y,
      width: focusedContent.width,
      height: focusedContent.height,
    };

  const previewWidth =
    focusedContent?.width ??
    areaWidth * (spreadGroup?.position === 'horizontal' ? spreadGroup.spreadIndexes.length : 1);
  const previewHeight =
    focusedContent?.height ??
    areaHeight * (spreadGroup?.position === 'vertical' ? spreadGroup.spreadIndexes.length : 1);
  const hasBackground = !!dataSources.backgroundImage;

  const offset: Coords = { left: 0, top: 0 };

  let width = 0;
  let height = 0;
  let mm2px: (x: number) => number;
  const useThumbnails = sizeContstraint.value <= THUMB_SIZE;
  if (sizeContstraint.dimension === 'height') {
    height = sizeContstraint.value;
    width = (previewWidth * height) / previewHeight;
    mm2px = (size) => (size * height) / previewHeight;
  } else if (sizeContstraint.dimension === 'width') {
    width = sizeContstraint.value;
    height = (previewHeight * width) / previewWidth;
    mm2px = (size) => (size * width) / previewWidth;
  } else {
    width = sizeContstraint.value;
    height = sizeContstraint.value;
    const min = Math.max(previewHeight, previewWidth);
    mm2px = (size) => (size * sizeContstraint.value) / min;
  }

  if (focusedContentRect) {
    offset.left = -mm2px(focusedContentRect.x);
    offset.top = -mm2px(focusedContentRect.y);
  }

  return requestRender(
    async (fabricCanvas) => {
      if (fabricCanvas.getWidth() !== width || fabricCanvas.getHeight() !== height) {
        fabricCanvas.setDimensions({ width, height });
      }
      fabricCanvas.backgroundColor = options?.backgroundColor ?? (showProduct ? 'white' : 'transparent');
      const rotationAngle = focusedContent?.rotate || 0;
      const canvasCenter = fabricCanvas.getCenter();
      const rotation: CanvasRotation = {
        angleDeg: rotationAngle,
        angleRad: fabric.util.degreesToRadians(rotationAngle),
        canvasCenter: new fabric.Point(canvasCenter.left, canvasCenter.top),
      };

      const visibleAreaPolygons = getVisibleAreaAsPolygons(
        spread.pages,
        offset,
        mm2px,
        rotation,
        spreadGroup,
        spreadGroupInfo?.[0].spreadOffsetCoords,
      );
      const contentClipPath = getTrimClipPath(spread.pages[0].groups.bleed, mm2px, false, { left: 0, top: 0 });

      try {
        if (showProduct) {
          if (focusedContentRect) {
            addPageElement(
              fabricCanvas,
              [],
              [],
              [],
              focusedContentRect,
              offset,
              mm2px,
              rotation,
              hasBackground,
              strongerShadow,
              contentClipPath,
              focusedPage?.backgroundColor,
            );
          } else if (spreadGroup) {
            spreadGroupInfo?.forEach((groupInfo) => {
              addPages(
                fabricCanvas,
                groupInfo.groupSpread,
                mm2px,
                offset,
                rotation,
                showBlanks,
                showBlankAreaShadowOnly,
                hasBackground,
                strongerShadow,
                visibleAreaPolygons.contentArea,
                contentClipPath,
                isMobile,
                t,
                groupInfo.spreadOffsetCoords,
              );
            });
          } else {
            addPages(
              fabricCanvas,
              spread,
              mm2px,
              offset,
              rotation,
              showBlanks,
              showBlankAreaShadowOnly,
              hasBackground,
              strongerShadow,
              visibleAreaPolygons.contentArea,
              contentClipPath,
              isMobile,
              t,
            );
          }
        }

        const { foregroundDim, backgroundDim, dispose } = await addSpreadMediaAndImages(
          fabricCanvas,
          mm2px,
          previewWidth,
          previewHeight,
          offset,
          dataSources,
          quality,
          rotation,
          showEmptyImages,
          useThumbnails,
          spread,
          visibleAreaPolygons,
          digitizedAssets,
          loadFont,
          contentClipPath,
          focusedContent,
          focusedPage,
          focusedContentAddress,
          spreadGroupInfo,
          spreadGroup,
          calendarConfig,
        );

        const shadow = (!noShadow && !hasBackground && (strongerShadow ? STRONG_SHADOW : LIGHT_SHADOW)) || undefined;
        const shadowSize = (shadow?.blur || 0) * 2;

        const { zoom, center, maxWidth, maxHeight } = getThumbnailZoomAndPan(
          defaultZoom,
          noCentering,
          { width, height },
          {
            width: mm2px(previewWidth + shadowSize),
            height: mm2px(previewHeight + shadowSize),
          },
          { image: dataSources.backgroundImage, dimensions: backgroundDim },
          { image: dataSources.foregroundImage, dimensions: foregroundDim },
        );

        if (offsetX) {
          center.x += offsetX;
        }
        if (offsetY) {
          center.y += offsetY;
        }

        if (contentAreaBox && cropContentArea) {
          // shift in case of bleeds
          fabricCanvas.absolutePan({ x: mm2px(contentAreaBox.x), y: mm2px(contentAreaBox.y) });
          fabricCanvas.setZoom(zoom);
        } else {
          fabricCanvas.setZoom(zoom);
          fabricCanvas.absolutePan(center);
        }

        orderObjects(fabricCanvas);
        fabricCanvas.renderAll();

        const options: fabric.IDataURLOptions = {
          format,
          quality: 0.6,
          enableRetinaScaling,
        };

        if (sizeContstraint.dimension === 'both') {
          options.left = undefined;
          options.top = undefined;
          options.width = sizeContstraint.value;
          options.height = sizeContstraint.value;
        } else {
          options.left = (width - maxWidth) / 2;
          options.top = (height - maxHeight) / 2;
          options.width = maxWidth;
          options.height = maxHeight;
        }

        const outputWidth = enableRetinaScaling ? maxWidth * window.devicePixelRatio : maxWidth;
        const outputHeight = enableRetinaScaling ? maxHeight * window.devicePixelRatio : maxHeight;

        if (outputFormat === 'dataURL') {
          if (format === 'png') {
            fabricCanvas.backgroundColor = 'transparent';
          }
          const dataURL = fabricCanvas.toDataURL(options);
          dispose?.();

          return {
            type: 'dataURL',
            dataURL,
            width: outputWidth,
            height: outputHeight,
          } as Output;
        }

        if (outputFormat === 'canvas') {
          return {
            type: 'canvas',
            canvas: fabricCanvas.getElement(),
            width: outputWidth,
            height: outputHeight,
          } as Output;
        }

        const blob = await fabricCanvasToBlob(fabricCanvas, options);
        dispose?.();

        return {
          type: 'blob',
          blob,
          width: outputWidth,
          height: outputHeight,
        } as Output;
      } catch (e) {
        captureException(new Error(e || 'SpreadPreview: failed to generate a preview'));
      }

      return null;
    },
    `${designKey}-${spreadIndex}-${focusedContentAddress?.pageIndex ?? '0'}-${focusedContentAddress?.contentIndex ?? '0'}`,
  );
}

export default createSpreadPreview;
