export function processLocale(locale: string) {
  let processed = locale;
  const splitDashLang = processed.split('-');
  if (splitDashLang.length > 1) {
    processed = `${splitDashLang[0]}_${splitDashLang[1].toUpperCase()}`;
  }
  const splitUnderscoreLang = processed.split('_');
  if (splitUnderscoreLang.length > 1) {
    processed = `${splitUnderscoreLang[0]}_${splitUnderscoreLang[1].toUpperCase()}`;
  }
  return processed;
}

const LOCALES = {
  en: 'English - EN',
  'de-DE': 'Deutsch - DE',
  'fr-FR': 'Français - FR',
  'es-ES': 'Español - ES',
  'it-IT': 'Italian - IT',
  'sv-SE': 'Svenska - SE',
  'nb-NO': 'Norsk - NO',
  'ja-JP': '日本語 - JP',
  'vi-VN': 'Tiếng Việt - VI',
  'nl-NL': 'Nederlands - NL',
  'da-DK': 'Dansk - DA',
};

export const browserLocaleToEditorLocale: { [language: string]: string } = {
  en: 'en',
  'cs-CZ': 'cs_CZ',
  'da-DK': 'da_DK',
  'nl-NL': 'nl_NL',
  'fr-FR': 'fr_FR',
  'de-DE': 'de_DE',
  'it-IT': 'it_IT',
  'ja-JP': 'ja_JP',
  'nb-NO': 'nb_NO',
  'pl-PL': 'pl_PL',
  'pt-BR': 'pt_BR',
  'pt-PT': 'pt_PT',
  'es-ES': 'es_ES',
  'sv-SE': 'sv_SE',
  'vi-VN': 'vi_VN',
};

export default LOCALES;
