import { PayloadAction } from '@reduxjs/toolkit';

import { DesignOption, VariantsState } from 'editor/src/store/variants/types';

const linkAllDesignOptionsVariantsReducer = (state: VariantsState, action: PayloadAction<DesignOption>) => {
  state.variationGroups.forEach((group) => {
    if (!group.linked && group.designOptions?.find((designOption) => designOption.optionKey === action.payload.value)) {
      group.linked = true;
    }
  });
};

export default linkAllDesignOptionsVariantsReducer;
