import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import { DesignData } from 'editor/src/store/design/types';
import loadDesignDataFonts from 'editor/src/store/fonts/utils/loadDesignDataFonts';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import loadImage from 'editor/src/util/loadImage';
import { createDraft } from 'product-personalizer/src/draftAPI';
import store from 'product-personalizer/src/store';
import { Asset } from 'product-personalizer/src/types/asset';
import { Design } from 'product-personalizer/src/types/design';
import { Variant } from 'product-personalizer/src/types/variant';
import { fetchDesign, fetchVariants, generatePreview } from 'product-personalizer/src/utils/loadData';

import { createSpreadPreview, RequestRenderFn } from 'editor/src/component/SpreadPreview';
import { SizeConstraints } from 'editor/src/component/SpreadPreview/createSpreadPreview';

import {
  assetsToGalleryImages,
  extractFirstUploadedAssetByDesignIds,
  formatDesignStructureForCanvasRendering,
  getNewDesignWithAppliedImage,
  getSceneNameFromProductUid,
  htmlImageToDesignAsset,
  htmlImageToGalleryImage,
  loadFont,
} from './utils';
import createCanvasRendering from './utils/createCanvasRendering';

const DEFAULT_DIMENSION = 500;

interface ReflectDesignAssetForVariantReturnValue {
  variantId: string;
  previewUrl: string;
}

interface ReflectDesignAssetForVariantParameters {
  designIds: string[];
  variantIds: string[];
  useThumbnail?: boolean;
  width?: number;
  height?: number;
}
export default class PreviewRenderer {
  async reflectDesignAssetForVariant({
    designIds,
    variantIds,
    useThumbnail = true,
    width = 500,
    height = 500,
  }: ReflectDesignAssetForVariantParameters): Promise<ReflectDesignAssetForVariantReturnValue[] | { error: string }> {
    try {
      const previewWidth = width || DEFAULT_DIMENSION;
      const previewHeight = height || DEFAULT_DIMENSION;

      const firstUploadedAsset = await extractFirstUploadedAssetByDesignIds(designIds);
      if (!firstUploadedAsset) {
        return {
          error: `No uploaded images found for design ids: ${designIds.join(', ')}`,
        };
      }

      const newImageUrl = firstUploadedAsset.files.find((file) => {
        if (useThumbnail) {
          return file.type === 'preview_thumbnail';
        }
        return file.type === 'preview_default';
      })?.url;

      if (!newImageUrl) {
        return {
          error: 'No valid preview image URL found in the first uploaded asset',
        };
      }

      const results = await Promise.all(
        variantIds.map(async (variantId): Promise<ReflectDesignAssetForVariantReturnValue | { error: string }> => {
          try {
            const variantDesign = await fetchDesign(variantId);
            const newDesignData = getNewDesignWithAppliedImage(
              newImageUrl,
              JSON.parse(variantDesign.structure) as DesignData,
              firstUploadedAsset,
            );
            if (!newDesignData) {
              return { error: `Cannot extract design data for variant ${variantId}` };
            }

            const previewUrl = await generatePreview({
              productUid: newDesignData.product_uid,
              designStructure: newDesignData,
              width: previewWidth,
              height: previewHeight,
              scene: getSceneNameFromProductUid(newDesignData.product_uid),
            });

            return { previewUrl, variantId };
          } catch (error) {
            return { error: `Failed to process variant ${variantId}: ${error.message}` };
          }
        }),
      );

      // Filter out any error results and return the successful ones
      const successfulResults = results.filter(
        (res): res is ReflectDesignAssetForVariantReturnValue => 'previewUrl' in res && 'variantId' in res,
      );

      // If no successful results, return the first error encountered
      if (successfulResults.length === 0) {
        const firstError = results.find((res): res is { error: string } => 'error' in res);
        return firstError || { error: 'Unknown error occurred during processing' };
      }

      return successfulResults;
    } catch (error) {
      return { error: `An unexpected error occurred: ${error.message}` };
    }
  }

  async createDraftAndGetUrl({
    designIds,
    variantId,
  }: {
    designIds: string[];
    variantId: string;
  }): Promise<string | { error: string }> {
    try {
      const firstUploadedAsset = await extractFirstUploadedAssetByDesignIds(designIds);
      if (!firstUploadedAsset) {
        return { error: `No uploaded images found for design ids ${designIds.join(', ')}` };
      }

      const newImageUrl = firstUploadedAsset.files.find((file) => file.type === 'preview_default')?.url;
      if (!newImageUrl) {
        return { error: 'No valid image URL found in the first uploaded asset' };
      }

      const [variants, variantDesign]: [{ [variantId: string]: Variant }, Design] = await Promise.all([
        fetchVariants([variantId]),
        fetchDesign(variantId),
      ]);

      const newDesignData = getNewDesignWithAppliedImage(
        newImageUrl,
        JSON.parse(variantDesign.structure) as DesignData,
        firstUploadedAsset,
      );
      if (!Object.keys(variants).length) {
        return { error: `No data found for variant ${variantId}` };
      }

      if (!newDesignData) {
        return { error: `Could not extract design data for variant ${variantId}` };
      }

      const variantData = variants[variantId];
      const newDraft = await createDraft(
        store,
        newDesignData,
        variantData.externalVariantId,
        variantData.variantId,
        undefined,
        true,
      );

      return `/products/${variantData.productHandle}?designId=${newDraft.designId}&variantId=${variantId}&externalVariantId=${variantData.variantId}`;
    } catch (error) {
      return { error: `An unexpected error occurred: ${error.message}` };
    }
  }

  async generateCanvasPreview({
    imageUrl,
    variantIds,
    usePreflightPreviews = false,
    sizePx = DEFAULT_DIMENSION,
    backgroundColor = 'white',
    generateFirstActiveSpread = true,
  }: {
    imageUrl: string;
    variantIds: string[];
    usePreflightPreviews?: boolean;
    sizePx?: number;
    backgroundColor?: string;
    generateFirstActiveSpread?: boolean;
  }): Promise<{ variantPreviews: string[]; variantId: string }[] | undefined> {
    const imageDetails = await loadImage(imageUrl, 'anonymous', {
      executor: 'generateCanvasPreview: load image',
    });

    const newGalleryImage: GalleryImage = htmlImageToGalleryImage(imageDetails, imageUrl);
    const newAsset: Asset = htmlImageToDesignAsset(imageDetails, imageUrl);

    const previewPromises = variantIds.map(async (variantId) => {
      const variantPreviews: string[] = [];
      const variantDesign = await fetchDesign(variantId);
      const { assets } = variantDesign;
      const newDesignStructure = JSON.parse(variantDesign.structure);

      // to mock it locally
      // const assets = mockAssets;
      // const newDesignStructure = mockDesign;

      let newDesignData = getNewDesignWithAppliedImage(imageUrl, cloneDeep(newDesignStructure), newAsset);

      if (!newDesignData) {
        // eslint-disable-next-line no-console
        console.error(`Can not reflect design data for ${variantId}`);
        return {
          variantId,
          variantPreviews,
        };
      }

      newDesignData = formatDesignStructureForCanvasRendering(newDesignData);

      // preload fonts
      const { availableFonts } = store.getState().fonts;
      await loadDesignDataFonts([newDesignData], availableFonts, []).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(`Can't load font for  ${variantId}, ${e}`);
        return {
          variantId,
          variantPreviews,
        };
      });

      const { requestRender } = createCanvasRendering();
      const images = [...assetsToGalleryImages(assets), newGalleryImage];
      if (generateFirstActiveSpread) {
        const firstSpreadPreview = await getFirstSpreadPreview(
          newDesignData,
          { dimension: 'both', value: sizePx },
          requestRender,
          images,
          backgroundColor,
        );

        if (firstSpreadPreview) {
          variantPreviews.push(firstSpreadPreview.dataURL);
        }
      }

      // if no need to use preflight previews return the first one
      if (!usePreflightPreviews) {
        return {
          variantId,
          variantPreviews,
        };
      }

      return {
        variantId,
        variantPreviews,
      };

      // TODO finalize preflight scene rendering
      // const spread = newDesignData.spreads[0];
      // let sizeConstraints: SizeConstraints = { dimension: 'both', value: sizePx };
      // if (usePreflightPreviews) {
      //   sizeConstraints = spread.pages[0].height > spread.pages[0].width
      //     ? { dimension: 'height', value: sizePx }
      //     : { dimension: 'width', value: sizePx };
      // }
      // const firstSpreadPreview = await createSpreadPreview(
      //   getDesignKeyFromDesign(newDesignData),
      //   spread,
      //   {
      //     backgroundImage: undefined,
      //     foregroundImage: undefined,
      //     images: [...assetsToGalleryImages(assets), newGalleryImage],
      //     addons: [],
      //     gridDesigns: [],
      //   },
      //   0,
      //   undefined,
      //   sizeConstraints,
      //   requestRender,
      //   {},
      //   loadFont,
      //   false,
      //   i18n.t,
      //   {
      //     showBlanks: true,
      //     showEmptyImages: true,
      //     output: 'dataURL',
      //     format: 'jpeg',
      //     noShadow: usePreflightPreviews,
      //     backgroundColor,
      //     cropContentArea: usePreflightPreviews,
      //     showProduct: !usePreflightPreviews,
      //   },
      // );
      // const dimensions = { width: 0, height: 0 };
      // const loadedAssets: LoadedAssets = {};
      //
      // if (usePreflightPreviews) {
      //   // TODO load scene
      //   // const scene = fetchScene(productUid, sizePx);
      //   const scene = mockScene;
      //   const sceneAssets = await loadSceneAssets(scene);
      //   sceneAssets.forEach((asset) => {
      //     const { name, image } = asset;
      //     if (image.width > dimensions.width) {
      //       dimensions.width = image.width;
      //     }
      //
      //     if (image.height > dimensions.height) {
      //       dimensions.height = image.height;
      //     }
      //
      //     loadedAssets[name] = image;
      //   });
      //
      //   const defaultHtmlImage = await loadImage(firstSpreadPreview.dataURL, undefined, {
      //     executor: 'generateCanvasPreview: load spread preview image',
      //   });
      //
      //   const scenePreview = createScenePreview(
      //     dimensions,
      //     scene,
      //     loadedAssets,
      //     {
      //       default: defaultHtmlImage,
      //     },
      //     { dimension: 'width', value: sizePx },
      //     requestRender,
      //     {
      //       output: 'dataURL',
      //     }
      //   );
      //
      //
      //   return scenePreview;
      // } else {
      //   return undefined;
      // }
    });

    return Promise.all(previewPromises);
  }
}

const getFirstSpreadPreview = (
  designData: DesignData,
  sizeConstraints: SizeConstraints,
  requestRender: RequestRenderFn,
  images: GalleryImage[],
  backgroundColor: string,
) => {
  const firstActiveSpreadIndex = designData.spreads.findIndex((spread) => !!spread.pages[0].groups.media?.length);
  return createSpreadPreview(
    getDesignKeyFromDesign(designData),
    designData.spreads[firstActiveSpreadIndex],
    {
      backgroundImage: undefined,
      foregroundImage: undefined,
      images,
      addons: [],
      gridDesigns: [],
    },
    0,
    undefined,
    sizeConstraints,
    requestRender,
    {},
    loadFont,
    false,
    i18n.t,
    {
      showBlanks: true,
      showEmptyImages: true,
      output: 'dataURL',
      format: 'jpeg',
      noShadow: false,
      backgroundColor,
      showProduct: true,
    },
  );
};
