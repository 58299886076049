export interface AddonsState {
  data?: AddonData;
  inUse: Addon[];
  attributionImage?: AttributionImage;
  requestedVersion: number;
}

export interface AddonData {
  search: {
    value: string;
    recent?: string[];
  };
  categoryId: string | number;
  resultsVersion?: number;
  results: {
    category?: AddonCategory;
    elements?: Addon[];
  };
}

export interface AttributionImage {
  url: string;
  backgroundSize: string;
}

export interface AddonCategory {
  id: string;
  parentId?: string;
  name: string;
  subcategories?: AddonCategory[];
  elements: Addon[];
  count: number;
}

export enum AddonType {
  ADDON_VECTOR = 'ADDON_VECTOR',
  ADDON_BITMAP = 'ADDON_BITMAP',
  ADDON_DESIGN = 'ADDON_DESIGN',
  ADDON_CATEGORY = 'ADDON_CATEGORY',
}

export interface Addon {
  id: string;
  type: AddonType;
  name: string;
  mimeType?: string;
  preview?: string;
  price?: string | number;
  tags?: string[];
}

export interface AddonVector extends Addon {
  type: AddonType.ADDON_VECTOR;
  allowColorChange?: boolean;
  preview: string;
}

export interface AddonBitmap extends Addon {
  type: AddonType.ADDON_BITMAP;
  preview: string;
}

export interface AddonDesign extends Addon {
  type: AddonType.ADDON_DESIGN;
}
