import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { ValueWithDisabled } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';
import DropDown from 'editor/src/component/DropDown';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown/indexSVG';

import styles from './index.module.scss';

interface Props {
  values: ValueWithDisabled[];
  selectedValue: string;
  onChange: (value: string) => void;
  className?: string;
}

function SelectorDropDown({ values, selectedValue, onChange, className }: Props) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const close = useCallback(() => setDropdownVisible(false), []);

  function onSelect(value: string) {
    onChange(value);
    close();
  }

  return (
    <>
      <div
        className={cn(styles.SelectorDropDownBtn, className, 'g-selector-dropdown-button')}
        onClick={() => setDropdownVisible(true)}
      >
        <div className={styles.label}>{selectedValue}</div>
        <IconArrowDown />
      </div>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-left"
        className={cn(styles.SelectorDropDown, 'g-selector-dropdown')}
        matchWidth
      >
        {values.map((value, i) => (
          <div
            key={i}
            className={cn(styles.dropdownOption, 'g-selector-dropdown-option', {
              [styles.active]: value.name === selectedValue,
            })}
            onClick={() => onSelect(value.name)}
          >
            <div className={styles.label}>{value.name}</div>
          </div>
        ))}
      </DropDown>
    </>
  );
}
export default React.memo(SelectorDropDown);
