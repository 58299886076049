import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';
import { setAssetProviderDataOnElement } from 'editor/src/store/plugins/utils/assetProviderUtils';

const setAssetProviderDataOnElementReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ElementAddress;
    pluginName: string;
    assetId: string;
  }>,
) => {
  const { address, pluginName, assetId } = action.payload;

  const element =
    state.designData?.spreads[address.spreadIndex]?.pages[address.pageIndex]?.groups.media?.[address.elementIndex];
  if (element?.type === 'image') {
    setAssetProviderDataOnElement(element, pluginName, assetId);
  }
};

export default setAssetProviderDataOnElementReducer;
