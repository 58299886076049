/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconSaturation({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 12.2499C15 15.9779 11.866 18.9999 8 18.9999C4.13401 18.9999 1 15.9779 1 12.2499C1 7.49994 8 0.999939 8 0.999939C8 0.999939 15 7.49994 15 12.2499Z"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1 11.9999C1 15.8659 4.13401 18.9999 8 18.9999C11.866 18.9999 15 15.8659 15 11.9999L1 11.9999Z" />
      </svg>
    </Icon>
  );
}

export default IconSaturation;
