import { union, difference } from 'polygon-clipping';

import { Content, Bleed, Coords, Rect, MediaBox } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { rectToPolygon, rotatePolygon } from 'editor/src/component/EditorArea/utils/polygonsUtils';

function getSpreadBackgroundPolygons(
  mediabox: MediaBox[],
  contents: Content[],
  bleeds: Bleed[],
  focusedContent: Rect | undefined,
  pageCoords: Coords,
  canvasRotation: CanvasRotation,
  mm2px: (size: number) => number,
  showBleeds: boolean,
) {
  if (showBleeds && !focusedContent) {
    return mediabox
      .map((box) => rectToPolygon(box, pageCoords, mm2px))
      .map((polygon) => rotatePolygon(polygon, canvasRotation));
  }

  let polygons = focusedContent
    ? [rectToPolygon(focusedContent, pageCoords, mm2px)]
    : contents
        .filter((content) => content.name !== 'Panel' && content.type === 'area')
        .map((content) => rectToPolygon(content, pageCoords, mm2px));

  if (!polygons.length) {
    return polygons;
  }

  if (polygons.length > 1) {
    polygons = union(polygons);
  }

  const areaBleedsPolygon = bleeds
    .filter((bleed) => bleed.type === 'area')
    .map((bleed) => rectToPolygon(bleed, pageCoords, mm2px));

  if (areaBleedsPolygon.length) {
    polygons = difference(polygons, areaBleedsPolygon);
  }

  return polygons.map((polygon) => rotatePolygon(polygon, canvasRotation));
}

export default getSpreadBackgroundPolygons;
