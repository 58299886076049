import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const enableSpreadReducer = (state: EditorState, action: PayloadAction<{ spreadIndex: number }>) => {
  const index = state.disabledSpreads.findIndex((spread) => spread.spreadIndex === action.payload.spreadIndex);
  if (index !== -1) {
    state.disabledSpreads.splice(index, 1);
  }
};

export default enableSpreadReducer;
