import { GridDesign } from '@gelatoas/design-editor-calendar';

import { FontDefinition } from 'editor/src/store/fonts/types';

import findMatchingFont from './findMatchingFont';
import loadFontFiles from './loadFontFiles';

function loadGridDesignFonts(gridDesign: GridDesign, fonts: FontDefinition[]) {
  const dateInfo = gridDesign.grid.cell.base.date;
  const dateFont = findMatchingFont(fonts, dateInfo.fontFamily, dateInfo.fontWeight, dateInfo.fontStyle);
  const monthInfo = gridDesign.month;
  const monthFont = findMatchingFont(fonts, monthInfo.fontFamily, monthInfo.fontWeight, monthInfo.fontStyle);
  const yearInfo = gridDesign.year;
  const yearFont = findMatchingFont(fonts, yearInfo.fontFamily, yearInfo.fontWeight, yearInfo.fontStyle);

  const fontFiles = new Set<string>();
  if (dateFont) {
    fontFiles.add(dateFont.metadata.fontFile);
  }
  if (monthFont) {
    fontFiles.add(monthFont.metadata.fontFile);
  }
  if (yearFont) {
    fontFiles.add(yearFont.metadata.fontFile);
  }

  return loadFontFiles([...fontFiles], fonts);
}
export default loadGridDesignFonts;
