import { useEffect } from 'react';

import { ColorSpace } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import { loadLittleCMSModule } from './cmykUtils/littleCMSModule';

function useSetupColorConversion() {
  const isCMYKColorSpace = useSelector((state) => state.design.designData?.color_space === ColorSpace.CMYK);

  useEffect(() => {
    if (isCMYKColorSpace) {
      void loadLittleCMSModule();
    }
  }, [isCMYKColorSpace]);
}

export default useSetupColorConversion;
