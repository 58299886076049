import { PayloadAction } from '@reduxjs/toolkit';

import copySpreadUtil from 'editor/src/store/design/util/copySpreadUtil';

import { VariantsState } from '../../types';

const copySpreadReducer = (
  state: VariantsState,
  action: PayloadAction<{ copyFromIndex: number; positionAtIndex: number; empty: boolean }>,
) => {
  const { copyFromIndex, positionAtIndex, empty } = action.payload;
  state.variationGroups.forEach((variationGroup) => {
    variationGroup.variationsInfo.forEach((variationInfo) => {
      if (!variationInfo.designData) {
        return;
      }

      copySpreadUtil(variationInfo.designData, copyFromIndex, positionAtIndex, empty);
    });
  });
};

export default copySpreadReducer;
