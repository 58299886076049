import { useState, useEffect } from 'react';

function useBlobAsUrl(blob: Blob | null) {
  const [url, setURL] = useState('');

  useEffect(() => {
    if (!blob) {
      return undefined;
    }

    const imgUrl = window.URL.createObjectURL(blob);
    setURL(imgUrl);
    return () => window.URL.revokeObjectURL(imgUrl);
  }, [blob]);

  return url;
}

export default useBlobAsUrl;
