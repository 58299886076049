import React from 'react';

import { DashPattern, MediaLine, MediaRectangle, MediaShape } from 'editor/src/store/design/types';
import { defaultRectangleStyles } from 'editor/src/store/design/util/rectangleUtil';

import IconArrowFillLinePreset from 'editor/src/component/Icon/IconArrowFillLinePreset';
import IconArrowLinePreset from 'editor/src/component/Icon/IconArrowLinePreset';
import IconLinePreset from 'editor/src/component/Icon/IconLinePreset';
import IconRectanglePreset from 'editor/src/component/Icon/IconRectanglePreset';

export type LineConfigurableStyles = Partial<Pick<MediaLine, 'edge1' | 'edge2' | 'dashPattern'>>;
export type RectConfigurableStyles = Partial<Pick<MediaRectangle, 'stroke' | 'strokeWidth' | 'fill' | 'dashPattern'>>;

export const DEFAULT_DASH_PATTERN = {
  dash: 5,
  gap: 5,
};

export interface ShapeIconProps {
  className?: string;
  dashPattern?: DashPattern;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
}

export interface PresetItem<T extends MediaShape['type']> {
  Icon: React.VFC<ShapeIconProps>;
  dashPattern?: DashPattern;
  key: PresetsFromType<T>;
  shapeStyles: ShapeStylesFromType<T>;
}

interface ShapePreset {
  type: MediaShape['type'];
  titlei18nKey: string;
  presetItems: PresetItem<MediaShape['type']>[];
}

export type ShapeStylesFromType<T extends MediaShape['type']> = T extends 'line'
  ? LineConfigurableStyles
  : T extends 'rectangle'
    ? RectConfigurableStyles
    : never;

export type LinePresetType = 'flat' | 'dashed' | 'arrow' | 'arrow-fill';
export type RectanglePresetTypes = 'no-stroke' | 'no-fill' | 'fill-stroke' | 'dashed';
export type PresetsFromType<T extends MediaShape['type']> = T extends 'line'
  ? LinePresetType
  : T extends 'rectangle'
    ? RectanglePresetTypes
    : never;

export const LINE_PRESETS: PresetItem<'line'>[] = [
  {
    key: 'flat',
    Icon: IconLinePreset,
    shapeStyles: { edge1: 'flat', edge2: 'flat' },
  },
  {
    key: 'dashed',
    Icon: IconLinePreset,
    shapeStyles: {
      edge1: 'flat',
      edge2: 'flat',
      dashPattern: DEFAULT_DASH_PATTERN,
    },
  },
  {
    key: 'arrow',
    Icon: IconArrowLinePreset,
    shapeStyles: { edge1: 'flat', edge2: 'arrow' },
  },
  {
    key: 'arrow-fill',
    Icon: IconArrowFillLinePreset,
    shapeStyles: { edge1: 'flat', edge2: 'arrow-fill' },
  },
];

export const RECTANGLE_PRESETS: PresetItem<'rectangle'>[] = [
  {
    key: 'no-stroke',
    Icon: IconRectanglePreset,
    shapeStyles: {
      fill: defaultRectangleStyles.stroke,
      stroke: undefined,
      strokeWidth: 5,
    },
  },
  {
    key: 'no-fill',
    Icon: IconRectanglePreset,
    shapeStyles: {
      stroke: defaultRectangleStyles.stroke,
      fill: undefined,
      strokeWidth: 5,
    },
  },
  {
    key: 'fill-stroke',
    Icon: IconRectanglePreset,
    shapeStyles: {
      fill: defaultRectangleStyles.fill,
      stroke: defaultRectangleStyles.stroke,
      strokeWidth: 5,
    },
  },
  {
    key: 'dashed',
    Icon: IconRectanglePreset,
    shapeStyles: {
      stroke: defaultRectangleStyles.stroke,
      strokeWidth: 5,
      fill: undefined,
      dashPattern: { dash: 4, gap: 2 },
    },
  },
];

export const SHAPE_PRESETS: ShapePreset[] = [
  {
    type: 'line',
    titlei18nKey: 'Lines',
    presetItems: LINE_PRESETS,
  },
  {
    type: 'rectangle',
    titlei18nKey: 'Rectangles',
    presetItems: RECTANGLE_PRESETS,
  },
];
