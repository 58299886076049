import { PayloadAction } from '@reduxjs/toolkit';

import { MockupState } from 'editor/src/store/mockup/types';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import { ProductControlMulti } from 'editor/src/store/variants/types';

const setMultiOptionReducer = (
  state: MockupState,
  action: PayloadAction<{ control: ProductControlMulti; value: string }>,
) => {
  const { control, value } = action.payload;
  const { multiOptions, singleOptions, variations } = state.productPlaceholder;

  multiOptions[control.key] = [
    {
      value,
      dependedOptions: control.dependsOnSingleControls
        ? Object.keys(singleOptions).map((key) => ({
            key,
            value: singleOptions[key],
          }))
        : [],
    },
  ];

  const matchingVariations = getAllMatchingVariations(variations, multiOptions, singleOptions, true, []);
  if (matchingVariations.length) {
    state.productPlaceholder.activeProductUid = matchingVariations[0].productUid;
  }
};

export default setMultiOptionReducer;
