import memoize from 'lodash/memoize';

import { RootState } from 'editor/src/store/index';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import getSpread from './getSpread';

const getSpreadPagesNames = memoize(
  (state: RootState, spreadIndex: number) => {
    const spread = getSpread(state, spreadIndex);
    if (!spread || !spread.pages) {
      return [];
    }

    return spread.pages.map((page) => {
      if (!page.groups.mediabox) {
        // If there's no 'mediabox', the page is non-editable and custom name is used.
        if (spreadIndex === 1) {
          return 'front-inside';
        }

        return 'back-inside';
      }
      // subtract 1 for cover page and 1 for non-editable page, so counting starts from 1st editable page after cover
      return page.page_nr - 2;
    });
  },
  (state: RootState, spreadIndex) =>
    state.design.designData ? `${getDesignKeyFromDesign(state.design.designData)}-${spreadIndex}` : undefined,
);

export default getSpreadPagesNames;
