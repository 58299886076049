// Chrome (Mobile): Prevent showing context menu on long taps on images
const noContextMenuListener = (e: Event) => {
  e.preventDefault();
};

const blockContextMenu = () => {
  window.addEventListener('contextmenu', noContextMenuListener);
};

const unblockContextMenu = () => {
  window.removeEventListener('contextmenu', noContextMenuListener);
};

const noContextMenuForMobile = (isMobile: boolean) => {
  if (isMobile) {
    blockContextMenu();
  } else {
    unblockContextMenu();
  }
};

export default noContextMenuForMobile;
