import { GridDate } from '@gelatoas/design-editor-calendar/lib';
import i18n from 'i18next';

import { CalendarConfig } from 'editor/src/store/design/types';

import { browserLocaleToEditorLocale } from 'editor/src/util/locales';

export const getCalendarEventsPerMonth = (
  gridDate: GridDate,
  locale: string = 'en',
  calendarConfig: CalendarConfig | undefined,
) => {
  if (!calendarConfig?.showEvents || !calendarConfig?.events) {
    return [];
  }

  return calendarConfig.events
    .filter(({ month, year, checked }) => month === gridDate.month && year === gridDate.year && checked)
    .map((e) => ({
      day: e.day,
      label: i18n.t(e.label, { lng: browserLocaleToEditorLocale[locale] }),
    }));
};
