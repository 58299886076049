import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import operationEventsConfig from 'editor/src/store/plugins/operationEventsConfig';
import { PluginEventName, PluginOperationParams } from 'editor/src/store/plugins/types';

function dispatchPluginOperations<T extends PluginEventName>(
  event: T,
  dispatch: Dispatch,
  params: PluginOperationParams[T],
) {
  const operations = operationEventsConfig.find((configItem) => configItem.event === event)
    ?.operations as unknown as AnyAction[];
  operations?.forEach(
    // eslint-disable-next-line prefer-spread
    (operation) => operation && dispatch(operation.apply(null, params)),
  );
}

export default dispatchPluginOperations;
