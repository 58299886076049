import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from '../..';
import setSidebarActiveTabByNameOperation from '../../editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { COLOR_RELATED_TAB_NAMES, TAB_NAMES } from '../../editorModules/sidebar/types';

const selectTextElementOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const { activeTabIndex } = getState().editorModules.sidebar;
  const tabs = getState().editorModules.sidebar;

  const activeTabName = tabs.tabs[activeTabIndex] as TAB_NAMES;

  // if the active tab is a color related tab, switch to the font colors tab for text element
  if (COLOR_RELATED_TAB_NAMES.includes(activeTabName)) {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_COLORS));
  }
};
export default selectTextElementOperation;
