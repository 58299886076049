import { MediaElement } from 'editor/src/store/design/types';

const isElementWithSpotFinishingType = (media: MediaElement) => {
  switch (media?.type) {
    case 'text':
    case 'addon':
    case 'image':
      return !!media?.has_spot_finishing;
    default:
      return false;
  }
};

export default isElementWithSpotFinishingType;
