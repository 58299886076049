import { MediaText } from 'editor/src/store/design/types';

import pt2mm from 'editor/src/util/pt2mm';

import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';

const textElementDataToFabricProps = (
  elementData: MediaText,
  elementIndex: number,
  mm2px: (size: number) => number,
  isInteractable: boolean,
  isMobile: boolean,
  loadedFontFamily: string,
) => ({
  zIndex: zIndex.MEDIA + elementIndex,
  editable: isInteractable && !elementData.uneditable,
  lockMovementY: elementData.locked,
  lockMovementX: elementData.locked,
  lockRotation: elementData.locked,
  hasControls: !elementData.locked,
  text: elementData.extra.text,
  evented: isInteractable,
  selectable: !isMobile,
  fontFamily: loadedFontFamily,
  textAlign: elementData.extra.textAlign ?? 'left',
  fill: elementData.fill,
  fontWeight: '', // we use the fontFile instead of font-family, so font weight does not matter
  fontStyle: '' as any, // we use the fontFile instead of font-family, so font style does not matter
  fontSize: mm2px(pt2mm(elementData.extra.fontSize)),
  lineHeight: elementData.extra.lineHeight,
  borderColor: ELEMENT_FRAME_COLOR,
  editingBorderColor: ELEMENT_FRAME_COLOR,
  cornerColor: ELEMENT_FRAME_COLOR,
  borderOpacityWhenMoving: 1,
  cornerStrokeColor: ELEMENT_FRAME_COLOR,
  cornerSize: isMobile ? 18 : 12,
  charSpacing: elementData.extra.letterSpacing || 0,
  strokeWidth: elementData.extra.stroke?.width ? mm2px(elementData.extra.stroke.width * elementData.extra.fontSize) : 0,
  stroke: elementData.extra.stroke?.color,
  underline: elementData.extra.underline,
  linethrough: elementData.extra.linethrough,
  wrapping: elementData.extra.wrapping,
  flexibleWidth: elementData.extra.flexibleWidth,
  curve: elementData.extra.curve,
});

export default textElementDataToFabricProps;
