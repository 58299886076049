import { PayloadAction } from '@reduxjs/toolkit';

import { conditionGroupChildenKey, DesignState, MediaElement } from 'editor/src/store/design/types';

const addElementToConditionGroupReducer = (
  state: DesignState,
  action: PayloadAction<{ spreadIndex: number; element: MediaElement }>,
) => {
  const { spreadIndex, element } = action.payload;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  conditionGroup.children[conditionGroupChildenKey].push({
    type: element.type,
    id: element.uuid,
  });
};

export default addElementToConditionGroupReducer;
