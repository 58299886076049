import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getVariationGroupsFromDesignOptions from 'editor/src/store/variants/selector/getVariationGroupsFromDesignOptions';

import { logEvent } from 'editor/src/amplitude';

import { updateRecoloredElementsAction as updateRecoloredElementsVariantsAction } from '../../variants/slice';
import { updateRecoloredElementsAction } from '../slice';

import { ColorReplacement } from './getAssetUrlWithReplacedColors';

export interface AssetReplacement {
  assetId: string;
  url: string;
  colors: ColorReplacement;
  variantKey: string | undefined;
  designOptionKey: string | undefined;
}

const setAssetWithReplacedColorsOperation =
  ({ assetId, url, colors, variantKey, designOptionKey }: AssetReplacement) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const requestedVariationGroup = state.variants.variationGroups.find(
      (variationGroup) => variationGroup.key === variantKey,
    );
    const { selectedGroupKey } = state.variants;
    const selectedGroupIsLinked = !!state.variants.variationGroups.find(
      (variationGroup) => variationGroup.key === selectedGroupKey,
    )?.linked;
    const { designOptionsEnabled } = state.variants;

    const updateCurrentSelectedDesignAsset =
      (!requestedVariationGroup?.linked && requestedVariationGroup?.key === selectedGroupKey) || // if the requested asset was unlinked and is currently selected
      (!designOptionsEnabled && requestedVariationGroup?.linked && selectedGroupIsLinked) || // if requested asset was linked and currently selected group is also linked
      (designOptionsEnabled &&
        requestedVariationGroup?.linked &&
        selectedGroupIsLinked &&
        designOptionKey === state.variants.selectedDesignOptionValue); // if design options are enabled

    const variationGroupsFromDesignOptions = designOptionsEnabled
      ? getVariationGroupsFromDesignOptions(state)
      : undefined;
    batch(() => {
      dispatch(
        updateRecoloredElementsVariantsAction({
          assetId,
          url,
          colors,
          variantKey,
          designOptionKey,
          variationGroupsFromDesignOptions,
        }),
      );
      if (updateCurrentSelectedDesignAsset) {
        dispatch(updateRecoloredElementsAction({ assetId, url, colors }));
      }

      logEvent('element modified', {
        type: 'image',
        property: 'colorOverrides',
        colorChangedCount: (colors?.fill?.length || 0) + (colors?.stroke?.length || 0),
      });
    });
  };

export default setAssetWithReplacedColorsOperation;
