import { fabric } from 'fabric';

import getPointPositionRotatedOnPoint from './getPointPositionRotatedOnPoint';

export interface Point {
  x: number;
  y: number;
}

const rotatePointOnPoint = (point1: Point, point2: Point, angle: number) =>
  new fabric.Point(...getPointPositionRotatedOnPoint(point1.x, point1.y, point2.x, point2.y, angle));

export default rotatePointOnPoint;
