import { captureException } from '@sentry/react';
import { useEffect } from 'react';

import addDigitizedAssetOperation from 'editor/src/store/design/operation/addDigitizedAssetOperation';
import setQuantizedAssetOperation from 'editor/src/store/design/operation/setQuantizedAssetOperation';
import { useDispatch } from 'editor/src/store/hooks';

import { OutboundPostMessage } from 'editor/src/util/postMessages/messages';
import getUploadedImageDataFromAssets from 'product-personalizer/src/utils/assets';

function useHandlePostMessage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const onPostMessage = async (e: MessageEvent<{ type: keyof OutboundPostMessage; data: any; callback?: any }>) => {
      if (!e.data || !e.data.type) {
        return;
      }

      switch (e.data.type) {
        case 'design.requestQuantizedAsset': {
          try {
            const { data } = e.data as { data: OutboundPostMessage['design.requestQuantizedAsset'] };
            const searchParams = new URLSearchParams();
            searchParams.append('id', data);
            searchParams.append('force', 'true');
            const res = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/asset:quantize?${searchParams.toString()}`, {
              method: 'POST',
            });
            const resJson = await res.json();
            const imageData = getUploadedImageDataFromAssets([resJson])[0];
            dispatch(setQuantizedAssetOperation(imageData));
          } catch (e) {
            captureException(e);
          }
          break;
        }
        case 'design.requestDigitizedAsset': {
          const { data } = e.data as { data: OutboundPostMessage['design.requestDigitizedAsset'] };
          try {
            const searchParams = new URLSearchParams();
            searchParams.append('outputPxWidth', `${data.payload.output_px_width}`);
            if (data.imageId) {
              searchParams.append('imageId', data.imageId);
            }

            if (data.reuseIfExist) {
              searchParams.append('reuseIfExist', '1');
            }

            searchParams.append('elementHash', data.elementId);

            if (data.elementType === 'text') {
              const res = await fetch(
                `${LOCAL_ENV.ecomProxyEndpoint}/embroidery/text:digitize?${searchParams.toString()}`,
                {
                  method: 'POST',
                  body: JSON.stringify({ body: { path: data.payload.path, text_element: data.payload.textElement } }),
                },
              );
              const { id, ...restJson } = await res.json();
              dispatch(addDigitizedAssetOperation({ ...data, ...restJson, imageId: id }));
            } else {
              searchParams.append('imageUrl', data.payload.imageUrl);
              searchParams.append('renderedMmWidth', `${data.payload.rendered_mm_width}`);
              const res = await fetch(
                `${LOCAL_ENV.ecomProxyEndpoint}/embroidery/image:digitize?${searchParams.toString()}`,
                {
                  method: 'POST',
                },
              );
              const { id, ...restJson } = await res.json();
              dispatch(addDigitizedAssetOperation({ ...data, ...restJson, imageId: id }));
            }
          } catch (e) {
            captureException(e);
          }
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener('message', onPostMessage);
    return () => window.removeEventListener('message', onPostMessage);
  }, []);
}

export default useHandlePostMessage;
