import React from 'react';

import Icon from 'editor/src/component/Icon/index';

/* eslint-disable max-len */

interface Props {
  className?: string;
}

function IconArrowLinePreset({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.93934 71.9393C5.35355 72.5251 5.35355 73.4749 5.93934 74.0607C6.52513 74.6464 7.47487 74.6464 8.06066 74.0607L5.93934 71.9393ZM74.5 7C74.5 6.17157 73.8284 5.5 73 5.5H59.5C58.6716 5.5 58 6.17157 58 7C58 7.82843 58.6716 8.5 59.5 8.5H71.5V20.5C71.5 21.3284 72.1716 22 73 22C73.8284 22 74.5 21.3284 74.5 20.5V7ZM8.06066 74.0607L74.0607 8.06066L71.9393 5.93934L5.93934 71.9393L8.06066 74.0607Z"
          fill="#383838"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowLinePreset;
