import { DropShadow } from 'editor/src/store/design/types';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import { SHADOW_OFFSET_SCALE } from './index';

function getShadowRect(frameRect: ObjectRect, shadow: DropShadow): ObjectRect {
  const shadowScale = shadow.scale ?? 1;
  const width = frameRect.width * SHADOW_OFFSET_SCALE * shadowScale;
  const height = frameRect.height * SHADOW_OFFSET_SCALE * shadowScale;

  const shadowWidthOffset = (width - frameRect.width) / 2;
  const shadowHeightOffset = (height - frameRect.height) / 2;
  const offsetVector = getPointPositionRotatedOnPoint(shadowWidthOffset, shadowHeightOffset, 0, 0, frameRect.angle);

  return {
    angle: frameRect.angle,
    width,
    height,
    left: frameRect.left - offsetVector[0] + shadow.offsetX,
    top: frameRect.top - offsetVector[1] + shadow.offsetY,
  };
}

export default getShadowRect;
