import { PayloadAction } from '@reduxjs/toolkit';

import { AppState } from 'editor/src/store/app/types';

import { PopupName, PopupData } from 'editor/src/component/Popup/popups';

const openPopupReducer = <A extends PopupName>(
  state: AppState,
  action: PayloadAction<{ open: A; data: PopupData[A] } | { open: undefined; data?: undefined }>,
) => {
  state.popup = action.payload;
};

export default openPopupReducer;
