import { captureException } from '@sentry/react';

import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';

import store from 'product-personalizer/src/store';

const saveDesign = () => {
  const state = store.getState();
  const { images } = state.gallery;
  const addons = state.editorModules.addons.inUse;
  const { designData } = state.design;

  if (!designData) {
    return undefined;
  }

  const designDataForExport = JSON.stringify(getDesignDataForExport(designData, images, addons, []));

  return fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs`, {
    method: 'POST',
    body: JSON.stringify({
      structure: designDataForExport,
      accessMode: 'public',
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => data)
    .catch((error) => {
      captureException(error);
    });
};

export default saveDesign;
