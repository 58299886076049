import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaText } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'product-personalizer/src/store/hooks';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';
import trackInteraction from 'product-personalizer/src/utils/trackInteraction';

import IconInfo from 'editor/src/component/Icon/IconInfo';
import TextInput from 'editor/src/component/PersonalizationContent/PersonalizationElements/TextInput';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

const updateTextPropertiesWithoutRenderModule = retryPromiseFn(
  () => import('editor/src/store/design/util/updateTextPropertiesWithoutRender'),
);

interface Props {
  elementId: number;
  trackUserInteraction: boolean | undefined;
}

function PersonalizationText({ elementId, trackUserInteraction }: Props) {
  const { t } = useTranslation();
  const { element, address } = useSelector(
    (state) => ({
      element: getMediaElementByUuid(state, elementId) as MediaText | undefined,
      address: getStructureIndexByElementUuidMemoized(state, elementId),
    }),
    shallowEqual,
  );
  const maxCharCount = element?.extra?.maxCharCount;
  const dispatch = useDispatch();
  const onTextChange = useCallback(
    (text: string) => {
      trackUserInteraction && trackInteraction();
      if (address && element) {
        void updateTextPropertiesWithoutRenderModule().then((updateTextPropertiesWithoutRender) => {
          const elementUpdate = { extra: { ...element.extra, text } };
          updateTextPropertiesWithoutRender.default(element, elementUpdate, undefined);
          dispatch(updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.TEXT_UPDATED, true));
        });
      }
    },
    [address, element?.extra, trackUserInteraction],
  );

  return (
    <div className={cn('g-selector-container', 'g-selector-text', [{ 'cy-with-char-limitation': !!maxCharCount }])}>
      <div className={cn(styles.label, 'g-selector-label')}>
        {element?.name}
        {maxCharCount && (
          <div className={styles.iconWrapper}>
            <WithTooltip overlay={t('editor-max-characters', { maxCharacters: maxCharCount })} placement="top">
              <IconInfo className={styles.infoIcon} size="small" />
            </WithTooltip>
          </div>
        )}
      </div>

      <TextInput
        value={element?.sample ? '' : element?.extra?.text || ''}
        onChange={onTextChange}
        className={styles.textInput}
        placeholder={element?.sample ? element?.extra.text : element?.name}
        debounceTime={0}
        maxCharCount={maxCharCount}
        form="none"
      />
      {maxCharCount && (
        <div className={cn(styles.charCounter, 'cy-char-counter')}>
          {element.extra.text.length}/{maxCharCount}
        </div>
      )}
    </div>
  );
}

export default React.memo(PersonalizationText);
