// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageCropTool--VO86S{width:100%;height:100%;position:relative;overflow:hidden}.loader--Bnijt{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#fff;transition:opacity 200ms linear;pointer-events:none}.loader--Bnijt.ready--mG9_v{opacity:0}.loader--Bnijt .icon--PmWrl{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.loader--Bnijt .icon--PmWrl svg{width:100px;height:100px}`, "",{"version":3,"sources":["webpack://./src/component/ImageCropTool/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CAGF,eACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,+BAAA,CACA,mBAAA,CAEA,4BACE,SAAA,CAGF,4BACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAEA,gCACE,WAAA,CACA,YAAA","sourcesContent":[".ImageCropTool {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.loader {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: white;\n  transition: opacity 200ms linear;\n  pointer-events: none;\n\n  &.ready {\n    opacity: 0;\n  }\n\n  .icon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    svg {\n      width: 100px;\n      height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageCropTool": `ImageCropTool--VO86S`,
	"loader": `loader--Bnijt`,
	"ready": `ready--mG9_v`,
	"icon": `icon--PmWrl`
};
export default ___CSS_LOADER_EXPORT___;
