/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconLoading({ className }: Props) {
  return (
    <div className={cn(styles.loader, className)}>
      <span />
    </div>
  );
}

export default IconLoading;
