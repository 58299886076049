import cn from 'classnames';
import React from 'react';

import { ConditionGroup, ConditionChild, ElementAddress } from 'editor/src/store/design/types';

import PersonalizationCondition from '../PersonalizationCondition';
import PersonalizationImage from '../PersonalizationImage';
import PersonalizationText from '../PersonalizationText';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  conditionGroup: ConditionGroup;
  elements: ConditionChild[];
  uploadImage: (address: ElementAddress) => void;
  editImageCrop: (address: ElementAddress) => void;
  trackUserInteraction: boolean | undefined;
  noTopBorder?: boolean;
}

function PersonalizationElements({
  spreadIndex,
  conditionGroup,
  elements,
  uploadImage,
  editImageCrop,
  noTopBorder,
  trackUserInteraction,
}: Props) {
  return (
    <div className={cn(styles.PersonalizationElements, { [styles.notEmpty]: !!elements.length && !noTopBorder })}>
      {elements.map((element) => {
        const key = element.type + element.id;
        switch (element.type) {
          case 'condition':
            return (
              <PersonalizationCondition
                key={key}
                spreadIndex={spreadIndex}
                conditionGroup={conditionGroup}
                condition={conditionGroup.conditions[element.id]}
                uploadImage={uploadImage}
                editImageCrop={editImageCrop}
                trackUserInteraction={trackUserInteraction}
              />
            );
          case 'addon':
          case 'image':
            return (
              <PersonalizationImage
                key={key}
                elementId={element.id}
                editImageCrop={editImageCrop}
                uploadImage={uploadImage}
                trackUserInteraction={trackUserInteraction}
              />
            );
          case 'text':
            return <PersonalizationText key={key} elementId={element.id} trackUserInteraction={trackUserInteraction} />;
          default:
            return null;
        }
      })}
    </div>
  );
}

export default React.memo(PersonalizationElements);
