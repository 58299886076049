import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';

const moveMediaElementZindexBottomReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  const media = state.designData?.spreads[spreadIndex].pages[pageIndex].groups.media;
  if (!media || elementIndex === 0) {
    return;
  } // nothing to do, already at the lowest position

  const element = media.splice(elementIndex, 1)[0]; // remove element from its current position
  media.splice(0, 0, element); // insert element at the first position
};

export default moveMediaElementZindexBottomReducer;
