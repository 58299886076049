import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Coords } from 'editor/src/store/design/types';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import { CONTAINER_ID } from './TooltipContainer';

import styles from './index.module.scss';

type Placement = 'top' | 'bottom';
const DEFAULT_PLACEMENT: Placement = 'bottom';

export interface Props {
  children: any;
  overlay: React.ReactNode;
  placement?: Placement;
  className?: string;
  disabled?: boolean;
  visible?: boolean;
  tappable?: boolean;
  tooltipStyle?: 'default' | 'rich';
}

function WithTooltip({
  children,
  placement,
  className,
  overlay,
  disabled,
  visible,
  tappable,
  tooltipStyle = 'default',
}: Props) {
  const isMobile = useIsMobile();
  const [position, setPosition] = useState<{
    coords: Coords;
    placement: Placement;
    offsetX: number;
  } | null>(null);
  const [isRendered, setIsRendered] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tapTimer = useRef(-1);

  function onMouseEnter(e: React.MouseEvent) {
    if (!e.target || disabled || isMobile) {
      return;
    }
    showTooltip();
  }

  function onMouseLeave() {
    setIsRendered(false);
  }

  function onTouchStart(e: React.TouchEvent) {
    if (!e.target || disabled || !tappable || !isMobile) {
      return;
    }
    if (isRendered && tapTimer.current !== -1) {
      clearTimeout(tapTimer.current);
    } else {
      showTooltip();
    }

    tapTimer.current = window.setTimeout(() => {
      setIsRendered(false);
    }, 4000);
  }

  function recalculatePosition() {
    if (!wrapperRef.current || !tooltipRef.current) {
      return;
    }

    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const { clientWidth, clientHeight } = document.documentElement;

    const position: Coords = { left: 0, top: 0 };
    let currentPlacement = placement || DEFAULT_PLACEMENT;

    switch (currentPlacement) {
      case 'bottom':
        position.left = wrapperRect.left + (wrapperRect.width - tooltipRect.width) / 2;
        if (wrapperRect.top + wrapperRect.height + tooltipRect.height <= clientHeight) {
          // fit at the bottom
          position.top = wrapperRect.top + wrapperRect.height;
        } else {
          currentPlacement = 'top';
          position.top = wrapperRect.top - tooltipRect.height;
        }
        break;
      case 'top':
        position.left = wrapperRect.left + (wrapperRect.width - tooltipRect.width) / 2;
        // fit at the top
        if (wrapperRect.top - tooltipRect.height >= 0) {
          position.top = wrapperRect.top - tooltipRect.height;
        } else {
          currentPlacement = 'bottom';
          position.top = wrapperRect.top + wrapperRect.height;
        }
        break;
      default:
        break;
    }

    const { left } = position;
    position.left = Math.max(0, Math.min(clientWidth - tooltipRect.width, left));
    const offsetX = left - position.left;

    setPosition({ coords: position, placement: currentPlacement, offsetX });
  }

  useOnClickOutside(wrapperRef, () => setIsRendered(false), !tappable);

  function showTooltip() {
    setPosition(null);
    setIsRendered(true);
  }

  useEffect(() => {
    if (visible === undefined) {
      return;
    }

    if (visible) {
      showTooltip();
    } else {
      setIsRendered(false);
    }
  }, [visible]);

  useEffect(() => {
    if (!isRendered || disabled) {
      return undefined;
    }

    const handle = window.setTimeout(
      () => {
        recalculatePosition();
      },
      visible ? 0 : 500,
    );

    return () => window.clearTimeout(handle);
  }, [isRendered, visible, placement, disabled]);

  useEffect(() => {
    window.clearTimeout(tapTimer.current);
  }, [disabled]);

  // overlay can change its size so need to recalculate position
  useEffect(() => {
    recalculatePosition();
  }, [overlay]);

  const container = document.getElementById(CONTAINER_ID);

  return (
    <div
      className={cn(styles.wrapper, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      ref={wrapperRef}
    >
      {children}
      {isRendered &&
        !disabled &&
        container &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={cn(
              'cy-tooltip',
              styles.tooltip,
              styles[tooltipStyle],
              styles[position?.placement ?? DEFAULT_PLACEMENT],
              { [styles.hidden]: !position },
            )}
            style={{
              left: `${position?.coords.left || 0}px`,
              top: `${position?.coords.top || 0}px`,
            }}
          >
            {tooltipStyle === 'default' && (
              <div className={styles.arrow} style={{ transform: `translateX(${position?.offsetX || 0}px)` }} />
            )}
            <div className={styles.overlay}>{overlay}</div>
          </div>,
          container,
        )}
    </div>
  );
}

export default WithTooltip;
