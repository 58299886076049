import { DropShadow, LeaningShadow } from 'editor/src/store/design/types';

function isShadowEmpty(shadow: DropShadow | LeaningShadow | undefined) {
  if (shadow?.type === 'leaning-shadow') {
    return false;
  }
  return !shadow || (shadow.offsetX === 0 && shadow.offsetY === 0 && !shadow.blur && (shadow.scale ?? 1) <= 0);
}

export default isShadowEmpty;
