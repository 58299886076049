import { MimeType } from 'editor/src/store/gallery/types';
import { PluginName } from 'editor/src/store/plugins/types';

import { MenuContext } from 'editor/src/component/Menu/type';

import canUsePluginToEditImage from './canUsePluginToEditImage';

export function canUseFiltersOnImageType(type: MimeType | undefined) {
  if (!type) {
    return false;
  }
  switch (type) {
    case MimeType.SVG:
    case MimeType.PDF:
      return false;
    default:
      return true;
  }
}

export default function canUseFiltersOnImage(context: MenuContext) {
  return canUseFiltersOnImageType(context.imageType) && canUsePluginToEditImage(PluginName.Filters, context);
}
