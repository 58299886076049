import cmykProfile from './cmyk.icc';
import loader from './lcms_rgb_to_cmyk';
import wasm from './lcms_rgb_to_cmyk.wasm';
import srgbProfile from './srgb.icc';

interface LittleCMS {
  _init_transform: (
    inputProfilePointer: number,
    profileLength: number,
    outputProfilePointer: number,
    outputLength: number,
  ) => void;
  _rgb_to_cmyk: (r: number, g: number, b: number, cmykPointer: number) => void;
  _cmyk_to_rgb: (c: number, m: number, y: number, k: number, rgbPointer: number) => void;
  _malloc: (size: number) => number;
  _free: (pointer: number) => void;
  HEAPU8: Uint8Array;
}

function allocateMemory(buffer: ArrayBuffer, lcms: LittleCMS): number {
  const size = buffer.byteLength;
  const pointer = lcms._malloc(size); // Allocating memory in the WASM module
  new Uint8Array(lcms.HEAPU8.buffer).set(new Uint8Array(buffer), pointer); // Copying data into the WASM memory
  return pointer;
}

let littleCMSModule: LittleCMS | undefined;

export async function loadLittleCMSModule(): Promise<void> {
  if (!littleCMSModule) {
    const [module, cmykProfileArray, rgbProfileArray] = await Promise.all([
      loader({ locateFile: () => wasm }) as Promise<LittleCMS>,
      fetch(cmykProfile)
        .then((resp) => resp.arrayBuffer())
        .then((buffer) => new Uint8Array(buffer)),
      fetch(srgbProfile)
        .then((resp) => resp.arrayBuffer())
        .then((buffer) => new Uint8Array(buffer)),
    ]);

    const rgbProfilePointer = allocateMemory(rgbProfileArray, module);
    const cmykProfilePointer = allocateMemory(cmykProfileArray, module);
    module._init_transform(rgbProfilePointer, rgbProfileArray.length, cmykProfilePointer, cmykProfileArray.length);
    littleCMSModule = module;
  }
}

export function getModule(): LittleCMS | undefined {
  return littleCMSModule;
}
