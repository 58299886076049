import { MediaLine } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';

import { getValidatedStrokeWidth } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';

function applyScaleToLineElement(
  sourceElement: MediaLine,
  destElement: MediaLine,
  strokeScale: number,
  scaleX: number,
  scaleY: number,
) {
  destElement.x1 = limitPrecision(sourceElement.x1 * scaleX);
  destElement.y1 = limitPrecision(sourceElement.y1 * scaleY);
  destElement.x2 = limitPrecision(sourceElement.x2 * scaleX);
  destElement.y2 = limitPrecision(sourceElement.y2 * scaleY);
  destElement.strokeWidth = getValidatedStrokeWidth(limitPrecision(sourceElement.strokeWidth * strokeScale, 2));
}

export default applyScaleToLineElement;
