import { createSlice } from '@reduxjs/toolkit';

import setDesignIsLoadingReducer from './reducer/setDesignIsLoadingReducer';
import setProductImagesReducer from './reducer/setProductImagesReducer';
import setSelectedPreviewIndexReducer from './reducer/setSelectedPreviewIndexReducer';

export interface ProductPersonalizerState {
  selectedPreviewIndex: number;
  designIsLoading: boolean;
  productImages: string[] | undefined;
}

export const productPersonalizerinitialState: ProductPersonalizerState = {
  selectedPreviewIndex: 0,
  designIsLoading: false,
  productImages: undefined,
};

const slice = createSlice({
  name: 'productPersonalizer',
  initialState: productPersonalizerinitialState,
  reducers: {
    setSelectedPreviewIndexAction: setSelectedPreviewIndexReducer,
    setDesignIsLoadingAction: setDesignIsLoadingReducer,
    setProductImagesAction: setProductImagesReducer,
  },
});

export const { setSelectedPreviewIndexAction, setDesignIsLoadingAction, setProductImagesAction } = slice.actions;
const { reducer } = slice;

export default reducer;
