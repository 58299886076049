import memoize from 'lodash/memoize';

import { StructureIndex } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import { getStructureIndexByElementUuid } from './getStructureIndexByElementUuid';

const getStructureIndexesByElementUuids = memoize(
  (state: RootState, uuids: number[]) => {
    const result: StructureIndex[] = [];

    uuids.forEach((uuid) => {
      const si = getStructureIndexByElementUuid(state.design.designData, uuid);
      if (si) {
        result.push(si);
      }
    });

    return result;
  },
  (state: RootState, uuids: number[]) => {
    const result: StructureIndex[] = [];

    uuids.forEach((uuid) => {
      const si = getStructureIndexByElementUuid(state.design.designData, uuid);
      if (si) {
        result.push(si);
      }
    });

    return JSON.stringify(result);
  },
);

export default getStructureIndexesByElementUuids;
