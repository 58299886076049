import React from 'react';

import styles from './index.module.scss';

export const CONTAINER_ID = 'menu-container-id';

function MobileMenuContainer() {
  return <div id={CONTAINER_ID} className={styles.MobileMenuContainer} />;
}

export default MobileMenuContainer;
