import { fontList } from '@gelatoas/design-editor-fonts';
import { createSlice } from '@reduxjs/toolkit';

import fontManager from './fontManager';
import setAvailableFontsReducer from './reducer/setAvailableFontsReducer';
import setDefaultFontStylesReducer from './reducer/setDefaultFontStylesReducer';
import setFontListReducer from './reducer/setFontListReducer';
import updateFontStatusReducer from './reducer/updateFontStatusReducer';
import { FontState } from './types';
import defaultFontStyles from './utils/defaultFontStyles';
import fontMetadataToFontDefinition from './utils/fontMetadataToFontDefinition';
import getOrderedFallbackFonts from './utils/getOrderedFallbackFonts';

export const fontsInitialState: FontState = {
  availableFonts: fontList.map(fontMetadataToFontDefinition),
  defaultFontStyles,
};

fontManager.fontDefinitions = getOrderedFallbackFonts(fontsInitialState.availableFonts);

const slice = createSlice({
  name: 'fonts',
  initialState: fontsInitialState,
  reducers: {
    setDefaultFontStylesAction: setDefaultFontStylesReducer,
    updateFontStatusAction: updateFontStatusReducer,
    setFontListAction: setFontListReducer,
    setAvailableFontsAction: setAvailableFontsReducer,
  },
});

const { actions, reducer } = slice;

export const { setDefaultFontStylesAction, updateFontStatusAction, setFontListAction, setAvailableFontsAction } =
  actions;

export default reducer;
