// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonalizationCondition--lBrlM{border-radius:6px;border:1px solid rgba(0,0,0,0)}.PersonalizationCondition--lBrlM .name--Ep5yK{font-size:14px;line-height:24px;color:#6b6b6b;user-select:none}`, "",{"version":3,"sources":["webpack://./src/component/PersonalizationContent/PersonalizationCondition/index.module.scss"],"names":[],"mappings":"AAEA,iCACE,iBAAA,CACA,8BAAA,CAGA,8CACE,cAAA,CACA,gBAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":["@import 'editor/src/comonStyles/variables';\n\n.PersonalizationCondition {\n  border-radius: 6px;\n  border: 1px solid transparent;\n\n\n  .name {\n    font-size: 14px;\n    line-height: 24px;\n    color: #6b6b6b;\n    user-select: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PersonalizationCondition": `PersonalizationCondition--lBrlM`,
	"name": `name--Ep5yK`
};
export default ___CSS_LOADER_EXPORT___;
