import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

import { makeGroupKey } from '../helpers/groupVariantsBy';

const addDesignOptionReducer = (state: VariantsState, action: PayloadAction<{ controlKey: string }>) => {
  const { controlKey } = action.payload;

  const control = state.designOptionsControl?.find((control) => control.key === controlKey);
  if (control) {
    const lastOption = control.options[control.options.length - 1];
    const splitValues = lastOption.value.split('-');
    const optionNumber = parseInt(splitValues[splitValues.length - 1], 10);
    const newControlValue = `design-option-${optionNumber + 1}`;
    control.options.push({
      title: `Design Option ${optionNumber + 1}`,
      value: newControlValue,
    });
    state.selectedDesignOptionValue = newControlValue;

    state.variationGroups.forEach((group) => {
      const variation = group.variationsInfo[0];
      const originalKey = makeGroupKey(
        {
          variation: variation.variation,
          dimensions: variation.dimensions,
          pageCount: variation.pageCount,
        },
        state.product.groupBy,
      );
      const spreadImages = state.spreadImages[group.key];

      if (spreadImages) {
        control.options.forEach((option) => {
          state.spreadImages[`${originalKey}-${option.value}`] = spreadImages;
        });
      }
    });
  }
};

export default addDesignOptionReducer;
