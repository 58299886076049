/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconCriticalWarning({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7L17 17M7 17L17 7.00001" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M7.36441 1.07924L16.4443 1L22.9208 7.36441L23 16.4443L16.6356 22.9208L7.55571 23L1.07924 16.6356L1 7.55571L7.36441 1.07924Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconCriticalWarning;
