import { DesignData, Spread } from 'editor/src/store/design/types';

import { elementUuids, conditionUuids, conditionOptionUuids } from 'editor/src/util/uuids';

function setupUuidGenerators(designDataItems: DesignData[]) {
  let elementMaxUuid = 1;
  const conditionMaxUuid = 1;
  const valueMaxUuid = 1;
  let hasMissingUuids = false;

  const extractMaxUuids = (spread: Spread) => {
    spread.pages.forEach((page) => {
      page.groups.media?.forEach((mediaElement) => {
        if (mediaElement.uuid) {
          elementMaxUuid = Math.max(elementMaxUuid, mediaElement.uuid);
        } else {
          hasMissingUuids = true;
        }
      });
    });
  };

  designDataItems.forEach((designData) => designData.spreads.forEach(extractMaxUuids));

  // we should guarantee that we don't reduce generator value for ecomm flow
  elementMaxUuid >= elementUuids.getCurrent() && elementUuids.setup(elementMaxUuid);
  conditionMaxUuid >= conditionUuids.getCurrent() && conditionUuids.setup(conditionMaxUuid);
  valueMaxUuid >= conditionOptionUuids.getCurrent() && conditionOptionUuids.setup(valueMaxUuid);

  if (hasMissingUuids) {
    const applyMaxUuids = (spread: Spread) => {
      spread.pages.forEach((page) => {
        page.groups.media?.forEach((mediaElement) => {
          if (!mediaElement.uuid) {
            mediaElement.uuid = elementUuids.generate();
          }
        });
      });
    };

    designDataItems.forEach((designData) => designData.spreads.forEach(applyMaxUuids));
  }
}

export default setupUuidGenerators;
