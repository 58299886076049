import { PayloadAction } from '@reduxjs/toolkit';

import {
  Condition,
  ConditionAddress,
  ConditionDisplayOption,
  conditionGroupChildenKey,
  ConditionOption,
  DesignState,
} from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

const createEmptyConditionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    name: string;
    option1: ConditionOption;
    option2: ConditionOption;
    parent?: { conditionId: string; optionId: string };
  }>,
) => {
  const { address, name, parent, option1, option2 } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition: Condition = {
    id: conditionId,
    name,
    displayOption: ConditionDisplayOption.Button,
    options: [option1, option2],
    parent: parent ? { conditionId: parent.conditionId, optionIds: [parent.optionId] } : undefined,
    activeOptionId: option1.id,
  };

  conditionGroup.conditions[conditionId] = condition;

  const childenKey = parent ? getConditionChildrenKey(parent.conditionId, parent.optionId) : conditionGroupChildenKey;
  if (!conditionGroup.children[childenKey]) {
    conditionGroup.children[childenKey] = [];
  }
  conditionGroup.children[childenKey].push({
    type: 'condition',
    id: conditionId,
  });

  condition.options.forEach((option) => {
    conditionGroup.children[getConditionChildrenKey(condition.id, option.id)] = [];
  });
};

export default createEmptyConditionReducer;
