// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlatPreviewList--BfLb1{height:140px;width:100%;display:flex;overflow-x:auto}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Preview/FlatPreview/FlatPreviewList/index.module.scss"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA","sourcesContent":["@import 'editor/src/comonStyles/index';\n\n.FlatPreviewList {\n  height: 140px;\n  width: 100%;\n  display: flex;\n  overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatPreviewList": `FlatPreviewList--BfLb1`
};
export default ___CSS_LOADER_EXPORT___;
