import { MockupProductEntry, MockupProductProduct } from 'editor/src/store/mockup/types';

function findFirstMockupProduct(productCategories: MockupProductEntry[]): MockupProductProduct | undefined {
  // eslint-disable-next-line no-restricted-syntax
  for (const productCategory of productCategories) {
    if (productCategory.type === 'category') {
      const result = findFirstMockupProduct(productCategory.children);
      if (result) {
        return result;
      }
    } else if (productCategory.type === 'product') {
      return productCategory;
    }
  }

  return undefined;
}

export default findFirstMockupProduct;
