import { PayloadAction } from '@reduxjs/toolkit';

import { FlatPreview, PreviewState } from 'editor/src/store/editorModules/preview/types';

const setFlatPreviewReadyReducer = (
  state: PreviewState,
  action: PayloadAction<{ index: number; preview: FlatPreview }>,
) => {
  state.flatPreviews[action.payload.index] = action.payload.preview;
};

export default setFlatPreviewReadyReducer;
