import { PayloadAction } from '@reduxjs/toolkit';

import { MockupState } from 'editor/src/store/mockup/types';

const updateMockupDataWithVariantReducer = (state: MockupState, action: PayloadAction<string>) => {
  const productUid = action.payload;
  const { variations, controls, multiOptions, singleOptions } = state.productPlaceholder;

  const currentVariation = variations.find((variation) => variation.productUid === productUid);
  if (currentVariation) {
    controls.forEach((control) => {
      if (control.type === 'multi') {
        multiOptions[control.key] = [
          {
            value: currentVariation[control.key],
            dependedOptions: control.dependsOnSingleControls
              ? controls
                  .filter((control) => control.type === 'single')
                  .map((control) => ({
                    value: currentVariation[control.key],
                    key: control.key,
                  }))
              : [],
          },
        ];
      } else if (control.type === 'single') {
        singleOptions[control.key] = currentVariation[control.key];
      }
    });
  }
};

export default updateMockupDataWithVariantReducer;
