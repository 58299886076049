import { setFocusedContentAction as setFocusedContentOperation } from 'editor/src/store/editor/slice';
import { WarningType } from 'editor/src/store/editorModules/warnings/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setHostSettingsAction } from 'editor/src/store/hostSettings/slice';
import { HostSettingsState } from 'editor/src/store/hostSettings/types';
import { RootState } from 'editor/src/store/index';

import { extractDisplayPages } from 'editor/src/component/EditorArea/utils/displayPageUtils';

const setHostSettingsOperation =
  (settings: Partial<HostSettingsState>) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    dispatch(setHostSettingsAction(settings));

    const state = getState();
    if (settings.splitDisplayPerContent) {
      if (state.design.designData) {
        const displayPage = extractDisplayPages(state.design.designData.spreads, true);
        if (displayPage.length) {
          dispatch(setFocusedContentOperation(displayPage[0].focusedContent));
        }
      }
    }

    // TODO: remove this when the host uses the warning system
    if (!state.hostSettings.dpiLevels) {
      dispatch(
        setHostSettingsAction({
          enabledWarnings: {
            ...state.hostSettings.enabledWarnings,
            [WarningType.LowDPI]: false,
          },
        }),
      );
    }
  };

export default setHostSettingsOperation;
