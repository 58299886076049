import { MediaText } from 'editor/src/store/design/types';

import { extractTextProperties } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

const updateTextPropertiesWithoutRender = (
  element: MediaText,
  elementUpdate: Partial<MediaText>,
  container: { x: number; width: number } | undefined,
) => {
  const { width, height, sx, sy, fontSize } = extractTextProperties({ ...element, ...elementUpdate }, container);
  elementUpdate.width = width;
  elementUpdate.height = height;
  elementUpdate.sx = sx;
  elementUpdate.sy = sy;
  elementUpdate.extra = {
    ...element.extra,
    ...elementUpdate.extra,
    fontSize,
  };
};

export default updateTextPropertiesWithoutRender;
