import { Addon } from 'editor/src/store/editorModules/addons/types';
import { RootState } from 'editor/src/store/index';

const getAddonByIdFromCurrent = (state: RootState, addonId: string) => {
  let addon = state.editorModules.addons.data?.results?.elements?.find((element) => element.id === addonId);
  if (!addon) {
    addon = state.editorModules.addons.data?.results?.category?.elements?.find((element) => element.id === addonId);
  }
  if (!addon) {
    addon = state.editorModules.addons.data?.results.category?.subcategories?.reduce<Addon | undefined>(
      (acc, category) => category.elements.find((element) => element.id === addonId) || acc,
      undefined,
    );
  }

  return addon;
};

export default getAddonByIdFromCurrent;
