import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const setCurrentStyleReducer = <T extends keyof EditorState['currentStyles']>(
  state: EditorState,
  action: PayloadAction<{
    type: T;
    update: Partial<EditorState['currentStyles'][T]>;
  }>,
) => {
  const { type, update } = action.payload;
  Object.assign(state.currentStyles[type], update);
};

export default setCurrentStyleReducer;
