import { MediaText } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';

function scaleTextElement(sourceElement: MediaText, destElement: MediaText, scale: number) {
  destElement.extra.fontSize = limitPrecision(sourceElement.extra.fontSize * scale);
  destElement.sx = limitPrecision(sourceElement.sx * scale);
  destElement.sy = limitPrecision(sourceElement.sy * scale);
}

export default scaleTextElement;
