export const imageElementNamePrefix = 'image_';
export const textElementNamePrefix = 'text_';

export const getImageElementName = (spreadIndex: number, pageElementIndex: number) =>
  getElementName(imageElementNamePrefix, spreadIndex, pageElementIndex);
export const getTextElementName = (spreadIndex: number, pageElementIndex: number) =>
  getElementName(textElementNamePrefix, spreadIndex, pageElementIndex);

const getElementName = (prefix: string, spreadIndex: number, pageElementIndex: number) =>
  `${prefix}${spreadIndex + 1}_${pageElementIndex}`;
