import { ConditionGroup, conditionGroupChildenKey } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

function getConditionParentKeys(conditionGroup: ConditionGroup, conditionId: string) {
  const condition = conditionGroup.conditions[conditionId];
  const { parent } = condition;
  return parent
    ? parent.optionIds.map((optionId) => getConditionChildrenKey(parent.conditionId, optionId))
    : [conditionGroupChildenKey];
}

export default getConditionParentKeys;
