import { unicodeBlocks } from '@gelatoas/design-editor-fonts';
import { captureException, captureMessage } from '@sentry/react';

import fontManager, { FontInfo } from 'editor/src/store/fonts/fontManager';
import { FontDefinition } from 'editor/src/store/fonts/types';
import loadFont from 'editor/src/store/fonts/utils/loadFont';

const fetched: { [char: string]: Promise<any> } = {};
const fontCacheByBlock: { [block: number]: FontDefinition } = {};
let lastFallbackFont: FontDefinition | undefined;

function findWorkingFont(char: string) {
  const unicode = char.charCodeAt(0);
  const blockIndex = unicodeBlocks.findIndex(([start, end]) => unicode >= start && unicode <= end);

  if (!fontCacheByBlock[blockIndex]) {
    const font = fontManager.fontDefinitions.find((font) => font.metadata.unicodeBlockIndexes.includes(blockIndex));
    if (!font) {
      captureMessage('no fallback font', {
        extra: { char, blockIndex },
      });
      return undefined;
    }
    fontCacheByBlock[blockIndex] = font;
  }

  return fontCacheByBlock[blockIndex];
}

function getFont(fontFamily: string, char: string, onFallbackFontLoaded: () => void): FontInfo | undefined {
  const font = fontManager.loadedFontMap[fontFamily] as FontInfo | undefined;

  if (font && char.length === 1 && !fontHasChar(font, char)) {
    const workingFont =
      (lastFallbackFont &&
        fontHasChar(fontManager.loadedFontMap[lastFallbackFont.metadata.fontFile], char) &&
        lastFallbackFont) ||
      findWorkingFont(char);

    if (workingFont) {
      lastFallbackFont = workingFont;
      if (fontManager.loadedFontMap[workingFont.metadata.fontFile]) {
        return fontManager.loadedFontMap[workingFont.metadata.fontFile];
      }

      fontManager.fallbackFonts.add(workingFont.metadata.fontFile);
      const promise = fetched[char] ?? loadFont(workingFont, { executor: 'FabricPathText' });
      fetched[char] = promise;

      promise
        .then(() => {
          const fallbackFont = fontManager.loadedFontMap[workingFont.metadata.fontFile];
          if (fallbackFont && !fontHasChar(fallbackFont, char)) {
            captureException(new Error('wrong fallback font'), {
              extra: { font: workingFont.metadata, char },
            });
          } else {
            onFallbackFontLoaded();
          }
        })
        .catch(() => {
          // font load error are already captured by loadFont
        })
        .finally(() => {
          delete fetched[char];
        });
    }
  }

  return font;
}

// according to the opentype fix that is not released yet https://github.com/opentypejs/opentype.js/pull/632/files
// 0 is the standard code for the .notdefglyph, to be displayed as a fallback. https://learn.microsoft.com/en-us/typography/opentype/spec/cmap
const fontHasChar = (fontInfo: FontInfo | undefined, char: string) => {
  return fontInfo && fontInfo.obj.charToGlyphIndex(char) > 0;
};

export default getFont;
