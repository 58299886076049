import debounce from 'lodash/debounce';
import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import {
  createHash,
  overrideColorsToReplacementColors,
} from 'editor/src/store/design/operation/getAssetUrlWithReplacedColors';
import requestDigitizedAssetOperation from 'editor/src/store/design/operation/requestDigitizedAssetOperation';
import setAssetWithReplacedColorsOperation from 'editor/src/store/design/operation/setAssetWithReplacedColorsOperation';
import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { DesignData, MediaImage, MediaText } from 'editor/src/store/design/types';
import getDigitizedElementId from 'editor/src/store/design/util/getDigitizedElementId';
import updateImageOnFailedUpload from 'editor/src/store/gallery/operation/updateImageOnFailedUpload';
import { ImageState } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import shouldDigitizeSpread from 'editor/src/store/utils/shouldDigitizeSpread';

import limitPrecision from 'editor/src/util/limitPrecision';
import { RequestDigitizedAssetData } from 'editor/src/util/postMessages/messages';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';
import applyColorOverridesToAsset from 'product-personalizer/src/utils/applyColorOverridesToAsset';

import { getRenderedTextObject } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

const useDigitizeElements = (designData: DesignData | undefined, currentSpreadIndex: number, previewSize: number) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fetchingColorOverridesRef = useRef(new Set<string>());
  const quantizingAssetRef = useRef(new Set<string>());
  const digitizingAssetRef = useRef(new Set<string>());
  const currentSpread = designData?.spreads[currentSpreadIndex];
  const debouncedFunctions = useRef<{ [key: number]: (request: any) => void }>({});

  const { digitizedAssets, galleryImages } = useSelector(
    (state) => ({
      digitizedAssets: state.design.digitizedAssets,
      galleryImages: state.gallery.images,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const pageMedia = currentSpread?.pages[0].groups.media;
    if (!pageMedia || !currentSpread || !shouldDigitizeSpread(currentSpread)) {
      return;
    }

    pageMedia.forEach((media) => {
      if (media.type !== 'text' && media.type !== 'image') {
        return;
      }

      const elementId = getDigitizedElementId(media);
      const digitizedAsset = digitizedAssets[elementId];
      // if we have digitized the same element before we should apply it from the cache
      if (digitizedAsset && elementId !== media.digitizing_hash) {
        dispatch(
          updateMediaElementByUuidOperation(media.uuid, {
            digitizing: undefined,
            digitizing_hash: elementId,
            stitch_count: digitizedAsset.stitchCount,
          }),
        );
      }
    });
  }, [currentSpread, digitizedAssets]);

  const { textElements, imageElements } = useMemo(() => {
    let textElements: MediaText[] = [];
    let imageElements: MediaImage[] = [];
    const pageMedia = currentSpread?.pages[0].groups.media;

    if (pageMedia && currentSpread && shouldDigitizeSpread(currentSpread)) {
      textElements = pageMedia.filter(
        (el) => el.type === 'text' && !digitizedAssets[getDigitizedElementId(el)],
      ) as MediaText[];
      imageElements = pageMedia.filter(
        (el) => el.type === 'image' && !digitizedAssets[getDigitizedElementId(el)],
      ) as MediaImage[];
    }

    return { textElements, imageElements };
  }, [currentSpread, digitizedAssets]);

  // we should have a separate debounce per element.
  // If we have some shared one it request only 2 assets max during the 1000ms. Other elements won't be rendered
  const debounceRequestByElementId = useCallback((request: any, elementId: number) => {
    if (!debouncedFunctions.current[elementId]) {
      debouncedFunctions.current[elementId] = debounce(
        (request: any) => {
          dispatch(requestDigitizedAssetOperation(request));
        },
        1000,
        { leading: true, trailing: true },
      );
    }

    return debouncedFunctions.current[elementId](request);
  }, []);

  useEffect(() => {
    if (!designData) {
      return;
    }

    textElements.forEach((textElement) => {
      const elementId = getDigitizedElementId(textElement);
      if (digitizingAssetRef.current.has(elementId)) {
        return;
      }
      digitizingAssetRef.current.add(elementId);

      const request: RequestDigitizedAssetData = {
        elementType: 'text',
        elementId,
        payload: {
          textElement,
          output_px_width: previewSize,
          path: getRenderedTextObject(textElement).toSVGRelativeCoords(),
          variantKey: undefined,
          elementUuid: textElement.uuid,
        },
        imageId: undefined,
        productId: designData.product_uid,
        reuseIfExist: true,
      };
      debounceRequestByElementId(request, textElement.uuid);
    });
  }, [debounceRequestByElementId, textElements, previewSize, designData, dispatch]);

  useEffect(() => {
    if (!designData) {
      return;
    }

    imageElements.forEach((imageElement) => {
      if (!imageElement.imageId) {
        return;
      }

      const galleryImage = galleryImages.find((img) => img.id === imageElement.imageId);
      if (galleryImage?.state !== ImageState.UPLOADED) {
        return;
      }

      const { quantizedUrl, quantizedColors } = galleryImage;
      // Handle Non-Quantized Uploaded Images
      if (!quantizedUrl || !quantizedColors) {
        if (quantizingAssetRef.current.has(imageElement.imageId)) {
          return;
        }
        quantizingAssetRef.current.add(imageElement.imageId);

        dispatch(updateMediaElementByUuidOperation(imageElement.uuid, { digitizing: true }));
        sendPostMessage('design.requestQuantizedAsset', imageElement.imageId);
        return;
      }

      const overrideColors = imageElement.colorOverrides;
      if (overrideColors && !overrideColors.url) {
        const hash = createHash({
          assetId: imageElement.imageId,
          elementId: imageElement.uuid,
          overrides: overrideColors,
          quantized: true,
          selectedKeys: {
            variantKey: undefined,
            designOptionKey: undefined,
          },
        });
        if (fetchingColorOverridesRef.current.has(hash)) {
          return;
        }
        fetchingColorOverridesRef.current.add(hash);
        window.setTimeout(() => fetchingColorOverridesRef.current.delete(hash), 10000);

        applyColorOverridesToAsset(imageElement.imageId, overrideColors)
          .then((response: { url: string }) => {
            dispatch(
              setAssetWithReplacedColorsOperation({
                assetId: imageElement.imageId,
                colors: overrideColorsToReplacementColors(overrideColors),
                url: response.url,
                variantKey: undefined,
                designOptionKey: undefined,
              }),
            );
          })
          .catch(() => {
            dispatch(updateImageOnFailedUpload(imageElement.imageId));
            toastController.warningPersist(t('editor-color-override-issue'), t('editor-colors-override-apply-failed'));
          });
        return;
      }

      // General Digitization Request
      const imageUrl = overrideColors?.url ? overrideColors.url : quantizedUrl;
      const elementId = getDigitizedElementId(imageElement);
      if (digitizingAssetRef.current.has(elementId)) {
        return;
      }
      digitizingAssetRef.current.add(elementId);

      const request: RequestDigitizedAssetData = {
        elementType: imageElement.type,
        elementId,
        payload: {
          imageUrl,
          rendered_mm_width: limitPrecision(imageElement.pw),
          output_px_width: previewSize,
          elementUuid: imageElement.uuid,
          variantKey: undefined,
        },
        imageId: undefined,
        productId: designData.product_uid,
        reuseIfExist: true,
      };
      debounceRequestByElementId(request, imageElement.uuid);
    });
  }, [debounceRequestByElementId, imageElements, galleryImages, previewSize, designData?.product_uid]);
};

export default useDigitizeElements;
