import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';

import useIsMounted from 'editor/src/util/useIsMounted';

import { PreviewAssetAPIContext, PreviewConfig } from './PreviewAssetAPI';

function use3dPreviewConfig(productUid: string | undefined) {
  const isMounted = useIsMounted();
  const [previewConfig, setPreviewConfig] = useState<PreviewConfig>();
  const [configLoading, setConfigLoading] = useState(true);
  const previewAssetAPI = React.useContext(PreviewAssetAPIContext);

  useEffect(() => {
    if (!productUid) {
      return;
    }

    setConfigLoading(true);

    previewAssetAPI
      .fetchAssets(productUid)
      .then((config) => {
        setConfigLoading(false);

        if (!isMounted() || !config) {
          setPreviewConfig(undefined);
          return;
        }

        const glbScene = config.scenes.find((scene) => scene.render_type === 'glb');
        if (!glbScene) {
          setPreviewConfig(undefined);
          return;
        }

        const modelUrl = glbScene.assets.model;
        const material = glbScene.config['model-material'];
        const viewer = glbScene.config['model-viewer'];
        const contentArea = glbScene.config['content-area'];
        if (!modelUrl || !material || !viewer) {
          setPreviewConfig(undefined);
          return;
        }

        setPreviewConfig({ modelUrl, material, viewer, contentArea });
      })
      .catch((e) => {
        setConfigLoading(false);
        captureException(e);
      });
  }, [productUid]);

  return { config: previewConfig, loading: configLoading };
}

export default use3dPreviewConfig;
