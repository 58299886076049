import { DigitizedAsset, MediaText, MediaImage, MediaAddon } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getDigitizedElementId from '../util/getDigitizedElementId';

const getDigitizedAsset = <T extends DigitizedAsset>(
  state: RootState,
  element: MediaText | MediaImage | MediaAddon,
): T | undefined => state.design.digitizedAssets[getDigitizedElementId(element)] as T | undefined;

export default getDigitizedAsset;
