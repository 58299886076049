import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const updateConditionChildPositionReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    childrenKey: string;
    prevIndex: number;
    newIndex: number;
  }>,
) => {
  const { spreadIndex, childrenKey, prevIndex, newIndex } = action.payload;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const children = conditionGroup.children[childrenKey];
  if (children) {
    const [element] = children.splice(prevIndex, 1);
    children.splice(newIndex, 0, element);
  }
};

export default updateConditionChildPositionReducer;
