import { captureException } from '@sentry/react';
import debounce from 'lodash/debounce';

import { DesignData } from 'editor/src/store/design/types';

import { PPStore } from './store';
import deleteDraftOperation from './store/drafts/operations/deleteDraftOperation';
import loadDraftsOperation from './store/drafts/operations/loadDraftsOperation';
import { Draft, addDraftAction, deleteDraftAction, updateDraftAction } from './store/drafts/slice';
import { getGuestCustomerId } from './store/drafts/utils/guestStorage';
import { DraftDesignItemResponse, getMetadataPayload, getMetadataValue } from './utils/draftDesign';

export async function loadDrafts(store: PPStore, customerId: string | undefined): Promise<void> {
  const customerReferenceId = customerId || getGuestCustomerId();
  const drafts = await getDraftsList(customerReferenceId);
  return store.dispatch(loadDraftsOperation(drafts, customerReferenceId));
}

export function getDraftsData(store: PPStore) {
  return store.getState().drafts;
}

const defaultDesignParams = { status: 'draft', accessMode: 'public' };
const MAX_KEEPALIVE_PAYLOAD = 63 * 1000; // 63 KB limit

export async function getDraftsList(customerReferenceId: string) {
  const url = `${LOCAL_ENV.ecomProxyEndpoint}/designs?customerReferenceId=${customerReferenceId}`;
  try {
    const res = await fetchWithRetry(url, { method: 'GET' });
    const { designs } = (await res.json()) as { designs: DraftDesignItemResponse[] };
    const drafts: Draft[] = [];
    designs.forEach((design) => {
      const externalVariantId = getMetadataValue(design.metadata, 'storeProductVariantId');
      const variantId = getMetadataValue(design.metadata, 'gelatoVariantId');

      if (externalVariantId) {
        drafts.push({
          designId: design.id,
          externalVariantId,
          variantId,
        });
      } else {
        captureException(`externalVariantId not found for design with id=${design.id}`);
      }
    });

    return drafts;
  } catch (error) {
    captureException(`Failed to fetch drafts for customerReferenceId=${customerReferenceId}: ${error.message}`, error);
    throw error;
  }
}

export async function createDraft(
  store: PPStore,
  designData: DesignData,
  storeProductVariantId: string,
  gelatoVariantId: string | undefined,
  customerId: string | undefined,
  ignoreCustomerId?: boolean,
): Promise<Draft> {
  // const variantId = gelatoVariantId || externalVariantId;
  if (!gelatoVariantId && !storeProductVariantId) {
    throw new Error('No variant id provided');
  }

  const res = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      structure: JSON.stringify(designData),
      customerReferenceId: ignoreCustomerId ? undefined : customerId || getGuestCustomerId(),
      metadata: getMetadataPayload(storeProductVariantId, gelatoVariantId),
      ...defaultDesignParams,
    }),
  });

  const { id: designId } = (await res.json()) as { id: string };
  const draft: Draft = {
    designId,
    externalVariantId: storeProductVariantId,
    variantId: gelatoVariantId,
  };
  store.dispatch(addDraftAction(draft));
  return draft;
}

export function updateDraft(store: PPStore) {
  const draftUpdateDebounceRef = debounce(
    (
      designId: string,
      draftData: { variantId: string | undefined; externalVariantId: string },
      designData: DesignData,
      customerId: string | undefined,
    ) => saveDraft(store, designId, draftData, designData, customerId),
    30000,
  );

  // Before page refresh or leave
  window.addEventListener('beforeunload', () => {
    draftUpdateDebounceRef.flush();
  });

  // For back/forward navigation
  window.addEventListener('popstate', () => {
    draftUpdateDebounceRef.flush();
  });

  // For visibility changes (like switching tabs)
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      draftUpdateDebounceRef.flush();
    }
  });

  return (
    designId: string,
    draftData: { variantId: string | undefined; externalVariantId: string },
    designData: DesignData,
    customerId: string | undefined,
  ) => draftUpdateDebounceRef(designId, draftData, designData, customerId);
}

export async function saveDraft(
  store: PPStore,
  designId: string,
  draftData: { variantId: string | undefined; externalVariantId: string },
  designData: DesignData,
  customerId: string | undefined,
) {
  const customerReferenceId = customerId || getGuestCustomerId();
  await sendUpdateDraftRequest(designId, draftData, customerReferenceId, JSON.stringify(designData));

  window.dispatchEvent(new CustomEvent('pp-draft-updated'));

  if (draftData.externalVariantId) {
    store.dispatch(
      updateDraftAction({
        designId,
        draft: { variantId: draftData.variantId, externalVariantId: draftData.externalVariantId },
      }),
    );
  }
}

export function sendUpdateDraftRequest(
  designId: string,
  draftData: { variantId: string | undefined; externalVariantId: string },
  customerReferenceId: string,
  structure: string,
) {
  const body = JSON.stringify({
    id: designId,
    customerReferenceId,
    metadata: getMetadataPayload(draftData.externalVariantId, draftData.variantId),
    structure,
    ...defaultDesignParams,
  });
  return fetchWithRetry(`${LOCAL_ENV.ecomProxyEndpoint}/designs/${designId}`, {
    method: 'put',
    headers: { 'content-type': 'application/json' },
    body,
    // TODO improve the logic for saving drafts ot tab close in scope of AD-4644
    keepalive: body.length * 2 < MAX_KEEPALIVE_PAYLOAD, // check if the body size is less than allowed for the keepalive flag
  });
}

export function deleteDraft(store: PPStore, designId: string): Promise<void> {
  return store.dispatch(deleteDraftOperation(designId));
}

export async function convertToDesignAndDelete(
  store: PPStore,
  designId: string,
  designData: DesignData,
): Promise<void> {
  await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs`, {
    method: 'put',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ id: designId, structure: JSON.stringify(designData), status: 'created' }),
  });
  store.dispatch(deleteDraftAction(designId));
}

const fetchWithRetry = async (url: string, options: RequestInit, retries = 3, delay = 1000): Promise<Response> => {
  const originalFetch = window.fetch.bind(window) || fetch;

  const timeout = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  try {
    const response = await originalFetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      await timeout(delay);
      return fetchWithRetry(url, options, retries - 1, delay);
    }
    throw error;
  }
};

function sendPutRequest(url: string, data: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(new Error(`HTTP ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Network Error'));
    xhr.send(JSON.stringify(data));
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function sendSaveDraftRequest(data: any) {
  try {
    return await sendPutRequest(`${LOCAL_ENV.ecomProxyEndpoint}/designs/${data.id}`, data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Save draft failed:', error);
    throw error;
  }
}
