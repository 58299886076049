import { VariationGroup } from 'editor/src/store/variants/types';

const getSelectedGroupKey = (variationGroups: VariationGroup[], selectedGroupKey: string | undefined) => {
  const isSelectedGroupKeyValid =
    selectedGroupKey !== undefined && !!variationGroups.find((group) => selectedGroupKey === group.key);
  if (isSelectedGroupKeyValid) {
    return selectedGroupKey;
  }

  return (variationGroups.length && variationGroups[0].key) || undefined;
};

export default getSelectedGroupKey;
