import React, { useMemo } from 'react';

import { Coords, Page } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import BlankElements from 'editor/src/component/EditorArea/Spread/Page/BlankElements';
import { getVisibleAreaAsPolygons } from 'editor/src/component/EditorArea/Spread/Page/contentClippingUtils';
import Diecut from 'editor/src/component/EditorArea/Spread/Page/Diecut';
import FoldingLine from 'editor/src/component/EditorArea/Spread/Page/FoldingLine';
import HoleElement from 'editor/src/component/EditorArea/Spread/Page/Hole';
import SpreadBackgroundColor from 'editor/src/component/EditorArea/Spread/Page/SpreadBackgroundColor';
import { NO_CANVAS_ROTATION } from 'editor/src/component/EditorArea/useCanvasRotation';

interface Props {
  page: Page;
  pagesKey: string;
  hasBackgroundImage: boolean;
  spreadCoords: Coords;
  backgroundColor: string;
  showBlankAreaShadowOnly?: boolean;
}

function PageElement({
  backgroundColor,
  page,
  pagesKey,
  spreadCoords,
  hasBackgroundImage,
  showBlankAreaShadowOnly = false,
}: Props) {
  const { mm2px } = useFabricUtils();

  const pageCoords = useMemo(
    () => ({ left: spreadCoords.left + mm2px(page.x || 0), top: spreadCoords.top + mm2px(page.y || 0) }),
    [mm2px, spreadCoords, page.x, page.y],
  );

  const { foldingline, blank, diecut, hole: holes } = page.groups;

  const visibleAreaPolygons = useMemo(
    () => getVisibleAreaAsPolygons([page], spreadCoords, mm2px, NO_CANVAS_ROTATION),
    [spreadCoords, mm2px, pagesKey],
  );

  return (
    <>
      {(!hasBackgroundImage || page.backgroundColor) && (
        <SpreadBackgroundColor
          page_nr={page.page_nr}
          contents={page.groups.content}
          bleeds={page.groups.bleed}
          mediabox={page.groups.mediabox}
          focusedContent={undefined}
          pageCoords={pageCoords}
          backgroundColor={page.backgroundColor}
          canvasRotation={NO_CANVAS_ROTATION}
        />
      )}
      <BlankElements
        blanks={blank}
        pageCoords={pageCoords}
        canvasRotation={NO_CANVAS_ROTATION}
        showBlankAreaShadowOnly={showBlankAreaShadowOnly}
      />
      {diecut?.map((diecut, index) => (
        <Diecut key={index} diecut={diecut} pageCoords={pageCoords} canvasRotation={NO_CANVAS_ROTATION} />
      ))}
      {foldingline?.map((foldingLine, index) => (
        <FoldingLine key={index} element={foldingLine} pageCoords={pageCoords} canvasRotation={NO_CANVAS_ROTATION} />
      ))}
      {holes?.map((hole, index) => (
        <HoleElement
          key={index}
          hole={hole}
          pageCoords={pageCoords}
          canvasRotation={NO_CANVAS_ROTATION}
          color={backgroundColor}
          contentAreaPolygon={visibleAreaPolygons.contentArea}
        />
      ))}
    </>
  );
}

export default React.memo(PageElement);
