import { fabric } from 'fabric';

export function setClipPath(element: fabric.Object | undefined | null, clipPath: fabric.Object | undefined) {
  if (!element) {
    return;
  }
  removeCacheCanvas(element.clipPath);
  element.set('clipPath', clipPath);
}

export function disposeElement(element: fabric.Object) {
  removeCacheCanvas(element.clipPath);
  removeCacheCanvas(element);
  (element as any).dispose?.();
}

function clearAndRemoveCache(element: fabric.Object | undefined) {
  if (element && (element as any)._cacheCanvas) {
    (element as any)._cacheCanvas.width = 0;
    (element as any)._cacheCanvas.height = 0;
  }
  (element as any)?._removeCacheCanvas();
}

export function removeCacheCanvas(element: fabric.Object | undefined) {
  if (element instanceof fabric.Group) {
    element._objects.forEach(clearAndRemoveCache);
  }
  clearAndRemoveCache(element);
}
