import { createSlice } from '@reduxjs/toolkit';

import addAddonInUseReducer from './reducer/addAddonInUseReducer';
import increaseRequestedVersionReducer from './reducer/increaseRequestedVersionReducer';
import removeAddonInUseReducer from './reducer/removeAddonInUseReducer';
import setAddonsAttributionImageReducer from './reducer/setAddonsAttributionImageReducer';
import setAddonsDataReducer from './reducer/setAddonsDataReducer';
import setAddonsInUseReducer from './reducer/setAddonsInUseReducer';
import setRequestedVersionReducer from './reducer/setRequestedVersionReducer';
import setSearchValueReducer from './reducer/setSearchValueReducer';
import { AddonsState } from './types';

export const addonsInitialState: AddonsState = {
  inUse: [],
  requestedVersion: 0,
  data: {
    categoryId: 0,
    search: {
      value: '',
    },
    resultsVersion: 0,
    results: {},
  },
  attributionImage: undefined,
};

export const slice = createSlice({
  name: 'addons',
  initialState: addonsInitialState,
  reducers: {
    setAddonsInUseAction: setAddonsInUseReducer,
    addAddonInUseAction: addAddonInUseReducer,
    removeAddonInUseAction: removeAddonInUseReducer,
    setAddonsAttributionImageAction: setAddonsAttributionImageReducer,
    setSearchValueAction: setSearchValueReducer,
    setAddonsDataAction: setAddonsDataReducer,
    increaseRequestedVersionAction: increaseRequestedVersionReducer,
    setRequestedVersionAction: setRequestedVersionReducer,
  },
});

export const {
  setAddonsInUseAction,
  addAddonInUseAction,
  removeAddonInUseAction,
  setAddonsAttributionImageAction,
  setSearchValueAction,
  setAddonsDataAction,
  increaseRequestedVersionAction,
  setRequestedVersionAction,
} = slice.actions;

export default slice.reducer;
