import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

export type Payload = string;

const toggleGroupLinkReducer = (state: VariantsState, action: PayloadAction<Payload>) => {
  const groupKey = action.payload;
  const group = state.variationGroups.find((g) => g.key === groupKey);
  if (group) {
    group.linked = !group.linked;
  }
};

export default toggleGroupLinkReducer;
