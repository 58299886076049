import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setSpreadLayoutSchemaNameReducer = (
  state: DesignState,
  action: PayloadAction<{ spreadIndex: number; layoutSchemaName: string }>,
) => {
  const { spreadIndex, layoutSchemaName } = action.payload;
  const spread = state.designData?.spreads[spreadIndex];

  if (spread) {
    spread.layoutSchemaName = layoutSchemaName;
  }
};

export default setSpreadLayoutSchemaNameReducer;
