import Hammer from 'hammerjs';
import React, { useCallback, useEffect, useRef } from 'react';

import PreviewPagination from 'product-personalizer/src/component/PreviewPagination';

import styles from './index.module.scss';

interface Props {
  images: string[];
  showPreviewDialog: () => void;
  selectedIndex: number;
  hideImageSelector?: boolean;
  onIndexChanged: (index: number) => void;
}

function MobileCarousel({ images, showPreviewDialog, selectedIndex, onIndexChanged, hideImageSelector }: Props) {
  const hammer = useRef<HammerManager>();
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carouselRef.current) {
      hammer.current = new Hammer(carouselRef.current);
    }
  }, []);

  useEffect(() => {
    if (!hammer.current) {
      return undefined;
    }

    hammer.current.on('swipeleft', () => {
      onIndexChanged((selectedIndex + 1) % images.length);
    });
    hammer.current.on('swiperight', () => {
      onIndexChanged((selectedIndex - 1 + images.length) % images.length);
    });

    return () => {
      hammer.current?.off('swiperight');
      hammer.current?.off('swipeleft');
    };
  }, [images.length, selectedIndex]);

  const onImageClick = useCallback(() => {
    showPreviewDialog();
  }, [showPreviewDialog]);

  return (
    <div className={styles.Carousel} ref={carouselRef}>
      {!!images[selectedIndex] && (
        <div className={styles.imageContainer} onClick={onImageClick}>
          <img className={styles.selectedImage} src={images[selectedIndex]} alt="Preview" />
        </div>
      )}
      {!hideImageSelector && (
        <PreviewPagination images={images} selectedIndex={selectedIndex} onIndexChanged={onIndexChanged} />
      )}
    </div>
  );
}

export default React.memo(MobileCarousel);
