import React from 'react';

import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { useDispatch } from 'editor/src/store/hooks';

import useSpreadTitle from 'editor/src/util/design/useSpreadTitle';

import Button from 'editor/src/component/Button';

import styles from './index.module.scss';

interface Props {
  spreadName: string;
  spreadIndex: number;
  active: boolean;
}

function SpreadButton({ spreadName, spreadIndex, active }: Props) {
  const title = useSpreadTitle(spreadName, spreadIndex, undefined);
  const dispatch = useDispatch();

  function onClick() {
    dispatch(setCurrentSpreadIndexOperation(spreadIndex));
  }

  return (
    <Button onClick={onClick} primary={active} className={styles.SpreadButton}>
      {title}
    </Button>
  );
}

export default React.memo(SpreadButton);
