import hexEncoder from 'crypto-js/enc-hex';
import sha256 from 'crypto-js/sha256';

import limitPrecision from 'editor/src/util/limitPrecision';

import { MediaAddon, MediaImage, MediaText } from '../types';

function quickHash(values: Array<string | number>) {
  const combinedString = values.join('');
  return sha256(combinedString).toString(hexEncoder);
}

export function getDigitizeTextId(element: MediaText) {
  return quickHash([
    element.width,
    element.height,
    element.fill,
    element.extra.text,
    element.extra.fontFamily,
    element.extra.fontSize,
    element.extra.textAlign ?? '',
    element.extra.lineHeight ?? '',
    element.extra.underline ? '1' : '0',
    element.extra.linethrough ? '1' : '0',
    element.extra.direction ?? '',
    element.extra.letterSpacing ?? '',
    element.extra.stroke ? `${element.extra.stroke.color}${element.extra.stroke.width}` : '',
    element.extra.shadow
      ? `${element.extra.shadow.color}${element.extra.shadow.offsetX}${element.extra.shadow.offsetY}${element.extra.shadow.blur}`
      : '',
  ]);
}

export function getDigitizeImageId(element: MediaImage | MediaAddon) {
  return quickHash([
    element.width,
    element.height,
    element.type === 'image' ? element.imageId : element.addonId,
    limitPrecision(element.pw, 5),
    limitPrecision(element.ph, 5),
    limitPrecision(element.px, 5),
    limitPrecision(element.py, 5),
    limitPrecision(element.pr, 5),
    element.colorOverrides?.fill?.map((v) => `${v.previous}-${v.new}`).join('') ?? '',
  ]);
}

function getDigitizedElementId(element: MediaText | MediaImage | MediaAddon) {
  return element.type === 'text' ? getDigitizeTextId(element) : getDigitizeImageId(element);
}

export default getDigitizedElementId;
