import { PayloadAction } from '@reduxjs/toolkit';

import { DesignOption, VariantsState } from 'editor/src/store/variants/types';

const deleteDesignOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{ designOption: DesignOption; controlKey: string }>,
) => {
  const { controlKey, designOption } = action.payload;

  const control = state.designOptionsControl?.find((control) => control.key === controlKey);
  if (control) {
    control.options = control.options.filter(
      (option) => option.value !== designOption.value && option.title !== designOption.title,
    );
  }
};

export default deleteDesignOptionReducer;
