function applyFoil(foilImage: HTMLImageElement, canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  const { width, height } = canvas;
  let foilWidth = 0;
  let foilHeight = 0;
  if (foilImage.width / foilImage.height > width / height) {
    foilHeight = height;
    foilWidth = (foilImage.width * height) / foilImage.height;
  } else {
    foilWidth = width;
    foilHeight = (foilImage.height * width) / foilImage.width;
  }
  const foilX = (canvas.width - foilWidth) / 2;
  const foilY = (canvas.height - foilHeight) / 2;
  ctx.save();
  ctx.resetTransform();
  ctx.globalCompositeOperation = 'source-in';
  ctx.drawImage(foilImage, foilX, foilY, foilWidth, foilHeight);
  ctx.restore();
}

export default applyFoil;
