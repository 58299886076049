import { PayloadAction } from '@reduxjs/toolkit';

import { MockupState } from 'editor/src/store/mockup/types';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';

const setSingleOptionReducer = (state: MockupState, action: PayloadAction<{ key: string; value: string }>) => {
  const { key, value } = action.payload;
  const { variations, controls, multiOptions, singleOptions } = state.productPlaceholder;
  singleOptions[key] = value;

  controls.forEach((control) => {
    if (control.type === 'multi') {
      multiOptions[control.key] = [
        {
          value: multiOptions[control.key][0].value,
          dependedOptions: control.dependsOnSingleControls
            ? Object.keys(singleOptions).map((key) => ({
                key,
                value: singleOptions[key],
              }))
            : [],
        },
      ];
    }
  });

  const matchingVariations = getAllMatchingVariations(variations, multiOptions, singleOptions, true, []);
  if (matchingVariations.length) {
    state.productPlaceholder.activeProductUid = matchingVariations[0].productUid;
  }
};

export default setSingleOptionReducer;
