import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaElement, MediaImage } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { getAppliedManipulationPluginNames } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import isImageManipulationPlugin from 'editor/src/util/plugins/isImageManipulationPlugin';

const updateImageManipulationBeforeImageUpdateOperation =
  (elementId: number, elementUpdate: Partial<MediaElement>) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const elementAddress = getStructureIndexByElementUuid(state.design.designData, elementId);
    if (!elementAddress) {
      return;
    }

    const mediaElement = getMediaElement(state, elementAddress);
    if (mediaElement?.type !== 'image') {
      return;
    }

    const imageManipulationPluginNames = state.plugins.list
      .filter(isImageManipulationPlugin)
      .map((plugin) => plugin.name);

    // eslint-disable-next-line no-param-reassign
    elementUpdate = elementUpdate as Partial<MediaImage>;
    const { plugins = {} } = elementUpdate;

    const shouldRemoveImageManipulationPlugins =
      elementUpdate.imageId &&
      elementUpdate.imageId !== mediaElement.imageId &&
      !getAppliedManipulationPluginNames(elementUpdate, state.plugins.list).length;

    if (shouldRemoveImageManipulationPlugins) {
      imageManipulationPluginNames.forEach((pluginName) => delete plugins[pluginName]);
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { plugins, sourceAssetId: undefined },
        }),
      );
    }
  };

export default updateImageManipulationBeforeImageUpdateOperation;
