import getAddonElementsUsedInSpread from 'editor/src/store/design/selector/getAddonElementsUsedInSpread';
import getImageElementsUsedInSpread from 'editor/src/store/design/selector/getImageElementsUsedInSpread';
import getTextElementsUsedInSpread from 'editor/src/store/design/selector/getTextElementsUsedInSpread';
import { Spread, SpreadGroup } from 'editor/src/store/design/types';
import { LayoutGroup } from 'editor/src/store/editorModules/layouts/types';

import { RootState } from 'editor/src/store';
import generateLayoutFramesForGroupedSpread from 'editor/src/util/layouts/generateLayoutFramesForGroupedSpread';
import generateLayoutMediaElements from 'editor/src/util/layouts/generateLayoutMediaElements';
import notUndefined from 'editor/src/util/notUndefined';

import getAppliedLayoutSchemaToSpreadMedia, { getImageWithDimensions } from './getAppliedLayoutSchemaToSpreadMedia';

import type { i18n } from 'i18next';

const applyGroupLayoutToSpread = (
  state: RootState,
  spreadIndex: number,
  spreads: Spread[],
  spreadGroup: SpreadGroup,
  layoutGroup: LayoutGroup,
  i18n: i18n,
) => {
  const spread = spreads[spreadIndex];
  const layoutFrames = generateLayoutFramesForGroupedSpread(spread, spreads, spreadGroup, layoutGroup);
  const layoutElements = generateLayoutMediaElements(state, spreadIndex, spread, layoutFrames, i18n);

  const imageElementsToPlace = getImageElementsUsedInSpread(spread)
    .map((img) => getImageWithDimensions(state, img))
    .filter(notUndefined);
  const addonElementsToPlace = getAddonElementsUsedInSpread(spread);
  const textElementsToPlace = getTextElementsUsedInSpread(spread);

  const media = getAppliedLayoutSchemaToSpreadMedia(
    spread,
    layoutElements,
    {
      text: textElementsToPlace,
      image: imageElementsToPlace,
      addon: addonElementsToPlace,
    },
    false,
  );

  spread.pages[0].groups.media = media;
  spread.layoutSchemaName = layoutGroup.name;
};

export default applyGroupLayoutToSpread;
