import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Page } from 'editor/src/store/design/types';

import useForceUpdate from 'editor/src/component/useForceUpdate';

const updateMethods: Map<string, Set<React.Dispatch<React.SetStateAction<any>>>> = new Map();

function getKey(page_nr: number, property: string) {
  return `${page_nr}_${property}`;
}

export function useLiveUpdatePage<T extends keyof Page>(page_nr: number, property: T) {
  const liveUpdate = useCallback((value: Page[T]) => {
    updateMethods.get(getKey(page_nr, property))?.forEach((update) => {
      update(value);
    });
  }, []);
  return liveUpdate;
}

function usePagePropertyLiveUpdates<T extends keyof Page>(page_nr: number, property: T, value: Page[T]) {
  const livePageRef = useRef<Page[T]>();

  const forceUpdate = useForceUpdate();
  const onElementUpdate = useCallback((updatedPageProperty: Page[T]) => {
    livePageRef.current = updatedPageProperty;
    forceUpdate();
  }, []);

  useMemo(() => {
    // reset the live element ref when the actual element gets updated by the store
    livePageRef.current = undefined;
  }, [page_nr, property, value]);

  useEffect(() => {
    const key = getKey(page_nr, property);
    if (!updateMethods.has(key)) {
      updateMethods.set(key, new Set());
    }
    updateMethods.get(key)?.add(onElementUpdate);

    return () => {
      updateMethods.get(key)?.delete(onElementUpdate);
    };
  }, [page_nr, property]);

  const liveUpdate = useCallback(
    (value: Page[T]) => {
      const key = getKey(page_nr, property);
      updateMethods.get(key)?.forEach((update) => {
        if (onElementUpdate !== update) {
          update(value);
        }
      });
    },
    [page_nr, property],
  );

  return { livePageProperty: livePageRef.current ?? value, liveUpdate };
}

export default usePagePropertyLiveUpdates;
