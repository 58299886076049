import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, DesignState } from 'editor/src/store/design/types';

const updateConditionNameReducer = (
  state: DesignState,
  action: PayloadAction<{ address: ConditionAddress; name: string }>,
) => {
  const { address, name } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  condition.name = name;
};

export default updateConditionNameReducer;
