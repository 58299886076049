import cn from 'classnames';
import React from 'react';

import updateConditionActiveOptionOperation from 'editor/src/store/design/operation/updateConditionActiveOptionOperation';
import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { ConditionGroup, Condition, ElementAddress } from 'editor/src/store/design/types';
import { useDispatch } from 'product-personalizer/src/store/hooks';

import trackInteraction from 'product-personalizer/src/utils/trackInteraction';

import PersonalizationElements from '../PersonalizationElements';

import ConditionOptions from './ConditionOptions';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  conditionGroup: ConditionGroup;
  condition: Condition;
  uploadImage: (address: ElementAddress) => void;
  editImageCrop: (address: ElementAddress) => void;
  trackUserInteraction: boolean | undefined;
}

function PersonalizationCondition({
  condition,
  spreadIndex,
  conditionGroup,
  uploadImage,
  editImageCrop,
  trackUserInteraction,
}: Props) {
  const children = conditionGroup.children[getConditionChildrenKey(condition.id, condition.activeOptionId)] || [];
  const dispatch = useDispatch();

  function onSelectOption(conditionId: string, optionId: string) {
    dispatch(updateConditionActiveOptionOperation({ spreadIndex, conditionId }, optionId));
    trackUserInteraction && trackInteraction();
  }

  return (
    <div className={cn(styles.PersonalizationCondition, 'g-selector-container', 'g-selector-condition')}>
      <div className={cn(styles.name, 'g-selector-label')}>{condition.name}</div>
      <ConditionOptions condition={condition} onSelectOption={onSelectOption} />
      <PersonalizationElements
        spreadIndex={spreadIndex}
        conditionGroup={conditionGroup}
        elements={children}
        editImageCrop={editImageCrop}
        uploadImage={uploadImage}
        trackUserInteraction={trackUserInteraction}
      />
    </div>
  );
}

export default React.memo(PersonalizationCondition);
