import { GridDesign } from '@gelatoas/design-editor-calendar';

import { DesignData, MediaElement } from 'editor/src/store/design/types';
import { FontDefinition } from 'editor/src/store/fonts/types';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

import isFontFileLoaded from './isFontFileLoaded';
import loadFontFiles from './loadFontFiles';
import loadGridDesignFonts from './loadGridDesignFonts';

async function loadDesignDataFonts(designDataItems: DesignData[], fonts: FontDefinition[], gridDesigns: GridDesign[]) {
  const { fontFamilies, designNames } = getFontDataFromDesignItems(designDataItems);
  if (!fontFamilies.size && !designNames.size) {
    return;
  }

  try {
    await loadFontFiles([...fontFamilies], fonts);
    await Promise.all(
      [...designNames].map((designName) => {
        const design = gridDesigns.find((design) => design.name === designName);
        return design ? loadGridDesignFonts(design, fonts) : undefined;
      }),
    );
  } catch (e) {
    throw new Error(
      e ||
        `loadDesignDataFonts: failed to load design fonts: ${JSON.stringify(fontFamilies)}, ${JSON.stringify(designNames)}}`,
    );
  }

  // imported cards from OP may have too small box
  designDataItems.forEach((designData) => {
    designData.spreads.forEach((spread) => {
      spread.pages[0].groups.media?.forEach((mediaElement) => {
        if (mediaElement.type === 'text' && mediaElement.extra.flexibleWidth) {
          updateTextElementWithoutRender(mediaElement, spread.pages[0]);
        }
      });
    });
  });
}

const getFontDataFromDesignItems = (designDataItems: DesignData[]) => {
  const fontFamilies = new Set<string>();
  const designNames = new Set<string>();

  const addFontData = (mediaElement: MediaElement) => {
    if (mediaElement.type === 'text' && !isFontFileLoaded(mediaElement.extra.fontFamily)) {
      fontFamilies.add(mediaElement.extra.fontFamily);
    }

    if (mediaElement.type === 'grid') {
      designNames.add(mediaElement.grid.designName);
    }
  };

  designDataItems.forEach((designData) => {
    designData.spreads.forEach((spread) => {
      spread.pages[0].groups.media?.forEach(addFontData);
    });
  });

  return { fontFamilies, designNames };
};

export default loadDesignDataFonts;
