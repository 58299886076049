import type { DesignState } from 'editor/src/store/design/types';
import { ElementMap } from 'editor/src/store/design/util/getSpreadGridElements';

import type { PayloadAction } from '@reduxjs/toolkit';

const updateElementsOnSpreadsReducer = (
  state: DesignState,
  action: PayloadAction<{
    gridMediaMapPerSpread: { [key: number]: ElementMap };
  }>,
) => {
  const { gridMediaMapPerSpread } = action.payload;

  state.designData?.spreads.forEach((spread, index) => {
    const elementMap = gridMediaMapPerSpread[index];
    spread.pages[0].groups.media?.forEach((media) => {
      const updatedElement = elementMap[media.uuid];
      if (updatedElement) {
        Object.assign(media, updatedElement);
      }
    });
  });
};

export default updateElementsOnSpreadsReducer;
