import { FontDefinition, FontStatus } from 'editor/src/store/fonts/types';

import notUndefined from 'editor/src/util/notUndefined';

import loadFont from './loadFont';

function loadFontFiles(fontFiles: string[], fonts: FontDefinition[]) {
  const fontDefs = fontFiles
    .map((fontFile) => fonts.find((font) => font.metadata.fontFile === fontFile))
    .filter(notUndefined)
    .filter((font) => font.status !== FontStatus.ready);

  return Promise.all(
    fontDefs.map(async (fontDefinition) => {
      try {
        return await loadFont(fontDefinition, {
          executor: 'loadFontFiles',
          fontDefinition,
        });
      } catch {
        throw new Error('loadFontFiles: failed to load font');
      }
    }),
  );
}

export default loadFontFiles;
