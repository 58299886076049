import { CollectionAsset, CollectionFolder } from 'editor/src/store/collections/types';

import { AssetFileType } from 'product-personalizer/src/types/asset';
import { FileManagerFile, FileManagerFolder } from 'product-personalizer/src/types/collections';

const getFolderAssetFileUrl = (asset: FileManagerFile, assetFileType: AssetFileType) =>
  asset.files.find((file) => file.type === assetFileType)?.url || '';

const mapFolderAssetToEventData = (asset: FileManagerFile): CollectionAsset => ({
  ...asset,
  url: getFolderAssetFileUrl(asset, AssetFileType.PREVIEW_DEFAULT),
  thumbnailUrl: getFolderAssetFileUrl(asset, AssetFileType.PREVIEW_THUMBNAIL),
});

const getCollectionDataFromAssets = (folder: FileManagerFolder): CollectionFolder => ({
  ...folder,
  assets: folder.assets.map((asset: FileManagerFile) => mapFolderAssetToEventData(asset)),
  assetCount: folder.countAssets,
  folderCount: folder.countSubFolders,
});

export default getCollectionDataFromAssets;
