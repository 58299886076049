import { fabric } from 'fabric';
import { MultiPolygon } from 'polygon-clipping';

import { Coords, Spread } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import addBlanks from './addBlanks';
import addHoles from './addHoles';
import addPageElement from './addPageElement';

const addPages = (
  fabricCanvas: fabric.StaticCanvas,
  spread: Spread,
  mm2px: (size: number) => number,
  offset: Coords,
  rotation: CanvasRotation,
  showBlanks: boolean,
  showBlankAreaShadowOnly: boolean,
  hasBackground: boolean,
  strongerShadow: boolean,
  contentAreaPolygons: MultiPolygon,
  contentClipPath: fabric.Object | undefined,
  isMobile: boolean,
  t: (key: string) => string,
  spreadGroupOffset?: Coords,
) =>
  (spread.pages || []).forEach((page) => {
    // offset the page position in case of grouped spreads
    const pageOffset: Coords = {
      left: offset.left + mm2px(page.x) + mm2px(spreadGroupOffset?.left ?? 0),
      top: offset.top + mm2px(page.y) + mm2px(spreadGroupOffset?.top ?? 0),
    };
    const { backgroundColor } = page;
    const { content, bleed, mediabox } = page.groups;

    addPageElement(
      fabricCanvas,
      content || [],
      bleed || [],
      mediabox || [],
      undefined,
      pageOffset,
      mm2px,
      rotation,
      hasBackground,
      strongerShadow,
      contentClipPath,
      backgroundColor,
    );
    if (showBlanks || showBlankAreaShadowOnly) {
      addBlanks(fabricCanvas, page, pageOffset, showBlankAreaShadowOnly, mm2px, rotation, isMobile, t);
    }

    addHoles(fabricCanvas, page, pageOffset, mm2px, rotation, contentAreaPolygons, contentClipPath);
  });

export default addPages;
