import { fabric } from 'fabric';
import React, { useCallback } from 'react';

import { Coords, Diecut } from 'editor/src/store/design/types';

import FabricPath from 'editor/src/fabric/FabricPath';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricPathComponent from 'editor/src/component/EditorArea/fabricComponents/FabricPathComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

interface Props {
  diecut: Diecut;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
}

const STOKE_DASH_ARRAY = [5, 5];

function DiecutElement({ diecut, pageCoords, canvasRotation }: Props) {
  const { mm2px } = useFabricUtils();

  const getFabricOptionsOnUpdate = useCallback(
    (element: FabricPath): Partial<fabric.IPathOptions> => {
      const rect = element.getBoundingRect();
      const x = pageCoords.left + mm2px(diecut.sx < 0 ? diecut.x - rect.width : diecut.x);
      const y = pageCoords.top + mm2px(diecut.sy < 0 ? diecut.y - rect.height : diecut.y);
      const rotatedPoint = fabric.util.rotatePoint(
        new fabric.Point(x, y),
        canvasRotation.canvasCenter,
        canvasRotation.angleRad,
      );

      return { left: rotatedPoint.x, top: rotatedPoint.y };
    },
    [pageCoords, diecut.sx, diecut.sy, diecut.x, diecut.y],
  );

  const scale = mm2px(1);

  return (
    <FabricPathComponent
      elementPath={diecut.path}
      getFabricOptionsOnUpdate={getFabricOptionsOnUpdate}
      flipX={diecut.sx < 0}
      flipY={diecut.sy < 0}
      selectable={false}
      evented={false}
      stroke="rgba(0, 0, 0, 1)"
      zIndex={zIndex.DIECUT}
      fill=""
      scaleX={scale}
      scaleY={scale}
      strokeUniform
      strokeLineCap="round"
      strokeDashArray={STOKE_DASH_ARRAY}
      angle={canvasRotation.angleDeg}
    />
  );
}

export default React.memo(DiecutElement);
