import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryState } from 'editor/src/store/gallery/types';

const addImageToSessionReducer = (state: GalleryState, action: PayloadAction<string>) => {
  const imageId = action.payload;
  if (!state.sessionImages.includes(imageId)) {
    state.sessionImages.unshift(imageId);
  }
};

export default addImageToSessionReducer;
