import { Unit } from 'editor/src/store/editor/types';
import { Product, VariantInfo } from 'editor/src/store/variants/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit } from 'editor/src/util/math';
import notUndefined from 'editor/src/util/notUndefined';

function getProductVariationSpecificTitle(product: Product, variantInfo: VariantInfo, unit: Unit): string {
  const productControls = product.groupBy.length
    ? product.groupBy
        .map((property) => product.productControls.find((control) => control.key === property))
        .filter(notUndefined)
    : product.productControls;

  const values = productControls
    .map((control) => control.options.find((o) => o.value && o.value === variantInfo.variation[control.key])?.title)
    .filter(notUndefined);

  product.externalProductControls.forEach((control) => {
    if (control.key === 'product-size' && variantInfo.dimensions) {
      const precision = unit === 'cm' ? 2 : 0;
      const widthCv = limitPrecision(convertMmToUnit(variantInfo.dimensions.width, unit), precision);
      const heightCv = limitPrecision(convertMmToUnit(variantInfo.dimensions.height, unit), precision);
      values.unshift(`${widthCv}x${heightCv} ${unit === 'cm' ? 'cm' : 'inch'}`);
    }
  });

  return values.length ? values.join(' - ') : product.title;
}

export default getProductVariationSpecificTitle;
