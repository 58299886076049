import { PayloadAction } from '@reduxjs/toolkit';

import { SavedState, UndoRedoState } from 'editor/src/store/editorModules/undoRedo/types';

const STEPS_LIMIT = 100;

const pushPreviousStateReducer = (state: UndoRedoState, action: PayloadAction<SavedState>) => {
  state.previous.push(action.payload);
  while (state.previous.length > STEPS_LIMIT) {
    state.previous.shift();
  }
};

export default pushPreviousStateReducer;
