import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { DesignData, MediaElement, SpreadGroup } from 'editor/src/store/design/types';

import { elementUuids } from 'editor/src/util/uuids';

const handleSpreadGroupsForExistingProducts = (designData: DesignData, spreadGroups: SpreadGroup[]) => {
  spreadGroups.forEach((spreadGroup) => {
    const mainSpread = designData.spreads[spreadGroup.spreadIndexes[0]];
    const spreadGroupPosition = spreadGroup.position;
    const spreadHorizontalOffset = spreadGroupPosition === 'horizontal' ? getSpreadWidthFromSpread(mainSpread) : 0;
    const spreadVerticalOffset = spreadGroupPosition === 'vertical' ? getSpreadHeightFromSpread(mainSpread) : 0;
    const media: MediaElement[] = [];

    const layouts = [];
    for (let i = 0; i < spreadGroup.spreadIndexes.length; i += 1) {
      const spread = designData.spreads[spreadGroup.spreadIndexes[i]];
      const layout = spread?.layoutSchemaName;
      if (layout) {
        layouts.push(layout);
      }

      spread?.pages[0].groups.media?.forEach((element) => {
        const yOffset = spreadVerticalOffset * i;
        const xOffset = spreadHorizontalOffset * i;
        const uuid = elementUuids.generate();
        if (element.type === 'line') {
          media.push({
            ...element,
            x1: element.x1 - xOffset,
            y1: element.y1 - yOffset,
            x2: element.x2 - xOffset,
            y2: element.y2 - yOffset,
            uuid,
          });
        } else {
          media.push({
            ...element,
            y: element.y - yOffset,
            x: element.x - xOffset,
            uuid,
          });
        }
      });
    }
    mainSpread.pages[0].groups.media = media;
    if (layouts.length) {
      mainSpread.layoutSchemaName = layouts.join('_');
    }
  });
};

export default handleSpreadGroupsForExistingProducts;
