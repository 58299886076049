export type DesignItemMetadata = [
  { key: 'storeProductVariantId'; value: string },
  { key: 'gelatoVariantId'; value: string | undefined },
  ...{ key: string; value: string | undefined }[],
];

export interface DraftDesignItemResponse {
  accessMode: string;
  assets: unknown[];
  clientId: string;
  customerReferenceId: string;
  id: string;
  status: string;
  storeId: string;
  structure: null | string;
  metadata: DesignItemMetadata;
}

type MetadataKey = 'storeProductVariantId' | 'gelatoVariantId' | string;
export const getMetadataValue = (metadata: DesignItemMetadata, key: MetadataKey) => {
  return metadata.find((metadataItem) => metadataItem.key === key)?.value;
};

export const getMetadataPayload = (
  storeProductVariantId: string,
  gelatoVariantId: string | undefined,
): DesignItemMetadata => {
  return [
    { key: 'storeProductVariantId', value: storeProductVariantId },
    { key: 'gelatoVariantId', value: gelatoVariantId },
  ];
};
