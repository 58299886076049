import { PayloadAction } from '@reduxjs/toolkit';

import { conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

const removeElementFromGroupConditionReducer = (
  state: DesignState,
  action: PayloadAction<{ spreadIndex: number; elementId: number }>,
) => {
  const { spreadIndex, elementId } = action.payload;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  conditionGroup.children[conditionGroupChildenKey] = conditionGroup.children[conditionGroupChildenKey].filter(
    (element) => element.type === 'condition' || element.id !== elementId,
  );
};

export default removeElementFromGroupConditionReducer;
