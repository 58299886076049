export type DropdownPosition =
  | 'right-fromtop'
  | 'right-frombottom'
  | 'left-fromtop'
  | 'left-frombottom'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'top-left'
  | 'top-center'
  | 'top-right';

const DEFAULT_MARGIN = 10;

function getPosition(
  openPosition: DropdownPosition,
  wrapperBox: DOMRect,
  dropBox: DOMRect,
  cWidth: number,
  cHeight: number,
  margin: number = DEFAULT_MARGIN,
): { x: number; y: number; maxHeight: number } {
  const maxX = cWidth - dropBox.width - margin;
  const maxY = cHeight - dropBox.height - margin;

  let x: number;
  let y: number;

  switch (openPosition) {
    case 'right-fromtop':
    case 'right-frombottom':
      x = Math.max(margin, Math.min(wrapperBox.left + wrapperBox.width, maxX));
      break;
    case 'left-fromtop':
    case 'left-frombottom':
      x = Math.max(margin, Math.min(wrapperBox.left - dropBox.width, maxX));
      break;
    case 'bottom-center':
    case 'top-center':
      x = Math.max(margin, Math.min(wrapperBox.left + wrapperBox.width / 2 - dropBox.width / 2, maxX));
      break;
    case 'bottom-right':
    case 'top-right':
      x = Math.max(margin, Math.min(wrapperBox.left + wrapperBox.width - dropBox.width, maxX));
      break;
    case 'bottom-left':
    case 'top-left':
    default:
      x = Math.max(margin, Math.min(wrapperBox.left, maxX));
      break;
  }

  switch (openPosition) {
    case 'right-fromtop':
    case 'left-fromtop':
      y = Math.max(margin, Math.min(wrapperBox.top, maxY));
      break;
    case 'right-frombottom':
    case 'left-frombottom':
      y = Math.max(margin, Math.min(wrapperBox.top + wrapperBox.height - dropBox.height, maxY));
      break;
    case 'top-left':
    case 'top-center':
    case 'top-right':
      y = Math.max(margin, Math.min(wrapperBox.top - dropBox.height, maxY));
      break;
    case 'bottom-left':
    case 'bottom-center':
    case 'bottom-right':
    default:
      y = Math.max(margin, Math.min(wrapperBox.top + wrapperBox.height, maxY));
      break;
  }

  return { x, y, maxHeight: cHeight - margin * 2 };
}

export default getPosition;
