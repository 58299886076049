import { DropShadow, Stroke, TextWrapping } from 'editor/src/store/design/types';

import type { FontMetaData } from '@gelatoas/design-editor-fonts';

export interface FontState {
  availableFonts: FontDefinition[];
  defaultFontStyles: FontStyles;
}

export enum FontStatus {
  new,
  ready,
  loading,
  failed,
}

export interface FontDefinition {
  status: FontStatus;
  metadata: FontMetaData;
  isPopular: boolean;
  popularOrder?: number;
}

export interface FontStyles {
  underline: boolean;
  linethrough: boolean;
  fontFamily: string;
  fontSize: number;
  color: string;
  lineHeight: number;
  textAlign: TextAlign;
  direction: TextDirection;
  angle: number;
  letterSpacing: number;
  stroke: Stroke;
  shadow: DropShadow;
  wrapping: TextWrapping | undefined;
  curve: number;
}

export enum TextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum TextDirection {
  rtl = 'rtl',
  ltr = 'ltr',
}
