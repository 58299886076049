import { SavedState } from 'editor/src/store/editorModules/undoRedo/types';

import { RootState } from 'editor/src/store';

const getCurrentState = (state: RootState): Omit<SavedState, 'key'> => ({
  design: state.design,
  selectedMediaElements: state.editor.selectedElementUuids,

  variantsProduct: state.variants.product,
  variantsSelectedSingleOptions: state.variants.selectedSingleOptions,
  variantsSelectedMultiOptions: state.variants.selectedMultiOptions,
  variantsVariationGroups: state.variants.variationGroups,
  variantsSelectedGroupKey: state.variants.selectedGroupKey,
  designOptionsEnabled: state.variants.designOptionsEnabled,
  designOptionsControl: state.variants.designOptionsControl,
  selectedPageCount: state.variants.selectedPageCount,
  ecommerceProduct: state.variants.eCommerceProduct,
});

export default getCurrentState;
