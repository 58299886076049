import { createSlice } from '@reduxjs/toolkit';

import layoutSchemas from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';

import setLayoutsReducer from './reducer/setLayoutsReducer';
import { LayoutsState } from './types';

export const layoutInitialState: LayoutsState = {
  schemas: layoutSchemas,
};

export const slice = createSlice({
  name: 'layouts',
  initialState: layoutInitialState,
  reducers: {
    setLayoutsAction: setLayoutsReducer,
  },
});

export const { setLayoutsAction } = slice.actions;

export default slice.reducer;
