import { useState, useEffect } from 'react';

import noop from 'editor/src/util/noop';

const queue: Array<{ src: string; cb: () => void }> = [];
let loading = 0;

function runQueue() {
  if (loading >= 3) {
    return;
  }
  const entry = queue.shift();
  if (!entry) {
    return;
  }

  loading += 1;
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = entry.src;

  const onLoad = () => {
    loading -= 1;
    entry.cb();
    runQueue();
  };

  img.onload = onLoad;
  img.onerror = onLoad;
}

function useImageLoader(url: string, isVisible: boolean) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      return undefined;
    }

    const entry = { src: url, cb: () => setLoaded(true) };
    queue.push(entry);
    runQueue();

    return () => {
      entry.cb = noop; // prevent from being called after being unmounted
      const index = queue.indexOf(entry);
      if (index !== -1) {
        queue.splice(index, 1);
      }
    };
  }, [url, isVisible]);

  return loaded;
}

export default useImageLoader;
