import React, { createContext, useContext, useState, ReactNode, useMemo, useEffect } from 'react';

import { useSelector } from 'editor/src/store/hooks';

import { useIsMobile } from '../../../useDetectDeviceType';

import getDefaultHeight from './getDefaultHeight';

export const THUMBNAILS_HEIGHT_MOBILE = 76;
export const THUMBNAILS_HEIGHT_DESKTOP = 108;

interface BottomBarHeightContextProps {
  bottomBarHeight: number;
  updateBottomBarHeight: (newHeight: number) => void;
}

const BottomBarHeightContext = createContext<BottomBarHeightContextProps | undefined>(undefined);

interface Props {
  children: ReactNode;
}

function BottomBarHeightProvider({ children }: Props) {
  const isMobile = useIsMobile();
  const hasMultipleSpreads = useSelector((state) => (state.design.designData?.spreads.length || 0) > 1);

  const [bottomBarHeight, setBottomBarHeight] = useState<number>(() => getDefaultHeight(isMobile, hasMultipleSpreads));

  useEffect(() => {
    setBottomBarHeight(getDefaultHeight(isMobile, hasMultipleSpreads));
  }, [isMobile, hasMultipleSpreads]);

  const providerExportValues = useMemo(
    () => ({ bottomBarHeight, updateBottomBarHeight: setBottomBarHeight }),
    [bottomBarHeight],
  );

  return <BottomBarHeightContext.Provider value={providerExportValues}>{children}</BottomBarHeightContext.Provider>;
}

const useBottomBarHeight = (): BottomBarHeightContextProps => {
  const context = useContext(BottomBarHeightContext);
  if (!context) {
    throw new Error('useBottomBarHeight must be used within a BottomBarHeightProvider');
  }

  return context;
};

export { BottomBarHeightProvider, useBottomBarHeight };
