import { TabInfo } from 'editor/src/store/editorModules/sidebar/types';
import { RootState } from 'editor/src/store/index';

import isTabActive from 'editor/src/util/isTabActive';

import getSidebarActiveTab from './getSidebarActiveTab';

const isSidebarTabActive = (state: RootState, tab: TabInfo) => {
  const activeTab = getSidebarActiveTab(state);
  return isTabActive(activeTab, tab) && state.editorModules.sidebar.isVisible;
};

export default isSidebarTabActive;
