import { setSidebarActiveTabAction, setSidebarVisibleAction } from 'editor/src/store/editorModules/sidebar/slice';
import { isNotDefaultTab } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const setSidebarActiveTabOperation =
  (tabIndex: number, payload?: any) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { activeTabIndex, tabs, isVisible } = state.editorModules.sidebar;

    if (tabIndex !== activeTabIndex || payload !== undefined) {
      const currentlyActiveTab = tabs[activeTabIndex];
      // TODO change isNotDefaultTab to isCustomTab once the dashboard shutterstock part is merged
      if (currentlyActiveTab && isNotDefaultTab(currentlyActiveTab) && (activeTabIndex !== tabIndex || isVisible)) {
        sendPostMessage('editor.customSidebarTabClosed', currentlyActiveTab.id);
      }

      const activeTab = tabs[tabIndex];
      // TODO change isNotDefaultTab to isCustomTab once the dashboard shutterstock part is merged
      if (activeTab && isNotDefaultTab(activeTab) && (activeTabIndex !== tabIndex || !isVisible)) {
        sendPostMessage('editor.customSidebarTabActive', activeTab.id);
      }

      dispatch(setSidebarActiveTabAction({ index: tabIndex, payload }));
    }

    if ((!isVisible || payload !== undefined) && tabIndex !== -1) {
      dispatch(setSidebarVisibleAction(true));
    }
  };

export default setSidebarActiveTabOperation;
