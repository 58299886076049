import { Dimensions } from 'editor/src/store/design/types';

function getNewImageDisplaySize(area: Dimensions, imageWidth: number, imageHeight: number, ratio = 0.8) {
  let width = 0;
  let height = 0;

  if (area.width / area.height < imageWidth / imageHeight) {
    width = Math.round(area.width * ratio);
    height = (width * imageHeight) / imageWidth;
  } else {
    height = Math.round(area.height * ratio);
    width = (height * imageWidth) / imageHeight;
  }

  return { width, height };
}

export default getNewImageDisplaySize;
