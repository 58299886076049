import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, DesignState } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

const addConditionOptionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    optionId: string;
    label: string;
  }>,
) => {
  const { address, optionId, label } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  condition.options.push({ id: optionId, label, imageId: undefined });
  conditionGroup.children[getConditionChildrenKey(conditionId, optionId)] = [];
};

export default addConditionOptionReducer;
