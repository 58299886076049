import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { EMPTY_PREVIEW_DIM } from 'editor/src/store/gallery/config';
import { addImageAction, updateImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, MimeType, ProgressImageData } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import addImageToSessionOperation from './addImageToSessionOperation';

const updateImageOnProgress =
  (imageData: ProgressImageData) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const image = getState().gallery.images.find((image) => image.uploaderId === imageData.file.id);
    const state = imageData.file.isRemote ? ImageState.REMOTE_PREVIEW : ImageState.LOCAL_PREVIEW;

    batch(() => {
      // if we fail to generate a preview locally (like for TIFF file), we won't get the preview generated message
      if (!image) {
        dispatch(
          addImageAction({
            state,
            id: imageData.file.id,
            uploaderId: imageData.file.id,
            name: imageData.file.name ?? '',
            type: imageData.file.type ? getMimeTypeEnum(imageData.file.type) : MimeType.Unknown,
            width: imageData.file.width ?? EMPTY_PREVIEW_DIM.width,
            height: imageData.file.height ?? EMPTY_PREVIEW_DIM.height,
            url: imageData.file.preview,
            source: imageData.file.source,
            hasAssetDimensions: !!imageData.file.width && !!imageData.file.height,
            bytesTotal: imageData.bytesTotal,
            bytesUploaded: imageData.bytesUploaded,
          }),
        );
        dispatch(addImageToSessionOperation(imageData.file.id));
      } else if (image.state !== ImageState.UPLOADED && image.state !== ImageState.FAILED) {
        dispatch(
          updateImageAction({
            id: imageData.file.id,
            state,
            bytesTotal: imageData.bytesTotal,
            bytesUploaded: imageData.bytesUploaded,
          }),
        );
      }
    });
  };

export default updateImageOnProgress;
