import { elementUuids } from 'editor/src/util/uuids';

import { DesignData, Spread } from '../types';

import updateDesignPagesUtil from './updateDesignPagesUtil';

function copySpreadUtil(
  designData: DesignData,
  copyFromIndex: number,
  positionAtIndex: number,
  empty: boolean,
  mediaFromIndex?: number,
) {
  let copyMediaFromIndex = copyFromIndex;
  if (mediaFromIndex !== undefined) {
    copyMediaFromIndex = mediaFromIndex;
  }

  const template = designData.spreads[copyFromIndex];
  const newSpread: Spread = JSON.parse(JSON.stringify(template));
  if (empty) {
    newSpread.pages.forEach((page) => {
      page.groups.media = undefined;
    });
  } else {
    // it's possible to have 'blank' + media on photobook front-inner spread.
    // When we duplicate we need to keep both: mediabox from 2 page and media from 1 one
    const newSpreadMedia: Spread =
      copyMediaFromIndex !== copyFromIndex
        ? JSON.parse(JSON.stringify(designData.spreads[copyMediaFromIndex]))
        : newSpread;

    newSpread.pages[0].groups.media = newSpreadMedia.pages[0].groups.media?.map((media) => {
      return {
        ...media,
        uuid: elementUuids.generate(),
      };
    });
  }
  designData.spreads.splice(positionAtIndex, 0, newSpread);
  updateDesignPagesUtil(designData);
}

export default copySpreadUtil;
