import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const setPluginPreviewModeReducer = (
  state: EditorState,
  action: PayloadAction<EditorState['pluginPreviewMode'] | undefined>,
) => {
  state.pluginPreviewMode = action.payload;
};

export default setPluginPreviewModeReducer;
