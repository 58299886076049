import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState, ManipulationMode } from 'editor/src/store/editor/types';

const setElementManipulationModeReducer = (
  state: EditorState,
  action: PayloadAction<{ uuid: number; mode: ManipulationMode } | undefined>,
) => {
  state.elementManipulationMode = action.payload;
};

export default setElementManipulationModeReducer;
