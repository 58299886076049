import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';

import { Coords, MediaLine } from 'editor/src/store/design/types';

import FabricLine from 'editor/src/fabric/FabricLine';
import { getBrowserFriendlyColor } from 'editor/src/util/color/colorUtils';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import getLineRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/getLineRect';
import lineElementDataToFabricProps from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/lineElementDataToFabricProps';
import {
  getEdgeOffset,
  getLineAngle,
  getLineCapOffset,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';

import createLineEdge from './createLineEdge';

const addLine = (
  fabricCanvas: fabric.StaticCanvas,
  line: MediaLine,
  elementIndex: number,
  offset: Coords,
  clipPathPolygons: Polygon[],
  contentClipPath: fabric.Object | undefined,
  mm2px: (size: number) => number,
) => {
  const fabricProps = lineElementDataToFabricProps(line, elementIndex, offset, mm2px);
  const clipPath = getClipPath(getLineRect(line, offset, mm2px), clipPathPolygons, false, contentClipPath);
  const { x1, x2, y1, y2 } = fabricProps;

  const angle = getLineAngle(x1, x2, y1, y2);
  const strokeWidthOffset = fabric.util.rotatePoint(
    new fabric.Point(fabricProps.strokeWidth / 2, 0),
    new fabric.Point(0, 0),
    fabric.util.degreesToRadians(angle + 90),
  );

  const edge1Offset = getEdgeOffset(line.edge1, fabricProps.strokeWidth, angle);
  const edge2Offset = getEdgeOffset(line.edge2, fabricProps.strokeWidth, angle);
  const lineCapOffset = getLineCapOffset(line.rounded, fabricProps.strokeWidth, angle);
  fabricProps.stroke = getBrowserFriendlyColor(fabricProps.stroke);

  const lineObj = new FabricLine(
    [
      x1 + strokeWidthOffset.x + edge1Offset.x,
      y1 + strokeWidthOffset.y + edge1Offset.y,
      x2 + strokeWidthOffset.x - edge2Offset.x,
      y2 + strokeWidthOffset.y - edge2Offset.y,
    ],
    { ...fabricProps, clipPath, objectCaching: false },
  );

  const edge1 = createLineEdge(
    fabricProps.strokeWidth,
    fabricProps.stroke,
    x1 + strokeWidthOffset.x - lineCapOffset.x,
    y1 + strokeWidthOffset.y - lineCapOffset.y,
    angle,
    fabricProps.zIndex + 0.1,
    line.edge1,
    clipPathPolygons,
    contentClipPath,
  );
  const edge2 = createLineEdge(
    fabricProps.strokeWidth,
    fabricProps.stroke,
    x2 + strokeWidthOffset.x + lineCapOffset.x,
    y2 + strokeWidthOffset.y + lineCapOffset.y,
    -180 + angle,
    fabricProps.zIndex + 0.1,
    line.edge2,
    clipPathPolygons,
    contentClipPath,
  );
  fabricCanvas.add(lineObj);
  edge1 && fabricCanvas.add(edge1);
  edge2 && fabricCanvas.add(edge2);
};

export default addLine;
