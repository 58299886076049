import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';

const removeMediaElementAdjustmentsReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  const mediaElement = state?.designData?.spreads[spreadIndex].pages[pageIndex].groups?.media?.[elementIndex];
  if (mediaElement && mediaElement.type === 'image') {
    mediaElement.adjustments = [];
    mediaElement.filters = [];
  }
};

export default removeMediaElementAdjustmentsReducer;
