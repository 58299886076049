import { captureException } from '@sentry/react';

async function fabricCanvasToBlob(
  fabricCanvas: fabric.StaticCanvas,
  options: fabric.IDataURLOptions,
): Promise<Blob | null> {
  const canvas: HTMLCanvasElement = (fabricCanvas as any).toCanvasElement(
    (fabricCanvas as any).getRetinaScaling(),
    options,
  );

  let blob: Blob | null = null;
  try {
    if (canvas.toBlob) {
      blob = await new Promise<Blob>((res, rej) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              res(blob);
            } else {
              rej();
            }
          },
          'image/jpeg',
          0.6,
        );
      });
    }
  } catch (e) {
    captureException(new Error(e || 'SpreadPreview: failed to conver canvas to blob'));
  }

  try {
    // fallback to toDataUrl
    if (!blob) {
      const dataUrl = canvas.toDataURL('image/jpeg', 0.6);
      blob = await (await fetch(dataUrl)).blob();
    }
  } catch (e) {
    captureException(new Error(e || 'SpreadPreview: failed to conver canvas to data URL'));
  }

  return blob;
}

export default fabricCanvasToBlob;
