// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small--F7VKr svg{width:16px;height:16px}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Icon/IconInfo/index.module.scss"],"names":[],"mappings":"AAAA,kBACE,UAAA,CACA,WAAA","sourcesContent":[".small svg {\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"small": `small--F7VKr`
};
export default ___CSS_LOADER_EXPORT___;
