import limitPrecision from '../../limitPrecision';
import RGBA from '../rgbaUtils';

import { getModule } from './littleCMSModule';

class CMYK {
  readonly c: number;
  readonly m: number;
  readonly y: number;
  readonly k: number;

  private rgb: RGBA | undefined;

  constructor({ c, m, y, k }: { c: number; m: number; y: number; k: number }, hex?: string) {
    this.c = c;
    this.m = m;
    this.y = y;
    this.k = k;

    if (hex) {
      this.rgb = RGBA.fromHex(hex);
    }
  }

  isEqual(cmyk: CMYK): boolean {
    return this.c === cmyk.c && this.m === cmyk.m && this.y === cmyk.y && this.k === cmyk.k;
  }

  toString(): string {
    const hex = this.toRGB().toHex();
    return `${hex} device-cmyk(${this.c}%, ${this.m}%, ${this.y}%, ${this.k}%)`;
  }

  private cmykToRgbFallback(): RGBA {
    const r = Math.round(255 * (1 - this.c / 100) * (1 - this.k / 100));
    const g = Math.round(255 * (1 - this.m / 100) * (1 - this.k / 100));
    const b = Math.round(255 * (1 - this.y / 100) * (1 - this.k / 100));
    return new RGBA({ r, g, b, a: 1 });
  }

  toRGB(): RGBA {
    if (this.rgb) {
      return this.rgb;
    }

    try {
      const littleCMSModule = getModule();
      if (!littleCMSModule) {
        throw new Error('littleCMSModule is not loaded');
      }

      const rgbPointer = littleCMSModule._malloc(3);
      littleCMSModule._cmyk_to_rgb(
        this.c * (255 / 100),
        this.m * (255 / 100),
        this.y * (255 / 100),
        this.k * (255 / 100),
        rgbPointer,
      );
      const rgbArray = new Uint8Array(littleCMSModule.HEAPU8.buffer, rgbPointer, 3);
      const rgb = new RGBA({
        r: Math.round(rgbArray[0]),
        g: Math.round(rgbArray[1]),
        b: Math.round(rgbArray[2]),
        a: 1,
      });
      littleCMSModule._free(rgbPointer);

      this.rgb = rgb;
      return rgb;
    } catch (e) {
      // fallback to JS implementation
      return this.cmykToRgbFallback();
    }
  }

  /**
   * JS implementation of RGB to CMYK conversion
   */
  private static rgbToCmykFallback(rgb: RGBA): CMYK {
    // Normalize the RGB values to the [0, 1] range
    const rNorm = rgb.r / 255;
    const gNorm = rgb.g / 255;
    const bNorm = rgb.b / 255;

    const k = 1 - Math.max(rNorm, gNorm, bNorm);
    if (k === 1) {
      return new CMYK({ c: 0, m: 0, y: 0, k: 100 });
    }

    const c = (1 - rNorm - k) / (1 - k);
    const m = (1 - gNorm - k) / (1 - k);
    const y = (1 - bNorm - k) / (1 - k);

    return new CMYK({
      c: limitPrecision(c * 100, 2),
      m: limitPrecision(m * 100, 2),
      y: limitPrecision(y * 100, 2),
      k: limitPrecision(k * 100, 2),
    });
  }

  static fromRGB(rgb: RGBA): CMYK {
    try {
      const littleCMSModule = getModule();
      if (!littleCMSModule) {
        throw new Error('littleCMSModule is not loaded');
      }
      const cmykPointer = littleCMSModule._malloc(4);
      littleCMSModule._rgb_to_cmyk(rgb.r, rgb.g, rgb.b, cmykPointer);
      const cmykArray = new Uint8Array(littleCMSModule.HEAPU8.buffer, cmykPointer, 4);
      const cmyk = new CMYK({
        c: limitPrecision((cmykArray[0] / 255) * 100, 2),
        m: limitPrecision((cmykArray[1] / 255) * 100, 2),
        y: limitPrecision((cmykArray[2] / 255) * 100, 2),
        k: limitPrecision((cmykArray[3] / 255) * 100, 2),
      });
      littleCMSModule._free(cmykPointer);

      return cmyk;
    } catch (e) {
      return CMYK.rgbToCmykFallback(rgb);
    }
  }

  /**
   * Helper function to parse percentages or absolute values
   */
  private static parsePercentage(value: string): number {
    if (value.endsWith('%')) {
      return limitPrecision(parseFloat(value), 2);
    }
    return limitPrecision(parseFloat(value) * (255 / 100), 2);
  }

  private static cmykRegExp =
    /(?:#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})\s*)?device-cmyk\(\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*\)/;

  static fromString(cmyk: string): CMYK | undefined {
    const res = cmyk.match(CMYK.cmykRegExp);
    if (!res) {
      return undefined;
    }
    try {
      const hex = res[1] ? `#${res[1]}` : undefined;
      return new CMYK(
        {
          c: CMYK.parsePercentage(res[2]),
          m: CMYK.parsePercentage(res[4]),
          y: CMYK.parsePercentage(res[6]),
          k: CMYK.parsePercentage(res[8]),
        },
        hex,
      );
    } catch {
      return undefined;
    }
  }
}

export default CMYK;
