import { GridDesign } from '@gelatoas/design-editor-calendar';

import { MediaText, Spread } from 'editor/src/store/design/types';
import isMediaGrid from 'editor/src/store/design/util/isMediaGrid';
import { LayoutFrame } from 'editor/src/store/editorModules/layouts/types';
import { FontDefinition } from 'editor/src/store/fonts/types';

import {
  applyTextPropertiesToTextElement,
  getGeneratedTextInfo,
} from 'editor/src/util/layouts/createGeneratedTextMediaElement';
import { alignTextVertically, fitTextInFrame } from 'editor/src/util/layouts/layoutTextUtils';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

export interface MediaContext {
  fonts: FontDefinition[];
  gridDesigns: GridDesign[];
}

function reapplyFrameToTextElement(
  frame: LayoutFrame,
  sourceSpread: Spread,
  destSpread: Spread,
  destSpreadWidth: number,
  destElement: MediaText,
  context: MediaContext,
) {
  const grid =
    frame.frameArea.type === 'generated-text' ? sourceSpread.pages[0].groups.media?.find(isMediaGrid) : undefined;

  if (frame.frameArea.type === 'generated-text' && grid) {
    const gridDesign =
      context.gridDesigns.find((design) => design.name === grid.grid.designName) ?? context.gridDesigns[0];
    const { textDesign, d2f } = getGeneratedTextInfo(
      frame.frameArea.textType,
      grid.grid,
      gridDesign,
      destSpread.name,
      destSpreadWidth,
    );
    if (textDesign && d2f) {
      applyTextPropertiesToTextElement(destElement, textDesign, context.fonts, d2f);
    }
    updateTextElementWithoutRender(destElement, frame);
    alignTextVertically(destElement, frame.height, frame.frameArea.vAlignment);
  } else {
    fitTextInFrame(destElement, frame);
    updateTextElementWithoutRender(destElement, frame);
  }
}

export default reapplyFrameToTextElement;
