import { fabric } from 'fabric';

interface FabricRect extends fabric.Rect {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricRect = fabric.util.createClass(fabric.Rect, {
  zIndex: 0,
}) as { new (options?: fabric.IRectOptions & { zIndex?: number }): FabricRect };

export default FabricRect;
