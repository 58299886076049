import { fabric } from 'fabric';

interface FabricLine extends fabric.Line {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricLine = fabric.util.createClass(fabric.Line, {
  zIndex: 0,
}) as {
  new (
    points: ConstructorParameters<typeof fabric.Line>[0],
    options?: fabric.ILineOptions & { zIndex?: number },
  ): FabricLine;
};

export default FabricLine;
