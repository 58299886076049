import { ContentAddress, Spread, SpreadGroup } from 'editor/src/store/design/types';

export type DisplayPage = {
  spreadIndex: number;
  focusedContent?: ContentAddress & { contentTitle?: string };
};

export function isDisplayPageActive(
  displayPage: DisplayPage,
  currentSpreadIndex: number,
  focusedContentAddress: ContentAddress | undefined,
) {
  const { spreadIndex, focusedContent } = displayPage;
  if (!focusedContentAddress || !focusedContent) {
    return spreadIndex === currentSpreadIndex;
  }

  return (
    spreadIndex === currentSpreadIndex &&
    focusedContent.contentIndex === focusedContentAddress.contentIndex &&
    focusedContent.pageIndex === focusedContentAddress.pageIndex
  );
}

function compareContent(displayPage1: DisplayPage, displayPage2: DisplayPage) {
  const title1 = displayPage1.focusedContent?.contentTitle;
  const title2 = displayPage2.focusedContent?.contentTitle;

  if (title1?.includes('Front')) {
    return -1;
  }

  if (title1?.includes('Back')) {
    return 1;
  }

  if (title2?.includes('Front')) {
    return 1;
  }

  if (title2?.includes('Back')) {
    return -1;
  }

  return 0;
}

export function extractDisplayPages(
  spreads: Spread[],
  splitDisplayPerContent: boolean,
  spreadGroups?: SpreadGroup[],
): DisplayPage[] {
  let displayPages: DisplayPage[];
  if (!splitDisplayPerContent) {
    displayPages = spreads.map((_, spreadIndex) => ({ spreadIndex }));
  } else {
    displayPages = spreads.reduce<DisplayPage[]>((acc, spread, spreadIndex) => {
      let foundContent = false;
      spread.pages.forEach((page, pageIndex) => {
        page.groups.content?.forEach((content, contentIndex) => {
          if (content.type !== 'sample' && content.name === 'Panel') {
            foundContent = true;
            acc.push({
              spreadIndex,
              focusedContent: {
                spreadIndex,
                contentIndex,
                pageIndex,
                contentTitle: content.title,
              },
            });
          }
        }, []);
      });

      if (!foundContent) {
        acc.push({ spreadIndex });
      }

      return acc;
    }, []);
  }

  displayPages.sort(compareContent);

  if (spreadGroups) {
    displayPages = displayPages.filter((displayPage) =>
      spreadGroups.find((spreadGroup) => spreadGroup.spreadIndexes[0] === displayPage.spreadIndex),
    );
  }
  return displayPages;
}
