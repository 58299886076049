import { fabric } from 'fabric';

import { Bleed, Content, Coords, MediaBox, Rect } from 'editor/src/store/design/types';

import FabricPolygon from 'editor/src/fabric/FabricPolygon';
import { getBrowserFriendlyColor } from 'editor/src/util/color/colorUtils';

import getSpreadBackgroundPolygons from 'editor/src/component/EditorArea/Spread/Page/getSpreadBackgroundPolygons';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

export const LIGHT_SHADOW = new fabric.Shadow({
  color: 'rgba(0, 0, 0, 0.4)',
  blur: 2,
  offsetX: 0,
  offsetY: 1,
});

export const STRONG_SHADOW = new fabric.Shadow({
  color: 'rgba(0, 0, 0, 0.5)',
  blur: 10,
  offsetX: 2,
  offsetY: 5,
});

const DEFAULT_PAGE_COLOR = 'rgba(255, 255, 255, 1)';

const addPageElement = (
  fabricCanvas: fabric.StaticCanvas,
  contents: Content[],
  bleeds: Bleed[],
  mediaboxes: MediaBox[],
  focusedContent: Rect | undefined,
  offset: Coords,
  mm2px: (size: number) => number,
  rotation: CanvasRotation,
  hasBackground: boolean,
  strongerShadow: boolean,
  contentClipPath: fabric.Object | undefined,
  backgroundColor?: string,
) => {
  const polygons = getSpreadBackgroundPolygons(
    mediaboxes,
    contents,
    bleeds,
    focusedContent,
    offset,
    rotation,
    mm2px,
    false,
  );

  let fill = hasBackground ? undefined : DEFAULT_PAGE_COLOR;
  if (backgroundColor) {
    fill = getBrowserFriendlyColor(backgroundColor);
  }

  polygons.forEach((polygon) => {
    const fabricPolygon = new FabricPolygon(
      polygon[0].map((p) => ({ x: p[0], y: p[1] })),
      {
        fill,
        shadow: (!hasBackground && (strongerShadow ? STRONG_SHADOW : LIGHT_SHADOW)) || undefined,
        zIndex: zIndex.BACKGROUND_COLOR,
        objectCaching: false,
      },
    );
    fabricPolygon.clipPath = contentClipPath;
    fabricCanvas.add(fabricPolygon);
  });
};

export default addPageElement;
