import { GridDesign } from '@gelatoas/design-editor-calendar';
import { fabric } from 'fabric';
import { MultiPolygon } from 'polygon-clipping';

import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import {
  CalendarConfig,
  Content,
  ContentAddress,
  Coords,
  DesignState,
  DigitizedAsset,
  DigitizedAssetImage,
  DigitizedAssetText,
  MediaText,
  Page,
  Spread,
  SpreadGroup,
} from 'editor/src/store/design/types';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import isFontFileLoaded from 'editor/src/store/fonts/utils/isFontFileLoaded';
import { GalleryImage } from 'editor/src/store/gallery/types';

import FabricImage from 'editor/src/fabric/FabricImage';
import loadImage from 'editor/src/util/loadImage';
import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import { getFocusedVisibleAreaAsPolygons } from 'editor/src/component/EditorArea/Spread/Page/contentClippingUtils';
import { FOIL_ASSET } from 'editor/src/component/EditorArea/Spread/Page/FoilElements/FoilElements';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import applyFoil from '../EditorArea/Spread/Page/foilHelper';

import addImage from './addImage';
import addLine from './addLine';
import addRectangle from './addRectangle';

const addTextModule = retryPromiseFn(() => import('editor/src/component/SpreadPreview/addText'));

const addElements = async (
  fabricCanvas: fabric.StaticCanvas,
  spread: Spread,
  dataSources: {
    gridDesigns: GridDesign[];
    images: GalleryImage[];
    addons: Addon[];
  },
  quality: number,
  offset: Coords,
  focusedAreas: { focusedContent: Content; focusedPage: Page; address: ContentAddress } | undefined,
  mm2px: (size: number) => number,
  rotation: CanvasRotation,
  showEmptyImages: boolean,
  useThumbnails: boolean,
  visibleAreaPolygons: { fullArea: MultiPolygon; contentArea: MultiPolygon },
  digitizedAssets: DesignState['digitizedAssets'],
  loadFont: (font: string) => Promise<void>,
  contentClipPath: fabric.Object | undefined,
  spreadGroup?: SpreadGroup,
  mediaBoxOffset?: Coords,
  calendarConfig?: CalendarConfig,
) => {
  const mediaElements = spread.pages[0].groups.media || [];
  const { contentArea } = focusedAreas
    ? getFocusedVisibleAreaAsPolygons(
        focusedAreas.focusedPage,
        focusedAreas.focusedContent,
        offset,
        mm2px,
        rotation,
        fabricCanvas.getWidth(),
        fabricCanvas.getHeight(),
        spreadGroup,
        mediaBoxOffset,
      )
    : visibleAreaPolygons;

  const textToLoad: Array<{ element: MediaText; elementIndex: number }> = [];

  const spotFinishingCanvas = spread.spot_finishing_type
    ? new fabric.StaticCanvas(null, {
        width: fabricCanvas.getWidth(),
        height: fabricCanvas.getHeight(),
      })
    : null;

  const promises = mediaElements.map((element, elementIndex) => {
    if (element.hidden) {
      return null;
    }

    if (
      element.belongs_to?.type === 'content' &&
      (element.belongs_to.contentIndex !== focusedAreas?.address.contentIndex ||
        element.belongs_to.pageIndex !== focusedAreas?.address.pageIndex)
    ) {
      return null;
    }

    const digitizedAsset = element.digitizing_hash
      ? digitizedAssets[element.digitizing_hash]
      : (undefined as DigitizedAsset | undefined);

    const renderCanvas = spotFinishingCanvas && element.has_spot_finishing ? spotFinishingCanvas : fabricCanvas;

    switch (element.type) {
      case 'addon':
      case 'image': {
        return addImage(
          renderCanvas,
          element,
          elementIndex,
          offset,
          contentArea,
          contentClipPath,
          mm2px,
          rotation,
          showEmptyImages,
          useThumbnails,
          dataSources,
          digitizedAsset as DigitizedAssetImage | undefined,
        );
      }
      case 'text': {
        if (!isFontFileLoaded(element.extra.fontFamily)) {
          textToLoad.push({ element, elementIndex });
          return null;
        }
        return addTextModule().then((addText) =>
          addText.default(
            renderCanvas,
            element,
            elementIndex,
            offset,
            contentArea,
            contentClipPath,
            mm2px,
            rotation,
            digitizedAsset as DigitizedAssetText | undefined,
          ),
        );
      }
      case 'grid':
        return import('editor/src/component/SpreadPreview/addCalendarGrid').then(({ default: addCalendarGrid }) =>
          addCalendarGrid(
            renderCanvas,
            element,
            elementIndex,
            dataSources.gridDesigns,
            getSpreadWidthFromSpread(spread),
            quality,
            offset,
            contentArea,
            contentClipPath,
            mm2px,
            rotation,
            calendarConfig,
          ),
        );
      case 'line':
        return addLine(renderCanvas, element, elementIndex, offset, contentArea, contentClipPath, mm2px);
      case 'rectangle':
        // don't add a shape if there is not visible
        if (!element.fill && !element.stroke) {
          return null;
        }

        return addRectangle(renderCanvas, element, elementIndex, offset, contentArea, contentClipPath, mm2px, rotation);
      default:
        return null;
    }
  });

  if (textToLoad.length > 0) {
    const fontFamilies = [...new Set(textToLoad.map(({ element }) => element.extra.fontFamily))];
    await Promise.all(fontFamilies.map((fontFamily) => loadFont(fontFamily)));
    textToLoad.forEach(({ element, elementIndex }) => {
      const digitizedAsset = (element.digitizing_hash ? digitizedAssets[element.digitizing_hash] : undefined) as
        | DigitizedAssetText
        | undefined;
      void addTextModule().then((addText) => {
        void addText.default(
          fabricCanvas,
          element,
          elementIndex,
          offset,
          contentArea,
          contentClipPath,
          mm2px,
          rotation,
          digitizedAsset,
        );
      });
    });
  }

  await Promise.all(promises);

  if (spotFinishingCanvas && spread.spot_finishing_type) {
    spotFinishingCanvas.renderAll();

    const foilImageElt = await loadImage(FOIL_ASSET[spread.spot_finishing_type], 'anonymous', {
      executor: 'addElements',
    });
    const canvas = spotFinishingCanvas.getElement();

    applyFoil(foilImageElt, canvas);

    const scale = 1 / window.devicePixelRatio;
    fabricCanvas.add(
      new FabricImage(canvas, {
        left: offset.left,
        top: offset.top,
        objectCaching: false,
        zIndex: 1000,
        scaleX: scale,
        scaleY: scale,
      }),
    );

    return () => {
      spotFinishingCanvas.dispose();
    };
  }

  return undefined;
};

export default addElements;
