import type { Spread } from 'editor/src/store/design/types';

import type { RootState } from 'editor/src/store';

export function getFirstGridElementInSpread(spread: Spread) {
  const { media } = spread.pages[0].groups;
  if (!media) {
    return undefined;
  }

  for (let j = 0; j < media.length; j += 1) {
    const element = media[j];
    if (element.type === 'grid') {
      return element;
    }
  }

  return undefined;
}

function getFirstGridElement(state: RootState, spreadIndex?: number) {
  const { designData } = state.design;
  if (!designData) {
    return undefined;
  }

  if (spreadIndex) {
    return getFirstGridElementInSpread(designData.spreads[spreadIndex]);
  }

  for (let i = 0; i < designData.spreads.length; i += 1) {
    const element = getFirstGridElementInSpread(designData.spreads[i]);
    if (element) {
      return element;
    }
  }

  return undefined;
}

export default getFirstGridElement;
