import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData } from 'editor/src/store/design/types';
import getDesignKey, { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';
import { VariantsState } from 'editor/src/store/variants/types';

import reflectDesignData from 'editor/src/util/reflectDesignData';
import { ReflectContext } from 'editor/src/util/reflectDesignData/getReflectContext';

import { UNSELECTED_PAGE_COUNT } from './setPageCountReducer';

const setGroupDesignDataReducer = (
  state: VariantsState,
  action: PayloadAction<{
    groupKey: string;
    designData: DesignData | undefined;
    reflectContext: ReflectContext;
  }>,
) => {
  const { groupKey, designData, reflectContext } = action.payload;
  const group = state.variationGroups.find((group) => group.key === groupKey);
  if (group) {
    const { selectedPageCount } = state;
    const designDataKey = designData ? getDesignKeyFromDesign(designData) : undefined;
    group.variationsInfo.forEach((variationInfo) => {
      const designKey = getDesignKey(variationInfo.variation.productUid, {
        ...variationInfo,
        pageCount: selectedPageCount === UNSELECTED_PAGE_COUNT ? undefined : selectedPageCount,
      });
      if (designKey === designDataKey) {
        variationInfo.designData = designData;
      } else {
        const template = state.designTemplates[designKey];
        variationInfo.designData =
          designData && template ? reflectDesignData(designData, template, reflectContext) : undefined;
      }
    });
  }
};

export default setGroupDesignDataReducer;
