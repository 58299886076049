import { createSlice } from '@reduxjs/toolkit';

import clearNextStatesReducer from './reducer/clearNextStatesReducer';
import clearPreviousStatesReducer from './reducer/clearPreviousStatesReducer';
import popNextStateReducer from './reducer/popNextStateReducer';
import popPreviousStateReducer from './reducer/popPreviousStateReducer';
import pushNextStateReducer from './reducer/pushNextStateReducer';
import pushPreviousStateReducer from './reducer/pushPreviousStateReducer';
import removeImageFromHistoryReducer from './reducer/removeImageFromHistoryReducer';
import setSaveStepsReducer from './reducer/setSaveStepsReducer';
import { UndoRedoState } from './types';

export const undoRedoInitialState: UndoRedoState = {
  previous: [],
  next: [],
  saveSteps: true,
};

export const slice = createSlice({
  name: 'undoRedo',
  initialState: undoRedoInitialState,
  reducers: {
    pushPreviousStateAction: pushPreviousStateReducer,
    pushNextStateAction: pushNextStateReducer,
    clearNextStatesAction: clearNextStatesReducer,
    clearPreviousStatesAction: clearPreviousStatesReducer,
    popPreviousStateAction: popPreviousStateReducer,
    popNextStateAction: popNextStateReducer,
    setSaveStepsAction: setSaveStepsReducer,
    removeImageFromHistoryAction: removeImageFromHistoryReducer,
  },
});

export const {
  pushPreviousStateAction,
  pushNextStateAction,
  clearNextStatesAction,
  clearPreviousStatesAction,
  popPreviousStateAction,
  popNextStateAction,
  setSaveStepsAction,
  removeImageFromHistoryAction,
} = slice.actions;

const editorModules = slice.reducer;

export default editorModules;
