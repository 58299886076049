/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconWarning({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M4.54165 35C3.90276 35 3.43054 34.7222 3.12498 34.1667C2.81942 33.6111 2.81942 33.0556 3.12498 32.5L18.5416 5.83333C18.8472 5.27778 19.3333 5 20 5C20.6666 5 21.1528 5.27778 21.4583 5.83333L36.875 32.5C37.1805 33.0556 37.1805 33.6111 36.875 34.1667C36.5694 34.7222 36.0972 35 35.4583 35H4.54165ZM20 16.6667C19.5278 16.6667 19.1322 16.8261 18.8133 17.145C18.4933 17.465 18.3333 17.8611 18.3333 18.3333V23.3333C18.3333 23.8056 18.4933 24.2011 18.8133 24.52C19.1322 24.84 19.5278 25 20 25C20.4722 25 20.8683 24.84 21.1883 24.52C21.5072 24.2011 21.6666 23.8056 21.6666 23.3333V18.3333C21.6666 17.8611 21.5072 17.465 21.1883 17.145C20.8683 16.8261 20.4722 16.6667 20 16.6667ZM20 30C20.4722 30 20.8683 29.84 21.1883 29.52C21.5072 29.2011 21.6666 28.8056 21.6666 28.3333C21.6666 27.8611 21.5072 27.4656 21.1883 27.1467C20.8683 26.8267 20.4722 26.6667 20 26.6667C19.5278 26.6667 19.1322 26.8267 18.8133 27.1467C18.4933 27.4656 18.3333 27.8611 18.3333 28.3333C18.3333 28.8056 18.4933 29.2011 18.8133 29.52C19.1322 29.84 19.5278 30 20 30ZM7.41665 31.6667H32.5833L20 10L7.41665 31.6667Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconWarning;
