import React from 'react';

import { ShapeIconProps } from 'editor/src/util/shapePresets';

import Icon from 'editor/src/component/Icon/index';

function IconRectanglePreset({ className, dashPattern, fill, stroke, strokeWidth = 5 }: ShapeIconProps) {
  return (
    <Icon className={className}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="stroke-clip-rect">
          <rect x="8" y="8" width="64" height="64" fill={undefined} vectorEffect="non-scaling-stroke" />
        </clipPath>
        <rect
          x="8"
          y="8"
          width="64"
          height="64"
          fill={fill}
          stroke={stroke}
          strokeWidth={stroke && strokeWidth * 2}
          strokeDasharray={dashPattern && `${dashPattern.dash * strokeWidth},${dashPattern.gap * strokeWidth}`}
          clipPath="url(#stroke-clip-rect)"
        />
      </svg>
    </Icon>
  );
}

export default IconRectanglePreset;
