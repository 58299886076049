// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicator--Y6SZK{width:100%;display:flex;justify-content:center}.circle--cAEky{display:inline-flex !important;width:8px;height:8px;border-radius:4px;background-color:#e6e6e6}.circle--cAEky.active--MNGgx{background-color:#6b6b6b}.circle--cAEky+.circle--cAEky{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/component/PreviewPagination/index.module.scss"],"names":[],"mappings":"AAAA,kBACE,UAAA,CACA,YAAA,CACA,sBAAA,CAGF,eACE,8BAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CAEA,6BACE,wBAAA,CAIJ,8BACE,eAAA","sourcesContent":[".indicator {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.circle {\n  display: inline-flex !important;\n  width: 8px;\n  height: 8px;\n  border-radius: 4px;\n  background-color: rgba(230, 230, 230, 1);\n\n  &.active {\n    background-color: rgba(107, 107, 107, 1);\n  }\n}\n\n.circle + .circle {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": `indicator--Y6SZK`,
	"circle": `circle--cAEky`,
	"active": `active--MNGgx`
};
export default ___CSS_LOADER_EXPORT___;
