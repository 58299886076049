import React, { useEffect, useRef } from 'react';

function useOnClickOutside(ref: React.RefObject<HTMLElement>, onClickOutside: () => void, disabled = false) {
  const cbRef = useRef(onClickOutside);
  cbRef.current = onClickOutside;

  const callCb = (e: Event) => {
    // in this case we do not want to close dropdown
    if (e.target && (e.target as HTMLElement).closest('[data-ignore-outside-click]')) {
      return;
    }

    cbRef.current();
  };

  const handleWindowEvent = (e: MouseEvent | WheelEvent | TouchEvent) => {
    if (ref.current && e.composedPath && !e.composedPath().includes(ref.current)) {
      callCb(e);
    }
  };

  useEffect(() => {
    if (disabled) {
      return undefined;
    }

    window.addEventListener('mousedown', handleWindowEvent, true);
    window.addEventListener('touchstart', handleWindowEvent, true);
    window.addEventListener('wheel', handleWindowEvent, true);

    return () => {
      window.removeEventListener('mousedown', handleWindowEvent, true);
      window.removeEventListener('touchstart', handleWindowEvent, true);
      window.removeEventListener('wheel', handleWindowEvent, true);
    };
  }, [disabled]);
}

export default useOnClickOutside;
