import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchProductPreviews } from 'product-personalizer/src/utils/loadData';

import ControlButton from 'editor/src/component/ControlButton';
import IconCross from 'editor/src/component/Icon/IconCross';
import IconEye from 'editor/src/component/Icon/IconEye';
import Carousel from 'editor/src/component/Preview/FlatPreview/Carousel';
import { PreviewItem } from 'editor/src/component/Preview/FlatPreview/FlatPreviewList/ListItem';
import useDetectDeviceType, { mobileDevices } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  variantId: string;
  customizationId?: string;
  placeholderUrl?: string;
  onClose?: () => void;
}

const IMAGE_DIMENSION = 800;

function ZoomInProductPreviewPopup({ customizationId, variantId, placeholderUrl, onClose }: Props) {
  const { t } = useTranslation();
  const deviceType = useDetectDeviceType();
  const isMobile = mobileDevices.has(deviceType);
  const placeholderImage = { name: 'placeholder', url: placeholderUrl };
  const [productPreviews, setProductPreviews] = useState<PreviewItem[]>([placeholderImage]);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    // TODO for the time being use the placeholderImage if provided
    if (placeholderUrl || (!variantId && !customizationId)) {
      return;
    }
    void fetchProductPreviews(variantId, IMAGE_DIMENSION, IMAGE_DIMENSION, customizationId).then((previewUrl) => {
      setProductPreviews([{ name: previewUrl, url: previewUrl }]);
    });
  }, [placeholderUrl, customizationId, variantId]);

  const closeModal = () => {
    setIsHidden(true);
    if (onClose) {
      onClose();
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <div
      className={cn(styles.Preview, 'cy-preview-container', {
        [styles.desktop]: !isMobile,
      })}
    >
      <div className={styles.bg} onClick={closeModal} />
      <div className={styles.popup}>
        <div className={cn(styles.topMenu, 'p-2')}>
          <div className={styles.title}>
            <IconEye className="mx-1" />
            {t('editor-flat-preview')}
          </div>
          <ControlButton onClick={closeModal} className={cn(styles.closeButton, 'cy-preview-close')}>
            <IconCross />
          </ControlButton>
        </div>
        <Carousel items={productPreviews} isMobile={isMobile} activeIndex={0} setActiveIndex={() => {}} />
      </div>
    </div>
  );
}

export default React.memo(ZoomInProductPreviewPopup);
