import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

const updateDesignOptionControlTitleReducer = (
  state: VariantsState,
  action: PayloadAction<{ controlKey: string; title: string }>,
) => {
  const { controlKey, title } = action.payload;

  const control = state.designOptionsControl?.find((control) => control.key === controlKey);
  if (control) {
    control.title = title;
  }
};

export default updateDesignOptionControlTitleReducer;
