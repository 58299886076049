import { PayloadAction } from '@reduxjs/toolkit';

import { conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

const addConditionGroupReducer = (state: DesignState, action: PayloadAction<{ spreadIndex: number }>) => {
  if (!state.designData) {
    return;
  }

  const { spreadIndex } = action.payload;
  const spread = state.designData.spreads[spreadIndex];
  spread.conditionGroup = {
    children: { [conditionGroupChildenKey]: [] },
    conditions: {},
  };
};

export default addConditionGroupReducer;
