import { BaseImage } from 'editor/src/store/gallery/types';

import { logEvent } from 'editor/src/amplitude';

export type PayloadFile = BaseImage & {
  type: string;
  size: number;
  isRemote: boolean;
  progress: {
    bytesTotal: number;
    bytesUploaded: number;
    percentage: number;
    uploadComplete: boolean;
    uploadStarted: number;
  };
};

export function registerUpload(file: PayloadFile) {
  uploadStats[file.id] = {
    type: file.type,
    size: file.size ?? 0,
    uploadTimestamp: Date.now(),
    startTimestamp: file.progress.uploadStarted,
    source: file.source,
    isRemote: file.isRemote,
  };
}

export function logUpload(
  id: string,
  processingTimestamp: number,
  status: 'success' | 'processing-failed' | 'upload-failed',
) {
  if (!uploadStats[id]) {
    return;
  }

  const { startTimestamp = 0, uploadTimestamp = 0, size = 0, type, source, isRemote } = uploadStats[id];

  const sizeKb = size / 1000;

  const uploadTime = (uploadTimestamp - startTimestamp) / 1000;
  const uploadSpeedKbs = sizeKb / uploadTime;
  const processTime = (processingTimestamp - uploadTimestamp) / 1000;
  const processSpeedKbs = sizeKb / processTime;
  const totalTime = uploadTime + processTime;
  const totalSpeedKbs = sizeKb / (uploadTime + processTime);

  logEvent('file uploaded', {
    sizeKb,
    type,
    uploadTime,
    uploadSpeedKbs,
    processTime,
    processSpeedKbs,
    totalTime,
    totalSpeedKbs,
    status,
    source: isRemote && source ? source : undefined,
  });
  delete uploadStats[id];
}

const uploadStats: {
  [uploadId: string]: {
    size: number;
    type: string;
    startTimestamp: number;
    uploadTimestamp: number;
    source: string | undefined;
    isRemote: boolean;
  };
} = {};

export default uploadStats;
