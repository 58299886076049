import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';

const updateCollectionImageOperation =
  (elementAddress: ElementAddress, assetId: string, url: string, assetWidth: number, assetHeight: number) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement<MediaImage>(state, elementAddress);
    if (!element) {
      return;
    }

    const { px, py, pw, ph } = getCenteredImagePosition(element, {
      width: assetWidth,
      height: assetHeight,
    });

    dispatch(
      updateMediaElementAction({
        elementAddress,
        elementUpdate: {
          imageId: assetId,
          url,
          px,
          py,
          pw,
          ph,
          pr: 0,
        },
      }),
    );
  };

export default updateCollectionImageOperation;
