import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryImage, GalleryState } from 'editor/src/store/gallery/types';

import UuidGenerator from 'editor/src/util/uuids/UuidGenerator';

export const imageUuid = new UuidGenerator();

const addImageReducer = (state: GalleryState, action: PayloadAction<Omit<GalleryImage, 'uuid'>>) => {
  const image = action.payload;
  if (!state.images.some((im) => im.id === image.id)) {
    state.images.unshift({ ...image, uuid: imageUuid.generate() });
  }
};

export default addImageReducer;
