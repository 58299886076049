import { CalendarDayEvent } from '@gelatoas/design-editor-calendar';

import { MediaGridInfo } from 'editor/src/store/design/types';

const createGridHash = (gridInfo: MediaGridInfo, events: CalendarDayEvent[]) => {
  return [
    gridInfo.designName,
    gridInfo.locale,
    gridInfo.firstDayOfWeek,
    gridInfo.hideWeekNum,
    gridInfo.gridDate.year,
    gridInfo.gridDate.month,
    JSON.stringify(events),
  ].join('-');
};

export default createGridHash;
