import React from 'react';

import { Blank, Coords } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import BlankArea from './BlankArea';

interface Props {
  blank: Blank;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  showBlankAreaShadowOnly: boolean;
}

function BlankElement({ blank, pageCoords, canvasRotation, showBlankAreaShadowOnly }: Props) {
  return (
    <div>
      <BlankArea
        blank={blank}
        pageCoords={pageCoords}
        canvasRotation={canvasRotation}
        showBlankAreaShadowOnly={showBlankAreaShadowOnly}
      />
    </div>
  );
}

export default BlankElement;
