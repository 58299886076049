// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChipsFields--XbW9Q{display:flex;flex-wrap:wrap;gap:12px}`, "",{"version":3,"sources":["webpack://./src/component/ChipsFields/index.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,cAAA,CACA,QAAA","sourcesContent":["@import 'editor/src/comonStyles/index';\n\n.ChipsFields {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChipsFields": `ChipsFields--XbW9Q`
};
export default ___CSS_LOADER_EXPORT___;
