import { LayoutGroup } from 'editor/src/store/editorModules/layouts/types';

export const GROUP_LAYOUT_TAG_CALENDAR = 'calendar';

const layouts = [
  { layouts: ['grid-empty', 'grid-empty'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i1t1_3'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // two blocks without a frame
  { layouts: ['grid-vertical-4', 'i2t0_ep0'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  // two blocks with a frame
  { layouts: ['grid-vertical-4', '3'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // three blocks with a frame
  { layouts: ['grid-vertical-4', 'i3t0_ep1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t3_ep1_2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_ep1_3'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // four blocks without a frame
  { layouts: ['grid-vertical-4', 'i4t0_ep0'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  // four with the frame
  { layouts: ['grid-vertical-4', '6'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // two in corners with overlap with frame
  { layouts: ['grid-vertical-4', 'i2t0_ep1_cascade'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  // two in corners with overlap without frame
  { layouts: ['grid-vertical-4', 'i2t0_ep0_cascade'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // three as pyramid with overlap with frame
  { layouts: ['grid-vertical-4', 'i3t0_cascade_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  // three in tree rows with overlap with frame
  { layouts: ['grid-vertical-4', 'i3t0_cascade_2'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // four in four items with overlap
  { layouts: ['grid-vertical-4', 'i4t0_cascade'], tag: GROUP_LAYOUT_TAG_CALENDAR },

  // four in four items
  { layouts: ['grid-vertical-4', 'i4t0_grid_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i4t0_grid_2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i4t0_grid_3'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i4t0_grid_4'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '9'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i5t0_grid_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '4'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', '5'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_3'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_4'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_5'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_6'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t1_grid_7'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t1_grid_8'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_9'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i1t1_grid_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i6t0_grid_1'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i6t0_grid_2'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t1_grid_10'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  { layouts: ['grid-vertical-4', 'i3t0_grid_11'], tag: GROUP_LAYOUT_TAG_CALENDAR },
  {
    layouts: ['grid-vertical-4', 'grid-empty'],
    tag: GROUP_LAYOUT_TAG_CALENDAR,
  },
];

const groupLayouts: LayoutGroup[] = layouts.map(({ layouts, tag }) => ({
  layouts,
  tag,
  name: layouts.join('_'),
}));

export default groupLayouts;
