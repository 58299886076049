import { Dimensions, MediaText } from 'editor/src/store/design/types';
import { Unit } from 'editor/src/store/editor/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getFrameTextTemplate from 'editor/src/util/layouts/getFrameTextTemplate';
import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit } from 'editor/src/util/math';
import getTextFromTemplate from 'editor/src/util/text/getTextFromTemplate';

import type { TFunction } from 'i18next';

type ImageSizeTemplateParams = 'width' | 'height' | 'unit';

const getResizableImageLabelText = (
  labelElement: MediaText,
  layout: LayoutSchema,
  dimensions: Dimensions,
  unit: Unit,
  t: TFunction,
): string | undefined => {
  if (!labelElement.layoutFrameId) {
    return undefined;
  }

  const textTemplate = getFrameTextTemplate(labelElement.layoutFrameId, layout, t);
  if (!textTemplate) {
    return undefined;
  }

  return getTextFromTemplate<ImageSizeTemplateParams>(textTemplate, {
    width: limitPrecision(convertMmToUnit(dimensions.width, unit), 1).toString(),
    height: limitPrecision(convertMmToUnit(dimensions.height, unit), 1).toString(),
    unit,
  });
};

export default getResizableImageLabelText;
