import cloneDeep from 'lodash/cloneDeep';

import { DesignData, Spread } from 'editor/src/store/design/types';

import { ReflectContext } from './getReflectContext';
import reflectMediaElements from './reflectMediaElements';
import { Mode } from './utils';

export const reflectSpreadAndApply = ({
  sourceSpread,
  destSpread,
  sourceDesign,
  newDesign,
  sourceSpreadIndex,
  context,
  mode = 'adapt',
}: {
  sourceSpread: Spread;
  destSpread: Spread;
  sourceDesign: DesignData;
  newDesign: DesignData;
  sourceSpreadIndex: number;
  context: ReflectContext;
  mode?: Mode;
}) => {
  destSpread.layoutSchemaName = sourceSpread.layoutSchemaName;
  destSpread.sceneId = sourceSpread.sceneId;
  destSpread.conditionGroup = cloneDeep(sourceSpread.conditionGroup);

  const sourceFirstPage = sourceSpread.pages[0];
  if (!sourceFirstPage || !destSpread.pages[0]) {
    return;
  }

  const mediaContext = (context.fonts || context.gridDesigns) && {
    fonts: context.fonts || [],
    gridDesigns: context.gridDesigns || [],
  };
  const media = reflectMediaElements(
    sourceSpread,
    sourceFirstPage,
    destSpread,
    context.layouts,
    mode,
    sourceSpreadIndex,
    sourceDesign,
    newDesign,
    mediaContext,
  );

  sourceSpread.pages.forEach((sourcePage, pageIndex) => {
    const destPage = destSpread.pages[pageIndex];
    if (!destPage) {
      return;
    }

    destPage.backgroundColor = sourcePage.backgroundColor;

    sourcePage.groups.content?.forEach((sourceContent) => {
      if (sourceContent.type === 'sample') {
        return;
      }
      const destContent = destPage.groups.content?.find((content) => content.name === sourceContent.name);
      if (destContent) {
        destContent.extra = cloneDeep(sourceContent.extra);
      }
    });

    destPage.groups.media = media;
  });
};

export default reflectSpreadAndApply;
