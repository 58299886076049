import { createSlice } from '@reduxjs/toolkit';

import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import { VariantsState } from 'editor/src/store/variants/types';

export const variantsInitialState: VariantsState = {
  product: {
    title: '',
    categoryTitle: '',
    description: '',
    productVariations: [],
    productControls: [],
    externalProductControls: [],
    controlsUIOrder: [],
    previewUrls: [],
    outOfStock: {},
    groupBy: [],
    pages: undefined,
    unavailableProducts: {},
  },
  replaceControlKeys: [],
  configuration: {},
  isLoaded: false,
  isVariantFlow: false,

  variationGroups: [],
  selectedGroupKey: undefined,
  selectedPageCount: UNSELECTED_PAGE_COUNT,
  selectedMultiOptions: {},
  selectedSingleOptions: {},
  selectedExternalOptions: {},

  designTemplates: {},
  spreadImages: {},
  layoutPerProductUids: {},

  existingVariants: [],
  eCommerceProduct: undefined,
  groupedSpreadsPerProductUids: {},
  designOptionsEnabled: false,
};

const slice = createSlice({
  name: 'variants',
  initialState: variantsInitialState,
  reducers: {},
});

const { reducer } = slice;

export default reducer;
