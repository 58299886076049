import { useMemo } from 'react';

import { conditionGroupChildenKey, Spread } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

const useSpreadWithPersonalization = () => {
  const designData = useSelector((state) => state.design.designData);

  const spreadsWithPersonalization = useMemo(() => {
    if (!designData) {
      return [];
    }

    const spreads: Array<{ spreadIndex: number; spread: Spread }> = [];
    designData.spreads.forEach((spread, spreadIndex) => {
      if (spread.conditionGroup?.children?.[conditionGroupChildenKey]?.length) {
        spreads.push({ spreadIndex, spread });
      }
    });
    return spreads;
  }, [designData]);

  return spreadsWithPersonalization;
};

export default useSpreadWithPersonalization;
