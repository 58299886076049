import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconArrowDownSVG({ className }: Props) {
  return (
    <svg className={className} width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.51472 1L5.75736 5.24264L10 1"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconArrowDownSVG);
