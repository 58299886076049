import { RootState } from '..';

import shouldDigitizeSpreadIndex from './shouldDigitizeSpreadIndex';

const shouldDigitizeCurrentSpread = (state: RootState): boolean => {
  const { currentSpreadIndex } = state.editor;
  return shouldDigitizeSpreadIndex(state, currentSpreadIndex);
};

export default shouldDigitizeCurrentSpread;
