import { createSlice } from '@reduxjs/toolkit';

import setCollectionFolderReducer from './reducer/setCollectionFolderReducer';
import { CollectionsState } from './types';

export const collectionsInitialState: CollectionsState = {
  folders: {},
  assets: {},
  topFolderId: undefined,
};

export const slice = createSlice({
  name: 'collections',
  initialState: collectionsInitialState,
  reducers: {
    setCollectionFolderAction: setCollectionFolderReducer,
  },
});

export const { setCollectionFolderAction } = slice.actions;

export default slice.reducer;
