import React from 'react';

import IconBrightness from 'editor/src/component/Icon/Adjustments/IconBrightness';
import IconContrast from 'editor/src/component/Icon/Adjustments/IconContrast';
import IconHue from 'editor/src/component/Icon/Adjustments/IconHue';
import IconSaturation from 'editor/src/component/Icon/Adjustments/IconSaturation';
import IconTemperature from 'editor/src/component/Icon/Adjustments/IconTemperature';
import IconVibrance from 'editor/src/component/Icon/Adjustments/IconVibrance';

export type AdjustmentType = {
  index: number;
  name:
    | 'brightness'
    | 'contrast'
    | 'temperature'
    | 'saturation'
    | 'hue'
    | 'vibrance'
    | 'sepia'
    | 'brownie'
    | 'kodachrome'
    | 'polaroid'
    | 'technicolor'
    | 'trueBlackAndWhite'
    | 'vintage'
    | 'black';
  min: number;
  max: number;
  step: number;
  default: number;
  translationKey: string;
  icon?: React.ReactNode;
  railBackground?: string;
  hidden?: boolean;
};

const ADJUSTMENTS: AdjustmentType[] = [
  {
    index: 0,
    name: 'brightness',
    min: -100,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-brightness',
    icon: <IconBrightness />,
  },
  {
    index: 1,
    name: 'contrast',
    min: -100,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-contrast',
    icon: <IconContrast />,
  },
  {
    index: 2,
    name: 'temperature',
    min: -100,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-temperature',
    icon: <IconTemperature />,
  },
  {
    index: 3,
    name: 'saturation',
    min: -100,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-saturation',
    icon: <IconSaturation />,
  },
  {
    index: 4,
    name: 'hue',
    min: -180,
    max: 180,
    step: 1,
    default: 0,
    translationKey: 'editor-hue',
    icon: <IconHue />,
    railBackground:
      'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 16%, rgba(0,255,0,1) 33%,' +
      ' rgba(0,255,255,1) 50%, rgba(0,0,255,1) 66%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%)',
  },
  {
    index: 5,
    name: 'vibrance',
    min: -100,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-vibrance',
    icon: <IconVibrance />,
  },
  {
    index: 6,
    name: 'sepia',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-sepia',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 7,
    name: 'brownie',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-brownie',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 8,
    name: 'kodachrome',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-kodachrome',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 9,
    name: 'polaroid',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-polaroid',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 9,
    name: 'technicolor',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-technicolor',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 10,
    name: 'trueBlackAndWhite',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-true-black-and-white',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 11,
    name: 'vintage',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-vintage',
    icon: <IconVibrance />,
    hidden: true,
  },
  {
    index: 11,
    name: 'black',
    min: 0,
    max: 100,
    step: 1,
    default: 0,
    translationKey: 'editor-black',
    icon: <IconVibrance />,
    hidden: true,
  },
];

export default ADJUSTMENTS;
