import setTimeoutPromise from 'editor/src/util/setTimeoutPromise';

const RETRIES = 2;

const fetchWithRetry = async (url: string, retries = RETRIES): Promise<Response> => {
  try {
    return await fetch(url, { mode: 'cors' });
  } catch (e) {
    if (retries === 0) {
      throw new Error(e);
    }
  }

  return setTimeoutPromise((RETRIES + 1 - retries) * 1000).then(() => fetchWithRetry(url, retries - 1));
};

export default fetchWithRetry;
