import updateImageIdByUuidOperation from 'editor/src/store/design/operation/updateImageIdByUuidOperation';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { MediaImage } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const setImageIfCurrentImageIsEmptyOperation =
  (imageId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const spreadIndex = getCurrentSpreadIndex(state);
    const emptyImages = getSpread(state, spreadIndex)?.pages[0].groups.media?.reduce<MediaImage[]>(
      (images, element) => {
        if (element.type === 'image' && (element.imageId === '' || element.sample)) {
          images.push(element);
        }
        return images;
      },
      [],
    );

    if (!emptyImages?.length) {
      return;
    }

    const selectedElementUuids = getSelectedElementUuids(state);
    const { autoApplyUploadedImages } = state.hostSettings;

    const firstEmptySelectedImage = emptyImages.find((image) => selectedElementUuids.includes(image.uuid));
    if (firstEmptySelectedImage) {
      dispatch(updateImageIdByUuidOperation(firstEmptySelectedImage.uuid, imageId));
    } else if (autoApplyUploadedImages) {
      dispatch(updateImageIdByUuidOperation(emptyImages[0].uuid, imageId));
    }
  };

export default setImageIfCurrentImageIsEmptyOperation;
