import { captureException } from '@sentry/react';

import { overrideColorsToReplacementColors } from 'editor/src/store/design/operation/getAssetUrlWithReplacedColors';
import { ImageBrushData } from 'editor/src/store/design/types';

import { AssetFileType } from '../types/asset';

/**
 * Applies color overrides to a given asset.
 *
 * @param {string} assetId - The ID of the asset to apply color overrides.
 * @param {ImageBrushData} colorOverrides - The color overrides to apply.
 * @returns {Promise<{url: string}>} A promise that resolves when the operation is complete.
 */
export default async function applyColorOverridesToAsset(
  assetId: string,
  colorOverrides: ImageBrushData,
): Promise<{ url: string }> {
  const endpoint = '/apps/gelato-pp/asset/previews:color';
  const colorReplacement = overrideColorsToReplacementColors(colorOverrides);
  const body = JSON.stringify({
    id: assetId,
    colorOverrides: [...colorReplacement.fill, ...colorReplacement.stroke],
    previewType: AssetFileType.PREVIEW_QUANTIZED,
  });

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    captureException(error);
    throw new Error('Error applying color overrides:', error.message);
  }
}
