import getAddonElementsUsedInSpread from 'editor/src/store/design/selector/getAddonElementsUsedInSpread';
import getImageElementsUsedInSpread from 'editor/src/store/design/selector/getImageElementsUsedInSpread';
import getTextElementsUsedInSpread from 'editor/src/store/design/selector/getTextElementsUsedInSpread';
import { Spread } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import { RootState } from 'editor/src/store';
import generateLayout from 'editor/src/util/layouts/generateLayout';
import generateLayoutMediaElements from 'editor/src/util/layouts/generateLayoutMediaElements';
import notUndefined from 'editor/src/util/notUndefined';

import getAppliedLayoutSchemaToSpreadMedia, { getImageWithDimensions } from './getAppliedLayoutSchemaToSpreadMedia';
import getLayoutBBox from './getLayoutBBox';

import type { i18n } from 'i18next';

const applyLayoutSchemaToSpread = (
  state: RootState,
  spread: Spread,
  spreadIndex: number,
  layoutSchema: LayoutSchema,
  i18n: i18n,
) => {
  const bbox = getLayoutBBox(layoutSchema, spread);
  if (!bbox.width || !bbox.height) {
    return;
  }

  const layoutFrames = generateLayout(layoutSchema, bbox.left, bbox.top, bbox.width, bbox.height);

  const layoutElements = generateLayoutMediaElements(state, spreadIndex, spread, layoutFrames, i18n);

  const imageElementsToPlace = getImageElementsUsedInSpread(spread)
    .map((img) => getImageWithDimensions(state, img))
    .filter(notUndefined);
  const addonElementsToPlace = getAddonElementsUsedInSpread(spread);
  const textElementsToPlace = getTextElementsUsedInSpread(spread);

  const media = getAppliedLayoutSchemaToSpreadMedia(
    spread,
    layoutElements,
    {
      text: textElementsToPlace,
      image: imageElementsToPlace,
      addon: addonElementsToPlace,
    },
    false,
  );

  spread.pages[0].groups.media = media;
  spread.layoutSchemaName = layoutSchema.name;
};

export default applyLayoutSchemaToSpread;
