import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryState } from 'editor/src/store/gallery/types';

const removeImageReducer = (state: GalleryState, action: PayloadAction<string>) => {
  const imageIndex = state.images.findIndex((image) => image.id === action.payload);
  if (imageIndex !== -1) {
    const image = state.images[imageIndex];
    if (image.previewData) {
      URL.revokeObjectURL(image.previewData);
    }
    state.images.splice(imageIndex, 1);
  }
};

export default removeImageReducer;
