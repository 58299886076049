import { fabric } from 'fabric';

const glsl = (e: TemplateStringsArray) => e;

export type ConstructorOption = {
  repeat: number;
  shiftX: number;
  shiftY: number;
  offsetX: number;
  offsetY: number;
  spacing: number;
  mirror: boolean;
};

interface BrickFilter extends fabric.IBaseFilter {}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const BrickFilter = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  repeat: 2,
  shiftX: 0,
  shiftY: 0,
  offsetX: 0,
  offsetY: 0,
  spacing: 0,
  mirror: false,
  type: 'BrickFilter',
  vertexSource: glsl`
    attribute vec2 aPosition;

    uniform float u_fOffsetX;
    uniform float u_fOffsetY;
    uniform float u_fRepeat;
    uniform float u_fSpacing;
    
    varying vec2 v_f2TexCoord;
    
    void main() {
      v_f2TexCoord = vec2(aPosition.x - u_fOffsetX, aPosition.y - u_fOffsetY) * u_fRepeat;
      gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);
    }
  `,

  fragmentSource: glsl`
    precision highp float;
    varying vec2 v_f2TexCoord;
    uniform sampler2D u_s2Texture;

    uniform float u_fShiftX;
    uniform float u_fShiftY;
    uniform float u_fSpacing;
    uniform float u_fRepeat;
    uniform int u_iMirror;

    void main() {
      float fStepX = floor(v_f2TexCoord.x);
      float fStepY = floor(v_f2TexCoord.y);

      vec2 f2CoordsWithShift = vec2(v_f2TexCoord.x - fStepY * u_fShiftX, v_f2TexCoord.y - fStepX * u_fShiftY);
      vec2 f2Coords = mod(f2CoordsWithShift, 1.0);

      float u_fSpacingR = u_fSpacing / 2.0;
      if (f2Coords.x < u_fSpacingR || f2Coords.x > 1.0 - u_fSpacingR || f2Coords.y < u_fSpacingR || f2Coords.y > 1.0 - u_fSpacingR) {
        gl_FragColor = vec4(0, 0, 0, 0);
      } else {
        f2Coords = f2Coords / (1.0 - u_fSpacing) - u_fSpacingR / (1.0 - u_fSpacing);

        if (u_iMirror == 1 && mod(floor(f2CoordsWithShift.x), 2.0) == 1.0) {
          f2Coords.x = 1.0 - f2Coords.x;
        }
        if (u_iMirror == 1 && mod(floor(f2CoordsWithShift.y), 2.0) == 1.0) {
          f2Coords.y = 1.0 - f2Coords.y;
        }

        vec4 color = texture2D(u_s2Texture, f2Coords);
        gl_FragColor = color;
      }
    }
  `,

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      repeat: gl.getUniformLocation(program, 'u_fRepeat'),
      shiftX: gl.getUniformLocation(program, 'u_fShiftX'),
      shiftY: gl.getUniformLocation(program, 'u_fShiftY'),
      offsetX: gl.getUniformLocation(program, 'u_fOffsetX'),
      offsetY: gl.getUniformLocation(program, 'u_fOffsetY'),
      spacing: gl.getUniformLocation(program, 'u_fSpacing'),
      mirror: gl.getUniformLocation(program, 'u_iMirror'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.repeat, parseFloat(this.repeat));
    gl.uniform1f(uniformLocations.shiftX, parseFloat(this.shiftX));
    gl.uniform1f(uniformLocations.shiftY, parseFloat(this.shiftY));
    gl.uniform1f(uniformLocations.offsetX, parseFloat(this.offsetX));
    gl.uniform1f(uniformLocations.offsetY, parseFloat(this.offsetY));
    gl.uniform1f(uniformLocations.spacing, parseFloat(this.spacing));
    gl.uniform1i(uniformLocations.mirror, this.mirror ? 1 : 0);
  },

  applyTo2d() {
    // we need to implement this for browser that don't support webgl
  },
}) as { new (options: ConstructorOption): BrickFilter };

(BrickFilter as any).fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).BrickFilter = BrickFilter;

export default BrickFilter;
