import { useEffect } from 'react';

import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { MediaAddon, MediaImage } from 'editor/src/store/design/types';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import limitPrecision from 'editor/src/util/limitPrecision';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import useDigitizedAsset from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useDigitizedAsset';

export const DIGITIZATION_PREVIEW_SIZE = 600;

function useDigitizedImage(
  element: MediaImage | MediaAddon,
  galleryImage: GalleryImage | undefined,
  imageUrl: string | undefined,
) {
  const previewSize =
    element.pw > element.ph
      ? DIGITIZATION_PREVIEW_SIZE
      : Math.round(DIGITIZATION_PREVIEW_SIZE * (element.ph / element.pw));
  const digitizeDesignElements = useSelector(shouldDigitizeCurrentSpread);
  const dispatch = useDispatch();
  const isImageQuantized = !!galleryImage?.quantizedUrl && !!galleryImage.quantizedColors;
  const hasColorUrl = !element.colorOverrides || !!element.colorOverrides.url;
  const empty = element.type === 'image' ? !element.imageId : !element.addonId;

  const { digitizedAsset, showDigitizedAsset } = useDigitizedAsset(
    element,
    empty || !isImageQuantized || !imageUrl || !hasColorUrl,
    (imageId, productId, variantKey, elementId) =>
      imageUrl
        ? {
            elementType: element.type,
            elementId,
            payload: {
              imageUrl,
              rendered_mm_width: limitPrecision(element.pw),
              output_px_width: previewSize,
              elementUuid: element.uuid,
              variantKey,
            },
            imageId,
            productId,
            reuseIfExist: true,
          }
        : undefined,
  );

  useEffect(() => {
    if (
      !digitizeDesignElements ||
      element.type !== 'image' ||
      galleryImage?.state !== ImageState.UPLOADED ||
      isImageQuantized
    ) {
      return undefined;
    }

    dispatch(updateMediaElementByUuidOperation(element.uuid, { digitizing: true }));
    sendPostMessage('design.requestQuantizedAsset', element.imageId);

    return () => {
      dispatch(
        updateMediaElementByUuidOperation(element.uuid, {
          digitizing: undefined,
        }),
      );
    };
  }, [digitizeDesignElements, galleryImage?.state, element.uuid, isImageQuantized]);

  return {
    digitizedAssetUrl: digitizedAsset?.url,
    showDigitizedAsset: digitizedAsset && showDigitizedAsset,
  };
}

export default useDigitizedImage;
