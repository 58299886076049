import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import controlDesignDataAndGetColorOverrides from 'editor/src/store/design/operation/controlDesignDataAndGetColorOverrides';
import setupUuidGenerators from 'editor/src/store/design/operation/setupUuidGenerators';
import updateHiddenElementsOperation from 'editor/src/store/design/operation/updateHiddenElementsOperation';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import requestVariantsColorOverrides from 'editor/src/store/design/util/requestVariantsColorOverrides';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import clearStatesOperation from 'editor/src/store/editorModules/undoRedo/operation/clearStatesOperation';
import loadDesignDataFonts from 'editor/src/store/fonts/utils/loadDesignDataFonts';
import type { AsyncThunk } from 'editor/src/store/hooks';

const setDesignDataPPOperation =
  (designData: DesignData): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    // backend sends wrong data ? let's not set it..
    if (!designData.spreads) {
      throw new Error('no spreads in the design data?');
    }

    if (designData.spreads.some((spread) => spread.pages.length === 0)) {
      throw new Error('no pages in the spreads?');
    }

    const colorOverrides = controlDesignDataAndGetColorOverrides({
      designData,
      i18n,
      spreadGroups: undefined,
    });
    requestVariantsColorOverrides(colorOverrides, undefined, undefined);
    setupUuidGenerators([designData]);

    const state = getState();
    await loadDesignDataFonts([designData], state.fonts.availableFonts, state.gridDesigns.grids).catch((e) => {
      throw e || new Error('failed loading design fonts');
    });

    batch(() => {
      dispatch(clearStatesOperation());
      dispatch(setCurrentSpreadIndexOperation(0));
      dispatch(setDesignDataAction(designData));
      dispatch(updateHiddenElementsOperation());
    });

    // eslint-disable-next-line no-console
    console.log(`Product uid: ${designData.product_uid}`);
  };

export default setDesignDataPPOperation;
