import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Vibrance = createClass(filters.BaseFilter, {
  type: 'GelatoVibrance',

  fragmentSource:
    'precision highp float;\n' +
    'uniform sampler2D uTexture;\n' +
    'uniform float uVibrance;\n' +
    'varying vec2 vTexCoord;\n' +
    'void main() {\n' +
    'vec4 color = texture2D(uTexture, vTexCoord);\n' +
    'float max = max(color.r, max(color.g, color.b));\n' +
    'float avg = (color.r + color.g + color.b) / 3.0;\n' +
    'float amt = (abs(max - avg) * 2.0) * (-uVibrance);\n' +
    'color.r += max != color.r ? (max - color.r) * amt : 0.00;\n' +
    'color.g += max != color.g ? (max - color.g) * amt : 0.00;\n' +
    'color.b += max != color.b ? (max - color.b) * amt : 0.00;\n' +
    'gl_FragColor = color;\n' +
    '}',

  vibrance: 0,
  mainParameter: 'vibrance',

  applyTo2d(options: Options) {
    if (this.vibrance === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;
    const adjust = this.vibrance;
    let max;
    let avg;
    let amt;

    for (let i = 0; i < len; i += 4) {
      max = Math.max(data[i], data[i + 1], data[i + 2]);
      avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      amt = ((Math.abs(max - avg) * 2) / 255) * adjust;
      data[i] += max !== data[i] ? (max - data[i]) * amt : 0;
      data[i + 1] += max !== data[i + 1] ? (max - data[i + 1]) * amt : 0;
      data[i + 2] += max !== data[i + 2] ? (max - data[i + 2]) * amt : 0;
    }
  },

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uVibrance: gl.getUniformLocation(program, 'uVibrance'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uVibrance, this.vibrance / 100);
  },

  isNeutralState() {
    return this.vibrance === 0;
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      vibrance: this.vibrance,
    });
  },
});

Vibrance.fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).GelatoVibrance = Vibrance;

export default Vibrance;
