import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LANGUAGE = 'en';

function createI18n(translationUrl: string) {
  const i18n = i18next.use(Backend).use(initReactI18next);

  function setLanguageWithDash(lng: string) {
    const underSplit = lng.split('_');
    if (underSplit.length > 1) {
      i18n.languageWithDash = `${underSplit[0]}-${underSplit[1]}`;
    } else {
      i18n.languageWithDash = lng;
    }
  }

  i18n.on('languageChanged', setLanguageWithDash);

  void i18n.init({
    backend: {
      loadPath: `${translationUrl}{{lng}}.json`,
      crossDomain: true,
    },
    fallbackLng: DEFAULT_LANGUAGE,
    lng: DEFAULT_LANGUAGE,
    ns: 'translations',
    defaultNS: 'translations',
    keySeparator: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  });

  setLanguageWithDash(DEFAULT_LANGUAGE);

  return i18n;
}

export default createI18n;
