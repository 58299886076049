import cloneDeep from 'lodash/cloneDeep';

import { DesignData } from 'editor/src/store/design/types';

import { ReflectContext } from './getReflectContext';
import reflectSpreadAndApply from './reflectSpreadAndApply';
import { Mode } from './utils';

function reflectDesignData(
  sourceDesign: DesignData,
  destDesign: DesignData,
  context: ReflectContext,
  mode: Mode = 'adapt',
): DesignData {
  const { groupedSpreadsPerProductUids } = context;
  const spreadGroups = groupedSpreadsPerProductUids?.[destDesign.product_uid];
  const newDesign = cloneDeep(destDesign);
  newDesign.spread_groups = spreadGroups;
  newDesign.lifecycle_metadata = cloneDeep(sourceDesign.lifecycle_metadata);
  newDesign.color_space = sourceDesign.color_space;

  newDesign.plugins = cloneDeep(sourceDesign.plugins);
  newDesign.personalizationSettings = cloneDeep(sourceDesign.personalizationSettings);
  newDesign.calendar = cloneDeep(sourceDesign.calendar);

  sourceDesign.spreads.forEach((sourceSpread, spreadIndex) => {
    const destSpread = newDesign.spreads.find((spread) => spread.name === sourceSpread.name);
    if (!destSpread) {
      return;
    }

    reflectSpreadAndApply({
      sourceSpread,
      destSpread,
      sourceDesign,
      newDesign,
      sourceSpreadIndex: spreadIndex,
      context,
      mode,
    });
  });

  return newDesign;
}

export default reflectDesignData;
