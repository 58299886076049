import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React, { useMemo } from 'react';

import FabricPolygon from 'editor/src/fabric/FabricPolygon';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends Omit<fabric.IPolylineOptions, 'points'> {
  zIndex?: number;
  polygon?: Polygon;
  points?: Array<{ x: number; y: number }>;
}

function FabricPolygonComponent(props: Props) {
  const fabricCanvas = useFabricCanvas();
  const { polygon, points, ...fabricProps } = props;

  const element = useMemo(
    () => new FabricPolygon(polygon?.[0].map((p) => ({ x: p[0], y: p[1] })) || points || []),
    [polygon, points],
  );
  useObjectProps(element, fabricProps);
  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(FabricPolygonComponent);
