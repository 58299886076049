import React from 'react';

import Icon from 'editor/src/component/Icon/index';

/* eslint-disable max-len */

interface Props {
  className?: string;
}

function IconArrowFillLinePreset({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.93934 71.9393C5.35355 72.5251 5.35355 73.4749 5.93934 74.0607C6.52513 74.6464 7.47487 74.6464 8.06066 74.0607L5.93934 71.9393ZM73 7L56.2697 11.4829L68.5171 23.7303L73 7ZM8.06066 74.0607L64.5147 17.6066L62.3934 15.4853L5.93934 71.9393L8.06066 74.0607Z"
          fill="#383838"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowFillLinePreset;
