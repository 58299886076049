import { ConditionGroup } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

function getElementParentKeys(conditionGroup: ConditionGroup, elementId: number) {
  const keys = new Set<{ conditionId: string; optionId: string }>();

  Object.values(conditionGroup.conditions).forEach((condition) => {
    condition.options.forEach((option) => {
      conditionGroup.children[getConditionChildrenKey(condition.id, option.id)].forEach((child) => {
        if (child.type !== 'condition' && child.id === elementId) {
          keys.add({ optionId: option.id, conditionId: condition.id });
        }
      });
    });
  });

  return keys;
}

export default getElementParentKeys;
