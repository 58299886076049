import { PayloadAction } from '@reduxjs/toolkit';

import { conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

const removeConditionFromConditionReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    parent: { conditionId: string; optionId: string };
    conditionId: string;
  }>,
) => {
  const { spreadIndex, parent, conditionId } = action.payload;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  const parentKey = getConditionChildrenKey(parent.conditionId, parent.optionId);
  conditionGroup.children[parentKey] = conditionGroup.children[parentKey].filter(
    (element) => element.type !== 'condition' || element.id !== conditionId,
  );

  if (condition.parent && condition.parent.optionIds.length > 1) {
    condition.parent.optionIds = condition.parent.optionIds.filter((id) => id !== parent.optionId);
  } else {
    condition.parent = undefined;
    conditionGroup.children[conditionGroupChildenKey].push({
      type: 'condition',
      id: conditionId,
    });
  }
};

export default removeConditionFromConditionReducer;
