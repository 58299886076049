import { fabric } from 'fabric';
import { Path, PathCommand } from 'opentype.js';

import { TextWrapping } from 'editor/src/store/design/types';
import { FontInfo } from 'editor/src/store/fonts/fontManager';

import {
  addRectToPathCommands,
  drawCommands,
  getCommandWithOffsetAndRotation,
} from 'editor/src/fabric/fabricTextUtils';
import degrees2Radians from 'editor/src/util/degrees2Radians';
import limitPrecision from 'editor/src/util/limitPrecision';
import noop from 'editor/src/util/noop';
import { Point } from 'editor/src/util/rotatePointOnPoint';

import getFont from './getFont';

export interface IPathTextOption extends Omit<fabric.ITextboxOptions, 'fill' | 'text'> {
  text: string;
  fill: string;
  zIndex: number;
  uuid: number;
  isFontLoaded: boolean;
  breakWords?: boolean;
  flexibleWidth?: boolean;
  maxWidth?: number;
  wrapping?: TextWrapping;
  curve?: number;
  // when we change size of box by disabling curve effect we need to know that we want to resize box
  recalculateTextWidth?: boolean;
}

interface FabricPathText extends Omit<fabric.Textbox, 'fill' | 'text'> {
  toSVGRelativeCoords: () => string;
  setWrappingMode: (wrapping: TextWrapping) => void;
  setCurveControlsVisibility: (hasCurve: boolean) => void;
  toggle(property: keyof this): FabricPathText;
  zIndex: number;
  uuid: number;
  isFontLoaded: boolean;
  fill: string;
  text: string;
  getElementBoundingRect: () => { left: number; top: number; width: number; height: number };
  flexibleWidth?: boolean;
  maxWidth?: number;
  wrapping?: TextWrapping;
  curve?: number;
}

export function isFabricPathText(object: fabric.Object): object is FabricPathText {
  return object.get('type') === 'PathText';
}

type CharStyle = { fontFamily: string; fontSize: number };

type FabricPathTextClass = {
  new (text: string, options?: Partial<IPathTextOption>): FabricPathText;
  fromObject(object: any, callback: () => void): FabricPathText;
};

const fontSizeCache: { [fontStyleId: string]: { [char: string]: number } } = {};

function getFontCache(fontFamily: string): { [char: string]: number } {
  if (fontSizeCache[fontFamily]) {
    return fontSizeCache[fontFamily];
  }

  const cachedIds = Object.keys(fontSizeCache);
  if (cachedIds.length > 10) {
    delete fontSizeCache[cachedIds[0]];
  }

  fontSizeCache[fontFamily] = {};
  return fontSizeCache[fontFamily];
}

function getCacheCharWidth(cache: { [char: string]: number }, font: FontInfo, char: string, fontSize: number) {
  if (!cache[char]) {
    cache[char] = font.obj.forEachGlyph(char, 0, 0, 20, undefined, noop);
  }
  return (cache[char] * fontSize) / 20;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricPathText = fabric.util.createClass(fabric.Textbox, {
  type: 'PathText',

  initialize(text: string, options: IPathTextOption | undefined) {
    this.pathCache = {};
    this.flexibleWidth = false;
    this.maxWidth = 0;
    // don't allow to break words with curved text
    this.breakWords = !options?.curve;
    this.fitOneLineAndResize = options?.recalculateTextWidth;
    this.recalculateTextWidth = options?.recalculateTextWidth;
    this._commandCacheKey = '';
    // wrapping should be ignored if curve is applied
    const wrapping = options?.wrapping && !options.curve ? options?.wrapping : TextWrapping.Wrap;
    if (options) {
      options.wrapping = wrapping;
    }
    this.setWrappingMode(wrapping, true);
    this.setCurveControlsVisibility(!!options?.curve);

    this.lockScalingX = false;
    this.lockScalingY = false;
    this.cacheProperties = [...this.cacheProperties, 'shadow'];
    this._dimensionAffectingProps = this._dimensionAffectingProps.concat('curve');
    // change it if you want to keep word unbreakable
    this.callSuper('initialize', text, options);
  },

  _getCommandCacheKey() {
    const isCurveEditing = !!(this.curve && this.isEditing);
    return `${this.underline}-${this.linethrough}-${this.curve}-${isCurveEditing}`;
  },

  _ensureCommandsAreGenerated() {
    if (!this.commands || this._commandCacheKey !== this._getCommandCacheKey()) {
      this._generatePathCommands();
    }
    return this.commands;
  },

  setWrappingMode(wrapping: TextWrapping, force?: boolean) {
    if (this.wrapping === wrapping && !force) {
      return;
    }
    this.wrapping = wrapping;
    this.isFitting = wrapping === TextWrapping.Fit || wrapping === TextWrapping.FitOneLine;

    if (this.isFitting) {
      this._dimensionAffectingProps = [...fabric.Textbox.prototype._dimensionAffectingProps, 'height'];

      // see src/mixins/default_controls.js
      // the goal here is to transform a scaleY into a height update, like it's done for scaleX/width
      const { controlsUtils } = fabric as any;

      const changeHeight = controlsUtils.wrapWithFireEvent(
        'resizing',
        controlsUtils.wrapWithFixedAnchor((eventData: any, transform: any, x: any, y: any) => {
          const { target } = transform;
          const localPoint = controlsUtils.getLocalPoint(transform, transform.originX, transform.originY, x, y);
          const strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1);
          const multiplier = transform.originX === 'center' && transform.originY === 'center' ? 2 : 1;
          const oldHeight = target.height;
          const newHeight = Math.abs((localPoint.y * multiplier) / target.scaleY) - strokePadding;
          target.set('height', Math.max(newHeight, 0));
          return oldHeight !== newHeight;
        }),
      );

      this.controls.mt = new fabric.Control({
        x: 0,
        y: -0.5,
        actionHandler: changeHeight,
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
      });

      this.controls.mb = new fabric.Control({
        x: 0,
        y: 0.5,
        actionHandler: changeHeight,
        cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
        actionName: 'resizing',
      });
    } else {
      this._dimensionAffectingProps = fabric.Textbox.prototype._dimensionAffectingProps;
      this.controls = fabric.Textbox.prototype.controls;
    }

    // disable not supported controls
    this.setControlsVisibility({
      mt: this.isFitting,
      mb: this.isFitting,
      ml: false,
      mr: false,
      bl: false,
      br: true,
      tl: false,
      tr: false,
      mtr: true,
    });

    if (!force) {
      this.initDimensions();
      this.fire('modified');
    }
  },

  setCurveControlsVisibility(hasCurve: boolean) {
    this.setControlsVisibility({
      ml: !hasCurve,
      mr: !hasCurve,
    });
  },

  _setShadow() {}, // overriden to apply it manually in the _drawCommands method

  // based on _renderChars
  _addCharsCommands({
    commands,
    line,
    left,
    top,
    lineIndex,
    ignoreCurve = false,
  }: {
    commands: PathCommand[];
    line: string[];
    left: number;
    top: number;
    lineIndex: number;
    ignoreCurve?: boolean;
  }) {
    if (!line.length) {
      return;
    }
    const font = getFont(this.fontFamily, line[0], () => this.forceRedrawOnFallbackFontLoad());
    if (!font) {
      return;
    }

    const applyCurve = !ignoreCurve && this.curve;

    const canRenderAllAtOnce =
      this.charSpacing === 0 && !applyCurve && line.every((char) => font.obj.charToGlyphIndex(char) > 0);
    if (canRenderAllAtOnce) {
      // no letter spacing, render the entire line at once
      this._addCharCommands(commands, font, line.join(''), left, top);
      return;
    }

    let leftPos = left;
    const sign = this.direction === 'ltr' ? 1 : -1;
    const lineWidth = this.__lineWidths[lineIndex];
    const curveRadius = applyCurve ? this._getCurveCircleRadius(lineWidth) : 0;
    const curveHeight = applyCurve ? this.calculateCurveHeight(curveRadius) : 0;

    for (let i = 0; i < line.length; i += 1) {
      const charBox = this.__charBounds[lineIndex][i];
      leftPos += sign * (charBox.kernedWidth - charBox.width);
      const font = getFont(this.fontFamily, line[i], () => this.forceRedrawOnFallbackFontLoad());

      const charCenter: Point = { x: charBox.width / 2, y: charBox.height / 2 };
      if (applyCurve) {
        const { angleDegrees, rotateOffsetX, rotateOffsetY } = this._getCharAngleAndRotateOffset(
          curveRadius,
          curveHeight,
          lineWidth,
          charBox.left + charCenter.x,
          charBox.width,
          top,
        );
        this._addCharCommands(commands, font, line[i], rotateOffsetX, rotateOffsetY, charCenter, angleDegrees);
      } else {
        this._addCharCommands(commands, font, line[i], leftPos, top);
      }

      leftPos += sign * charBox.width;
    }
  },

  getElementBoundingRect() {
    let { top, left } = this;
    // if fitting is applied we should not change the box height
    const height = this.isFitting ? this.height : this.calcTextHeight();

    if (!this.curve && !this.fitOneLineAndResize) {
      return {
        left,
        top,
        width: this.width,
        height,
      };
    }

    // added margin to avoid text wrapping
    const margin = this._calcLineHeightWithoutSpacing() / 4;
    const width = limitPrecision(this.calcTextWidth() + margin, 13);

    // TODO adapt it for multilines and calculate width
    // align it by center, but not in editing mode
    if (!this.isEditing && limitPrecision(this.height) !== height) {
      top += (this.height - height) / 2;
    }

    if (!this.isEditing && limitPrecision(this.width) !== width) {
      left += (this.width - width) / 2;
    }

    return {
      left,
      top,
      width,
      height,
    };
  },

  _calcLineHeightWithoutSpacing() {
    return this.getHeightOfLine(0) / this.lineHeight;
  },

  _getCurveCircleRadius(lineWidth: number) {
    const fullCircleLength = Math.abs(lineWidth * (360 / this.curve));
    return fullCircleLength / (2 * Math.PI);
  },

  calculateCurveHeight(radius: number): number {
    const angleInRadians = degrees2Radians(this.curve);
    const halfAngle = angleInRadians / 2;
    const chordLength = 2 * radius * Math.sin(halfAngle);
    const halfChordLength = chordLength / 2;
    const heightFromCenter = Math.abs(Math.sqrt(radius ** 2 - halfChordLength ** 2));

    if (Math.abs(this.curve) > 180) {
      return radius + heightFromCenter;
    }

    return radius - heightFromCenter;
  },

  _getCharAngleAndRotateOffset(
    radius: number,
    curveHeight: number,
    fullLineWidth: number,
    charOffset: number,
    charWidth: number,
    charTop: number,
  ) {
    const circleLength = 2 * Math.PI * radius;
    // 1 or -1
    const curveDirection = this.curve / Math.abs(this.curve);

    // add unused segment length to the offset
    const unusedSegmentLength = circleLength - fullLineWidth;
    const angleDegrees = curveDirection * (180 / Math.PI) * ((charOffset + unusedSegmentLength * 0.5) / radius) - 180;
    const radians = degrees2Radians(angleDegrees);

    // calculate coordinates of point on the circle
    const absCurveAngle = Math.abs(this.curve);
    const charCenterY = charTop / 2;
    let angleRate = 0;
    if (absCurveAngle && absCurveAngle < 180) {
      angleRate = Math.sin(degrees2Radians(this.curve) / 2);
    } else if (absCurveAngle > 180) {
      angleRate = 1;
    }
    angleRate = 0;
    const rotateOffsetX =
      (radius * curveDirection - charCenterY) * Math.sin(radians) + (this.width - charWidth) / 2 + angleRate * charTop;
    let negativeAngleShift = 0;
    if (curveDirection < 0) {
      negativeAngleShift = curveHeight;
    }

    const rotateOffsetY =
      (-radius * curveDirection + charCenterY) * Math.cos(radians) +
      charTop -
      charCenterY +
      radius * curveDirection +
      negativeAngleShift;

    return { angleDegrees, rotateOffsetX, rotateOffsetY };
  },

  _addCharCommands(
    commands: PathCommand[],
    font: FontInfo,
    _char: string,
    left: number,
    top: number,
    charCenter?: Point,
    charAngle = 0,
  ) {
    const pathId = `${font.definition.metadata.fontFile}-${this.fontSize}-${_char}`;
    const cachedPath = this.pathCache[pathId] as Path | undefined;
    const path = cachedPath || font.obj.getPath(_char, 0, 0, this.fontSize);
    this.pathCache[pathId] = path;
    path.commands.forEach((command) =>
      commands.push(getCommandWithOffsetAndRotation(command, left, top, charAngle, charCenter)),
    );
  },

  _addLineCommands(commands: PathCommand[], lineYOffset: number, lineTop: number, lineLeft: number, lineWidth: number) {
    const startX = this.direction === 'rtl' ? this.width - lineLeft : lineLeft;
    const startY = lineTop + lineYOffset * this.fontSize;
    addRectToPathCommands(startX, startY, lineWidth, this.fontSize / 15, commands);
  },

  calcTextHeight() {
    if (this.isFitting && this.textLines) {
      return limitPrecision(this.textLines.length * this.getHeightOfLine(0));
    }

    let height = 0;
    if (!this.curve) {
      height = this.callSuper('calcTextHeight');
    } else {
      height = this.calcCurvedTextHeight(this._calcLineHeightWithoutSpacing());
    }

    return limitPrecision(height, 13);
  },

  calcCurvedTextHeight(firstLineHeight: number): number {
    const lineWidth = this.getLineWidth(0);
    const curveRadius = this._getCurveCircleRadius(lineWidth);
    const curveHeight = this.calculateCurveHeight(curveRadius);
    let curvedTextHeight = curveHeight + firstLineHeight;

    // there is additional height if the text is reflected on both sides
    const absCurveAngle = Math.abs(this.curve);
    if (absCurveAngle > 180) {
      const mirroredAngle = 90 - (360 - absCurveAngle) / 2;
      const curveRadians = mirroredAngle * (Math.PI / 180);
      curvedTextHeight += Math.abs(Math.sin(curveRadians)) * firstLineHeight;
    }

    return limitPrecision(curvedTextHeight, 13);
  },

  calcTextWidth() {
    let width;
    if (!this.curve) {
      width = this.callSuper('calcTextWidth');
    } else {
      width = this.calcCurvedTextWidth(this._calcLineHeightWithoutSpacing());
    }

    return limitPrecision(width, 13);
  },

  calcCurvedTextWidth(firstLineHeight: number): number {
    if (!this.curve) {
      return this.calcTextWidth();
    }

    const curveRadius = this._getCurveCircleRadius(this.getLineWidth(0));
    const angleInRadians = degrees2Radians(Math.abs(this.curve));

    // we need to find the size of chord. Chord Length=2 * radius * sin(angleInRadians / 2)
    // if curve angle more than 180 we can consider diameter as the widest part
    let curveAngleRate = 1;
    // if chars on the sides is inclined we should include them in bounding box dimensions
    // so if curve 0 we don't consider it and if more that 180 we should add full line length on the sides
    let lineAngleRate = 0;
    const absCurveAngle = Math.abs(this.curve);
    if (absCurveAngle < 180) {
      curveAngleRate = Math.sin(angleInRadians / 2);

      if (absCurveAngle > 0) {
        lineAngleRate = curveAngleRate;
      }
    } else if (absCurveAngle >= 180) {
      lineAngleRate = 1;
    }

    return limitPrecision(2 * curveRadius * curveAngleRate + 2 * firstLineHeight * lineAngleRate);
  },

  _getTopOffset() {
    let offset = -this.height / 2;
    if (this.isFitting) {
      offset += (this.height - this._textLines.length * this.getHeightOfLine(0)) / 2;
    }
    return offset;
  },

  _getLineLeftOffset(i: number) {
    // text in the editing mode for curve should be aligned to the left
    if (this.curve && this.isEditing) {
      return 0;
    }

    return this.callSuper('_getLineLeftOffset', i);
  },

  _generatePathCommands() {
    const commands: {
      text: PathCommand[];
      textInEditing: PathCommand[];
      underline: PathCommand[];
      linethrough: PathCommand[];
    } = {
      text: [],
      textInEditing: [],
      underline: [],
      linethrough: [],
    };
    this.commands = commands;
    this._commandCacheKey = this._getCommandCacheKey();

    const lineHeight = this.getHeightOfLine(0);

    let lineTop = this.isFitting ? (this.height - this._textLines.length * lineHeight) / 2 : 0;

    const boundingRect = this.getElementBoundingRect();

    if (!this.isFitting) {
      if (limitPrecision(this.height) !== limitPrecision(boundingRect.height)) {
        this.height = boundingRect.height;
        if (this.curve || this.fitOneLineAndResize) {
          this.top = boundingRect.top;
        }
      }

      const isWidthChanged = limitPrecision(this.width) !== limitPrecision(boundingRect.width);
      if ((this.curve || this.fitOneLineAndResize) && isWidthChanged) {
        this.left = boundingRect.left;
        this.width = boundingRect.width;
      }
    }

    const topOffset = (lineHeight / this.lineHeight) * (1 - this._fontSizeFraction);
    const isCurveEditing = this.curve && this.isEditing;

    for (let i = 0, len = this._textLines.length; i < len; i += 1) {
      const lineWidth = this.getLineWidth(i);
      const lineLeft = this._getLineLeftOffset(i);

      // TODO make it work with curve
      if (this.underline && !this.curve) {
        this._addLineCommands(commands.underline, this.offsets.underline, lineTop + topOffset, lineLeft, lineWidth);
      }

      if (this.linethrough && !this.curve) {
        this._addLineCommands(commands.linethrough, this.offsets.linethrough, lineTop + topOffset, lineLeft, lineWidth);
      }

      this._addCharsCommands({
        // show how it will look on the background (curved version)
        commands: isCurveEditing ? commands.textInEditing : commands.text,
        line: this._textLines[i],
        left: lineLeft,
        top: lineTop + topOffset,
        lineIndex: i,
      });

      if (isCurveEditing) {
        this._addCharsCommands({
          commands: commands.text,
          line: this._textLines[i],
          left: lineLeft,
          top: lineTop + topOffset,
          lineIndex: i,
          ignoreCurve: true,
        });
      }

      lineTop += lineHeight;
    }
  },

  // based on _setShadow
  _setCustomShadow(ctx: CanvasRenderingContext2D) {
    const { shadow } = this as {
      shadow: { color: string; blur: number; offsetX: number; offsetY: number };
    };
    if (!shadow) {
      return;
    }

    // the text element is drawn in a separate canvas. We apply the shadow on that canvas, which means the unit
    // depends solely on this canvas size. the _getCacheCanvasDimensions gives us the value of that canvas size
    // based on the viewport
    const dims = this._limitCacheSize(this._getCacheCanvasDimensions());
    const cacheWidth = dims.width;
    const cacheHeight = dims.height;

    // based on the _getCacheCanvasDimensions override of the fabric.Text element
    const scaleX = (cacheWidth - this.fontSize * dims.zoomX) / this.width;
    const scaleY = (cacheHeight - this.fontSize * dims.zoomY) / this.height;
    ctx.shadowColor = shadow.color;
    ctx.shadowBlur = (shadow.blur / 4) * (scaleX + scaleY);
    ctx.shadowOffsetX = shadow.offsetX * scaleX;
    ctx.shadowOffsetY = shadow.offsetY * scaleY;
  },

  _renderTextInEditing(ctx: CanvasRenderingContext2D, offsetX: number, offsetY: number) {
    if (this.commands?.textInEditing?.length) {
      ctx.save();
      ctx.beginPath();
      ctx.globalAlpha = 0.2;
      drawCommands(ctx, this.commands.textInEditing, offsetX, offsetY);
      ctx.fillStyle = this.fill;
      ctx.fill();
      ctx.restore();
    }
  },

  _render(ctx: CanvasRenderingContext2D) {
    this._ensureCommandsAreGenerated();
    const offsetX = -this.width / 2;
    const offsetY = -this.height / 2;

    this._renderTextInEditing(ctx, offsetX, offsetY);

    ctx.save();
    ctx.beginPath();

    drawCommands(ctx, this.commands.underline, offsetX, offsetY);
    drawCommands(ctx, this.commands.text, offsetX, offsetY);
    drawCommands(ctx, this.commands.linethrough, offsetX, offsetY);

    this._setCustomShadow(ctx);

    if (this.stroke) {
      ctx.strokeStyle = this.stroke;
      ctx.lineWidth = this.strokeWidth;
      ctx.fillStyle = this.stroke;
      ctx.fill();
      ctx.stroke();
      this._removeShadow(ctx);
    }

    if (this.fill) {
      ctx.fillStyle = this.fill;
      ctx.fill();
    }
    ctx.restore();
  },

  forceRedrawOnFallbackFontLoad() {
    if (this.commands) {
      this._clearCache();
      this.fire('fontFallbackLoaded');
    }
  },

  _measureChar(char: string, charStyle: CharStyle, previousChar?: string) {
    const font = getFont(this.fontFamily, char, () => this.forceRedrawOnFallbackFontLoad());
    const cache = getFontCache(font?.definition.metadata.fontFile ?? this.fontFamily);
    if (!font) {
      const width = cache[char] ?? 0;
      return { width, kernedWidth: width };
    }

    const glyphWidth = getCacheCharWidth(cache, font, char, this.fontSize);
    const width = glyphWidth;
    let kernedWidth = glyphWidth;

    if (previousChar) {
      const couple = previousChar + char;
      const coupleWidth = getCacheCharWidth(cache, font, couple, this.fontSize);
      const previousWidth = previousChar ? getCacheCharWidth(cache, font, previousChar, this.fontSize) : 0;
      kernedWidth = coupleWidth - previousWidth;
    }

    return { width, kernedWidth };
  },

  _fitsInBox(
    lines: string[],
    desiredWidth: number,
  ): { delta: number; wrapped: string[][]; base: number; lineWidth: number } {
    let wrapped: string[][] = [];
    let lineWidth = 0;
    const lineHeight = this.getHeightOfLine(0);
    for (let i = 0; i < lines.length; i += 1) {
      const line = this._wrapLine2(lines[i], i, desiredWidth) as { lines: string[][]; lineWidth: number };
      wrapped = wrapped.concat(line.lines);
      lineWidth = Math.max(lineWidth, line.lineWidth);
    }

    const textHeight = wrapped.length * lineHeight;
    const deltaY = textHeight - this.height;
    return { delta: deltaY, base: textHeight, wrapped, lineWidth };
  },

  getHeightOfLine() {
    return limitPrecision(this.fontSize * this.lineHeight * this._fontSizeMult, 13);
  },

  _wrapText(lines: string[], desiredWidth: number) {
    // TODO adapt it for multilines
    if (this.fitOneLineAndResize || this.curve) {
      return [lines.join(' ').split('')];
    }

    if (!this.isFitting) {
      return this.callSuper('_wrapText', lines, desiredWidth);
    }

    const maxFontSize = this.height / lines.length / (this.lineHeight * this._fontSizeMult);
    this.fontSize = Math.min(this.fontSize, maxFontSize);
    let fit = this._fitsInBox(lines, desiredWidth);
    let lastNegativeFit = fit.delta <= 0 ? fit : undefined;
    const threshold = 2;

    if (this.wrapping === TextWrapping.FitOneLine) {
      if (fit.lineWidth > desiredWidth || Math.abs(fit.delta) > threshold || fit.delta > 0) {
        const scale = Math.min(desiredWidth / fit.lineWidth, this.height / fit.base);
        this.fontSize *= scale;
      }
    } else {
      let lastFontSize = this.fontSize;
      let changedOnced = false;

      if (Math.abs(fit.delta) > threshold || fit.delta > 0) {
        let factor = (Math.abs(fit.delta) / (fit.wrapped.length * 2)) * Math.abs(fit.delta / fit.base); // starting factor to increase or decrease font size
        let i = 20; // allow max 20 iterations
        let lastChange = fit.delta;
        while ((Math.abs(fit.delta) > threshold || fit.delta > 0) && i > 0) {
          if (fit.delta < 0) {
            lastNegativeFit = fit;
          }

          const signChanged = lastChange * fit.delta < 0;
          if (signChanged) {
            // when we overshoot, we reduce the factor and retry in the opposite direction
            changedOnced = true;
          } else {
            lastChange = fit.delta;
          }

          if (changedOnced) {
            factor = Math.abs(lastFontSize - this.fontSize) / 2;
          }

          if (factor < 0.01 && lastNegativeFit) {
            break;
          }

          lastFontSize = this.fontSize;
          this.fontSize += fit.delta < 0 ? factor : -factor;
          fit = this._fitsInBox(lines, desiredWidth);
          i -= 1;
        }
      }
    }

    lastNegativeFit = lastNegativeFit || fit;
    return lastNegativeFit.wrapped;
  },

  /**
   * Wraps a line of text using the width of the Textbox and a context.
   */
  _wrapLine(_line: string, lineIndex: number, defaultDesiredWidth: number, reservedSpace = 0) {
    return this._wrapLine2(_line, lineIndex, defaultDesiredWidth, reservedSpace).lines;
  },

  /**
   * this separation exist to allow returning the length of the line without overriding the original behavior used by internals
   */
  _wrapLine2(_line: string, lineIndex: number, defaultDesiredWidth: number, reservedSpace = 0) {
    const { splitByGrapheme, breakWords } = this;
    const graphemeLines = [];
    // spaces in different languages?
    const words: (string | string[])[] = splitByGrapheme
      ? fabric.util.string.graphemeSplit(_line)
      : _line.split(this._wordJoiners);
    const infix = splitByGrapheme ? '' : ' ';
    const additionalSpace = this._getWidthOfCharSpacing();

    let offset = 0;
    let infixWidth = 0;
    let largestWordWidth = 0;
    let lineWidth = 0;
    let line: string[] = [];
    let lineJustStarted = true;

    // fix a difference between split and graphemeSplit
    if (words.length === 0) {
      words.push([]);
    }

    const desiredWidth =
      (this.flexibleWidth && this.isEditing ? Math.max(this.maxWidth, defaultDesiredWidth) : defaultDesiredWidth) -
      reservedSpace;
    let i;
    for (i = 0; i < words.length; i += 1) {
      // if using splitByGrapheme words are already in graphemes.
      const currentWord = words[i];
      let word = (
        splitByGrapheme || Array.isArray(currentWord) ? currentWord : fabric.util.string.graphemeSplit(currentWord)
      ) as string[];
      const wordWidth = this._measureWord(word, lineIndex, offset);
      offset += word.length;

      // this.charSpacing / 100 + 1
      // break the line if a word is wider than the set width
      if (breakWords && wordWidth >= desiredWidth && this.wrapping !== TextWrapping.FitOneLine) {
        if (!lineJustStarted) {
          lineJustStarted = true;
          graphemeLines.push(line);
          line = [];
          lineWidth = 0;
        }

        // Loop through each character in word
        for (let w = 0; w < word.length; w += 1) {
          const letter = word[w];
          const letterStyle = this.getCompleteStyleDeclaration(lineIndex, w);
          const previousLetter = w > 0 ? word[w - 1] : undefined;
          const previousLetterStyle = previousLetter ? this.getCompleteStyleDeclaration(lineIndex, w - 1) : undefined;
          const letterWidth =
            this._measureChar(letter, letterStyle, previousLetter, previousLetterStyle).width + additionalSpace;

          if (lineWidth + letterWidth > desiredWidth && line.length > 0) {
            graphemeLines.push(line);
            line = [];
            lineWidth = letterWidth;
          } else {
            lineWidth += letterWidth;
          }

          line.push(letter);

          // largest word width is the largest letter in the text
          if (letterWidth > largestWordWidth) {
            largestWordWidth = letterWidth + letterWidth * 0.1;
          }
        }
        word = [];
      } else {
        lineWidth += infixWidth + wordWidth - additionalSpace;
      }

      if (lineWidth > desiredWidth && !lineJustStarted && this.wrapping !== TextWrapping.FitOneLine) {
        graphemeLines.push(line);
        line = [];
        lineWidth = wordWidth;
        lineJustStarted = true;
      } else {
        lineWidth += additionalSpace;
      }

      if (!lineJustStarted && !splitByGrapheme) {
        line.push(infix);
      }
      line = line.concat(word);

      infixWidth = splitByGrapheme ? 0 : this._measureWord([infix], lineIndex, offset);
      offset += 1;
      lineJustStarted = false;

      // keep track of largest word if words are unbreakable
      if (wordWidth > largestWordWidth && !breakWords) {
        largestWordWidth = wordWidth;
      }
    }

    if (i) {
      graphemeLines.push(line);
    }

    if (largestWordWidth + reservedSpace > this.dynamicMinWidth && !this.isFitting) {
      this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
    }

    if (this.flexibleWidth && this.isEditing && !this.isFitting) {
      const newWidth = Math.max(lineWidth, this.width);
      if (newWidth > this.width) {
        if (this.textAlign === 'right') {
          this.left += this.width - newWidth;
        } else if (this.textAlign !== 'left') {
          this.left += (this.width - newWidth) / 2;
        }
        this.width = newWidth;
      }
    }

    return { lines: graphemeLines, lineWidth };
  },

  /**
   * Detect if a line has a linebreak and so we need to account for it when moving
   */
  missingNewlineOffset(lineIndex: number) {
    if (this.splitByGrapheme || this.breakWords) {
      return this.isEndOfWrapping(lineIndex) ? 1 : 0;
    }

    return 1;
  },

  initDimensions() {
    if (this.__skipDimension) {
      return;
    }
    this.isEditing && this.initDelayedCursor();
    this.clearContextTop();
    this._clearCache();
    // clear dynamicMinWidth as it will be different after we re-wrap line
    this.dynamicMinWidth = 0;
    // wrap lines
    this._styleMap = this._generateStyleMap(this._splitText());
    // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
    if (this.dynamicMinWidth > this.width) {
      this._set('width', this.dynamicMinWidth);
    }
    if (this.textAlign.indexOf('justify') !== -1) {
      // once text is measured we need to make space fatter to make justified text.
      this.enlargeSpaces();
    }

    const previousCurve = (this.__dimensionAffectingProps as any)?.curve;
    // if curve has changed we should adjust width automatically
    const curveChanged = this.curve !== previousCurve;
    this.fitOneLineAndResize = curveChanged || this.recalculateTextWidth;

    if (!this.isFitting || !this.height) {
      // clear cache and re-calculate height
      const boundingRect = this.getElementBoundingRect();

      if (this.height && this.height !== boundingRect.height) {
        this.top = boundingRect.top;
      }

      this.height = boundingRect.height;

      const widthChanged = this.width !== boundingRect.width;
      if (this.width && widthChanged && (this.recalculateTextWidth || (this.curve && !curveChanged))) {
        this.width = boundingRect.width;
        this.left = boundingRect.left;
      }
    }
    this.saveState({ propertySet: '_dimensionAffectingProps' });
  },

  _reNewline: /\r?\n/g,

  _splitTextIntoLines(text: string) {
    let lines =
      this.wrapping === TextWrapping.FitOneLine || this.fitOneLineAndResize || this.curve
        ? [text.replaceAll(this._reNewline, '')]
        : text.split(this._reNewline);
    let graphemeLines = new Array(lines.length);
    const newLine = ['\n'];
    let graphemeText: string[][] = [];
    for (let i = 0; i < lines.length; i += 1) {
      graphemeLines[i] = fabric.util.string.graphemeSplit(lines[i]);
      graphemeText = graphemeText.concat(graphemeLines[i], newLine);
    }
    graphemeText.pop();
    const _unwrappedLines = graphemeLines;

    graphemeLines = this._wrapText(lines, this.width);
    lines = new Array(graphemeLines.length);
    for (let i = 0; i < graphemeLines.length; i += 1) {
      lines[i] = graphemeLines[i].join('');
    }

    return { _unwrappedLines, lines, graphemeText, graphemeLines };
  },

  onKeyDown(e: KeyboardEvent) {
    // prevent user from adding new lines when the text is set to fit one line
    if (this.wrapping === TextWrapping.FitOneLine && e.key === 'Enter') {
      e.preventDefault();
      return;
    }

    this.callSuper('onKeyDown', e);
  },

  /**
   * Detect if the text line is ended with an hard break
   */
  isEndOfWrapping(lineIndex: number) {
    let isWordBreak = false;
    const unwrappedLineIndex = this._styleMap[lineIndex].line;
    const nextLineOffset: number | undefined = this._styleMap[lineIndex + 1]?.offset;
    const currentLine: string[] | undefined = this._textLines[lineIndex];
    if (nextLineOffset && currentLine) {
      isWordBreak =
        this._unwrappedTextLines[unwrappedLineIndex][nextLineOffset - 1] === currentLine[currentLine.length - 1];
    }

    return !isWordBreak;
  },

  _clearCache() {
    this.callSuper('_clearCache');
    this.commands = undefined;
  },

  toSVGRelativeCoords() {
    this._ensureCommandsAreGenerated();

    let pathData = '';
    const path = new Path();
    path.commands = this.commands.underline;
    pathData += path.toPathData(2);
    path.commands = this.commands.text;
    pathData += path.toPathData(2);
    path.commands = this.commands.linethrough;
    pathData += path.toPathData(2);
    return pathData;
  },

  _createCacheCanvas() {
    this.callSuper('_createCacheCanvas');
    this._cacheCanvas.id = `fabric-cache-${this.type}-${this.uuid ?? ''}`;
  },
}) as FabricPathTextClass;

FabricPathText.fromObject = (object: any, callback: () => void) =>
  fabric.Object._fromObject('PathText', object, callback, 'text') as FabricPathText;

export default FabricPathText;
