import { MediaText } from 'editor/src/store/design/types';

import mm2pt from 'editor/src/util/mm2pt';

import { extractTextProperties } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

export function alignTextVertically(
  element: MediaText,
  availableHeight: number,
  vAlignment: 'top' | 'middle' | 'bottom' | undefined,
) {
  if (vAlignment === 'bottom') {
    element.y = element.y + availableHeight - element.height;
  } else if (vAlignment === 'middle' || !vAlignment) {
    element.y += (availableHeight - element.height) / 2;
  }
}

export function fitTextInFrame(element: MediaText, frame: { width: number; height: number }) {
  // if the font size is bigger than the frame height, we first adjust the font size so it fits
  const frameHeightPt = mm2pt(frame.height);
  if (element.extra.fontSize > frameHeightPt) {
    element.extra.fontSize = frameHeightPt;
  }

  // now we check if the text actually fits within the frame and resize the font size proportionally if needed
  const { width, height } = extractTextProperties(element, {
    x: 0,
    width: frame.width,
  });
  if (width > frame.width || height > frame.height) {
    if (width - frame.width > height - frame.height) {
      element.extra.fontSize = Math.round((frame.width * element.extra.fontSize) / width);
    } else {
      element.extra.fontSize = Math.round((frame.height * element.extra.fontSize) / height);
    }
  }
}
