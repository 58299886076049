import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, conditionGroupChildenKey, DesignState, MediaElement } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';
import getElementParentKeys from './getElementParentKeys';

const removeElementFromConditionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    optionId: string;
    element: MediaElement;
  }>,
) => {
  const { address, optionId, element } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const childrenKey = getConditionChildrenKey(conditionId, optionId);
  conditionGroup.children[childrenKey] = conditionGroup.children[childrenKey].filter(
    (value) => value.type === 'condition' || value.id !== element.uuid,
  );

  // add back to the parent level if it's a personalisable element
  const currentParents = getElementParentKeys(conditionGroup, element.uuid);
  if (!element.personalizationLocked && currentParents.size === 0) {
    conditionGroup.children[conditionGroupChildenKey].push({
      type: element.type,
      id: element.uuid,
    });
  }
};

export default removeElementFromConditionReducer;
