import { PayloadAction } from '@reduxjs/toolkit';

import { ProductImage } from 'editor/src/store/design/types';
import { MockupPreviewVariant, MockupState } from 'editor/src/store/mockup/types';
import getVariantProductImageKey from 'editor/src/store/mockup/utils/getVariantProductImageKey';

const setVariantProductImageReducer = (
  state: MockupState,
  action: PayloadAction<{ variant: MockupPreviewVariant; image: ProductImage }>,
) => {
  const { variant, image } = action.payload;
  state.productPlaceholder.variantProductImages[getVariantProductImageKey(variant)] = image;
};

export default setVariantProductImageReducer;
