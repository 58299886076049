import { useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Spread } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useIsMounted from 'editor/src/util/useIsMounted';

import { createSpreadPreview, RequestRenderFn, SpreadPreviewDataURL } from 'editor/src/component/SpreadPreview';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

function useSpreadPreviews(
  requestRender: RequestRenderFn,
  spreadIndexes: number[],
  designKey: string,
  size: { dimension: 'height' | 'width' | 'both'; value: number },
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { spreadBackgrounds, spreadForegrounds, gridDesigns, images, addons, digitizedAssets, designData } =
    useSelector((state) => {
      const spreadImages = state.variants.spreadImages[designKey];
      return {
        spreadBackgrounds: spreadImages?.spreadBackgrounds ?? state.design.spreadBackgrounds,
        spreadForegrounds: spreadImages?.spreadForegrounds ?? state.design.spreadForegrounds,
        gridDesigns: state.gridDesigns.grids,
        images: state.gallery.images,
        addons: state.editorModules.addons.inUse,
        digitizedAssets: state.design.digitizedAssets,
        designData: state.design.designData,
      };
    }, shallowEqual);

  async function loadFont(fontFamily: string) {
    const loadFontOperation = await import('editor/src/store/fonts/operation/loadFontOperation');
    await dispatch(loadFontOperation.default(fontFamily));
  }

  const [previews, setPreviews] = useState<Array<SpreadPreviewDataURL | undefined>>(spreadIndexes.map(() => undefined));
  const isMounted = useIsMounted();

  const version = useRef(spreadIndexes.map(() => 0));
  useLayoutEffect(() => {
    setPreviews(spreadIndexes.map(() => undefined));
    version.current = spreadIndexes.map(() => 0);
  }, [spreadIndexes]);

  function renderSpreadPreview(spread: Spread, spreadIndex: number, index: number) {
    const foregroundImage = spreadForegrounds?.find((image) => image.name === spread.name);
    const backgroundImage = spreadBackgrounds?.find((image) => image.name === spread.name);

    version.current[index] += 1;
    const currentVersion = version.current[index];

    void createSpreadPreview(
      designKey,
      spread,
      {
        backgroundImage,
        foregroundImage,
        gridDesigns,
        images,
        addons,
      },
      spreadIndex,
      undefined,
      size,
      requestRender,
      digitizedAssets,
      loadFont,
      isMobile,
      t,
      {
        showBlanks: false,
        showBlankAreaShadowOnly: true,
        output: 'dataURL',
        strongerShadow: true,
        quality: 5,
        format: 'png',
      },
      undefined,
      undefined,
      designData?.calendar,
    ).then((preview) => {
      if (currentVersion === version.current[index] && isMounted() && preview) {
        setPreviews((previews) => previews.map((prev, i) => (i === index ? preview : prev)));
      }
    });
  }

  useLayoutEffect(() => {
    spreadIndexes.forEach((spreadIndex, index) => {
      const spread = designData?.spreads[spreadIndex];
      if (!spread) {
        return;
      }
      renderSpreadPreview(spread, spreadIndex, index);
    });
    currentDesignData.current = designData;
  }, [images, spreadIndexes, addons, designKey, spreadBackgrounds, spreadForegrounds]);

  const currentDesignData = useRef(designData);
  useLayoutEffect(() => {
    spreadIndexes.forEach((spreadIndex, index) => {
      const spread = designData?.spreads[spreadIndex];
      if (!spread || currentDesignData.current?.spreads[spreadIndex] === spread) {
        return;
      }
      renderSpreadPreview(spread, spreadIndex, index);
    });
    currentDesignData.current = designData;
  }, [designData]);

  return { previews };
}

export default useSpreadPreviews;
