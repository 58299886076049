import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import updateDesignPagesUtil from '../../util/updateDesignPagesUtil';

const removeSpreadReducer = (state: DesignState, action: PayloadAction<number>) => {
  if (!state.designData) {
    return;
  }

  const index = action.payload;
  state.designData.spreads.splice(index, 1);
  updateDesignPagesUtil(state.designData);
};

export default removeSpreadReducer;
