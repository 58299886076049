import limitPrecision from 'editor/src/util/limitPrecision';

import { AreaClip } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import { getElementBoundingBox, RectMediaElement } from './utils';

function applyScaleToRectangleElement(
  sourceElement: RectMediaElement,
  sourceMediaBox: AreaClip,
  destElement: RectMediaElement,
  destMediaBox: AreaClip,
  scale: number,
) {
  destElement.width = limitPrecision(sourceElement.width * scale);
  destElement.height = limitPrecision(sourceElement.height * scale);

  const sourceBbox = getElementBoundingBox(sourceElement);
  const destBbox = getElementBoundingBox(destElement);

  const pageOffsetX = destMediaBox.x + destMediaBox.width / 2 - (sourceMediaBox.x + sourceMediaBox.width / 2) * scale;
  destElement.x = limitPrecision(sourceBbox.x * scale + pageOffsetX + (destElement.x - destBbox.x));

  const pageOffsetY = destMediaBox.y + destMediaBox.height / 2 - (sourceMediaBox.y + sourceMediaBox.height / 2) * scale;
  destElement.y = limitPrecision(sourceBbox.y * scale + pageOffsetY + (destElement.y - destBbox.y));
}

export default applyScaleToRectangleElement;
