// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button--EJ_hg{display:flex;gap:4px;align-items:center;border:none;background:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/component/EditCartItem/index.module.scss"],"names":[],"mappings":"AAAA,eACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,WAAA,CACA,wBAAA","sourcesContent":[".button {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  border: none;\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button--EJ_hg`
};
export default ___CSS_LOADER_EXPORT___;
