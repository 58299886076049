class RGBA {
  r: number;
  g: number;
  b: number;
  a: number;

  constructor({ r, g, b, a }: { r: number; g: number; b: number; a: number }) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  private static rgbaRegExp = /rgba\(\s*(\d+%?)\s*,\s*(\d+%?)\s*,\s*(\d+%?)\s*,\s*(\d*(?:\.\d+)?)\s*\)/;

  private static fromRGBAString(color: string): RGBA | undefined {
    const res = color.match(RGBA.rgbaRegExp);
    if (!res) {
      return undefined;
    }

    return new RGBA({
      r: parseFloat(res[1]),
      g: parseFloat(res[2]),
      b: parseFloat(res[3]),
      a: parseFloat(res[4]),
    });
  }

  private static hexRegExp =
    /^\s*#?([a-f\d])([a-f\d])([a-f\d])\s*$|^\s*#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?\s*$/i;

  static fromHex(hex: string): RGBA | undefined {
    const match = hex.match(RGBA.hexRegExp);
    if (!match) {
      return undefined;
    }

    const [, r1, g1, b1, r2, g2, b2, a] = match;
    let r;
    let g;
    let b;
    if (r1 && g1 && b1) {
      // Shorthand format
      r = r1 + r1;
      g = g1 + g1;
      b = b1 + b1;
    } else if (r2 && g2 && b2) {
      // Full format
      r = r2;
      g = g2;
      b = b2;
    } else {
      return undefined;
    }

    return new RGBA({
      r: parseInt(r, 16),
      g: parseInt(g, 16),
      b: parseInt(b, 16),
      a: a !== undefined ? parseInt(a, 16) / 255 : 1,
    });
  }

  static fromString(color: string): RGBA | undefined {
    return RGBA.fromRGBAString(color) ?? RGBA.fromHex(color);
  }

  toRGBAString() {
    return `rgba(${this.r},${this.g},${this.b},${this.a})`;
  }

  toHex() {
    return `#${this.r.toString(16).padStart(2, '0')}${this.g
      .toString(16)
      .padStart(2, '0')}${this.b.toString(16).padStart(2, '0')}`;
  }

  toString() {
    return this.a === 1 ? this.toHex() : this.toRGBAString();
  }
}

export const EMPTY_RGBA = new RGBA({ r: 0, g: 0, b: 0, a: 1 });
export const EMPTY_RGBA_STRING = 'rgba(0, 0, 0, 0)'; // have to keep the spaces for now :/

export default RGBA;
