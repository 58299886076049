import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { DesignData } from 'editor/src/store/design/types';

import loadImage from 'editor/src/util/loadImage';
import store from 'product-personalizer/src/store';

import designContainsText from './designContainsText';
import loadCollections from './loadCollections';
import loadFonts from './loadFonts';
import loadSceneImages from './loadSceneImages';
import ResourceLoader from './resourceLoader';

async function loadFont(fontFamily: string) {
  const loadFontOperation = await import('editor/src/store/fonts/operation/loadFontOperation');
  await store.dispatch(loadFontOperation.default(fontFamily));
}

const loadResources = (designData: DesignData) => {
  const resourceLoader = new ResourceLoader();
  resourceLoader.addResource(
    () =>
      loadSceneImages(designData.product_uid).then(() => {
        const state = store.getState();
        const loadingImagePromise: Promise<any>[] = [];
        if (state.design.spreadBackgrounds) {
          state.design.spreadBackgrounds
            .filter((image) => designData?.spreads.find((spread) => spread.name === image.name))
            .forEach((image) => {
              loadingImagePromise.push(loadImage(image.url, 'anonymous', { executor: 'loadResources' }));
            });
        }

        if (state.design.spreadForegrounds) {
          state.design.spreadForegrounds
            .filter((image) => designData?.spreads.find((spread) => spread.name === image.name))
            .forEach((image) => {
              loadingImagePromise.push(loadImage(image.url, 'anonymous', { executor: 'loadResources' }));
            });
        }
        return Promise.all(loadingImagePromise);
      }),
    5000,
  );
  resourceLoader.addResource(() => loadCollections(designData), 4000);

  const state = store.getState();
  designData.spreads?.forEach((spread) => {
    const page = spread.pages[0];
    const loadedFonts: string[] = [];
    page.groups.media?.forEach((element) => {
      if (element.type === 'image' || element.type === 'addon') {
        const url = getImageElementUrl(state, element)?.imageUrl;
        if (url) {
          resourceLoader.addResource(() => loadImage(url, 'anonymous', { executor: 'loadResources' }), 5000);
        }
      }
      if (element.type === 'text') {
        if (!loadedFonts.find((font) => font === element.extra.fontFamily)) {
          resourceLoader.addResource(() => loadFont(element.extra.fontFamily), 4000);
          loadedFonts.push(element.extra.fontFamily);
        }
      }
    });
  });

  if (designContainsText(designData) && state.fonts?.availableFonts.length === 0) {
    resourceLoader.addResource(loadFonts, 4000);
  }

  return resourceLoader.waitForResources();
};

export default loadResources;
