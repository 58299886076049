import { createSlice } from '@reduxjs/toolkit';

import setDefaultZoomReducer from './reducer/setDefaultZoomReducer';
import setSpreadsDefaultZoomReducer from './reducer/setSpreadsDefaultZoomReducer';
import { PanAndZoomState } from './types';

export const panAndZoomInitialState: PanAndZoomState = {
  defaultZoom: undefined,
  defaultSpreadZoom: [],
};

export const slice = createSlice({
  name: 'panAndZoom',
  initialState: panAndZoomInitialState,
  reducers: {
    setDefaultZoomAction: setDefaultZoomReducer,
    setSpreadsDefaultZoomAction: setSpreadsDefaultZoomReducer,
  },
});

export const { setDefaultZoomAction, setSpreadsDefaultZoomAction } = slice.actions;

const panAndZoomReducer = slice.reducer;

export default panAndZoomReducer;
