import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconChevronLeft({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          // eslint-disable-next-line max-len
          d="M11.042 14.375L7.292 10.625C7.19466 10.5277 7.125 10.427 7.083 10.323C7.04166 10.219 7.021 10.1113 7.021 10C7.021 9.88867 7.04166 9.781 7.083 9.677C7.125 9.573 7.19466 9.47234 7.292 9.375L11.042 5.625C11.222 5.44434 11.4303 5.354 11.667 5.354C11.903 5.354 12.1113 5.44434 12.292 5.625C12.472 5.80567 12.562 6.014 12.562 6.25C12.562 6.486 12.472 6.69434 12.292 6.875L9.167 10L12.292 13.125C12.472 13.3057 12.562 13.514 12.562 13.75C12.562 13.986 12.472 14.1943 12.292 14.375C12.1113 14.5557 11.903 14.646 11.667 14.646C11.4303 14.646 11.222 14.5557 11.042 14.375Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconChevronLeft;
