import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import { updateDesign } from '../../variants/reducer/updateRecoloredElementsReducer';
import { ColorReplacement } from '../operation/getAssetUrlWithReplacedColors';

const updateRecoloredElementsReducer = (
  state: DesignState,
  action: PayloadAction<{
    assetId: string;
    url: string;
    colors: ColorReplacement;
  }>,
) => {
  const { assetId, url, colors } = action.payload;
  if (state.designData) {
    updateDesign(state.designData, assetId, url, colors);
  }
};

export default updateRecoloredElementsReducer;
