import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import { updateDesign } from '../../variants/reducer/updateDigitizedElementsReducer';

const updateDigitizedElementsReducer = (
  state: DesignState,
  action: PayloadAction<{ elementId: string; stitchCount: number }>,
) => {
  const { elementId, stitchCount } = action.payload;
  if (state.designData) {
    updateDesign(state.designData, elementId, stitchCount);
  }
};

export default updateDigitizedElementsReducer;
