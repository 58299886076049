import { setSpreadBackgroundsAction } from 'editor/src/store/design/slice';
import { SpreadGroundImage } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';

export type SpreadGroundImageWODimensions = Omit<
  SpreadGroundImage,
  'imgWidth' | 'imgHeight' | 'thumbnailUrl' | 'thumbnailWidth' | 'thumbnailHeight'
> & {
  url_webp?: string;
  image_width?: number;
  image_height?: number;
  thumbnail_url?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
  type?: 'foreground' | 'background';
};

export async function loadSpreadImages(data: SpreadGroundImageWODimensions[]) {
  const spreadGroundDimensions = await Promise.all(
    data.map(async (spread) => {
      try {
        if (spread.image_width && spread.image_height) {
          return { width: spread.image_width, height: spread.image_height };
        }
        return await loadImageDimensions(spread.url, 'anonymous', {
          executor: 'loadSpreadImage',
        });
      } catch {
        return undefined;
      }
    }),
  );

  const spreadGroundImages: SpreadGroundImage[] = [];
  data.forEach((spread, i) => {
    const dimensions = spreadGroundDimensions[i];
    if (dimensions) {
      spreadGroundImages.push({
        ...spread,
        url: isWebPSupported ? spread.url_webp ?? spread.url : spread.url,
        imgWidth: dimensions.width,
        imgHeight: dimensions.height,
        thumbnailUrl: isWebPSupported ? spread.thumbnail_url ?? spread.url : spread.url,
        thumbnailWidth: isWebPSupported ? spread.thumbnail_width ?? dimensions.width : dimensions.width,
        thumbnailHeight: isWebPSupported ? spread.thumbnail_height ?? dimensions.height : dimensions.height,
      });
    }
  });

  return spreadGroundImages;
}

const setSpreadBackgroundsOperation = (data: SpreadGroundImageWODimensions[]) => async (dispatch: ThunkDispatch) => {
  const spreadGroundImages = await loadSpreadImages(data);
  dispatch(setSpreadBackgroundsAction(spreadGroundImages));
};

export default setSpreadBackgroundsOperation;
