import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Saturation = createClass(filters.BaseFilter, {
  type: 'GelatoSaturation',

  fragmentSource:
    'precision highp float;\n' +
    'uniform sampler2D uTexture;\n' +
    'uniform float uSaturation;\n' +
    'varying vec2 vTexCoord;\n' +
    'void main() {\n' +
    'vec4 color = texture2D(uTexture, vTexCoord);\n' +
    'float rgMax = max(color.r, color.g);\n' +
    'float rgbMax = max(rgMax, color.b);\n' +
    'color.r += rgbMax != color.r ? (rgbMax - color.r) * (-uSaturation) : 0.00;\n' +
    'color.g += rgbMax != color.g ? (rgbMax - color.g) * (-uSaturation) : 0.00;\n' +
    'color.b += rgbMax != color.b ? (rgbMax - color.b) * (-uSaturation) : 0.00;\n' +
    'gl_FragColor = color;\n' +
    '}',

  saturation: 0,
  mainParameter: 'saturation',

  applyTo2d(options: Options) {
    if (this.saturation === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;
    const adjust = this.saturation;

    for (let i = 0; i < len; i += 4) {
      const max = Math.max(data[i], data[i + 1], data[i + 2]);
      data[i] += max !== data[i] ? (max - data[i]) * adjust : 0;
      data[i + 1] += max !== data[i + 1] ? (max - data[i + 1]) * adjust : 0;
      data[i + 2] += max !== data[i + 2] ? (max - data[i + 2]) * adjust : 0;
    }
  },

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uSaturation: gl.getUniformLocation(program, 'uSaturation'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uSaturation, this.saturation / 100);
  },

  isNeutralState() {
    return this.saturation === 0;
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      saturation: this.saturation,
    });
  },
});

Saturation.fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).GelatoSaturation = Saturation;

export default Saturation;
