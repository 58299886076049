import { MediaBox, Spread } from 'editor/src/store/design/types';
import isPagePrintable from 'editor/src/store/design/util/isPagePrintable';

import { getGroupElementsByKey } from './getSpreadGroupElementsByKey';

const getSpreadContentElementsWithPossibleMirroring = (spread: Spread) => {
  const contentElements = getGroupElementsByKey(spread, 'content', {
    name: 'Content Front',
  });

  if (contentElements.length > 0 && contentElements[0].extra?.mirroring === 'mirror') {
    return contentElements;
  }

  const mediaboxes: MediaBox[] = [];
  spread.pages.forEach((page) => {
    if (isPagePrintable(page)) {
      page.groups.mediabox?.forEach((mediabox) => {
        mediaboxes.push({
          ...mediabox,
          x: mediabox.x + page.x,
          y: mediabox.y + page.y,
        });
      });
    }
  });

  return mediaboxes;
};

export default getSpreadContentElementsWithPossibleMirroring;
