import { addLoaderAction } from 'editor/src/store/app/slice';
import { LoaderType } from 'editor/src/store/app/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const showLoaderOperation = (loaderType: LoaderType) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  if (
    loaderType !== LoaderType.EditorArea ||
    (loaderType === LoaderType.EditorArea && state.hostSettings.resizeProductBasedOnFirstElement)
  ) {
    dispatch(addLoaderAction(loaderType));
  }
};

export default showLoaderOperation;
