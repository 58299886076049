import { PayloadAction } from '@reduxjs/toolkit';

import { MockupState } from 'editor/src/store/mockup/types';

const setActiveProductReducer = (state: MockupState, action: PayloadAction<string>) => {
  const productUid = action.payload;
  state.productPlaceholder.activeProductUid = productUid;
};

export default setActiveProductReducer;
