import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import image from './emptyDraft.png';

import styles from './index.module.scss';

function EmptyDraft() {
  const { t } = useTranslation();

  return (
    <div className={styles.EmptyDraft}>
      <img src={image} alt={t("You don't have any design in progress")} />
      <div className={styles.title}>{t("You don't have any design in progress")}</div>
      <div className={cn(styles.content, 'mt-2')}>{t('Empty draft progress explanation')}</div>
    </div>
  );
}

export default React.memo(EmptyDraft);
