import { PayloadAction } from '@reduxjs/toolkit';

import { DesignOptionControl, VariantsState } from 'editor/src/store/variants/types';

const setDesignOptionsReducer = (state: VariantsState, action: PayloadAction<DesignOptionControl[]>) => {
  const designControls = action.payload;
  state.designOptionsControl = designControls;

  designControls.forEach((control) => {
    state.variationGroups.forEach((group) => {
      const spreadImages = state.spreadImages[group.key];
      if (spreadImages) {
        control.options.forEach((option) => {
          state.spreadImages[`${group.key}-${option.value}`] = spreadImages;
        });
      }
    });
  });
};

export default setDesignOptionsReducer;
