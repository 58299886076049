import { DesignData } from '../types';

function updateDesignPagesUtil(design: DesignData) {
  let page_nr = 1;

  for (let i = 0; i < design.spreads.length; i += 1) {
    const spread = design.spreads[i];
    if (page_nr === 1) {
      spread.name = 'Cover';
    } else {
      const spread_nr = Math.floor(page_nr / 2);
      spread.name = `Inside ${spread_nr}`;
      spread.canRemove = false;
      spread.canAddAfter = false;

      if (page_nr === 2 || page_nr === 3) {
        spread.canAddAfter = true;
      } else if (page_nr === 4 || page_nr === 5) {
        spread.isTemplate = true;
        spread.canAddAfter = true;
        spread.canRemove = true;
      } else if (page_nr < design.spreads.length * 2 - 2) {
        spread.canAddAfter = true;
        spread.canRemove = true;
      }
    }

    spread.pages.forEach((page) => {
      page.spread = spread.name;
      page.page_nr = page_nr;
      page_nr += 1;
    });
  }

  design.page_count = page_nr - 4;
}

export default updateDesignPagesUtil;
