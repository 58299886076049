import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const removeCalendarEventReducer = (state: DesignState, action: PayloadAction<{ id: string }>) => {
  if (!state.designData?.calendar?.events) {
    return;
  }
  const { id } = action.payload;
  state.designData.calendar.events = state.designData.calendar.events.filter((event) => id !== event?.id) || [];
};

export default removeCalendarEventReducer;
