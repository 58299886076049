import cn from 'classnames';
import React, { useEffect } from 'react';

import styles from './index.module.scss';

interface Props {
  src: string | undefined;
  index: number;
  onClick: (index: number) => void;
  selected: boolean;
  className?: string;
}

function GalleryItem({ src, index, onClick, selected, className }: Props) {
  const divRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(index);
  };

  useEffect(() => {
    if (selected) {
      divRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [selected]);

  return (
    <div
      className={cn(styles.galleryItem, className, { [styles.selected]: selected }, 'cy-pp-gallery-item')}
      onClick={handleClick}
      ref={divRef}
      style={{
        opacity: src ? undefined : 0,
        backgroundImage: src ? `url(${src})` : 'none',
      }}
    />
  );
}

export default React.memo(GalleryItem);
