import { fabric } from 'fabric';
import React, { useContext } from 'react';

export const FabricCanvasContext = React.createContext<fabric.Canvas>(null as any);

export function useFabricCanvas() {
  return useContext(FabricCanvasContext);
}

export default useFabricCanvas;
