// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpreadRenderer--mojAx{position:relative;width:100%;padding-bottom:100%}.SpreadRenderer--mojAx.loaded--mF9Ah canvas{opacity:1}.SpreadRenderer--mojAx canvas{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;transition:opacity 300ms ease-in-out}.loading--l_SE4{top:0;left:0;position:absolute;width:100%;height:100%}.loader--NyZy0{position:absolute;top:50%;left:50%}`, "",{"version":3,"sources":["webpack://./src/component/PreviewView/SpreadRenderer/index.module.scss"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CACA,UAAA,CACA,mBAAA,CAEA,4CACE,SAAA,CAGF,8BACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,oCAAA,CAIJ,gBACE,KAAA,CACA,MAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CAGF,eACE,iBAAA,CACA,OAAA,CACA,QAAA","sourcesContent":[".SpreadRenderer {\n  position: relative;\n  width: 100%;\n  padding-bottom: 100%;\n\n  &.loaded canvas {\n    opacity: 1;\n  }\n\n  canvas {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    opacity: 0;\n    transition: opacity 300ms ease-in-out;\n  }\n}\n\n.loading {\n  top: 0;\n  left: 0;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpreadRenderer": `SpreadRenderer--mojAx`,
	"loaded": `loaded--mF9Ah`,
	"loading": `loading--l_SE4`,
	"loader": `loader--NyZy0`
};
export default ___CSS_LOADER_EXPORT___;
