import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress, MediaElement } from 'editor/src/store/design/types';

const updateMediaElementReducer = (
  state: DesignState,
  action: PayloadAction<{
    elementAddress: ElementAddress;
    elementUpdate: Partial<MediaElement>;
  }>,
) => {
  const { elementAddress, elementUpdate } = action.payload;
  const { spreadIndex, pageIndex, elementIndex } = elementAddress;
  const mediaElement = state?.designData?.spreads[spreadIndex].pages[pageIndex].groups?.media?.[elementIndex];
  if (mediaElement) {
    Object.assign(mediaElement, elementUpdate);
  }
};

export default updateMediaElementReducer;
