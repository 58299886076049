import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const addUnlockSizeRatioMediaElementReducer = (state: EditorState, action: PayloadAction<number>) => {
  if (state.unlockSizeRatioElementUuids.indexOf(action.payload) === -1) {
    state.unlockSizeRatioElementUuids.push(action.payload);
  }
};

export default addUnlockSizeRatioMediaElementReducer;
