import cn from 'classnames';
import React from 'react';

import useImageLoader from 'editor/src/component/DesktopSidebar/TabContents/AddonsTabContent/AddonsList/AddonListItem/useImageLoader';
import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  url: string;
  id: string;
  onSelect: (id: string) => void;
  isActive: boolean;
  className?: string;
}

function ImageThumbnail({ id, url, isActive, onSelect, className }: Props) {
  const loaded = useImageLoader(url, true);

  return (
    <button className={cn(styles.ImageThumbnail, { [styles.active]: isActive }, className)} type="button">
      {!loaded && (
        <div className={styles.loader}>
          <IconLoading />
        </div>
      )}
      <img
        crossOrigin="anonymous"
        alt=""
        src={url}
        onClick={() => onSelect(id)}
        draggable={false}
        className={cn({ [styles.loaded]: loaded })}
      />
    </button>
  );
}
export default React.memo(ImageThumbnail);
