import { PayloadAction } from '@reduxjs/toolkit';

import { MockupPreviewProduct, MockupState } from 'editor/src/store/mockup/types';

const setStoreProductsReducer = (
  state: MockupState,
  action: PayloadAction<{
    limit: number;
    offset: number;
    storeId: string;
    products: MockupPreviewProduct[];
  }>,
) => {
  const { storeId, products, offset, limit } = action.payload;
  const product = state.preview.products[storeId];
  if (!product.entries) {
    product.entries = [];
  }
  product.entries.splice(offset, 0, ...products);
  product.hasMore = products.length === limit;
  products.forEach((product) => {
    state.preview.variants[product.id] = { hasMore: true, entries: undefined };
  });
};

export default setStoreProductsReducer;
