import loadImage from 'editor/src/util/loadImage';

const SIZE_THESHOLD = 1500;

export interface ImageInfo {
  uuid: string;
  url: string;
  width: number;
  height: number;
}

function getResizedImageUrl(img: HTMLImageElement, fileName: string, fileType: string) {
  return new Promise<string>((resolve, reject) => {
    const canva = document.createElement('canvas');
    const ctx = canva.getContext('2d');
    if (!ctx) {
      reject(new Error('Failed to create canvas.'));
      return;
    }

    const { width, height } = img;
    const scale = SIZE_THESHOLD / Math.max(width, height);
    const newWidth = width * scale;
    const newHeight = height * scale;
    canva.width = newWidth;
    canva.height = newHeight;
    ctx.drawImage(img, 0, 0, width, height, 0, 0, newWidth, newHeight);
    canva.toBlob((blob) => {
      if (blob) {
        const resizedFile = new File([blob], fileName, { type: fileType });
        resolve(URL.createObjectURL(resizedFile));
      } else {
        reject(new Error('Failed to resize image.'));
      }
    });
  });
}

async function getImageInfo(file: File): Promise<ImageInfo> {
  const uuid = `${file.name}-${file.size}-${file.lastModified}`;
  let imageBlobUrl = URL.createObjectURL(file);

  const img = await loadImage(imageBlobUrl, 'anonymous', { executor: 'getImageInfo' });

  const { width, height } = img;

  if (width > SIZE_THESHOLD || height > SIZE_THESHOLD) {
    URL.revokeObjectURL(imageBlobUrl);
    imageBlobUrl = await getResizedImageUrl(img, file.name, file.type);
  }

  return {
    uuid,
    url: imageBlobUrl,
    width,
    height,
  };
}

export default getImageInfo;
