import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'product-personalizer/src/store/hooks';
import getSelectedPreviewIndex from 'product-personalizer/src/store/productPersonalizer/selector/getSelectedPreviewIndex';
import { setSelectedPreviewIndexAction } from 'product-personalizer/src/store/productPersonalizer/slice';

import useDetectDeviceType, { DeviceTypeContext, mobileDevices } from 'editor/src/component/useDetectDeviceType';
import DesktopCarousel from 'product-personalizer/src/component/PreviewView/Carousel/DesktopCarousel';
import MobileCarousel from 'product-personalizer/src/component/PreviewView/Carousel/MobileCarousel';
import PreviewDialog from 'product-personalizer/src/component/PreviewView/PreviewDialog';

import styles from './index.module.scss';

/**
 * This component is used in the case of virtual variants: We want to show different images
 * for each variant so we can't rely on the default images provided by Shopify but this is still
 * in editor personalization so we don't have a canvas.
 */

const EMPTY_SPREAD_INDEXES: number[] = [];

function StaticPreviewView() {
  const deviceType = useDetectDeviceType();
  const isMobile = mobileDevices.has(deviceType);
  const dispatch = useDispatch();
  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);
  const selectedIndex = useSelector(getSelectedPreviewIndex);
  const imageUrls = useSelector((state) => state.productPersonalizer.productImages) ?? [];

  const onSelectedPreviewChange = useCallback((index: number) => {
    dispatch(setSelectedPreviewIndexAction(index));
  }, []);

  const openPreviewDialog = useCallback(() => {
    setShowPreviewDialog(true);
  }, []);

  const hidePreviewDialog = useCallback(() => {
    setShowPreviewDialog(false);
  }, []);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      <div className={cn(styles.StaticPreviewView, { [styles.dialogOpened]: showPreviewDialog })}>
        {isMobile ? (
          <MobileCarousel
            images={imageUrls}
            showPreviewDialog={openPreviewDialog}
            selectedIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
          />
        ) : (
          <DesktopCarousel
            spreadIndexes={EMPTY_SPREAD_INDEXES}
            images={imageUrls}
            selectedImageIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
            showPreviewDialog={openPreviewDialog}
          />
        )}
        {showPreviewDialog && (
          <PreviewDialog
            isMobile={isMobile}
            images={imageUrls}
            selectedIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
            onClose={hidePreviewDialog}
          />
        )}
      </div>
    </DeviceTypeContext.Provider>
  );
}

export default React.memo(StaticPreviewView);
