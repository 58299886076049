import { SpreadGroupPosition } from 'editor/src/store/design/types';

const getGroupedSpreadMultiplier = (groupCount: number, groupPosition?: SpreadGroupPosition) => {
  let widthMultiplier = 1;
  let heightMultiplier = 1;

  if (groupPosition === 'vertical') {
    heightMultiplier = groupCount;
  } else if (groupPosition === 'horizontal') {
    widthMultiplier = groupCount;
  }

  return {
    heightMultiplier,
    widthMultiplier,
  };
};

export default getGroupedSpreadMultiplier;
