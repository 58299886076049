import { createSlice } from '@reduxjs/toolkit';

import addImageReducer from './reducer/addImageReducer';
import addImageToSessionReducer from './reducer/addImageToSessionReducer';
import addUploadedImagesReducer from './reducer/addUploadedImagesReducer';
import removeImageReducer from './reducer/removeImageReducer';
import removeImagesReducer from './reducer/removeImagesReducer';
import replaceImageReducer from './reducer/replaceImageReducer';
import replaceSessionImageReducer from './reducer/replaceSessionImageReducer';
import resetSessionImageReducer from './reducer/resetSessionImageReducer';
import selectAllImagesReducer from './reducer/selectAllImagesReducer';
import selectImageReducer from './reducer/selectImageReducer';
import selectImagesReducer from './reducer/selectImagesReducer';
import setHideUsedPhotosReducer from './reducer/setHideUsedPhotosReducer';
import setUploadedImagesReducer from './reducer/setUploadedImagesReducer';
import unselectAllImagesReducer from './reducer/unselectAllImagesReducer';
import unselectImagesReducer from './reducer/unselectImagesReducer';
import updateImageReducer from './reducer/updateImageReducer';
import { GalleryState } from './types';

export const galleryInitialState: GalleryState = {
  images: [],
  hideUsedPhotos: false,
  sessionImages: [],
  selectedImagesIds: [],
};

export const slice = createSlice({
  name: 'gallery',
  initialState: galleryInitialState,
  reducers: {
    addImageAction: addImageReducer,
    removeImageAction: removeImageReducer,
    updateImageAction: updateImageReducer,
    addUploadedImagesAction: addUploadedImagesReducer,
    setUploadedImagesAction: setUploadedImagesReducer,
    setHideUsedPhotosAction: setHideUsedPhotosReducer,
    removeImagesAction: removeImagesReducer,
    replaceImageAction: replaceImageReducer,
    replaceSessionImageAction: replaceSessionImageReducer,
    addImageToSessionAction: addImageToSessionReducer,
    resetSessionImageAction: resetSessionImageReducer,
    selectImageAction: selectImageReducer,
    selectImagesAction: selectImagesReducer,
    unselectImagesAction: unselectImagesReducer,
    selectAllImagesAction: selectAllImagesReducer,
    unselectAllImagesAction: unselectAllImagesReducer,
  },
});

export const {
  addImageAction,
  removeImageAction,
  updateImageAction,
  addUploadedImagesAction,
  setUploadedImagesAction,
  setHideUsedPhotosAction,
  removeImagesAction,
  replaceImageAction,
  addImageToSessionAction,
  resetSessionImageAction,
  replaceSessionImageAction,
  selectImageAction,
  unselectImagesAction,
  selectImagesAction,
  selectAllImagesAction,
  unselectAllImagesAction,
} = slice.actions;

const gallery = slice.reducer;

export default gallery;
