import cloneDeep from 'lodash/cloneDeep';

import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { ConditionGroup, DesignData, MediaElement, MediaImage } from 'editor/src/store/design/types';
import getContentAreaFromSpread from 'editor/src/store/design/util/getContentAreaFromSpread';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import { GalleryImage, ImageState, MimeType } from 'editor/src/store/gallery/types';

import applyFillImageToSpread from 'editor/src/util/design/applyFillImageToSpread';
import loadImage from 'editor/src/util/loadImage';
import store from 'product-personalizer/src/store';
import { Asset, AssetFileType } from 'product-personalizer/src/types/asset';
import { fetchDesignFromId } from 'product-personalizer/src/utils/loadData';

import { PreflightScene, SceneAssetType } from './types';

export function getNewDesignWithAppliedImage(
  newImageUrl: string,
  newDesignData: DesignData,
  firstUploadedAsset: Asset,
) {
  if (!firstUploadedAsset.width || !firstUploadedAsset.height) {
    return undefined;
  }

  let elementToReplace: MediaImage | undefined = extractFirstImageSampleElement(newDesignData);

  // if we have a sample element we should replace image instead of it
  if (elementToReplace) {
    const originalElementPosition = {
      x: elementToReplace.x,
      y: elementToReplace.y,
      width: elementToReplace.width,
      height: elementToReplace.height,
    };

    const newImageProperties: Partial<MediaImage> = {
      imageId: firstUploadedAsset.id,
      url: newImageUrl,
      width: firstUploadedAsset.width,
      height: firstUploadedAsset.height,
      px: 0,
      py: 0,
      pw: firstUploadedAsset.width,
      ph: firstUploadedAsset.height,
      pr: 0,
      sample: undefined,
    };

    const areaToFit: Area = {
      left: elementToReplace.x,
      top: elementToReplace.y,
      width: elementToReplace.width,
      height: elementToReplace.height,
    };

    elementToReplace = Object.assign(elementToReplace, newImageProperties);
    elementToReplace = Object.assign(
      elementToReplace,
      applyFillImageToSpread(elementToReplace, areaToFit),
      originalElementPosition,
    );
  } else {
    // apply image as a new element to fill spread
    const area = getContentAreaFromSpread(newDesignData.spreads[0]);
    if (!area) {
      return undefined;
    }

    let newImageElement: MediaImage = {
      imageId: firstUploadedAsset.id,
      url: newImageUrl,
      width: firstUploadedAsset.width,
      height: firstUploadedAsset.height,
      px: 0,
      py: 0,
      pw: firstUploadedAsset.width,
      ph: firstUploadedAsset.height,
      pr: 0,
      sample: undefined,
      r: 0,
      type: 'image',
      x: 0,
      y: 0,
      uuid: getMaxDesignElementUid(newDesignData) + 1,
      name: 'Uploaded image',
      group: '',
    };

    const areaToFit: Area = {
      left: area.left,
      top: area.top,
      width: area.width,
      height: area.height,
    };

    newImageElement = Object.assign(newImageElement, applyFillImageToSpread(newImageElement, areaToFit));
    if (!newDesignData.spreads[0].pages[0].groups?.media) {
      newDesignData.spreads[0].pages[0].groups.media = [];
    }

    newDesignData.spreads[0].pages[0].groups.media.push(newImageElement);
  }

  return newDesignData;
}

export async function extractFirstUploadedAssetByDesignIds(designIds: string[]): Promise<Asset | undefined> {
  // Fetch all designs in parallel
  const designPromises = designIds.map(async (designId) => {
    const design = await fetchDesignFromId(designId); // assets are part of this design object
    const designData = JSON.parse(design.structure) as DesignData; // structure is parsed separately
    return { designData, assets: design.assets }; // return both designData and assets
  });

  const designs = await Promise.all(designPromises);

  // Extract the first uploaded asset from each design
  const firstUploadedAsset = designs
    .map(({ designData, assets }) => {
      return extractFirstUploadedAssetFromDesign(designData, assets); // pass the assets correctly
    })
    .find((asset) => asset && asset.width && asset.height);

  return firstUploadedAsset;
}

export function extractFirstUploadedAssetFromDesign(designData: DesignData, assets: Asset[]) {
  const mediaAssets = designData.spreads.flatMap((spread) => spread.pages[0]?.groups.media || []);
  const uploadedAsset = mediaAssets.find((media) => media.type === 'image' && media.createdWhileEmbedded);

  if (uploadedAsset) {
    return assets.find((asset) => asset.id === (uploadedAsset as MediaImage).imageId);
  }

  // If no uploaded asset are found, return the first archived asset
  return assets.find((asset) => asset.status === 'archived');
}

export function extractFirstImageSampleElement(designData: DesignData): MediaImage | undefined {
  const mediaImagesWithConditions = designData.spreads.flatMap((spread) => {
    const mediaGroup = spread.pages[0]?.groups.media || [];
    return mediaGroup
      .filter((media): media is MediaImage => media.type === 'image')
      .map((media) => ({ media, conditionGroup: spread.conditionGroup }));
  });

  return mediaImagesWithConditions.find(
    ({ media, conditionGroup }) => media.sample || (conditionGroup && isElementInGroup(media, conditionGroup)),
  )?.media;
}

export function getMaxDesignElementUid(designData: DesignData): number {
  let maxUid = 0;
  designData.spreads.forEach((spread) => {
    const mediaGroup = spread.pages[0]?.groups.media || [];
    mediaGroup.forEach((media) => {
      if (media.uuid > maxUid) {
        maxUid = media.uuid;
      }
    });
  });

  return maxUid;
}

export function isElementInGroup(element: MediaElement, conditionGroup: ConditionGroup): boolean {
  return Object.keys(conditionGroup.children).some((parentId) => {
    const child = conditionGroup.children[parentId];
    return child.some((elt) => elt.id === element.uuid);
  });
}

// eslint-disable-next-line
export function getSceneNameFromProductUid(productId: string) {
  // TODO add scene names if needed. By default if we don't specify scene it will render the default preview
  return undefined;
}

export function htmlImageToGalleryImage(loadedImage: HTMLImageElement, imageUrl: string): GalleryImage {
  return {
    id: 'uploaded-image',
    uuid: 99999,
    name: 'uploaded-image',
    type: MimeType.Unknown,
    width: loadedImage.width,
    height: loadedImage.height,
    hasAssetDimensions: true,
    state: ImageState.UPLOADED,
    url: imageUrl,
  };
}

export function htmlImageToDesignAsset(loadedImage: HTMLImageElement, imageUrl: string): Asset {
  return {
    files: [
      { type: AssetFileType.PREVIEW_DEFAULT, url: imageUrl },
      { type: AssetFileType.PREVIEW_THUMBNAIL, url: imageUrl },
    ],
    id: 'uploaded-image',
    mimeType: MimeType.Unknown,
    width: loadedImage.width,
    height: loadedImage.height,
    status: ImageState.UPLOADED,
  };
}

export const assetsToGalleryImages = (assets: Asset[]): GalleryImage[] => {
  return assets.map((asset, index) => ({
    width: asset.width || 1,
    height: asset.height || 1,
    id: asset.id,
    uuid: index,
    name: asset.id,
    type: MimeType.Unknown,
    hasAssetDimensions: true,
    state: ImageState.UPLOADED,
    url: asset.files.find((file) => file.type === 'preview_default')?.url,
    thumbnailUrl: asset.files.find((file) => file.type === 'preview_thumbnail')?.url,
  }));
};

export function loadFont(fontFamily: string) {
  return store.dispatch(loadFontOperation(fontFamily));
}

export const loadSceneAssets = (scene: PreflightScene) => {
  return Promise.all(
    Object.entries<string>(scene.assets as any).map(([name, url]) => {
      return new Promise<{ name: SceneAssetType; image: HTMLImageElement }>((resolve, reject) => {
        loadImage(url, 'anonymous', {
          executor: 'loadAssetScene',
        })
          .then((image) => {
            resolve({ name: name as SceneAssetType, image });
          })
          .catch((error) => {
            reject(error);
          });
      });
    }),
  );
};

export const formatDesignStructureForCanvasRendering = (designData: DesignData): DesignData => {
  const newDesign = cloneDeep(designData) as DesignData;
  newDesign.spreads.forEach((spread) => {
    spread.pages[0].groups?.media?.forEach((media) => {
      if (media.type === 'image' || media.type === 'text') {
        if (media.sample) {
          media.sample = undefined;
        }
      }
    });
  });

  return newDesign;
};
