// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--wpcQx{display:flex;align-items:center}.button--EJc8h{padding:48px}.button--EJc8h:hover{transform:scale(1.4)}.button--EJc8h.nonClickable--Ek9eN{cursor:not-allowed;pointer-events:none;opacity:.4}.name--EVI2L{display:flex;justify-content:center;font-family:"Gelato Sans",sans-serif;width:115px}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Preview/FlatPreview/SpreadPreviewNavigation/index.module.scss"],"names":[],"mappings":"AAAA,kBACI,YAAA,CACA,kBAAA,CAGJ,eACI,YAAA,CAEA,qBACI,oBAAA,CAGJ,mCACI,kBAAA,CACA,mBAAA,CAEA,UAAA,CAIR,aACI,YAAA,CACA,sBAAA,CACA,oCAAA,CACA,WAAA","sourcesContent":[".container {\n    display: flex;\n    align-items: center;;\n}\n\n.button {\n    padding: 48px;\n\n    &:hover {\n        transform: scale(1.4);\n    }\n\n    &.nonClickable {\n        cursor: not-allowed;\n        pointer-events: none;\n\n        opacity: 0.4;\n    }\n}\n\n.name {\n    display: flex;\n    justify-content: center;\n    font-family: \"Gelato Sans\", sans-serif;\n    width: 115px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--wpcQx`,
	"button": `button--EJc8h`,
	"nonClickable": `nonClickable--Ek9eN`,
	"name": `name--EVI2L`
};
export default ___CSS_LOADER_EXPORT___;
