import { PayloadAction } from '@reduxjs/toolkit';

import { DesignOption, VariantsState } from 'editor/src/store/variants/types';

const updateDesignOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{
    controlKey: string;
    index: number;
    option: DesignOption;
  }>,
) => {
  const { controlKey, index, option } = action.payload;

  const control = state.designOptionsControl?.find((control) => control.key === controlKey);
  if (control) {
    control.options[index] = option;
  }
};

export default updateDesignOptionReducer;
