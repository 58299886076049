import { PayloadAction } from '@reduxjs/toolkit';

import { ProductControlSize, ProductSizeOption, VariantsState } from 'editor/src/store/variants/types';

const addControlOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{ controlKey: string; option: ProductSizeOption }>,
) => {
  const { controlKey, option } = action.payload;

  const control = state.product.externalProductControls.find(
    (control) => control.key === controlKey,
  ) as ProductControlSize;
  if (control) {
    control.options.push(option);
  }
};

export default addControlOptionReducer;
