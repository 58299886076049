import { PayloadAction } from '@reduxjs/toolkit';

import { ActionBarNames, AppState } from 'editor/src/store/app/types';

const showActionBarReducer = (
  state: AppState,
  { payload: activeActionBar }: PayloadAction<ActionBarNames | undefined>,
) => {
  state.activeActionBar = activeActionBar;
};

export default showActionBarReducer;
