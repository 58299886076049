import { getGroupElementsByKey } from 'editor/src/store/design/selector/getSpreadGroupElementsByKey';
import { Spread } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';

function getLayoutBBox(layoutSchema: LayoutSchema, spread: Spread) {
  if (layoutSchema.params.useBleed && !layoutSchema.params.useContentAreasOnly) {
    const mediaBoxes = getGroupElementsByKey(spread, 'mediabox');
    return getBoxesMinMax(mediaBoxes);
  }

  const contentBoxes = getGroupElementsByKey(spread, 'content');
  if (layoutSchema.params.useBleed) {
    return getBoxesMinMax(contentBoxes);
  }

  // try to find area with Content Front, then use only that
  const frontBox = contentBoxes.find((content) => content.name === 'Content Front');
  if (frontBox) {
    return getBoxesMinMax([frontBox]);
  }

  return getBoxesMinMax(contentBoxes.filter((content) => content.type === 'area'));
}

export default getLayoutBBox;
