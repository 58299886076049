import { PayloadAction } from '@reduxjs/toolkit';

import { SpreadGroup } from 'editor/src/store/design/types';
import { VariantsState } from 'editor/src/store/variants/types';

const setSpreadGroupsPerProductUidReducer = (
  state: VariantsState,
  action: PayloadAction<{ [productUid: string]: SpreadGroup[] }>,
) => {
  state.groupedSpreadsPerProductUids = action.payload;
};

export default setSpreadGroupsPerProductUidReducer;
