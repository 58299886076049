import { MediaImage } from 'editor/src/store/design/types';
import { Plugin } from 'editor/src/store/plugins/types';
import { getImageManipulationPluginData } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import isImageManipulationPlugin from 'editor/src/util/plugins/isImageManipulationPlugin';

const containsImageManipulationPlugin = (media: Partial<MediaImage>, plugin: Plugin): boolean =>
  isImageManipulationPlugin(plugin) && getImageManipulationPluginData(plugin.name, media)?.imageModified === true;

export default containsImageManipulationPlugin;
