// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewView--fkVAs{width:100%;max-width:100%;max-height:100%;display:flex;justify-content:center;position:sticky;top:3rem;box-sizing:border-box}.PreviewView--fkVAs.hideImageSelector--WkMNc{height:100%}.PreviewView--fkVAs *{box-sizing:border-box}.PreviewView--fkVAs .imageContainer--yAZK7{width:80px;height:80px;cursor:pointer;border:1px solid #fefefe;border-radius:6px;text-align:center;touch-action:pan-y}.PreviewView--fkVAs .image--Feqxv{display:block;width:100%;object-fit:contain}.PreviewView--fkVAs .loader--OnEBh{position:absolute;top:50%;left:50%;background-color:rgba(255,255,255,.3)}.PreviewView--fkVAs .loader--OnEBh.loaded--CpI_d{opacity:0}.PreviewView--fkVAs.dialogOpened--Nl_la{z-index:5}`, "",{"version":3,"sources":["webpack://./src/component/PreviewView/index.module.scss"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA,CACA,QAAA,CACA,qBAAA,CAEA,6CACE,WAAA,CAGF,sBACE,qBAAA,CAGF,2CACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA,CAGF,kCACE,aAAA,CACA,UAAA,CACA,kBAAA,CAGF,mCACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,qCAAA,CAEA,iDACE,SAAA,CAIJ,wCACE,SAAA","sourcesContent":[".PreviewView {\n  width: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  display: flex;\n  justify-content: center;\n  position: sticky;\n  top: 3rem;\n  box-sizing: border-box;\n\n  &.hideImageSelector {\n    height: 100%;\n  }\n\n  * {\n    box-sizing: border-box;\n  }\n\n  .imageContainer {\n    width: 80px;\n    height: 80px;\n    cursor: pointer;\n    border: 1px solid #fefefe;\n    border-radius: 6px;\n    text-align: center;\n    touch-action: pan-y;\n  }\n\n  .image {\n    display: block;\n    width: 100%;\n    object-fit: contain;\n  }\n\n  .loader {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    background-color: rgba(255, 255, 255, 0.3);\n\n    &.loaded {\n      opacity: 0;\n    }\n  }\n\n  &.dialogOpened {\n    z-index: 5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PreviewView": `PreviewView--fkVAs`,
	"hideImageSelector": `hideImageSelector--WkMNc`,
	"imageContainer": `imageContainer--yAZK7`,
	"image": `image--Feqxv`,
	"loader": `loader--OnEBh`,
	"loaded": `loaded--CpI_d`,
	"dialogOpened": `dialogOpened--Nl_la`
};
export default ___CSS_LOADER_EXPORT___;
