import { setGlobalPluginAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { PluginName } from 'editor/src/store/plugins/types';

export type PersonalizationType =
  | 'in-page-personalization'
  | 'editor-personalization'
  | 'ai-personalization'
  | 'email-workflow-personalization';

export const personalizationTypeToPluginMap = new Map<PersonalizationType, PluginName>([
  ['in-page-personalization', PluginName.shopifyPersonalization],
  ['editor-personalization', PluginName.shopifyPersonalizationInEditor],
  ['ai-personalization', PluginName.AIPersonalization],
  ['email-workflow-personalization', PluginName.emailWorkflowPersonalization],
]);

export const personalizationPluginNames = new Set(personalizationTypeToPluginMap.values());

const updatePersonalizationOperation =
  (option: PersonalizationType) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const pluginName = personalizationTypeToPluginMap.get(option);
    if (!pluginName) {
      return;
    }

    const state = getState();
    const { globalPlugins } = state.design;
    // filter out all values from the map present in the plugins array
    const filteredPluginsArray = globalPlugins.filter(
      (plugin) => !personalizationPluginNames.has(plugin as PluginName),
    );
    const pluginsArray = [...filteredPluginsArray, pluginName];
    // after this dispatch, the designListener is calling checkPluginUse
    dispatch(setGlobalPluginAction(pluginsArray));
  };

export default updatePersonalizationOperation;
