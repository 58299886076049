import type { UploaderConfig } from 'shared/uploader';

const companionUrl = 'https://uploader-backend.ie.test.gelato.tech/';

const config: UploaderConfig = {
  core: {
    restrictions: {
      maxNumberOfFiles: 200,
      allowedFileTypes: ['image/jpeg', 'image/png', '.heic', 'application/pdf', 'image/svg+xml'],
    },
    autoProceed: true,
    allowMultipleUploads: true,
  },
  dashboard: {
    thumbnailWidth: 1000,
  },
  statusBar: false,
  companionUrl,
  googleDrive: { companionUrl },
  dropbox: { companionUrl },
};

export default config;
