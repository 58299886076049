import { MediaElement } from 'editor/src/store/design/types';
import { PluginName } from 'editor/src/store/plugins/types';

function isElementACollection(element: MediaElement): boolean {
  return (
    element.type === 'image' &&
    (!!element.plugins?.[PluginName.AdvancedPersonalisation] || !!element.plugins?.[PluginName.personalizationStudio])
  );
}

export default isElementACollection;
