import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Brightness = createClass(filters.BaseFilter, {
  type: 'GelatoBrightness',

  fragmentSource:
    'precision highp float;\n' +
    'uniform sampler2D uTexture;\n' +
    'uniform float uBrightness;\n' +
    'varying vec2 vTexCoord;\n' +
    'void main() {\n' +
    'vec4 color = texture2D(uTexture, vTexCoord);\n' +
    'color.rgb += uBrightness;\n' +
    'gl_FragColor = color;\n' +
    '}',

  brightness: 0,
  mainParameter: 'brightness',

  applyTo2d(options: Options) {
    if (this.brightness === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;

    for (let i = 0; i < len; i += 4) {
      data[i] += this.brightness;
      data[i + 1] += this.brightness;
      data[i + 2] += this.brightness;
    }
  },

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uBrightness: gl.getUniformLocation(program, 'uBrightness'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uBrightness, this.brightness / 100);
  },

  isNeutralState() {
    return this.brightness === 0;
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      brightness: this.brightness,
    });
  },
});

Brightness.fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).GelatoBrightness = Brightness;

export default Brightness;
