import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Contrast = createClass(filters.BaseFilter, {
  type: 'Contrast',

  fragmentSource:
    'precision highp float;\n' +
    'uniform sampler2D uTexture;\n' +
    'uniform float uContrast;\n' +
    'varying vec2 vTexCoord;\n' +
    'void main() {\n' +
    'vec4 color = texture2D(uTexture, vTexCoord);\n' +
    'float contrastF = 1.015 * (uContrast + 1.0) / (1.0 * (1.015 - uContrast));\n' +
    'color.rgb = contrastF * (color.rgb - 0.5) + 0.5;\n' +
    'gl_FragColor = color;\n' +
    '}',

  contrast: 0,
  mainParameter: 'contrast',

  applyTo2d(options: Options) {
    if (this.contrast === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;
    const contrast = Math.floor(this.contrast * 255);
    const contrastF = (259 * (contrast + 255)) / (255 * (259 - contrast));

    for (let i = 0; i < len; i += 4) {
      data[i] = contrastF * (data[i] - 128) + 128;
      data[i + 1] = contrastF * (data[i + 1] - 128) + 128;
      data[i + 2] = contrastF * (data[i + 2] - 128) + 128;
    }
  },

  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uContrast: gl.getUniformLocation(program, 'uContrast'),
    };
  },

  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uContrast, this.contrast / 100);
  },

  isNeutralState() {
    return this.contrast === 0;
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      contrast: this.contrast,
    });
  },
});

Contrast.fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).GelatoContrast = Contrast;

export default Contrast;
