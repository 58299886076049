import { MediaElement, MediaImage } from 'editor/src/store/design/types';
import { PluginName } from 'editor/src/store/plugins/types';

export enum CollectionDisplayOption {
  Thumbnail,
  Dropdown,
  Color,
}

export interface AdvancedPersonalisationElementData {
  folderId: string;
  displayOption: CollectionDisplayOption;
}

export function getAdvPersoDataFromElement(element: MediaElement): AdvancedPersonalisationElementData | undefined {
  return (
    element.type === 'image' &&
    (element.plugins?.[PluginName.AdvancedPersonalisation] || element.plugins?.[PluginName.personalizationStudio])
  );
}

export function setAdvPersoDataOnElement(
  element: Partial<MediaImage>,
  folderId: string,
  displayOption: CollectionDisplayOption,
) {
  const plugins = element.plugins ? { ...element.plugins } : {};
  const data: AdvancedPersonalisationElementData = { folderId, displayOption };
  // TODO remove advancedPersonalizationPlugin after cleanup from apps n services.
  plugins[PluginName.AdvancedPersonalisation] = data;
  plugins[PluginName.personalizationStudio] = data;
  element.plugins = plugins;
}
