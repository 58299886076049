import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  images: string[];
  selectedIndex: number;
  onIndexChanged: (index: number) => void;
}

function PreviewPagination({ selectedIndex, onIndexChanged, images }: Props) {
  return (
    <div className={cn(styles.indicator, 'mt-2', 'mb-4')}>
      {images.map((_, index) => (
        <div
          key={index}
          onClick={() => onIndexChanged(index)}
          className={cn(styles.circle, { [styles.active]: index === selectedIndex })}
        />
      ))}
    </div>
  );
}

export default React.memo(PreviewPagination);
