import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateImagesAfterDropOperation from 'editor/src/store/design/operation/updateImagesAfterDropOperation';
import setImageIfCurrentImageIsEmptyOperation from 'editor/src/store/editor/operation/setImageIfCurrentImageIsEmptyOperation';
import isUseNotUploadedImagesAllowed from 'editor/src/store/editor/selector/isUseNotUploadedImagesAllowed';
import { addImageAction, updateImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, LocalPreviewImageData } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';

import addImageToSessionOperation from './addImageToSessionOperation';

const addImageLocalPreviewFromUploader =
  (imagePreview: LocalPreviewImageData) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const hasAssetDimensions = !!imagePreview.width && !!imagePreview.height;
    const mimeType = getMimeTypeEnum(imagePreview.type);

    // extracting dimensions from the preview
    if (!hasAssetDimensions && imagePreview.preview) {
      try {
        const imageDimensions = await loadImageDimensions(imagePreview.preview, undefined, {
          executor: 'addImageLocalPreviewFromUploader',
        });
        imagePreview.width = imageDimensions.width;
        imagePreview.height = imageDimensions.height;
      } catch {
        /* */
      }
    }

    const dimensions = {
      width: imagePreview.width ?? 0,
      height: imagePreview.height ?? 0,
    };

    batch(() => {
      const state = getState();
      // loading image dimensions could be delayed after another message for progress or success is sent
      const existingImage = state.gallery.images.find((image) => image.uploaderId === imagePreview.id);
      if (!existingImage) {
        dispatch(
          addImageAction({
            state: ImageState.LOCAL_PREVIEW,
            id: imagePreview.id,
            uploaderId: imagePreview.id,
            name: imagePreview.name,
            type: mimeType,
            width: dimensions.width,
            height: dimensions.height,
            previewData: imagePreview.preview,
            source: imagePreview.source,
            hasAssetDimensions,
          }),
        );
        dispatch(addImageToSessionOperation(imagePreview.id));
      } else if (existingImage.state === ImageState.LOCAL_DROP) {
        dispatch(
          updateImageAction({
            state: ImageState.LOCAL_PREVIEW,
            id: imagePreview.id,
            name: imagePreview.name,
            type: mimeType,
            width: existingImage.hasAssetDimensions ? existingImage.width : dimensions.width,
            height: existingImage.hasAssetDimensions ? existingImage.height : dimensions.height,
            previewData: imagePreview.preview,
            source: imagePreview.source,
            hasAssetDimensions,
          }),
        );
        dispatch(addImageToSessionOperation(imagePreview.id));
        if (
          !existingImage.hasAssetDimensions &&
          existingImage.width / existingImage.height !== dimensions.width / dimensions.height
        ) {
          dispatch(updateImagesAfterDropOperation(imagePreview.id, dimensions));
        }
      }

      const canUseImage = isUseNotUploadedImagesAllowed(getState());
      if (canUseImage && imagePreview.width && imagePreview.height) {
        dispatch(setImageIfCurrentImageIsEmptyOperation(imagePreview.id));
      }
    });
  };

export default addImageLocalPreviewFromUploader;
