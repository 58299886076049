import sendPostMessage from './postMessages/sendPostMessage';

let hasUserInteracted = false;
const trackInteraction = () => {
  if (!hasUserInteracted) {
    hasUserInteracted = true;
    sendPostMessage('editor.userInteracted', undefined);
  }
};

export default trackInteraction;
