import { createSlice } from '@reduxjs/toolkit';

import setActiveRightbarReducer from './reducer/setActiveRightbarReducer';
import { RightbarState, RightTab } from './types';

export const rightbarInitialState: RightbarState = {
  activeTab: RightTab.Variants,
};

const slice = createSlice({
  name: 'rightbar',
  initialState: rightbarInitialState,
  reducers: {
    setActiveRightbarAction: setActiveRightbarReducer,
  },
});

const { actions, reducer } = slice;

export const { setActiveRightbarAction } = actions;

export default reducer;
