import { v4 as uuidv4 } from 'uuid';

const createCalendarEventId = (custom: boolean, merchant: boolean) => {
  const uuid = uuidv4();
  if (custom) {
    return `custom-event-${uuid}`;
  }
  if (merchant) {
    return `merchant-event-${uuid}`;
  }

  return `public-holiday-${uuid}`;
};

export default createCalendarEventId;
