import { createListenerMiddleware } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';

import type { RootState } from 'product-personalizer/src/store/index';

import { DraftState } from '../slice';

export const variantStoragelistenerMiddleware = createListenerMiddleware<RootState>();

export interface StoredVariantsData {
  timestamp: number;
  variants: DraftState['variants'];
}

variantStoragelistenerMiddleware.startListening({
  predicate: (_, currentState, previousState) => currentState.drafts.variants !== previousState.drafts.variants,
  effect: (_, api) => {
    const state = api.getState();
    const objectToSave: StoredVariantsData = {
      timestamp: Date.now(),
      variants: state.drafts.variants,
    };

    window.localStorage.setItem(DRAFT_VARIANTS_KEY, JSON.stringify(objectToSave));
  },
});

export const DRAFT_VARIANTS_KEY = 'gelato-variants-drafts';
export function getStoredVariantData() {
  try {
    const localVariantsData = window.localStorage.getItem(DRAFT_VARIANTS_KEY);
    if (localVariantsData) {
      const data = JSON.parse(localVariantsData) as StoredVariantsData;
      // only 10 minutes old data
      if (data && data.timestamp > Date.now() - 10 * 60 * 1000) {
        return data.variants;
      }
    }
  } catch (e) {
    captureException(e);
  }

  return {};
}
