import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryImage, GalleryState } from 'editor/src/store/gallery/types';

import { imageUuid } from './addImageReducer';

const setUploadedImagesReducer = (state: GalleryState, action: PayloadAction<Omit<GalleryImage, 'uuid'>[]>) => {
  const images = action.payload.map((image) => ({
    ...image,
    uuid: imageUuid.generate(),
  }));
  state.images = images;
};

export default setUploadedImagesReducer;
