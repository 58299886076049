import { LayoutFrameArea, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

function getLayoutFrameById(frameId: string, layout: LayoutSchema): LayoutFrameArea | undefined {
  // eslint-disable-next-line no-restricted-syntax
  for (const row of layout.frameRows) {
    // eslint-disable-next-line no-restricted-syntax
    for (const frameArea of row) {
      if (frameArea.id === frameId) {
        return frameArea;
      }
    }
  }

  return undefined;
}

export default getLayoutFrameById;
