import { PayloadAction } from '@reduxjs/toolkit';

import { FontState, FontStyles } from 'editor/src/store/fonts/types';

const setDefaultFontStylesReducer = (state: FontState, action: PayloadAction<Partial<FontStyles>>) => {
  const fs = action.payload;

  if (fs.fontFamily) {
    const fonts = state.availableFonts;
    const font = fonts.find(
      (font) => font.metadata.family === fs.fontFamily || font.metadata.fontFile === fs.fontFamily,
    );
    if (font) {
      fs.fontFamily = font.metadata.fontFile;
    }
  }

  Object.assign(state.defaultFontStyles, fs);
};

export default setDefaultFontStylesReducer;
