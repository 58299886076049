import { createSlice } from '@reduxjs/toolkit';

import setVariantWarningsReducer from './reducer/setVariantWarningsReducer';
import setWarningsReducer from './reducer/setWarningsReducer';
import { WarningsState } from './types';

export const warningsInitialState: WarningsState = {
  list: [],
  variantWarnings: {},
};

export const slice = createSlice({
  name: 'warnings',
  initialState: warningsInitialState,
  reducers: {
    setWarningsAction: setWarningsReducer,
    setVariantWarningsAction: setVariantWarningsReducer,
  },
});

export const { setWarningsAction, setVariantWarningsAction } = slice.actions;

const warningsReducer = slice.reducer;

export default warningsReducer;
