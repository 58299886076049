import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, DesignState } from 'editor/src/store/design/types';

const updateConditionOptionImageIdReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    optionId: string;
    imageId: string;
  }>,
) => {
  const { address, optionId, imageId } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  const value = condition.options.find((value) => value.id === optionId);
  if (value) {
    value.imageId = imageId;
  }
};

export default updateConditionOptionImageIdReducer;
