import limitPrecision from 'editor/src/util/limitPrecision';

import { AreaClip } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import applyScaleToRectangleElement from './applyScaleToRectangleElement';
import { getElementBoundingBox, RectMediaElement } from './utils';

function applyScaleToImageElement(
  sourceElement: RectMediaElement,
  sourceMediaBox: AreaClip,
  destElement: RectMediaElement,
  destMediaBox: AreaClip,
  scale: number,
) {
  const wasFillingH =
    limitPrecision(sourceElement.x, 2) === limitPrecision(sourceMediaBox.x, 2) &&
    limitPrecision(sourceElement.x + sourceElement.width, 2) ===
      limitPrecision(sourceMediaBox.x + sourceMediaBox.width, 2);
  const wasFillingV =
    limitPrecision(sourceElement.y, 2) === limitPrecision(sourceMediaBox.y, 2) &&
    limitPrecision(sourceElement.y + sourceElement.height, 2) ===
      limitPrecision(sourceMediaBox.y + sourceMediaBox.height, 2);

  if (wasFillingH && wasFillingV) {
    destElement.x = limitPrecision(destMediaBox.x);
    destElement.y = limitPrecision(destMediaBox.y);
    destElement.width = limitPrecision(destMediaBox.width);
    destElement.height = limitPrecision(destMediaBox.height);
  } else if (wasFillingH || wasFillingV) {
    let localScale = 1;
    if (wasFillingH) {
      const isCovering =
        sourceElement.y <= sourceMediaBox.y &&
        sourceElement.y + sourceElement.height > sourceMediaBox.y + sourceMediaBox.height;
      localScale = destMediaBox.width / sourceElement.width;
      if (isCovering) {
        localScale = Math.max(destMediaBox.height / sourceElement.height, localScale);
      }
    } else {
      const isCovering =
        sourceElement.x <= sourceMediaBox.x &&
        sourceElement.x + sourceElement.width > sourceMediaBox.x + sourceMediaBox.width;
      localScale = destMediaBox.height / sourceElement.height;
      if (isCovering) {
        localScale = Math.max(destMediaBox.width / sourceElement.width, localScale);
      }
    }

    const sourceBbox = getElementBoundingBox(sourceElement);
    const destBbox = getElementBoundingBox(destElement);
    const pageOffsetX =
      destMediaBox.x + destMediaBox.width / 2 - (sourceMediaBox.x + sourceMediaBox.width / 2) * localScale;
    const pageOffsetY =
      destMediaBox.y + destMediaBox.height / 2 - (sourceMediaBox.y + sourceMediaBox.height / 2) * localScale;

    destElement.x = limitPrecision(sourceBbox.x * localScale + pageOffsetX + (destElement.x - destBbox.x));
    destElement.y = limitPrecision(sourceBbox.y * localScale + pageOffsetY + (destElement.y - destBbox.y));
    destElement.width = limitPrecision(sourceElement.width * localScale);
    destElement.height = limitPrecision(sourceElement.height * localScale);
  } else {
    applyScaleToRectangleElement(sourceElement, sourceMediaBox, destElement, destMediaBox, scale);
  }
}

export default applyScaleToImageElement;
