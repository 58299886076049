import { fabric } from 'fabric';

import { Adjustment } from 'editor/src/store/design/types';

import ADJUSTMENTS from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/adjustments';

import Black from './filters/Black';
import Brightness from './filters/Brightness';
import Brownie from './filters/Brownie';
import Contrast from './filters/Contrast';
import Hue from './filters/Hue';
import Kodachrome from './filters/Kodachrome';
import Polaroid from './filters/Polaroid';
import Saturation from './filters/Saturation';
import Sepia from './filters/Sepia';
import Technicolor from './filters/Technicolor';
import Temperature from './filters/Temperature';
import TrueBlackAndWhite from './filters/TrueBlackAndWhite';
import Vibrance from './filters/Vibrance';
import Vintage from './filters/Vintage';

export const getAdjustment = (adjustmentName: string) => ADJUSTMENTS.find((element) => element.name === adjustmentName);

export const getAdjustmentFilters = (adjustments: Adjustment[] | undefined) => {
  const filters: fabric.IBaseFilter[] = [];
  adjustments?.forEach((adjustment: Adjustment) => {
    const adjustmentDefinition = getAdjustment(adjustment.name);
    if (!adjustmentDefinition) {
      return;
    }
    switch (adjustment.name) {
      case 'brightness': {
        filters[adjustmentDefinition.index] = new Brightness({
          brightness: adjustment.value,
        });
        break;
      }
      case 'contrast': {
        filters[adjustmentDefinition.index] = new Contrast({
          contrast: adjustment.value,
        });
        break;
      }
      case 'saturation': {
        filters[adjustmentDefinition.index] = new Saturation({
          saturation: adjustment.value,
        });
        break;
      }
      case 'hue': {
        filters[adjustmentDefinition.index] = new Hue({
          rotation: adjustment.value,
        });
        break;
      }
      case 'temperature': {
        filters[adjustmentDefinition.index] = new Temperature({
          shift: adjustment.value,
        });
        break;
      }
      case 'vibrance': {
        filters[adjustmentDefinition.index] = new Vibrance({
          vibrance: adjustment.value,
        });
        break;
      }
      case 'sepia': {
        filters[adjustmentDefinition.index] = new Sepia({
          value: adjustment.value,
        });
        break;
      }
      case 'brownie': {
        filters[adjustmentDefinition.index] = new Brownie({
          value: adjustment.value,
        });
        break;
      }
      case 'kodachrome': {
        filters[adjustmentDefinition.index] = new Kodachrome({
          value: adjustment.value,
        });
        break;
      }
      case 'polaroid': {
        filters[adjustmentDefinition.index] = new Polaroid({
          value: adjustment.value,
        });
        break;
      }
      case 'technicolor': {
        filters[adjustmentDefinition.index] = new Technicolor({
          value: adjustment.value,
        });
        break;
      }
      case 'trueBlackAndWhite': {
        filters[adjustmentDefinition.index] = new TrueBlackAndWhite({
          value: adjustment.value,
        });
        break;
      }
      case 'vintage': {
        filters[adjustmentDefinition.index] = new Vintage({
          value: adjustment.value,
        });
        break;
      }
      case 'black': {
        filters[adjustmentDefinition.index] = new Black({
          value: adjustment.value,
        });
        break;
      }

      default:
        break;
    }
  });
  return filters;
};

export default getAdjustmentFilters;
