import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryImage, GalleryState } from 'editor/src/store/gallery/types';

import { imageUuid } from './addImageReducer';

const addUploadedImagesReducer = (state: GalleryState, action: PayloadAction<Omit<GalleryImage, 'uuid'>[]>) => {
  const images = action.payload
    .filter((image) => !state.images.some((stateImage) => stateImage.id === image.id))
    .map((image) => ({ ...image, uuid: imageUuid.generate() }));
  state.images.unshift(...images);
};

export default addUploadedImagesReducer;
