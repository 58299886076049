import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { MediaElement } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import selectFirstEmptyImageFrameOperation from './selectFirstEmptyImageFrameOperation';

const selectFirstEmptyImageFrameOnCurrentPageOperation =
  (skipIfImageSelected: boolean) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const ignorePersonalizationLock = isPersonalizationLockIgnored(state);
    if (skipIfImageSelected) {
      const selectedElements = getSelectedMediaElementsObjects(state).filter(
        (el) => el && el.type === 'image' && (!el.personalizationLocked || ignorePersonalizationLock),
      ) as MediaElement[];
      if (selectedElements.length) {
        return;
      }
    }
    const spreadIndex = getCurrentSpreadIndex(state);
    const pageIndex = 0;
    dispatch(selectFirstEmptyImageFrameOperation(spreadIndex, pageIndex));
  };

export default selectFirstEmptyImageFrameOnCurrentPageOperation;
