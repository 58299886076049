import { captureException } from '@sentry/react';
import { fabric } from 'fabric';

import disposeCanvas from 'editor/src/component/EditorArea/disposeCanvas';
import { disposeElement } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';

export type RequestRenderFn = <T>(cb: (canvas: fabric.StaticCanvas) => Promise<T>, id: string | number) => Promise<T>;
type RenderRequest = {
  cb: (canvas: fabric.StaticCanvas) => Promise<any>;
  id: string | number;
  res: (data: any) => void;
  promise: Promise<any>;
};

function createCanvasRendering(enable = true, preserveCanvas = false) {
  let canvasRef: fabric.StaticCanvas | undefined;
  let used = false;
  const queue: RenderRequest[] = [];

  const initializeCanvas = () => {
    if (!enable) {
      return;
    }

    const domCanvas = document.createElement('canvas');
    domCanvas.id = 'canvas-rendering-queue';

    const fabricCanvas = new fabric.StaticCanvas(domCanvas, {
      renderOnAddRemove: false,
      imageSmoothingEnabled: false,
      backgroundColor: 'rgb(255, 255, 255)',
      width: 1,
      height: 1,
    });

    canvasRef = fabricCanvas;
    runQueue();
  };

  const cleanupCanvas = () => {
    if (canvasRef && !used) {
      disposeCanvas(canvasRef);
      canvasRef = undefined;
    }
  };

  const runQueue = () => {
    if (used || !canvasRef) {
      return;
    }

    const item = queue.shift();
    if (item) {
      used = true;
      const fabricCanvas = canvasRef;
      item
        .cb(fabricCanvas)
        .then((res) => {
          const objects = fabricCanvas.getObjects();
          objects.forEach(disposeElement);
          fabricCanvas.remove(...objects);

          if (fabricCanvas !== canvasRef) {
            disposeCanvas(fabricCanvas);
          } else if (queue.length === 0 && !preserveCanvas) {
            fabricCanvas.setDimensions({ width: 1, height: 1 });
          }

          used = false;
          runQueue();
          item.res(res);
        })
        .catch((e) => {
          captureException(e || 'error in the rendering queue');
          used = false;
          runQueue();
        });
    }
  };

  const requestRender: RequestRenderFn = (cb, id) => {
    let request = queue.find((q) => q.id === id);
    if (request) {
      request.cb = cb;
    } else {
      let res: any;
      const promise = new Promise((resolve) => {
        res = resolve;
      });
      request = {
        cb,
        id,
        promise,
        res,
      };
      queue.push(request);
    }
    runQueue();
    return request.promise;
  };

  initializeCanvas();

  return { requestRender, cleanupCanvas };
}

export default createCanvasRendering;
