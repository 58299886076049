import { ColorOverride } from 'editor/src/store/design/operation/controlDesignDataAndGetColorOverrides';
import getAssetUrlWithReplacedColors from 'editor/src/store/design/operation/getAssetUrlWithReplacedColors';

export function requestVariantsColorOverrides(
  colorOverrides: ColorOverride[],
  variantKey: string | undefined,
  designOptionKey: string | undefined,
) {
  colorOverrides.forEach(({ elementUuid, imageId, overrides, quantized }) => {
    getAssetUrlWithReplacedColors(imageId, elementUuid, overrides, quantized, variantKey, designOptionKey);
  });
}

export default requestVariantsColorOverrides;
