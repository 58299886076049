import { PayloadAction } from '@reduxjs/toolkit';

import {
  DesignOptionControl,
  MultiOptions,
  Product,
  SingleOptions,
  VariantsState,
  VariationGroup,
} from 'editor/src/store/variants/types';

const applyStateReducer = (
  state: VariantsState,
  action: PayloadAction<{
    product: Product;
    selectedGroupKey: string | undefined;
    variationGroups: VariationGroup[];
    selectedMultiOptions: MultiOptions;
    selectedSingleOptions: SingleOptions;
    designOptionsEnabled: boolean;
    designOptionsControl: DesignOptionControl[] | undefined;
  }>,
) => {
  state.product = action.payload.product;
  state.selectedGroupKey = action.payload.selectedGroupKey;
  state.variationGroups = action.payload.variationGroups;
  state.selectedMultiOptions = action.payload.selectedMultiOptions;
  state.selectedSingleOptions = action.payload.selectedSingleOptions;
  state.designOptionsEnabled = action.payload.designOptionsEnabled;
  state.designOptionsControl = action.payload.designOptionsControl;
};

export default applyStateReducer;
