import { MimeType } from 'editor/src/store/gallery/types';

function getMimeTypeEnum(type: string): MimeType {
  switch (type) {
    case 'image/svg':
    case 'image/svg+xml':
      return MimeType.SVG;
    case 'application/pdf':
      return MimeType.PDF;
    case 'image/jpeg':
    case 'image/jpg':
      return MimeType.JPG;
    case 'image/png':
      return MimeType.PNG;
    case 'image/webp':
      return MimeType.WebP;
    default:
      return MimeType.Unknown;
  }
}

export default getMimeTypeEnum;
