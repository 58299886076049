import { getNewElementDisplayForSpread } from 'editor/src/store/design/operation/getNewElementDisplay';
import getNewImageDisplaySize from 'editor/src/store/design/operation/getNewImageDisplaySize';
import { Dimensions, MediaAddon, MediaElement, MediaImage, MediaText, Spread } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';

import { RootState } from 'editor/src/store';
import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

export type ImageWithDim = MediaImage & { dimensions: Dimensions };

export type ImageToPlace = {
  imageId: string;
  dimensions: Dimensions;
  adjustments?: MediaImage['adjustments'];
  filters?: MediaImage['filters'];
  pattern?: MediaImage['pattern'];
  plugins?: MediaImage['plugins'];
  name?: MediaImage['name'];
  personalizationLocked?: MediaImage['personalizationLocked'];
  sample?: MediaImage['sample'];
  url?: MediaImage['url'];
  colorOverrides?: MediaImage['colorOverrides'];
  createdByLayout?: MediaImage['createdByLayout'];
  layoutFrameId?: MediaImage['layoutFrameId'];
};

type LinkedImageElement = {
  imageId: string;
  url?: string;
  dimensions: Dimensions;
  plugins?: any;
};

export function getImageWithDimensions(state: RootState, image: MediaImage): ImageWithDim | undefined {
  const galleryImage = getImageById(state, image.imageId);
  if (!galleryImage) {
    return undefined;
  }
  return {
    ...image,
    dimensions: { width: galleryImage.width, height: galleryImage.height },
  };
}

const getAppliedLayoutSchemaToSpreadMedia = (
  spread: Spread,
  layoutElements: MediaElement[],
  previousElements: {
    text: MediaText[];
    addon: MediaAddon[];
    image: ImageWithDim[];
  },
  preservePrevious: boolean,
  imagesToPlace?: ImageToPlace[],
) => {
  const media: MediaElement[] = [];

  let imageElementIndex = 0;
  let textElementIndex = 0;

  const linkedElementsImageId: {
    [linkId: string]: {
      imageId: string;
      url?: string;
      dimensions: Dimensions;
      plugins?: any;
    };
  } = {};
  layoutElements.forEach((mediaElement) => {
    // do not try replacing automatically generated text like calendar month/year or an uneditable field
    if (mediaElement.type === 'text' && !mediaElement.uneditable && textElementIndex < previousElements.text.length) {
      applyTextElement(mediaElement, previousElements.text[textElementIndex], spread.pages[0].width);
      textElementIndex += 1;
    } else if (mediaElement.type === 'image') {
      const originalElement = imagesToPlace
        ? imagesToPlace[imageElementIndex]
        : (previousElements.image[imageElementIndex] as ImageWithDim | undefined);
      let imageToPlace: ImageToPlace | undefined = originalElement;

      if (mediaElement.linkId) {
        if (!linkedElementsImageId[mediaElement.linkId] && originalElement) {
          linkedElementsImageId[mediaElement.linkId] = originalElement;
        } else if (linkedElementsImageId[mediaElement.linkId]) {
          imageToPlace = linkedElementsImageId[mediaElement.linkId];
        }
      }

      applyImageElement(mediaElement, originalElement, imageToPlace);
      imageElementIndex += 1;
    }

    media.push(mediaElement);
  });

  if (preservePrevious) {
    const preservedMediaElements = [
      ...previousElements.image.slice(imageElementIndex),
      ...previousElements.text.slice(textElementIndex),
      ...previousElements.addon,
    ].filter((elt) => !elt.createdByLayout);

    preservedMediaElements.forEach((mediaElement) => {
      if (mediaElement.type !== 'image') {
        media.push({ ...mediaElement, locked: undefined });
        return;
      }

      const { imageId, plugins, dimensions } = mediaElement;

      let linkedElement: LinkedImageElement | undefined;
      if (mediaElement.linkId) {
        if (!linkedElementsImageId[mediaElement.linkId] && imageId && dimensions) {
          linkedElementsImageId[mediaElement.linkId] = {
            imageId,
            dimensions,
            plugins,
          };
        } else if (linkedElementsImageId[mediaElement.linkId]) {
          linkedElement = linkedElementsImageId[mediaElement.linkId];
        }
      }

      const preservedElement = getPreservedImageElement(mediaElement, spread, linkedElement);
      if (!preservedElement) {
        return;
      }
      media.push(preservedElement);
    });
  }

  return media;
};

const applyTextElement = (mediaElement: MediaText, originalElement: MediaText, areaWidth: number) => {
  mediaElement.extra.text = originalElement.extra?.text;
  if (!originalElement.createdByLayout) {
    mediaElement.createdByLayout = undefined;
  }
  if (originalElement.sample !== mediaElement.sample) {
    mediaElement.sample = originalElement.sample;
  }
  if (originalElement.personalizationLocked !== mediaElement.personalizationLocked) {
    mediaElement.personalizationLocked = originalElement.personalizationLocked;
  }
  updateTextElementWithoutRender(mediaElement, { x: 0, width: areaWidth });
};

export const applyImageElement = (
  mediaElement: MediaImage,
  originalElement: ImageToPlace | undefined,
  imageToPlace: ImageToPlace | undefined,
) => {
  if (originalElement) {
    if (originalElement.adjustments) {
      mediaElement.adjustments = originalElement.adjustments;
    }
    if (originalElement.filters) {
      mediaElement.filters = originalElement.filters;
    }
    if (originalElement.pattern) {
      mediaElement.pattern = originalElement.pattern;
    }
    if (originalElement.plugins) {
      mediaElement.plugins = originalElement.plugins;
    }
    if (originalElement.name) {
      mediaElement.name = originalElement.name;
    }
    if (originalElement.colorOverrides) {
      mediaElement.url = originalElement.url;
      mediaElement.colorOverrides = originalElement.colorOverrides;
    }
    if (originalElement.sample !== mediaElement.sample) {
      mediaElement.sample = originalElement.sample;
    }
    if (originalElement.personalizationLocked !== mediaElement.personalizationLocked) {
      mediaElement.personalizationLocked = originalElement.personalizationLocked;
    }
    if (!originalElement.createdByLayout) {
      mediaElement.createdByLayout = undefined;
    }
  }

  if (imageToPlace) {
    const { px, py, pw, ph } = getCenteredImagePosition(mediaElement, imageToPlace.dimensions);
    if (mediaElement.pattern) {
      mediaElement.px = 0;
      mediaElement.py = 0;
    } else {
      mediaElement.px = px;
      mediaElement.py = py;
    }
    mediaElement.pw = pw;
    mediaElement.ph = ph;
    mediaElement.pr = 0;
    mediaElement.imageId = imageToPlace.imageId;
    mediaElement.url = imageToPlace.url;
  }
};

const getPreservedImageElement = (
  mediaElement: ImageWithDim,
  spread: Spread,
  linkedImageElement: LinkedImageElement | undefined,
): MediaImage | undefined => {
  const { dimensions, ...image } = mediaElement;

  let { imageId, url } = mediaElement;
  let dim = dimensions;

  if (linkedImageElement) {
    imageId = linkedImageElement.imageId;
    dim = linkedImageElement.dimensions;
    url = linkedImageElement.url;
  }

  if (!imageId) {
    return undefined;
  }

  const display = getNewElementDisplayForSpread(spread, (area) => getNewImageDisplaySize(area, dim.width, dim.height));
  if (!display) {
    return undefined;
  }

  const { center, rotation, width, height } = display;

  return {
    ...image,
    url,
    imageId,
    x: center.x,
    y: center.y,
    width,
    height,
    r: rotation,
    locked: undefined,
    px: 0,
    py: 0,
    pw: width,
    ph: height,
    pr: 0,
  };
};

export default getAppliedLayoutSchemaToSpreadMedia;
