import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaElement, MediaText } from 'editor/src/store/design/types';
import isSampleFlagChangeAllowed from 'editor/src/store/editor/selector/isSampleFlagChangeAllowed';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

export enum MediaUpdateActionName {
  UNKNOWN = 'Element changed',
  MOVED = 'Element moved',
  CROPPED = 'Element cropped',
  RESIZED = 'Element resized',
  ROTATED = 'Element rotated',
  TOGGLE_LOCK = 'Element locked/unlocked',
  TOGGLE_PERSO_LOCK = 'Element personalization locked/unlocked',
  TOGGLE_SAMPLE = 'Element toggle sample',
  TOGGLE_FOIL = 'Element toggle foil',
  TEXT_UPDATED = 'Text updated',
  FONT_UPDATED = 'Text font updated',
  CHANGE_FOLDER_IMAGE = 'Image bundle changed',
  RENAMED = 'Element was renamed',
  COLOR_CHANGED = 'Color was changed',
  REMOVE_IMAGE_FROM_FRAME = 'Image removed from frame',
  ADJUSTMENT_APPLIED = 'An adjustment was applied',
  FILTER_APPLIED = 'A filter was applied',
  PATTERN_APPLIED = 'A pattern was applied',
  LINE_STROKE_UPDATED = 'Line Stroke updated',
  LINE_POSITION_UPDATED = 'Line position updated',
  RECT_UPDATED = 'Rect updated',
  LINE_EDGE_1_UPDATED = 'LINE_EDGE_1_UPDATED',
  LINE_EDGE_2_UPDATED = 'LINE_EDGE_2_UPDATED',
  LINE_ROUNDED_UPDATED = 'LINE_ROUNDED_UPDATED',
  LINE_DASH_PATTERN_UPDATED = 'LINE_DASH_PATTERN_UPDATED',
  RECTANGLE_POSITION_UPDATED = 'RECTANGLE_POSITION_UPDATED',
  RECTANGLE_STROKE_UPDATED = 'RECTANGLE_STROKE_UPDATED',
  RECTANGLE_FILL_UPDATED = 'RECTANGLE_FILL_UPDATED',
  MOCKUP_UPDATED = 'MOCKUP_UPDATED',
  TRANSFORM_PERSPECTIVE_UPDATED = 'TRANSFORM_PERSPECTIVE_UPDATED',
  PLACEHOLDER_UPDATE = 'PLACEHOLDER_UPDATE',
  DIGITIZING = 'DIGITIZING',
}

const updateMediaElementOperation =
  (
    elementAddress: ElementAddress,
    elementUpdate: Partial<MediaElement>,
    action: MediaUpdateActionName,
    preventSavingStore = false,
  ) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element) {
      return;
    }

    // everything is the same, no need to update
    if ((Object.keys(elementUpdate) as Array<keyof MediaElement>).every((key) => element[key] === elementUpdate[key])) {
      return;
    }

    batch(() => {
      if (!preventSavingStore) {
        dispatch(saveUndoRedoStateOperation(action));
      }

      // do we have a text style/content update
      if (element.type === 'text') {
        const textUpdate = elementUpdate as Partial<MediaText>;

        // text was changed, it's not a sample anymore
        if (!isSampleFlagChangeAllowed(state) && element.sample && textUpdate.extra) {
          textUpdate.sample = undefined;
        }
      }

      dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
    });
  };

export default updateMediaElementOperation;
