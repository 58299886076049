/* eslint-disable max-len */
import { fabric } from 'fabric';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

function getRotateIcon(angle: number, size: number) {
  return encodeURI(`
    <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
      <path transform="rotate(${angle} ${size / 2} ${size / 2})" d="M13.6028 1C10.5658 1 8.22979 2.2449 6.67397 3.46249C5.89546 4.07176 5.30313 4.68081 4.90316 5.14035C4.82597 5.22904 4.75576 5.31237 4.69258 5.3893L2.70711 3.40382L2 9.76778L8.36396 9.06068L6.11522 6.81194C6.19095 6.71508 6.28995 6.59337 6.41177 6.4534C6.74617 6.06919 7.24759 5.55324 7.90659 5.03751C9.22577 4.0051 11.1397 3 13.6028 3C16.0658 3 17.9798 4.0051 19.299 5.03751C19.958 5.55324 20.4594 6.06919 20.7938 6.4534C20.7957 6.45563 20.7977 6.45785 20.7996 6.46006L18.6028 8.65685L24.9667 9.36396L24.2596 3L22.2167 5.04295C21.8196 4.5966 21.2563 4.02964 20.5316 3.46249C18.9758 2.2449 16.6397 1 13.6028 1Z"/>
      <path transform="rotate(${angle} ${size / 2} ${size / 2})" d="M6.36582 3.06874C5.64319 3.63428 5.07504 4.19955 4.66522 4.65483L3.06066 3.05027L2.32505 2.31466L2.21016 3.34861L1.50306 9.71257L1.43404 10.3337L2.05522 10.2647L8.41918 9.55762L9.45313 9.44273L8.71751 8.70712L6.79038 6.77999C7.1084 6.41478 7.58658 5.92286 8.21474 5.43126C9.47466 4.44524 11.2831 3.5 13.6028 3.5C15.9225 3.5 17.7309 4.44524 18.9908 5.43126C19.442 5.78437 19.8158 6.13765 20.1085 6.44402L18.2492 8.3033L17.5136 9.03891L18.5476 9.1538L24.9115 9.8609L25.5327 9.92992L25.4637 9.30875L24.7566 2.94478L24.6417 1.91083L23.9061 2.64645L22.2303 4.32227C21.8592 3.93783 21.3951 3.50338 20.8397 3.06874C19.2248 1.80491 16.7833 0.5 13.6028 0.5C10.4223 0.5 7.98071 1.80491 6.36582 3.06874Z" stroke="white" stroke-opacity="0.8"/>
    </svg>
  `);
}

function getResizeIcon(angle: number, size: number) {
  return encodeURI(`
    <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
      <path transform="rotate(${angle} ${size / 2} ${size / 2})" fill-rule="evenodd" clip-rule="evenodd" d="M7 8H21V11L26 7L21 3V6H7V3L2 7L7 11V8Z" />
      <path transform="rotate(${angle} ${size / 2} ${size / 2})" d="M20.5 3V5.5H7.5V3V1.95969L6.68765 2.60957L1.68765 6.60957L1.19961 7L1.68765 7.39043L6.68765 11.3904L7.5 12.0403V11V8.5H20.5V11V12.0403L21.3123 11.3904L26.3123 7.39043L26.8004 7L26.3123 6.60957L21.3123 2.60957L20.5 1.95969V3Z" stroke="white" stroke-opacity="0.8"/>
    </svg>
  `);
}

function getCursorCSSEntry(
  angle: number,
  width: number,
  height: number,
  getIcon: (angle: number, w: number, h: number) => string,
) {
  const [x, y] = getPointPositionRotatedOnPoint(width / 2, height / 2, width / 2, width / 2, angle);
  return `url('data:image/svg+xml;utf8,${getIcon(angle || 0, width, width)}') ${x} ${y}, pointer`;
}

function getResizeCursorHandler(angle: number) {
  return (_: any, _1: any, target: fabric.Object) =>
    getCursorCSSEntry(angle + (target.angle || 0), 28, 14, getResizeIcon);
}

function setCursors(object: fabric.Object) {
  const { mtr, ml, mr, mt, mb, tl, tr, bl, br } = (object as any).controls;
  mtr.cursorStyleHandler = (_: any, _1: any, target: fabric.Object) =>
    getCursorCSSEntry(target.angle || 0, 27, 11, getRotateIcon);
  ml.cursorStyleHandler = getResizeCursorHandler(0);
  mr.cursorStyleHandler = getResizeCursorHandler(0);
  mt.cursorStyleHandler = getResizeCursorHandler(90);
  mb.cursorStyleHandler = getResizeCursorHandler(90);
  tl.cursorStyleHandler = getResizeCursorHandler(45);
  br.cursorStyleHandler = getResizeCursorHandler(45);
  tr.cursorStyleHandler = getResizeCursorHandler(-45);
  bl.cursorStyleHandler = getResizeCursorHandler(-45);
}

export default setCursors;
