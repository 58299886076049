import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { ConditionGroup, conditionGroupChildenKey } from 'editor/src/store/design/types';

function getConditionLayerIds(
  conditionId: string,
  conditionGroup: ConditionGroup,
  parentIsActive: boolean,
  hiddenIds: Set<number>,
  activeIds: Set<number>,
) {
  const condition = conditionGroup.conditions[conditionId];
  condition.options.forEach((option) => {
    const children = conditionGroup.children[getConditionChildrenKey(conditionId, option.id)] || [];
    const isActive = parentIsActive && condition.activeOptionId === option.id;

    children.forEach((child) => {
      if (child.type === 'condition') {
        getConditionLayerIds(child.id, conditionGroup, isActive, hiddenIds, activeIds);
      } else if (isActive) {
        activeIds.add(child.id);
      } else {
        hiddenIds.add(child.id);
      }
    });
  });

  return { hiddenIds, activeIds };
}

function getHiddenMediaElementUuids(conditionGroup: ConditionGroup) {
  const hiddenIds = new Set<number>();
  const activeIds = new Set<number>();

  conditionGroup.children[conditionGroupChildenKey].forEach((child) => {
    if (child.type === 'condition') {
      getConditionLayerIds(child.id, conditionGroup, true, hiddenIds, activeIds);
    } else {
      activeIds.add(child.id);
    }
  });

  activeIds.forEach((id) => hiddenIds.delete(id));
  return hiddenIds;
}

export default getHiddenMediaElementUuids;
