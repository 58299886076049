import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import getSpreadPagesNames from 'editor/src/store/design/selector/getSpreadPagesNames';
import { useSelector } from 'editor/src/store/hooks';

import isSpreadNameAMonth from 'editor/src/util/isSpreadNameAMonth';

function useSpreadTitle(
  spreadName: string,
  spreadIndex: number,
  focusedContent: { contentTitle?: string } | undefined,
) {
  const { t, i18n } = useTranslation();
  const { pageNames, gridDate } = useSelector(
    (state) => ({
      pageNames: getSpreadPagesNames(state, spreadIndex),
      gridDate: getFirstGridElement(state, spreadIndex)?.grid.gridDate,
    }),
    shallowEqual,
  );

  const translateSpreadTitle = (title: string) => {
    if (isSpreadNameAMonth(title) && gridDate) {
      const date = new Date(gridDate.year, gridDate.month, 1);
      const year = date.getFullYear();
      const month = date.toLocaleString(i18n.languageWithDash, {
        month: 'short',
      });
      return `${month} ${year}`;
    }

    const spreadTitleTranslationKey = `editor-spread-name-${title.toLowerCase().replace('-embroidery', '').replaceAll(' ', '-')}`;
    const translatedTitle = t(spreadTitleTranslationKey) as string;
    if (translatedTitle !== spreadTitleTranslationKey) {
      return translatedTitle;
    }
    return spreadName;
  };

  if (focusedContent) {
    return translateSpreadTitle(focusedContent.contentTitle || spreadName);
  }

  if (pageNames && pageNames.length > 1) {
    return pageNames
      .map((pageName) => {
        const pageTitleTranslationKey = `editor-page-name-${pageName}`;
        const translatedPage = t(pageTitleTranslationKey) as string;

        if (translatedPage !== pageTitleTranslationKey) {
          return translatedPage;
        }
        return pageName;
      })
      .join(' - ');
  }

  return translateSpreadTitle(spreadName);
}

export default useSpreadTitle;
