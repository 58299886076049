import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import IconArrowDownSVG from './indexSVG';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconArrowDown({ className }: Props) {
  return (
    <Icon className={className}>
      <IconArrowDownSVG />
    </Icon>
  );
}

export default React.memo(IconArrowDown);
