import { RootState } from 'editor/src/store/index';
import { PluginName } from 'editor/src/store/plugins/types';

import isPluginEnabled from './isPluginEnabled';

const hasPersonalizationPluginEnabled = (state: RootState) =>
  isPluginEnabled(state, PluginName.AdvancedPersonalisation) ||
  isPluginEnabled(state, PluginName.personalizationStudio);

export default hasPersonalizationPluginEnabled;
