import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import isPopupOpen from 'editor/src/store/app/module/popup/selector/isPopupOpen';
import { useSelector } from 'editor/src/store/hooks';

import IconCross from 'editor/src/component/Icon/IconCross';

import PopupIcon from './PopupIcon';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
  type: PopupType;
  name: string;
  buttons: React.ReactNode;
  onClickOutside?: () => void;
  className?: string;
  fadeMaskClassName?: string;
  hideTitleIcon?: boolean;
}

export enum PopupType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

function AbstractPopup({
  children,
  onClickOutside,
  className,
  fadeMaskClassName,
  type,
  title,
  buttons,
  name,
  hideTitleIcon,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const isOpen = useSelector((state) => isPopupOpen(state, name));

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (isOpen && e.target && ref?.current && e.target instanceof Node && !ref.current.contains(e.target)) {
        if (onClickOutside) {
          onClickOutside();
        }
      }
    };

    requestAnimationFrame(() => {
      window.addEventListener('click', handleClickOutside, false);
    });

    return () => window.removeEventListener('click', handleClickOutside, false);
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className={cn(styles.fadeMask, fadeMaskClassName, 'cy-popup-backdrop')} />
      <div
        ref={ref}
        className={cn(styles.abstractPopup, className, {
          [styles.error]: type === PopupType.ERROR,
          [styles.warning]: type === PopupType.WARNING,
          [styles.info]: type === PopupType.INFO,
          [styles.success]: type === PopupType.SUCCESS,
        })}
      >
        <div className={styles.header}>
          {!hideTitleIcon && <PopupIcon type={type} className={styles.icon} />}
          {title}
          <div className={styles.close} onClick={onClickOutside}>
            <IconCross />
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.buttons}>{buttons}</div>
      </div>
    </>
  );
}

export default AbstractPopup;
