import type { DesignState } from 'editor/src/store/design/types';
import { ElementMap } from 'editor/src/store/design/util/getSpreadGridElements';

import type { PayloadAction } from '@reduxjs/toolkit';

const updateElementsOnSpreadReducer = (
  state: DesignState,
  action: PayloadAction<{ elementMap: ElementMap; spreadIndex: number }>,
) => {
  const { elementMap, spreadIndex } = action.payload;
  const spread = state.designData?.spreads[spreadIndex];
  if (!spread) {
    return;
  }

  spread.pages[0].groups.media?.forEach((media) => {
    const updatedElement = elementMap[media.uuid];
    if (updatedElement) {
      Object.assign(media, updatedElement);
    }
  });
};

export default updateElementsOnSpreadReducer;
