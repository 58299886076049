import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  value: string;
  placeholder?: string;
  className?: string;
  focusOnMount?: boolean;
  selectOnMount?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  debounceTime?: number;
  form?: string;
  maxCharCount?: number;
  isAllowedChar?: (char: string) => boolean;
}

function TextInput({
  value,
  className,
  focusOnMount,
  selectOnMount,
  onChange,
  onBlur,
  placeholder,
  form,
  debounceTime = 300,
  maxCharCount,
  isAllowedChar,
}: Props) {
  const [content, setContent] = useState(value);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => setContent(value), [value]);
  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus();
    }
    if (selectOnMount) {
      inputRef.current?.select();
    }
  }, [focusOnMount, selectOnMount]);

  const onContentUpdate = useCallback(debounce(onChange, debounceTime, { trailing: true }), [onChange, debounceTime]);

  function onTextChange(e: React.ChangeEvent) {
    const { value } = e.target as HTMLInputElement;
    let content = value;
    if (maxCharCount && content.length > maxCharCount) {
      content = content.slice(0, maxCharCount);
    }

    if (isAllowedChar) {
      for (let i = 0; i < content.length; i += 1) {
        if (!isAllowedChar(content[i])) {
          content = content.slice(0, i) + content.slice(i + 1);
          i -= 1;
        }
      }
    }

    setContent(content);
    onContentUpdate(content);
  }

  return (
    <input
      ref={inputRef}
      placeholder={placeholder}
      type="text"
      value={content}
      className={className}
      onChange={onTextChange}
      onBlur={onBlur}
      form={form}
    />
  );
}

export default React.memo(TextInput);
