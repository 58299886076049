import { PayloadAction } from '@reduxjs/toolkit';

import { SpreadGroundImage } from 'editor/src/store/design/types';
import { MockupState, MockupVariant, MockupSize, MockupCategory, SceneCategory } from 'editor/src/store/mockup/types';
import { ProductVariation, VariationProductControl } from 'editor/src/store/variants/types';

const setMockDataReducer = (
  state: MockupState,
  action: PayloadAction<{
    scenesCategories: SceneCategory[];
    sizes: MockupSize[];
    categories: MockupCategory[];
    variants: Array<{
      variant: MockupVariant;
      foregrounds?: SpreadGroundImage[];
      backgrounds?: SpreadGroundImage[];
    }>;
    controls: VariationProductControl[];
    variations: ProductVariation[];
  }>,
) => {
  const { scenesCategories, variants, controls, variations, sizes, categories } = action.payload;
  state.scenes.categories = scenesCategories;
  state.mockup.sizes = sizes;
  state.mockup.categories = categories;

  const variantMap: { [productUid: string]: MockupVariant } = {};
  const sceneImages: {
    [productUid: string]: {
      foregrounds: SpreadGroundImage[];
      backgrounds: SpreadGroundImage[];
    };
  } = {};
  variants.forEach(({ variant, foregrounds, backgrounds }) => {
    variantMap[variant.productUid] = variant;
    if (foregrounds && backgrounds) {
      sceneImages[variant.productUid] = { foregrounds, backgrounds };
    }
  });
  state.productPlaceholder = {
    variants: variantMap,
    productImages: {},
    variantProductImages: {},
    controls,
    variations,
    singleOptions: {},
    multiOptions: {},
    activeProductUid: '',
    sceneImages,
  };
};

export default setMockDataReducer;
