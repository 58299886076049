import { fabric } from 'fabric';
import React, { useImperativeHandle, useState } from 'react';

import FabricGroup, { IGroupOptions } from 'editor/src/fabric/FabricGroup';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useControlVisibility, { ControlVisibility } from './useControlVisibility';
import useEvent, { EventHandler } from './useEvent';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends IGroupOptions {
  uuid?: number;
  children: React.ReactNode | React.ReactNodeArray;
  controlVisibility?: ControlVisibility;
  onMouseDownBefore?: EventHandler;
  onMouseMove?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onModified?: EventHandler;
}

export const GroupContext = React.createContext<fabric.Group | undefined>(undefined);

function FabricGroupComponent(props: Props, ref: React.Ref<FabricGroup>) {
  const fabricCanvas = useFabricCanvas();
  const [element] = useState(() => new FabricGroup());
  const {
    children,
    onMouseDownBefore,
    onMouseMove,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onModified,
    controlVisibility,
    ...fabricProps
  } = props;

  useObjectProps(element, fabricProps);
  useImperativeHandle(ref, () => element, [element]);
  useObjectUpdate(fabricCanvas, element);
  useControlVisibility(element, controlVisibility);

  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown:before', onMouseDownBefore);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'modified', onModified);

  return <GroupContext.Provider value={element}>{children}</GroupContext.Provider>;
}

export default React.memo(React.forwardRef(FabricGroupComponent));
