import { RootState } from 'editor/src/store/index';
import { DesignOptionGroup } from 'editor/src/store/variants/types';

const getVariationGroupsFromDesignOptions = (state: RootState) => {
  const { variationGroups, designOptionsControl } = state.variants;
  if (!designOptionsControl) {
    return undefined;
  }
  const designOptionsGroup: DesignOptionGroup[] = [];
  variationGroups.forEach((variationGroup) => {
    variationGroup.designOptions?.forEach((designOption) => {
      const optionKey = designOptionsControl
        .flatMap((externalOption) => externalOption.options || [])
        .find((innerOption) => innerOption.value === designOption.optionKey);
      if (!optionKey) {
        return;
      }
      const designOptionGroup = designOptionsGroup.find(
        (group) => group.key === optionKey.value && group.name === optionKey.title,
      );
      if (designOptionGroup) {
        designOptionGroup.value.push(variationGroup);
      } else {
        designOptionsGroup.push({
          name: optionKey.title,
          key: optionKey.value,
          value: [variationGroup],
        });
      }
    });
  });
  return designOptionsGroup;
};

export default getVariationGroupsFromDesignOptions;
