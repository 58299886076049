import { PayloadAction } from '@reduxjs/toolkit';

import { DraftState } from '../slice';

const deleteDraftReducer = (state: DraftState, action: PayloadAction<string>) => {
  const index = state.drafts.findIndex((draft) => draft.designId === action.payload);
  if (index !== -1) {
    state.drafts.splice(index, 1);
  }
};

export default deleteDraftReducer;
