import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { updateImageAction } from 'editor/src/store/gallery/slice';
import { ImageState } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

// called when retrying a failed upload
const updateImageOnStartOperation = (uploadIds: string[]) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    const state = getState();
    uploadIds.forEach((uploadId) => {
      const image = state.gallery.images.find((image) => image.uploaderId === uploadId);
      if (!image || image.state !== ImageState.FAILED) {
        return;
      }

      dispatch(updateImageAction({ id: image.id, state: ImageState.LOCAL_PREVIEW }));
    });
  });
};

export default updateImageOnStartOperation;
