import { PayloadAction } from '@reduxjs/toolkit';

import { CanvasState } from 'editor/src/store/canvas/types';

const setCanvasSizeReducer = (state: CanvasState, action: PayloadAction<{ width: number; height: number }>) => {
  const { width, height } = action.payload;
  state.width = width;
  state.height = height;
};

export default setCanvasSizeReducer;
