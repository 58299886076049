import { captureException } from '@sentry/react';

import { CrossOrigin } from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';

import { AssetDownloadError } from './loadImageDimensions';

const RETRIES = 2;

function loadImageLoop<T extends { executor: string }>(
  src: string,
  crossOrigin: CrossOrigin,
  context: T,
  retry = RETRIES,
): Promise<HTMLImageElement> {
  return new Promise((res, rej) => {
    const img = new Image();

    function onError() {
      img.onerror = null;
      img.onload = null;
      if (retry <= 0) {
        captureException(new AssetDownloadError(src, context.executor), {
          extra: { context, src, crossOrigin },
        });
        rej();
      } else {
        window.setTimeout(
          () => {
            loadImageLoop(src, crossOrigin, context, retry - 1).then(res, rej);
          },
          (RETRIES + 1 - retry) * 1000,
        );
      }
    }

    img.onerror = onError;
    img.onload = () => {
      if (!img.naturalWidth || !img.naturalHeight) {
        onError();
      } else {
        img.onerror = null;
        img.onload = null;
        res(img);
      }
    };
    img.crossOrigin = crossOrigin || null;
    img.src = src;
  });
}

function loadImage<T extends { executor: string }>(
  src: string,
  crossOrigin: CrossOrigin,
  context: T,
): Promise<HTMLImageElement> {
  return loadImageLoop(src, crossOrigin, context);
}

export default loadImage;
