import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import removeImageOperation from 'editor/src/store/gallery/operation/removeImageOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import isImageManipulationPlugin from 'editor/src/util/plugins/isImageManipulationPlugin';

const removeImageManipulationBeforeImageRemoveOperation =
  (imageId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const state = getState();
      const imageManipulationPlugins = state.plugins.list.filter(isImageManipulationPlugin);
      state.design.designData?.spreads.forEach((spread) =>
        spread.pages[0].groups.media?.forEach((media) => {
          if (media.type !== 'image' || media.role === 'placeholder') {
            return;
          }

          if (imageManipulationPlugins.some(() => media.sourceAssetId === imageId)) {
            dispatch(removeImageOperation(media.imageId, true));
          }
        }),
      );
    });
  };

export default removeImageManipulationBeforeImageRemoveOperation;
