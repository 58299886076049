class ResourceLoader {
  resources: Promise<void>[];

  constructor() {
    this.resources = [];
  }

  addResource(resourcePromiseCb: () => Promise<any>, timeout?: number) {
    let resolve: () => void = () => {};
    const returnedPromise = new Promise<void>((res) => {
      resolve = res;
    });

    let handle = -1;
    if (timeout !== undefined) {
      // if a timeout is set, resolve the promise after the timeout
      handle = window.setTimeout(resolve, timeout);
    }

    void resourcePromiseCb()
      .then(resolve) // once the ressource is loaded, resolve the promise
      .catch(resolve)
      .finally(() => {
        window.clearTimeout(handle);
      });

    this.resources.push(returnedPromise);
  }

  waitForResources() {
    return Promise.all(this.resources).then(() => {});
  }
}

export default ResourceLoader;
