import { PayloadAction } from '@reduxjs/toolkit';

import { Draft, DraftState } from '../slice';

const updateDraftReducer = (state: DraftState, action: PayloadAction<{ designId: string; draft: Partial<Draft> }>) => {
  const draft = state.drafts.find((draft) => draft.designId === action.payload.designId);
  if (draft) {
    Object.assign(draft, action.payload.draft);
  }
};

export default updateDraftReducer;
