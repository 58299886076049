import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import numberToOffset from 'editor/src/util/layouts/numberToOffset';
import numberToPadding from 'editor/src/util/layouts/numberToPadding';

const defineLayoutSchema = (
  name: string,
  cols: number,
  rows: number,
  shiftSteps: number,
  spacing: number,
  padding: number,
  offset: number,
  frameRows: LayoutSchema['frameRows'],
  useBleed?: boolean,
  tags?: string[],
): LayoutSchema => ({
  name,
  tags: tags ?? ['*', 'cover'],
  params: {
    cols,
    rows,
    shiftSteps,
    spacing: numberToOffset(spacing),
    padding: numberToPadding(padding),
    offset: numberToPadding(offset),
    useBleed: useBleed ?? false,
  },
  frameRows,
});

export default defineLayoutSchema;
