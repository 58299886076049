import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import { SetDigitizedAssetData } from 'editor/src/util/postMessages/messages';

const addDigitizedAssetReducer = (state: DesignState, action: PayloadAction<SetDigitizedAssetData>) => {
  const { elementId, ...rest } = action.payload;
  state.digitizedAssets[elementId] = rest;
};

export default addDigitizedAssetReducer;
