import { PayloadAction } from '@reduxjs/toolkit';

import { SidebarState } from 'editor/src/store/editorModules/sidebar/types';

const setIsTextPersonalizationPromotionBannerDismissedReducer = (
  state: SidebarState,
  action: PayloadAction<boolean>,
) => {
  state.isTextPersonalizationPromotionBannerDismissed = action.payload;
};

export default setIsTextPersonalizationPromotionBannerDismissedReducer;
