import React from 'react';

export interface MaterialConfig {
  'material-name': string;
  'texture-width': number;
  'texture-height': number;
}

interface Viewer {
  exposure: number;
  'shadow-intensity': number;
  'shadow-softness': number;
  'camera-orbit': string;
}

interface GLBConfig {
  render_type: 'glb';
  assets: {
    background: string;
    model: string;
    uv: string;
  };
  config: {
    scale: number;
    'content-area': [number, number, number, number];
    'model-material': MaterialConfig;
    'model-viewer': Viewer;
    texture: {
      width: number;
      height: number;
    };
  };
  dimensions: { width: number; height: number };
}

export interface PreviewConfigList {
  scenes: Array<GLBConfig>;
}

export interface PreviewConfig {
  modelUrl: string;
  material: MaterialConfig;
  contentArea: [number, number, number, number];
  viewer: Viewer;
}

export interface PreviewAssetAPI {
  fetchAssets(productUid: string): Promise<PreviewConfigList>;
}

export const PreviewAssetAPIContext = React.createContext<PreviewAssetAPI>(null as any);
