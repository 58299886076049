import { PayloadAction } from '@reduxjs/toolkit';

import { SidebarState } from 'editor/src/store/editorModules/sidebar/types';

const setIsImagePersonalizationPromotionBannerDismissedReducer = (
  state: SidebarState,
  action: PayloadAction<boolean>,
) => {
  state.isImagePersonalizationPromotionBannerDismissed = action.payload;
};

export default setIsImagePersonalizationPromotionBannerDismissedReducer;
