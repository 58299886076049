import CMYK from './cmykUtils';
import HSVA from './hsvUtils';
import RGBA, { EMPTY_RGBA } from './rgbaUtils';

export function parseColorToCMYK(color: string): CMYK {
  const cmyk = CMYK.fromString(color);
  if (cmyk) {
    return cmyk;
  }

  const hsv = HSVA.fromString(color);
  if (hsv) {
    const rgb = hsv.toRGB();
    return CMYK.fromRGB(rgb);
  }

  const rgbaColor = RGBA.fromString(color) ?? EMPTY_RGBA;
  return CMYK.fromRGB(rgbaColor);
}

export function parseColorToHSVA(color: string): HSVA {
  const cmyk = CMYK.fromString(color);
  if (cmyk) {
    const rgb = cmyk.toRGB();
    return HSVA.fromRGB(rgb);
  }

  const hsv = HSVA.fromString(color);
  if (hsv) {
    return hsv;
  }

  const rgbaColor = RGBA.fromString(color) ?? EMPTY_RGBA;
  return HSVA.fromRGB(rgbaColor);
}

export function parseColorToRGBA(color: string): RGBA {
  const cmyk = CMYK.fromString(color);
  if (cmyk) {
    return cmyk.toRGB();
  }

  const hsv = HSVA.fromString(color);
  if (hsv) {
    return hsv.toRGB();
  }

  return RGBA.fromString(color) ?? EMPTY_RGBA;
}

export function getBrowserFriendlyColor(color: string): string {
  return (
    RGBA.fromString(color)?.toString() ??
    HSVA.fromString(color)?.toRGB().toString() ??
    CMYK.fromString(color)?.toRGB().toString() ??
    color
  );
}
