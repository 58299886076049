import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const updateMediaImageUrlReducer = (
  state: DesignState,
  action: PayloadAction<{ imageId: string; imageUrl: string }>,
) => {
  const { imageId, imageUrl } = action.payload;

  state.designData?.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((media) => {
      if (media.type === 'image' && media.imageId === imageId) {
        media.url = imageUrl;
      }
    });
  });
};

export default updateMediaImageUrlReducer;
