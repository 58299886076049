import getFirstEmptyImageFrameOnPage from 'editor/src/store/editor/selector/getFirstEmptyImageFrameOnPage';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import removeAllSelectedMediaElementsOperation from './removeAllSelectedMediaElementsOperation';

const selectFirstEmptyImageFrameOperation =
  (spreadIndex: number, pageIndex: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const nextElementUuid = getFirstEmptyImageFrameOnPage(state, spreadIndex, pageIndex);
    if (!nextElementUuid) {
      return;
    }
    dispatch(removeAllSelectedMediaElementsOperation());
    dispatch(setSelectedElementUuidsAction([nextElementUuid]));
  };

export default selectFirstEmptyImageFrameOperation;
