import classNames from 'classnames';
import React from 'react';

import { Condition } from 'editor/src/store/design/types';

import ConditionOptionButton from './ConditionOptionButton';

import styles from './index.module.scss';

interface Props {
  condition: Condition;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptionsButton({ condition, onSelectOption }: Props) {
  return (
    <div className={classNames(styles.ConditionOptionsButton, 'g-selector-buttons')}>
      {condition.options.map((option) => (
        <ConditionOptionButton
          key={option.id}
          conditionId={condition.id}
          option={option}
          isActive={condition.activeOptionId === option.id}
          onSelectOption={onSelectOption}
        />
      ))}
    </div>
  );
}

export default React.memo(ConditionOptionsButton);
