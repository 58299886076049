import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

const enableGroupLinkingReducer = (
  state: VariantsState,
  action: PayloadAction<{ groupKey: string; enabled: boolean }>,
) => {
  const { groupKey, enabled } = action.payload;
  const group = state.variationGroups.find((g) => g.key === groupKey);
  if (group) {
    group.linkingDisabled = !enabled;
  }
};

export default enableGroupLinkingReducer;
