import {
  ExistingVariant,
  ExternalOptions,
  ExternalProductControl,
  ProductControlSize,
} from 'editor/src/store/variants/types';

import getSizeOptionValue from './getSizeOptionValue';

function getInitialExternalOptions(externalControls: ExternalProductControl[], existingVariants: ExistingVariant[]) {
  const externalOptions: ExternalOptions = {};

  externalControls.forEach((control) => {
    externalOptions[control.key] = [];
  });

  existingVariants.forEach((variant) => {
    const dimensions = variant.designData?.related_dimensions || variant.designData?.dimensions;
    if (!dimensions) {
      return;
    }

    const control = externalControls.find((control) => control.key === 'product-size') as ProductControlSize;
    const option = control?.options.find(
      (option) => option.width === dimensions?.width && option.height === dimensions.height,
    );
    const newOption = option || {
      width: dimensions.width,
      height: dimensions.height,
      value: getSizeOptionValue(dimensions.width, dimensions.height),
    };
    if (!option) {
      control?.options.push(newOption);
    }

    if (
      !externalOptions['product-size'].some(
        (selectedOption) => selectedOption.width === dimensions.width && selectedOption.height === dimensions.height,
      )
    ) {
      externalOptions['product-size'].push(newOption);
    }
  });

  return externalOptions;
}

export default getInitialExternalOptions;
