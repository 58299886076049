import { PayloadAction } from '@reduxjs/toolkit';

import { Warning, WarningsState } from 'editor/src/store/editorModules/warnings/types';

const setVariantWarningsReducer = (
  state: WarningsState,
  { payload }: PayloadAction<{ productId: string; warnings: Warning[] }>,
) => {
  state.variantWarnings[payload.productId] = payload.warnings;
};

export default setVariantWarningsReducer;
