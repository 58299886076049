import { DesignData } from 'editor/src/store/design/types';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import { ResizableElement } from 'editor/src/store/variants/types';

import { RootState } from 'editor/src/store';
import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';
import getFullSizeImagePosition from 'editor/src/util/resizableElement/getFullSizeImagePosition';
import getResizableImageLabelText from 'editor/src/util/resizableElement/getResizableImageLabelText';

import type { i18n } from 'i18next';

const applyResizableProductToDesign = (
  state: RootState,
  designData: DesignData,
  resizableElement: ResizableElement,
  layoutSchema: LayoutSchema,
  i18n: i18n,
) => {
  const { media } = designData.spreads[0]?.pages[0].groups || {};
  const dimensions = designData?.related_dimensions;
  const { fullSizeFrameId, fullSizeFrameLabelId, scaledFrameId, scaledFrameLabelId } = resizableElement;

  const fullElementIdx =
    media?.findIndex((element) => element.type === 'image' && element.layoutFrameId === fullSizeFrameId) || -1;
  const scaledElementIdx =
    media?.findIndex((element) => element.type === 'image' && element.layoutFrameId === scaledFrameId) || -1;

  if (fullElementIdx === -1 || scaledElementIdx === -1 || !media) {
    return designData;
  }

  const fullElement = media[fullElementIdx];
  const scaledElement = media[scaledElementIdx];
  if (scaledElement?.type !== 'image' || fullElement?.type !== 'image' || !dimensions) {
    return designData;
  }

  if (!designData.spreads[0].pages[0].groups.media) {
    return designData;
  }

  const { imageId } = fullElement;
  const galleryImage = imageId ? state.gallery.images.find((image) => image.id === imageId) : undefined;
  designData.spreads[0].pages[0].groups.media[fullElementIdx] = {
    ...fullElement,
    ...getFullSizeImagePosition(fullElement, dimensions, galleryImage),
  };

  const scaledElementImagePosition = galleryImage
    ? getCenteredImagePosition({ ...scaledElement, minDimensions: dimensions }, galleryImage)
    : {};
  designData.spreads[0].pages[0].groups.media[scaledElementIdx] = {
    ...scaledElement,
    ...scaledElementImagePosition,
    minDimensions: dimensions,
  };

  const fullElementLabelIdx = fullSizeFrameLabelId
    ? media?.findIndex((el) => el.type === 'text' && el.layoutFrameId === fullSizeFrameLabelId)
    : -1;
  const scaledElementLabelIdx = scaledFrameLabelId
    ? media?.findIndex((el) => el.type === 'text' && el.layoutFrameId === scaledFrameLabelId)
    : -1;

  const unit = getSettingsValue(state, SettingsProperty.units);
  if (fullElementLabelIdx !== -1) {
    const labelElement = media[fullElementLabelIdx];
    if (labelElement.type === 'text') {
      const updatedLabelText = getResizableImageLabelText(labelElement, layoutSchema, dimensions, unit, i18n.t);
      if (updatedLabelText) {
        labelElement.extra.text = updatedLabelText;
      }
    }
  }

  if (scaledElementLabelIdx !== -1) {
    const labelElement = media[scaledElementLabelIdx];
    if (labelElement.type === 'text') {
      const updatedLabelText = getResizableImageLabelText(labelElement, layoutSchema, scaledElement, unit, i18n.t);
      if (updatedLabelText) {
        labelElement.extra.text = updatedLabelText;
      }
    }
  }

  return designData;
};

export default applyResizableProductToDesign;
