import { ProductControlSize, ProductSizeUnit } from 'editor/src/store/variants/types';

import { convertUnitToMm } from 'editor/src/util/math';

function updateDefaultSizeMeasurementValues(sizeControl: ProductControlSize) {
  const { defaultSizeCalculationParameters } = sizeControl;
  if (defaultSizeCalculationParameters) {
    (Object.keys(defaultSizeCalculationParameters) as ProductSizeUnit[]).forEach((unit) => {
      const dimensionParameters = defaultSizeCalculationParameters[unit];
      dimensionParameters.dimensionMax = convertUnitToMm(dimensionParameters.dimensionMax, unit);
      dimensionParameters.dimensionIncrement = convertUnitToMm(dimensionParameters.dimensionIncrement, unit);
      dimensionParameters.otherDimensionValues.forEach((otherdimensionParameters, index) => {
        dimensionParameters.otherDimensionValues[index] = convertUnitToMm(otherdimensionParameters, unit);
      });
    });
  }
}

export default updateDefaultSizeMeasurementValues;
