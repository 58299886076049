import { Coords, MediaLine } from 'editor/src/store/design/types';

import getLineWidth, { getLineAngle, getLineCapOffset } from './utils';

const getLineRect = (lineData: MediaLine, pageCoords: Coords, mm2px: (size: number) => number) => {
  const angle = getLineAngle(lineData.x1, lineData.x2, lineData.y1, lineData.y2);
  const strokeWidth = mm2px(lineData.strokeWidth);
  const x1 = pageCoords.left + mm2px(lineData.x1);
  const y1 = pageCoords.top + mm2px(lineData.y1);
  const x2 = pageCoords.left + mm2px(lineData.x2);
  const y2 = pageCoords.top + mm2px(lineData.y2);
  const lineWidth = getLineWidth(x1, x2, y1, y2);
  const lineCapOffset = getLineCapOffset(lineData.rounded, strokeWidth, angle);
  return {
    left: x1 - lineCapOffset.x,
    top: y1 - lineCapOffset.y,
    width: lineWidth + (lineData.rounded ? strokeWidth : 0),
    height: strokeWidth,
    angle,
  };
};

export default getLineRect;
