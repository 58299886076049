import { toastr, BasicToastrOptions } from 'react-redux-toastr';

const toastController = {
  success: (title: string, message: string) => toastr.success(title, message),
  warning: (title: string, message: string, options?: BasicToastrOptions) => toastr.warning(title, message, options),
  error: (title: string, message: string) => toastr.error(title, message),
  info: (title: string, message: string) => toastr.info(title, message),
  successPersist: (title: string, message: string) => toastr.success(title, message, { timeOut: 0 }),
  warningPersist: (title: string, message: string) => toastr.warning(title, message, { timeOut: 0 }),
  errorPersist: (title: string, message: string) => toastr.error(title, message, { timeOut: 0 }),
  infoPersist: (title: string, message: string) => toastr.info(title, message, { timeOut: 0 }),
};

export default toastController;
