/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconBrightness({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0.999939L10 1.99994" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 9.99994L2 9.99994" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.63623 16.3639L4.34334 15.6568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.3643 16.3637L15.6572 15.6566" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 17.9999L10 18.9999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 9.99994L19 9.99994" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.6567 4.34308L16.3638 3.63597" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.34326 4.3432L3.63615 3.63609" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="10" cy="9.99994" r="5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconBrightness;
