import { DropShadow, LeaningShadow } from 'editor/src/store/design/types';

import isShadowEmpty from 'editor/src/util/isShadowEmpty';

function getImageShadow(
  shadow: DropShadow | LeaningShadow | undefined,
  mm2px: (size: number) => number,
): DropShadow | undefined {
  if (shadow?.type === 'leaning-shadow') {
    return undefined;
  }
  if (!shadow || isShadowEmpty(shadow)) {
    return undefined;
  }

  return {
    type: 'drop-shadow',
    color: shadow.color,
    offsetX: mm2px(shadow.offsetX),
    offsetY: mm2px(shadow.offsetY),
    blur: shadow.blur,
    scale: shadow.scale || 1,
  };
}

export default getImageShadow;
