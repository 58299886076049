import { setAvailableFontsAction } from 'editor/src/store/fonts/slice';

import store from 'product-personalizer/src/store';

const loadFonts = async () => {
  const fontModule = await import('@gelatoas/design-editor-fonts');
  const fontMetadataToFontDefinition = await import('editor/src/store/fonts/utils/fontMetadataToFontDefinition');
  store.dispatch(setAvailableFontsAction(fontModule.fontList.map(fontMetadataToFontDefinition.default)));
};

export default loadFonts;
