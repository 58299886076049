import React, { useContext, useEffect, useState } from 'react';

import setCustomerDeviceTypeOperation from 'editor/src/store/app/operation/setCustomerDeviceTypeOperation';
import { DeviceType } from 'editor/src/store/app/types';

import noContextMenuForMobile from 'editor/src/util/dnd/noContextMenuForMobile';
import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';
import globalVars from 'editor/src/util/globalVars';

import { useDispatch } from '../store/hooks';

export const DeviceTypeContext = React.createContext<DeviceType>(DeviceType.Desktop);

export const mobileDevices = new Set([DeviceType.Mobile, DeviceType.Tablet]);
export function useIsMobile() {
  return mobileDevices.has(useContext(DeviceTypeContext));
}

function useDetectDeviceType() {
  const dispatch = useDispatch();
  const [deviceType, setDeviceType] = useState(getCustomerDeviceType());

  useEffect(() => {
    function onResize() {
      setDeviceType(getCustomerDeviceType());
    }

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    // Chrome (Mobile): Prevent showing context menu on long taps on images
    noContextMenuForMobile(globalVars.isTouchEnabled);
    dispatch(setCustomerDeviceTypeOperation(deviceType));
  }, [deviceType]);

  return deviceType;
}

export default useDetectDeviceType;
