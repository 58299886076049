/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconContrast({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="9.99994" r="9" strokeWidth="2" strokeLinecap="round" fill="none" strokeLinejoin="round" />
        <path d="M10 18.9999C14.9706 18.9999 19 14.9705 19 9.99994C19 5.02938 14.9706 0.999939 10 0.999939V18.9999Z" />
      </svg>
    </Icon>
  );
}

export default IconContrast;
