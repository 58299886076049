function createUuidGenerator() {
  function* getUuid(): Generator<number> {
    let uuid = 0;
    let setCounterValue = 0;
    while (true) {
      uuid += 1;
      setCounterValue = (yield uuid) as number;
      if (setCounterValue) {
        uuid = setCounterValue;
      }
    }
  }

  return getUuid();
}

class UuidGenerator<T = number> {
  private generator: Generator<number, any, unknown>;
  private current: number = 0;

  constructor() {
    this.generator = createUuidGenerator();
  }

  protected getNext(): T {
    const { value } = this.generator.next();
    this.current = value;
    return value;
  }

  getCurrent() {
    return this.current;
  }

  generate(): T {
    return this.getNext();
  }

  setup(initialValue: number) {
    this.current = initialValue;
    this.generator.next().value && this.generator.next(initialValue - 1);
  }
}

export default UuidGenerator;
