import { designs } from '@gelatoas/design-editor-calendar';

import { MediaGridInfo } from 'editor/src/store/design/types';

import getSpreadDate from 'editor/src/util/getSpreadDate';

import type { i18n } from 'i18next';

export const DEFAULT_FIRST_DAY_OF_WEEK = 1; // 0 sunday, 1 monday

function getGridDefaults(i18n: i18n, spreadName: string): MediaGridInfo {
  return {
    gridDate: getSpreadDate(spreadName, undefined),
    locale: i18n.languageWithDash,
    firstDayOfWeek: DEFAULT_FIRST_DAY_OF_WEEK,
    designName: designs.defaultGridDesign.name,
  };
}

export default getGridDefaults;
