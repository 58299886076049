import { FontDefinition } from 'editor/src/store/fonts/types';

function findMatchingFont(
  fonts: FontDefinition[],
  family: string,
  weight: string | undefined,
  style: string | undefined,
) {
  return fonts.find((font) => {
    const lowerFamily = family.toLowerCase();
    const familyMatch =
      font.metadata.fontFile.toLocaleLowerCase() === lowerFamily ||
      font.metadata.family.toLocaleLowerCase() === lowerFamily;
    const styleMatch =
      ((!weight && !font.metadata.weight) || font.metadata.weight?.toLowerCase() === weight) &&
      ((!style && !font.metadata.style) || font.metadata.style?.toLowerCase() === style);
    return familyMatch && styleMatch;
  });
}

export default findMatchingFont;
