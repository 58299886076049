import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, ConditionDisplayOption, DesignState } from 'editor/src/store/design/types';

const updateConditionDisplayOptionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    displayOption: ConditionDisplayOption;
  }>,
) => {
  const { address, displayOption } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  condition.displayOption = displayOption;
};

export default updateConditionDisplayOptionReducer;
