import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

function getGroupRectangleRect(objectRectPx: ObjectRect, strokeWidthPx: number): ObjectRect {
  return {
    top: -(objectRectPx.height + strokeWidthPx) / 2,
    left: -(objectRectPx.width + strokeWidthPx) / 2,
    width: objectRectPx.width,
    height: objectRectPx.height,
    angle: 0,
  };
}

export default getGroupRectangleRect;
