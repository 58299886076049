import { RootState } from 'editor/src/store/index';

const getMediaElementByUuid = (state: RootState, uuid: number) => {
  if (!state.design.designData) {
    return undefined;
  }

  const { spreads } = state.design.designData;

  for (let spreadIndex = 0; spreadIndex < spreads.length; spreadIndex += 1) {
    const { pages } = spreads[spreadIndex];

    for (let pageIndex = 0; pageIndex < pages.length; pageIndex += 1) {
      const { media } = pages[pageIndex].groups;

      if (!media) {
        continue;
      }
      for (let elementIndex = 0; elementIndex < media.length; elementIndex += 1) {
        const element = media[elementIndex];

        if (element.uuid === uuid) {
          return element;
        }
      }
    }
  }

  return undefined;
};

export default getMediaElementByUuid;
