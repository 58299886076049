import { RootState } from 'editor/src/store/index';
import { ResizableElement } from 'editor/src/store/variants/types';

const getResizableProductForGivenVariant = (
  state: RootState,
  customProductId?: string,
): ResizableElement | undefined => {
  const productId = customProductId || state.design.designData?.product_uid;
  const { resizableElementPerProductUids } = state.variants;
  return productId ? resizableElementPerProductUids && resizableElementPerProductUids[productId] : undefined;
};

export default getResizableProductForGivenVariant;
