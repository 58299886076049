import { addImageToSessionAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const addImageToSessionOperation = (id: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  dispatch(addImageToSessionAction(id));
  if (state.gallery.sessionImages.length === 0) {
    sendPostMessage('uploader.uploadStarted', undefined);
  }
};

export default addImageToSessionOperation;
