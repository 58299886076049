import { DesignState, StructureIndex } from 'editor/src/store/design/types';

const getImageStructureIndexes = (state: DesignState, imageId: string) => {
  const structureIndexes: StructureIndex[] = [];
  state.designData?.spreads.forEach((spread, spreadIndex) => {
    spread.pages[0].groups.media?.forEach((media, elementIndex) => {
      if (media.type === 'image' && media.imageId === imageId) {
        structureIndexes.push({
          spreadIndex,
          pageIndex: 0,
          elementIndex,
          uuid: media.uuid,
        });
      }
    });
  });

  return structureIndexes;
};

export default getImageStructureIndexes;
