import { setActiveTopMenuDropdownAction } from 'editor/src/store/app/slice';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import selectPreviousTabOperation from 'editor/src/store/editorModules/sidebar/operation/selectPreviousTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { isSystemTab } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const setActiveTopMenuDropdownOperation =
  (activeTopMenuDropdown?: TopMenuDropdown, selectPreviousTab = true) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    dispatch(setActiveTopMenuDropdownAction(activeTopMenuDropdown));

    if (!activeTopMenuDropdown) {
      const state = getState();
      const activeTab = getSidebarActiveTab(state);
      if (selectPreviousTab && activeTab && isSystemTab(activeTab)) {
        dispatch(selectPreviousTabOperation());
      }
    }
  };

export default setActiveTopMenuDropdownOperation;
