import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const setPersonalizationStudioBannerDataReducer = (
  state: EditorState,
  action: PayloadAction<{ productExamplesLink: string }>,
) => {
  state.personalizationStudioBannerData = action.payload;
};

export default setPersonalizationStudioBannerDataReducer;
