/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconZoomIn({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M31.5 33.8333L22.1667 24.5C21.3333 25.1667 20.375 25.6944 19.2917 26.0833C18.2083 26.4722 17.0556 26.6667 15.8333 26.6667C12.8056 26.6667 10.2433 25.6183 8.14667 23.5217C6.04889 21.4239 5 18.8611 5 15.8333C5 12.8056 6.04889 10.2428 8.14667 8.145C10.2433 6.04833 12.8056 5 15.8333 5C18.8611 5 21.4239 6.04833 23.5217 8.145C25.6183 10.2428 26.6667 12.8056 26.6667 15.8333C26.6667 17.0556 26.4722 18.2083 26.0833 19.2917C25.6944 20.375 25.1667 21.3333 24.5 22.1667L33.875 31.5417C34.1806 31.8472 34.3267 32.2289 34.3133 32.6867C34.2989 33.1456 34.1389 33.5278 33.8333 33.8333C33.5278 34.1389 33.1389 34.2917 32.6667 34.2917C32.1944 34.2917 31.8056 34.1389 31.5 33.8333ZM15.8333 23.3333C17.9167 23.3333 19.6878 22.6044 21.1467 21.1467C22.6044 19.6878 23.3333 17.9167 23.3333 15.8333C23.3333 13.75 22.6044 11.9789 21.1467 10.52C19.6878 9.06222 17.9167 8.33333 15.8333 8.33333C13.75 8.33333 11.9789 9.06222 10.52 10.52C9.06222 11.9789 8.33333 13.75 8.33333 15.8333C8.33333 17.9167 9.06222 19.6878 10.52 21.1467C11.9789 22.6044 13.75 23.3333 15.8333 23.3333ZM15.8333 20.8333C15.3611 20.8333 14.9656 20.6733 14.6467 20.3533C14.3267 20.0344 14.1667 19.6389 14.1667 19.1667V17.5H12.4583C11.9861 17.5 11.5972 17.34 11.2917 17.02C10.9861 16.7011 10.8333 16.3056 10.8333 15.8333C10.8333 15.3611 10.9928 14.965 11.3117 14.645C11.6317 14.3261 12.0278 14.1667 12.5 14.1667H14.1667V12.4583C14.1667 11.9861 14.3267 11.5972 14.6467 11.2917C14.9656 10.9861 15.3611 10.8333 15.8333 10.8333C16.3056 10.8333 16.7017 10.9928 17.0217 11.3117C17.3406 11.6317 17.5 12.0278 17.5 12.5V14.1667H19.2083C19.6806 14.1667 20.0694 14.3261 20.375 14.645C20.6806 14.965 20.8333 15.3611 20.8333 15.8333C20.8333 16.3056 20.6739 16.7011 20.355 17.02C20.035 17.34 19.6389 17.5 19.1667 17.5H17.5V19.2083C17.5 19.6806 17.3406 20.0694 17.0217 20.375C16.7017 20.6806 16.3056 20.8333 15.8333 20.8333Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconZoomIn;
