import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const updateHiddenElementsReducer = (
  state: DesignState,
  action: PayloadAction<{ spreadIndex: number; hiddenElementIds: number[] }>,
) => {
  const { spreadIndex, hiddenElementIds } = action.payload;
  const spread = state.designData?.spreads[spreadIndex];

  spread?.pages.forEach((page) => {
    page.groups.media?.forEach((element) => {
      element.hidden = hiddenElementIds.includes(element.uuid) || undefined;
    });
  });
};

export default updateHiddenElementsReducer;
