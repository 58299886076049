import cloneDeep from 'lodash/cloneDeep';

import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Spread, SpreadGroup } from 'editor/src/store/design/types';
import { LayoutFrame, LayoutGroup } from 'editor/src/store/editorModules/layouts/types';

import getLayoutBBox from 'editor/src/util/design/getLayoutBBox';

import generateLayout from './generateLayout';
import schemas from './layoutSchemas/defaultSchemas';

const generateLayoutFramesForGroupedSpread = (
  spread: Spread,
  spreads: Spread[],
  spreadGroup: SpreadGroup,
  groupLayout: LayoutGroup,
) => {
  const layoutFrames: LayoutFrame[] = [];
  const groupIndexes = spreadGroup.spreadIndexes;
  for (let i = 0; i < groupIndexes.length; i += 1) {
    const layoutSchema = schemas.find((schema) => schema.name === groupLayout.layouts[i]) ?? schemas[0];
    const spreadClone = cloneDeep(spread);
    if (i !== 0) {
      const previousSpread = spreads[groupIndexes[i - 1]];
      const previousSpreadHeight = spreadGroup.position === 'vertical' ? getSpreadHeightFromSpread(previousSpread) : 0;
      const previousSpreadWidth = spreadGroup.position === 'horizontal' ? getSpreadWidthFromSpread(previousSpread) : 0;
      spreadClone.pages = spreadClone.pages.map((page) => {
        page.y -= previousSpreadHeight;
        page.x -= previousSpreadWidth;
        return page;
      });
    }
    const bbox = spread ? getLayoutBBox(layoutSchema, spreadClone) : undefined;
    if (!bbox) {
      return [];
    }

    layoutFrames.push(...generateLayout(layoutSchema, bbox.left, bbox.top, bbox.width, bbox.height));
  }
  return layoutFrames;
};

export default generateLayoutFramesForGroupedSpread;
