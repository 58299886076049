import isElementACollection from 'editor/src/store/design/selector/isElementACollection';
import { IMAGE_CORRUPTED, MediaImage } from 'editor/src/store/design/types';
import { PluginName } from 'editor/src/store/plugins/types';

const canImageBeEmptied = (element: MediaImage) =>
  element.imageId !== IMAGE_CORRUPTED &&
  element.imageId !== '' &&
  !isElementACollection(element) &&
  !element.plugins?.[PluginName.Graphics];

export default canImageBeEmptied;
