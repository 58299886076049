import { PayloadAction } from '@reduxjs/toolkit';

import { SpreadGroup } from 'editor/src/store/design/types';
import { VariantsState } from 'editor/src/store/variants/types';

export interface ProductDataPayload {
  product: {
    previewUrls: string[];
    title: string;
    description: string;
  };
  layoutPerProductUids?: { [productUid: string]: string };
  groupedSpreadsPerProductUids?: { [productUid: string]: SpreadGroup[] };
}

const setSingleVariantProductDataReducer = (state: VariantsState, action: PayloadAction<ProductDataPayload>) => {
  const { product, layoutPerProductUids, groupedSpreadsPerProductUids } = action.payload;
  state.product.title = product.title;
  state.product.description = product.description;
  state.product.previewUrls = product.previewUrls;
  state.layoutPerProductUids = layoutPerProductUids ?? {};
  state.groupedSpreadsPerProductUids = groupedSpreadsPerProductUids ?? {};
};

export default setSingleVariantProductDataReducer;
