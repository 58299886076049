import { fabric } from 'fabric';
import React from 'react';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';
import { getRotatedCropPosition, getUnrotatedCropPosition } from 'editor/src/util/reflectDesignData/updateImageElement';
import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

const updateOnImageGhostChange = (
  frameRect: ObjectRect,
  imageGhost: React.RefObject<fabric.Image>,
  event: fabric.IEvent,
) => {
  if (!imageGhost.current) {
    return;
  }
  const action = (event.transform as any)?.action;
  const {
    left: frameLeft = 0,
    top: frameTop = 0,
    angle: frameAngle = 0,
    width: frameWidth,
    height: frameHeight,
  } = frameRect;
  const {
    angle: ghostAngle = 0,
    left: ghostLeft = 0,
    top: ghostTop = 0,
    width: ghostWidth = 0,
    height: ghostHeight = 0,
  } = imageGhost.current;
  const pr = ghostAngle - frameAngle;
  const bbox = getRectangleBoundingBox(0, 0, frameWidth, frameHeight, pr);

  // UPDATE DIMENSIONS
  let pw;
  let ph;
  if (action === 'scale' || action === 'rotate') {
    // pw/ph might be updated
    pw = Math.max(bbox.width, imageGhost.current.getScaledWidth());
    ph = Math.max(bbox.height, imageGhost.current.getScaledHeight());

    pw = (ph * ghostWidth) / ghostHeight;
    if (pw < bbox.width) {
      ph = (bbox.width * ghostHeight) / ghostWidth;
    }
  } else {
    // just take the current value of the image
    pw = imageGhost.current.getScaledWidth();
    ph = imageGhost.current.getScaledHeight();
  }

  // make sure the new with/height are within the constraint of the frame
  if (pw < bbox.width) {
    pw = bbox.width;
    ph = pw * (ghostHeight / ghostWidth);
  } else if (ph < bbox.height) {
    ph = bbox.height;
    pw = ph * (ghostWidth / ghostHeight);
  }

  // UPDATE POSITION
  const ghostCoords = getPointPositionRotatedOnPoint(ghostLeft - frameLeft, ghostTop - frameTop, 0, 0, -frameAngle);
  const cropPosWORotation = getUnrotatedCropPosition(
    ghostCoords[0],
    ghostCoords[1],
    bbox,
    { width: frameWidth, height: frameHeight },
    pr,
  );

  // make sure the new px/py are within the constraint of the frame
  const pxBoxed = Math.min(0, Math.max(bbox.width - pw, cropPosWORotation[0]));
  const pyBoxed = Math.min(0, Math.max(bbox.height - ph, cropPosWORotation[1]));

  const cropPosWRotation = getRotatedCropPosition(
    pxBoxed,
    pyBoxed,
    bbox,
    { width: frameWidth, height: frameHeight },
    pr,
  );
  const [px, py] = getPointPositionRotatedOnPoint(cropPosWRotation[0], cropPosWRotation[1], 0, 0, frameAngle);

  imageGhost.current.set({
    left: px + frameLeft,
    top: py + frameTop,
    scaleX: pw / ghostWidth,
    scaleY: ph / ghostHeight,
  });
};

export default updateOnImageGhostChange;
