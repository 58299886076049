import { Spread, SpreadGroup } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getLayoutBBox from 'editor/src/util/design/getLayoutBBox';
import generateLayout from 'editor/src/util/layouts/generateLayout';
import generateLayoutFramesForGroupedSpread from 'editor/src/util/layouts/generateLayoutFramesForGroupedSpread';
import groupLayouts from 'editor/src/util/layouts/groupLayouts';

const getLayoutFrames = (
  spread: Spread,
  layouts: LayoutSchema[],
  spreads: Spread[],
  spreadGroup: SpreadGroup | undefined,
) => {
  const layoutName = spread.layoutSchemaName;
  if (!layoutName) {
    return undefined;
  }

  if (spreadGroup) {
    const groupLayout = groupLayouts.find((layout) => layout.name === layoutName);
    if (groupLayout) {
      return generateLayoutFramesForGroupedSpread(spread, spreads, spreadGroup, groupLayout);
    }
  }

  const layout = layouts.find((layout) => layout.name === layoutName);
  if (layout) {
    const bBbox = getLayoutBBox(layout, spread);
    return generateLayout(layout, bBbox.left, bBbox.top, bBbox.width, bBbox.height);
  }

  return undefined;
};

export default getLayoutFrames;
