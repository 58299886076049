import { FontStyles } from 'editor/src/store/fonts/types';

const fontStylesToTextElement = (fs: FontStyles, text = '') => ({
  fill: fs.color,
  r: fs.angle,
  extra: {
    fontFamily: fs.fontFamily,
    fontSize: fs.fontSize,
    lineHeight: fs.lineHeight,
    textAlign: fs.textAlign,
    direction: fs.direction,
    underline: undefined,
    wrapping: fs.wrapping,
    text,
  },
});

export default fontStylesToTextElement;
