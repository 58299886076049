import { OutboundPostMessage as EditorOutboundPostMessage } from 'editor/src/util/postMessages/messages';
import editorSendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { OutboundPostMessage } from './messages';

function sendPostMessage<A extends keyof OutboundPostMessage>(type: A, data: OutboundPostMessage[A]) {
  editorSendPostMessage(
    type as unknown as keyof EditorOutboundPostMessage,
    data as EditorOutboundPostMessage[keyof EditorOutboundPostMessage],
  );
}

export default sendPostMessage;
