import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData } from 'editor/src/store/design/types';
import { MockupState } from 'editor/src/store/mockup/types';

const saveSceneStructureReducer = (
  state: MockupState,
  action: PayloadAction<{ sceneId: string; structure: DesignData }>,
) => {
  const { sceneId, structure } = action.payload;
  state.scenes.structure[sceneId] = structure;
};

export default saveSceneStructureReducer;
