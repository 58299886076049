import React, { useState } from 'react';

import useBlobAsUrl from 'editor/src/util/useBlobAsUrl';

import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  url?: string;
  blob: Blob | null;
}

function ListItem({ url, blob }: Props) {
  const blobUrl = useBlobAsUrl(blob);
  const imageUrl = url || blobUrl;
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={styles.listItem}>
      <img
        src={imageUrl}
        alt=""
        draggable={false}
        onLoad={handleImageLoad}
        style={{ display: loaded ? 'block' : 'none' }}
      />
      {!loaded && <IconLoading className={styles.loader} />}
    </div>
  );
}

export default React.memo(ListItem);
