import { MediaImage, MediaText } from 'editor/src/store/design/types';
import { MimeType } from 'editor/src/store/gallery/types';

import degrees2Radians from 'editor/src/util/degrees2Radians';
import limitPrecision from 'editor/src/util/limitPrecision';

// as design data follow the SVG specification we arranged rates to make the condition
// `value * fontSize` works on preflight without additional conversions
export const CANVAS_TO_SVG_OFFSET = 2222;
export const CANVAS_TO_SVG_BLUR = 1 / 6600;

export const SHADOW_OFFSET_DEFAULT = 100;
export const SHADOW_DIRECTION_DEFAULT = -45;

const RANGE_MULTIPLIERS = {
  text: {
    offset: CANVAS_TO_SVG_OFFSET,
    blur: CANVAS_TO_SVG_BLUR,
  },
  image: {
    offset: 1,
    blur: 0.01, // divide by 100
  },
};

export const getRangeMultiplier = (elementType: string, property: 'offset' | 'blur') => {
  switch (elementType) {
    case 'text':
    case 'image':
      return RANGE_MULTIPLIERS[elementType][property];
    default:
      return 1;
  }
};

export const calculateOffsetAndDirectionFromOffsets = (offsetX: number, offsetY: number, offsetMultiplier: number) => {
  const offset = Math.sqrt(offsetX ** 2 + offsetY ** 2);
  let direction = Math.atan2(offsetY - offset, offsetX) * (360 / Math.PI);
  if (direction <= -180) {
    direction = (direction + 360) % 360;
  }

  return {
    offset: Math.round(offset * offsetMultiplier),
    direction: Math.round(direction),
  };
};

export const calculateOffsetsFromDirectionAndOffset = (
  direction: number,
  offset: number,
  offsetMultiplier: number,
) => ({
  offsetX: limitPrecision(Math.round(offset * Math.cos(degrees2Radians(direction + 90))) / offsetMultiplier),
  offsetY: limitPrecision(Math.round(offset * Math.sin(degrees2Radians(direction + 90))) / offsetMultiplier),
});

export const getElementShadow = (element: MediaImage | MediaText) => {
  switch (element.type) {
    case 'text':
      return element.extra.shadow;
    default:
      return element.shadow;
  }
};

export const isSupportedImageTypeForShadow = (mimeType: MimeType) =>
  ![MimeType.PDF, MimeType.Unknown, MimeType.SVG].includes(mimeType);
