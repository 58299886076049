// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlatPreview--ody9c{display:flex;flex-direction:column;align-items:center;grid-area:preview;overflow:hidden;user-select:none}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Preview/FlatPreview/index.module.scss"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":["@import 'editor/src/comonStyles/index';\n\n.FlatPreview {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  grid-area: preview;\n  overflow: hidden;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatPreview": `FlatPreview--ody9c`
};
export default ___CSS_LOADER_EXPORT___;
