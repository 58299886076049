import { addElementToConditionGroupAction } from 'editor/src/store/design/slice';
import { ConditionGroup, MediaElement } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import addConditionGroupOperation from './addConditionGroupOperation';

function isElementUsed(conditionGroup: ConditionGroup, elementId: number) {
  return Object.values(conditionGroup.children).some((children) =>
    children.some((element) => element.type !== 'condition' && element.id === elementId),
  );
}

const addElementToConditionGroupOperation =
  (element: MediaElement) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    let state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    let conditionGroup = state.design.designData?.spreads[spreadIndex]?.conditionGroup;

    if (!conditionGroup) {
      dispatch(addConditionGroupOperation());
      state = getState();
      conditionGroup = state.design.designData?.spreads[spreadIndex]?.conditionGroup;
    }

    // we only add it to the list if the element is not currently used in some of the conditions
    if (conditionGroup && !isElementUsed(conditionGroup, element.uuid)) {
      dispatch(addElementToConditionGroupAction({ spreadIndex, element }));
    }
  };

export default addElementToConditionGroupOperation;
