import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Temperature = createClass(filters.ColorMatrix, {
  type: 'GelatoTemperature',
  shift: 0,
  mainParameter: 'shift',

  calculateMatrix() {
    this.matrix = [1 + this.shift / 500, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1 - this.shift / 500, 0, 0, 0, 0, 0, 1, 0];
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      shift: this.shift,
    });
  },
});

Temperature.fromObject = (fabric.Image.filters.ColorMatrix as any).fromObject;
(fabric.Image.filters as any).GelatoTemperature = Temperature;

export default Temperature;
