import { Polygon } from 'polygon-clipping';
import React, { useEffect, useMemo, useState } from 'react';

import { Coords, MediaBox, MediaElement, SpotFinishingType } from 'editor/src/store/design/types';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricImageCanvasComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageCanvasComponent';
import useMirrorCanvas from 'editor/src/component/EditorArea/Spread/Mirror/useMirrorCanvas';
import applyFoil from 'editor/src/component/EditorArea/Spread/Page/foilHelper';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { polygonToFabricPolygon } from 'editor/src/component/EditorArea/utils/polygonsUtils';

import foilCopper from './foil-copper.jpg';
import foilGold from './foil-gold.jpg';
import foilSilver from './foil-silver.jpg';

interface Props {
  pageCoords: Coords;
  mediaBox: MediaBox;
  contentClippingPolygons: Polygon[];
  mediaElements: MediaElement[];
  contentClipPath: fabric.Object | undefined;
  spotFinishingType: SpotFinishingType;
}

const ratio = window.devicePixelRatio;

export const FOIL_ASSET: { [S in SpotFinishingType]: string } = {
  [SpotFinishingType.Gold]: foilGold,
  [SpotFinishingType.Silver]: foilSilver,
  [SpotFinishingType.Copper]: foilCopper,
};

function FoilElements({
  pageCoords,
  mediaBox,
  contentClippingPolygons,
  mediaElements,
  contentClipPath,
  spotFinishingType,
}: Props) {
  const fabricCanvas = useFabricCanvas();
  const uuidsForFoil = useMemo(() => {
    const uuids = new Set<number>();
    mediaElements.forEach((mediaElement) => {
      if (mediaElement.has_spot_finishing) {
        uuids.add(mediaElement.uuid);
      }
    });
    return uuids;
  }, [mediaElements]);

  const { mirrorCanvas, mediaBoxBox } = useMirrorCanvas(pageCoords, mediaBox, undefined, uuidsForFoil);
  const { mm2px } = useFabricUtils();
  const renderRatio = ratio * ratio;

  const clipPath = useMemo(
    () =>
      new fabric.Group(
        contentClippingPolygons.map((p) => polygonToFabricPolygon(p)),
        { absolutePositioned: true },
      ),
    [pageCoords, mm2px, contentClippingPolygons],
  );
  clipPath.clipPath = contentClipPath;

  const [foilImageElt, setFoilImageElt] = useState<HTMLImageElement | undefined>();
  useEffect(() => {
    const image = new window.Image();
    image.onload = () => setFoilImageElt(image);
    image.src = FOIL_ASSET[spotFinishingType];

    return () => {
      image.onload = null;
    };
  }, [spotFinishingType]);

  useEffect(() => {
    if (!foilImageElt) {
      return undefined;
    }

    function onRender() {
      if (!foilImageElt) {
        return;
      }
      const canvas = mirrorCanvas.getElement();
      applyFoil(foilImageElt, canvas);
    }

    mirrorCanvas.on('after:render', onRender);
    fabricCanvas.requestRenderAll();
    return () => {
      mirrorCanvas.off('after:render', onRender);
    };
  }, [mirrorCanvas, foilImageElt]);

  return foilImageElt ? (
    <FabricImageCanvasComponent
      sourceCanvas={mirrorCanvas}
      crossOrigin="anonymous"
      left={mediaBoxBox.x}
      top={mediaBoxBox.y}
      scaleX={1 / renderRatio}
      scaleY={1 / renderRatio}
      width={mediaBoxBox.width * renderRatio}
      height={mediaBoxBox.height * renderRatio}
      clipPath={clipPath}
      evented={false}
      zIndex={zIndex.MEDIA + mediaElements.length + 1}
      objectCaching={false}
    />
  ) : null;
}

export default React.memo(FoilElements);
