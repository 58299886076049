import { fabric } from 'fabric';
import { MultiPolygon } from 'polygon-clipping';
import React, { useMemo } from 'react';

import { Coords, Hole } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricCircleComponent from 'editor/src/component/EditorArea/fabricComponents/FabricCircleComponent';
import FabricGroupComponent from 'editor/src/component/EditorArea/fabricComponents/FabricGroupComponent';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import holeElementDataToFabricProps from './holeElementDataToFabricProps';
import { getGradient, SHADOW_OFFSET_Y } from './utils';

interface Props {
  hole: Hole;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  color: string;
  contentAreaPolygon: MultiPolygon;
}

function HoleElement({ hole, pageCoords, canvasRotation, color, contentAreaPolygon }: Props) {
  const { mm2px } = useFabricUtils();

  const diameter = mm2px(hole.diameter);
  const { left, top, radius } = useMemo(
    () => holeElementDataToFabricProps(hole, pageCoords, mm2px, canvasRotation),
    [hole, pageCoords, mm2px, canvasRotation],
  );

  const circleClipPath = new fabric.Circle({
    radius,
    left,
    top,
    absolutePositioned: true,
    strokeWidth: 0,
    objectCaching: false,
  });

  const boundingRect: ObjectRect = {
    left,
    top,
    width: diameter,
    height: diameter,
    angle: 0,
  };

  const clipPath = getClipPath(boundingRect, contentAreaPolygon, false);
  const gradient = getGradient(radius);
  const shadowRadius = radius + SHADOW_OFFSET_Y;

  return (
    <>
      <FabricCircleComponent
        radius={radius}
        left={left}
        top={top}
        zIndex={zIndex.HOLE}
        selectable={false}
        evented={false}
        fill={color}
        strokeWidth={0}
        objectCaching={false}
      />
      <FabricGroupComponent
        left={left}
        top={top}
        width={diameter}
        height={diameter}
        zIndex={zIndex.HOLE}
        selectable={false}
        evented={false}
        clipPath={clipPath}
        objectCaching={false}
      >
        <FabricCircleComponent
          radius={shadowRadius}
          left={-shadowRadius}
          top={-shadowRadius}
          zIndex={zIndex.HOLE}
          selectable={false}
          evented={false}
          fill={gradient}
          strokeWidth={0}
          clipPath={circleClipPath}
          objectCaching={false}
        />
      </FabricGroupComponent>
    </>
  );
}

export default React.memo(HoleElement);
