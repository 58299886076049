import { fabric } from 'fabric';

import FabricImage, { isFabricImage } from 'editor/src/fabric/FabricImage';
import FabricPathText, { isFabricPathText } from 'editor/src/fabric/FabricPathText';

import {
  disposeElement,
  removeCacheCanvas,
  setClipPath,
} from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';
import orderObjects from 'editor/src/component/EditorArea/orderObjects';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';

export interface BBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

function updateObject(
  objectBase: fabric.Object & { zIndex?: number },
  clone: fabric.Object & { zIndex?: number },
  mediaBox: BBox,
  ratio: number,
) {
  clone.set({
    angle: objectBase.angle,
    width: objectBase.width,
    height: objectBase.height,
    scaleX: (objectBase.scaleX ?? 1) * ratio,
    scaleY: (objectBase.scaleY ?? 1) * ratio,
    left: ((objectBase.left || 0) - mediaBox.x) * ratio,
    top: ((objectBase.top || 0) - mediaBox.y) * ratio,
    zIndex: objectBase.zIndex,
  });
}

function updateRectangle(objectBase: fabric.Group, clone: fabric.Group) {
  const cloneChild = clone.getObjects()[0] as fabric.Rect & { zIndex?: number };
  const objectBaseChild = objectBase.getObjects()[0] as fabric.Rect & { zIndex?: number };
  const objectBaseChildClipPath = objectBaseChild.clipPath as fabric.Rect | undefined;

  cloneChild.set({
    clipPath: new fabric.Rect({
      left: objectBaseChildClipPath?.left || 0,
      top: objectBaseChildClipPath?.top || 0,
      width: objectBaseChildClipPath?.width || 0,
      height: objectBaseChildClipPath?.height || 0,
      rx: objectBaseChildClipPath?.rx,
      ry: objectBaseChildClipPath?.ry,
    }),
    angle: objectBaseChild.angle,
    width: objectBaseChild.width,
    height: objectBaseChild.height,
    left: objectBaseChild.left || 0,
    top: objectBaseChild.top || 0,
    strokeWidth: objectBaseChild.strokeWidth,
    stroke: objectBaseChild.stroke,
    fill: objectBaseChild.fill,
    paintFirst: 'fill',
    rx: objectBaseChild.rx,
    ry: objectBaseChild.ry,
    strokeDashArray: objectBaseChild.strokeDashArray,
    objectCaching: false,
    zIndex: objectBaseChild.zIndex,
  });
}

function updateTextObject(objectBase: FabricPathText, clone: FabricPathText) {
  removeCacheCanvas(clone.clipPath);
  clone.set({
    fill: objectBase.fill,
    text: objectBase.text,
    fontFamily: objectBase.fontFamily,
    textAlign: objectBase.textAlign,
    fontSize: objectBase.fontSize,
    lineHeight: objectBase.lineHeight,
    charSpacing: objectBase.charSpacing,
    shadow: objectBase.shadow,
    paintFirst: 'stroke',
    clipPath: undefined,
    stroke: objectBase.stroke,
    strokeWidth: objectBase.strokeWidth,
    objectCaching: false,
  });
}

function updateImageObject(objectBase: FabricImage, clone: FabricImage, mediaBox: BBox, ratio: number) {
  if (objectBase.frameRect && objectBase.clipPath) {
    const frameRect = {
      left: (objectBase.frameRect.left - mediaBox.x) * ratio,
      top: (objectBase.frameRect.top - mediaBox.y) * ratio,
      width: objectBase.frameRect.width * ratio,
      height: objectBase.frameRect.height * ratio,
      angle: objectBase.frameRect.angle,
    };
    setClipPath(clone, getClipPath(frameRect, [], true));
  } else {
    setClipPath(clone, undefined);
  }

  clone.flipX = objectBase.flipX;
  clone.flipY = objectBase.flipY;

  clone.setElement(objectBase.getElement());
}

function updateMirrorCanvas(
  fabricCanvas: fabric.Canvas,
  mirrorCanvas: fabric.StaticCanvas,
  mediaBoxBox: BBox,
  ratio: number,
  filteredUids?: Set<number>,
) {
  const mirrorObjects: Map<number, fabric.Object> = new Map();
  mirrorCanvas.getObjects().forEach((object) => {
    if ((object as any).uuid) {
      mirrorObjects.set((object as any).uuid, object);
    }
  });
  const mainCanvasObjects = fabricCanvas.getObjects();

  for (let i = 0; i < mainCanvasObjects.length; i += 1) {
    const mainObject = mainCanvasObjects[i];

    if (isFabricPathText(mainObject) && mainObject.isFontLoaded) {
      const { uuid } = mainObject;
      if (filteredUids && !filteredUids.has(uuid)) {
        continue;
      }
      const mirrorObject = mirrorObjects.get(uuid) as FabricPathText | undefined;
      const clone = mirrorObject || new FabricPathText(mainObject.text);
      updateObject(mainObject as fabric.Object, clone as fabric.Object, mediaBoxBox, ratio);
      updateTextObject(mainObject, clone);
      if (!mirrorObject) {
        clone.uuid = mainObject.uuid;
        mirrorCanvas.add(clone);
      }

      mirrorObjects.delete(mainObject.uuid);
    } else if (isFabricImage(mainObject) && mainObject.uuid !== undefined && mainObject.isLoaded) {
      const { uuid } = mainObject;
      if (filteredUids && !filteredUids.has(uuid)) {
        continue;
      }
      const mirrorObject = mirrorObjects.get(mainObject.uuid) as FabricImage | undefined;
      const clone = mirrorObject || new FabricImage();
      updateObject(mainObject, clone, mediaBoxBox, ratio);
      updateImageObject(mainObject, clone, mediaBoxBox, ratio);
      if (!mirrorObject) {
        clone.uuid = mainObject.uuid;
        mirrorCanvas.add(clone);
      }

      mirrorObjects.delete(mainObject.uuid);
    } else if (mainObject instanceof fabric.Group && mainObject.type === 'gelato-rectangle') {
      const { uuid } = mainObject as any;
      if (filteredUids && !filteredUids.has(uuid)) {
        continue;
      }
      const mirrorObject = mirrorObjects.get(uuid) as fabric.Group | undefined;

      const clone = mirrorObject || new fabric.Group([new fabric.Rect()]);
      updateObject(mainObject as fabric.Object, clone as fabric.Object, mediaBoxBox, ratio);
      updateRectangle(mainObject, clone);
      if (!mirrorObject) {
        (clone as any).uuid = uuid;
        mirrorCanvas.add(clone);
      }

      mirrorObjects.delete(uuid);
    }
  }

  const mirrorObjectsToDelete = [...mirrorObjects.values()];
  if (mirrorObjectsToDelete.length) {
    mirrorObjectsToDelete.forEach(disposeElement);
    mirrorCanvas.remove(...mirrorObjectsToDelete);
  }

  orderObjects(mirrorCanvas);
  mirrorCanvas.renderAll();
}

export default updateMirrorCanvas;
