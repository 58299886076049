import memoize from 'lodash/memoize';

import { Bleed, MediaBox, Content, Diecut, Spread } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import getSpread from './getSpread';

type GroupElement = {
  bleed: Bleed;
  mediabox: MediaBox;
  content: Content;
  diecut: Diecut;
};

type GroupElementKey = keyof GroupElement;

export function getGroupElementsByKey<K extends GroupElementKey>(
  spread: Spread,
  groupKey: K,
  filterFor?: { type?: string; name?: string },
  pageNum?: number,
) {
  const pages = pageNum !== undefined ? [spread.pages[pageNum]] : spread.pages;
  return pages.reduce<GroupElement[K][]>((acc, page) => {
    const groupElements = page.groups[groupKey] as GroupElement[K][] | undefined;
    groupElements?.forEach((element) => {
      if (
        !filterFor ||
        ((!filterFor.type || element.type === filterFor.type) && (!filterFor.name || element.name === filterFor.name))
      ) {
        acc.push({
          ...element,
          x: element.x + page.x,
          y: element.y + page.y,
        });
      }
    });
    return acc;
  }, []);
}

const getSpreadGroupElementsByKey = memoize(
  (
    state: RootState,
    spreadIndex: number,
    groupKey: GroupElementKey,
    filterFor?: { group?: string; type?: string; name?: string },
  ) => {
    const spread = getSpread(state, spreadIndex);
    return spread ? getGroupElementsByKey(spread, groupKey, filterFor) : [];
  },
  (state: RootState) => (state.design.designData ? getDesignKeyFromDesign(state.design.designData) : undefined),
);

export default getSpreadGroupElementsByKey;
