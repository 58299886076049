import { fabric } from 'fabric';

interface FabricText extends fabric.Textbox {
  zIndex: number;
}

export interface IFabricText extends fabric.ITextboxOptions {
  zIndex?: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricText = fabric.util.createClass(fabric.Textbox, {
  zIndex: 0,
}) as { new (text: string, options?: IFabricText): FabricText };

export default FabricText;
