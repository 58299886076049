import { fabric } from 'fabric';

import { Coords, Page } from 'editor/src/store/design/types';

import FabricRect from 'editor/src/fabric/FabricRect';
import FabricText from 'editor/src/fabric/FabricText';
import pt2mm from 'editor/src/util/pt2mm';

import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import {
  calculateLeftPosition,
  calculateTopPosition,
  calculateWidth,
} from '../EditorArea/utils/blankPageTextCalculations';

import { STRONG_SHADOW } from './addPageElement';

const addBlanks = (
  fabricCanvas: fabric.StaticCanvas,
  page: Page,
  offset: Coords,
  showBlankAreaShadowOnly: boolean,
  mm2px: (size: number) => number,
  rotation: CanvasRotation,
  isMobile: boolean,
  t: (key: string) => string,
) => {
  const blanks = page.groups.blank;
  if (!blanks) {
    return;
  }

  blanks.forEach((blank) => {
    switch (blank.type) {
      case 'area': {
        const point = fabric.util.rotatePoint(
          new fabric.Point(offset.left + mm2px(blank.x), offset.top + mm2px(blank.y)),
          rotation.canvasCenter,
          rotation.angleRad,
        );
        const rect = new FabricRect({
          left: point.x,
          top: point.y,
          width: mm2px(blank.width),
          height: mm2px(blank.height),
          fill: showBlankAreaShadowOnly ? 'white' : 'rgba(34, 34, 34, 0.05)',
          objectCaching: false,
          zIndex: zIndex.BLANK,
          shadow: showBlankAreaShadowOnly ? STRONG_SHADOW : undefined,
        });
        fabricCanvas.add(rect);
        if (isMobile && !showBlankAreaShadowOnly) {
          const blankPageText = new FabricText(t('editor-blank-page-description'), {
            left: calculateLeftPosition(offset, blank.x, blank.width, mm2px),
            width: calculateWidth(blank.width, mm2px),
            fontSize: mm2px(pt2mm(28)),
            textAlign: 'center',
            fill: 'rgba(51, 51, 51, 0.5)',
            fontFamily: 'Gelato Sans, sans-serif',
            evented: false,
            zIndex: zIndex.BLANK,
          });
          blankPageText.set('top', calculateTopPosition(offset, blank.height, blankPageText.getScaledHeight(), mm2px));
          fabricCanvas.add(blankPageText);
        }
        break;
      }
      default:
    }
  });
};

export default addBlanks;
