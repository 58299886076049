import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

import updateDesignPagesUtil from '../util/updateDesignPagesUtil';

const reOrderSpreadsReducer = (
  state: DesignState,
  action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>,
) => {
  const { designData } = state;
  const spreads = designData?.spreads || [];

  if (!designData || spreads.length === 0) {
    return;
  }

  const { sourceIndex, destinationIndex } = action.payload;

  const spread = spreads[sourceIndex];
  spreads.splice(sourceIndex, 1);
  spreads.splice(destinationIndex, 0, spread);
  updateDesignPagesUtil(designData);
};

export default reOrderSpreadsReducer;
