import showLoaderOperation from 'editor/src/store/app/operation/showLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import addUploadedImagesOperation from 'editor/src/store/gallery/operation/addUploadedImagesOperation';

import store from 'product-personalizer/src/store';

import getCollectionDataFromAssets from './collections';
import loadFonts from './loadFonts';

async function fetchAndLoadDummyDesign() {
  await loadFonts();
  const setCollectionFolderOperation = await import(
    'editor/src/store/collections/operations/setCollectionFolderOperation'
  );
  const setDesignDataOperation = await import('editor/src/store/design/operation/setDesignDataOperation');
  const collectionDummyData = await import('product-personalizer/src/utils/dummy-collection.json');
  const dummyDesignData = await import('product-personalizer/src/utils/dummy-structure.json');
  const dummyAssetsData = await import('product-personalizer/src/utils/dummy-assets.json');

  store.dispatch(showLoaderOperation(LoaderType.ProductPersonalizerPreview));
  void store.dispatch(setDesignDataOperation.default(dummyDesignData.default as any, false));
  const collectionData = getCollectionDataFromAssets(collectionDummyData.default as any);
  void store.dispatch(setCollectionFolderOperation.default(collectionData));
  void store.dispatch(addUploadedImagesOperation(dummyAssetsData.default));
}

export default fetchAndLoadDummyDesign;
