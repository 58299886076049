import React from 'react';

import styles from './index.module.scss';

export const CONTAINER_ID = 'dropdown-container-id';
export const DropDownContext = React.createContext(CONTAINER_ID);

function DropDownContainer() {
  return <div id={CONTAINER_ID} className={styles.dropdownContainer} />;
}

export default DropDownContainer;
