// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel--Qo6T8{display:flex;width:100%;flex-direction:column;overflow:hidden}.imageContainer--F4iTL{width:100%;position:relative;padding-bottom:100%}.selectedImage--aWzqj{position:absolute;top:0;left:0;width:100%;height:100%;display:block;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/component/PreviewView/Carousel/MobileCarousel/index.module.scss"],"names":[],"mappings":"AAAA,iBACE,YAAA,CACA,UAAA,CACA,qBAAA,CACA,eAAA,CAGF,uBACE,UAAA,CACA,iBAAA,CACA,mBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,kBAAA","sourcesContent":[".Carousel {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.imageContainer {\n  width: 100%;\n  position: relative;\n  padding-bottom: 100%; // makes a square with the height = width\n}\n\n.selectedImage {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: block;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Carousel": `Carousel--Qo6T8`,
	"imageContainer": `imageContainer--F4iTL`,
	"selectedImage": `selectedImage--aWzqj`
};
export default ___CSS_LOADER_EXPORT___;
