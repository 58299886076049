import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData, SpreadGroundImage } from 'editor/src/store/design/types';
import getDesignKey from 'editor/src/store/variants/helpers/getDesignKey';
import { makeGroupKey } from 'editor/src/store/variants/helpers/groupVariantsBy';
import getSpreadGroupsForGivenVariant from 'editor/src/store/variants/selector/getSpreadGroupsForGivenVariant';
import { VariantsState } from 'editor/src/store/variants/types';

const addDesignTemplatesReducer = (
  state: VariantsState,
  action: PayloadAction<
    Array<{
      designData: DesignData;
      spreadBackgrounds?: SpreadGroundImage[];
      spreadForegrounds?: SpreadGroundImage[];
    }>
  >,
) => {
  action.payload.forEach(({ designData, spreadForegrounds, spreadBackgrounds }) => {
    const pageCount = designData.page_count_limit ? designData.page_count : undefined;
    const designKey = getDesignKey(designData.product_uid, {
      dimensions: designData.dimensions,
      relatedDimensions: designData.related_dimensions,
      pageCount,
    });
    const spreadGroups = getSpreadGroupsForGivenVariant(state, designData.product_uid);
    state.designTemplates[designKey] = {
      ...designData,
      spread_groups: spreadGroups,
    };

    const noParamDesignKey = getDesignKey(designData.product_uid, {
      dimensions: undefined,
      pageCount: undefined,
    });
    if (!state.designTemplates[noParamDesignKey]) {
      state.designTemplates[noParamDesignKey] = designData;
    }

    if (spreadBackgrounds && spreadForegrounds) {
      const variation = state.product.productVariations.find(
        (variation) => variation.productUid === designData.product_uid,
      );
      if (variation) {
        const groupKey = makeGroupKey(
          {
            variation,
            dimensions: designData.dimensions,
            pageCount: designData.page_count,
          },
          state.product.groupBy,
        );
        state.spreadImages[groupKey] = {
          spreadBackgrounds,
          spreadForegrounds,
        };

        const designOptionController = state.designOptionsControl?.[0];
        if (designOptionController) {
          designOptionController?.options.forEach((option) => {
            state.spreadImages[`${groupKey}-${option.value}`] = {
              spreadBackgrounds,
              spreadForegrounds,
            };
          });
        }
      }
      state.spreadImages[designKey] = {
        spreadBackgrounds,
        spreadForegrounds,
      };
      state.spreadImages[noParamDesignKey] = {
        spreadBackgrounds,
        spreadForegrounds,
      };
    }
  });
};

export default addDesignTemplatesReducer;
