import { UploadedImageData } from 'editor/src/store/gallery/types';
import { PayloadFile } from 'editor/src/store/gallery/utils/uploadLogging';

import { SuccessPayload } from 'editor/src/component/Uploader/useUploader';

import getImageInfo from './getImageInfo';

export const fileToPayloadFile = async (file: File): Promise<SuccessPayload> => {
  const { uuid, url, width, height } = await getImageInfo(file);
  const payloadFile: PayloadFile = {
    id: uuid,
    width,
    height,
    type: file.type,
    size: file.size,
    isRemote: false,
    progress: {
      bytesTotal: file.size,
      bytesUploaded: file.size,
      percentage: 100,
      uploadComplete: true,
      uploadStarted: file.size,
    },
  };
  return {
    file: payloadFile,
    url,
  };
};

export const successPayloadToUploadedImageData = (payload: SuccessPayload): UploadedImageData => {
  return {
    file: payload.file,
    url: payload.url,
    thumbnailUrl: payload.url,
    mimeType: payload.file.type,
  };
};
