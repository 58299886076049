import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, DesignState } from 'editor/src/store/design/types';

const updateConditionActiveOptionReducer = (
  state: DesignState,
  action: PayloadAction<{ address: ConditionAddress; optionId: string }>,
) => {
  const { address, optionId } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  condition.activeOptionId = optionId;
};

export default updateConditionActiveOptionReducer;
