import React, { useContext } from 'react';

export interface FabricUtilsContext {
  mm2px(size: number): number;
  px2mm(size: number): number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FabricUtilsContext = React.createContext<FabricUtilsContext>(null as any);

export function useFabricUtils() {
  return useContext(FabricUtilsContext);
}

export default useFabricUtils;
