import { EMPTY_PREVIEW_DIM } from 'editor/src/store/gallery/config';
import { addUploadedImagesAction, setUploadedImagesAction } from 'editor/src/store/gallery/slice';
import { ImageState, UploadedImageData } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import notUndefined from 'editor/src/util/notUndefined';

const addUploadedImages =
  (inputImages: UploadedImageData[], clearExisting = false) =>
  async (dispatch: ThunkDispatch) => {
    if (!Array.isArray(inputImages)) {
      throw new Error(`inputImage should be an array. The actual value is: ${JSON.stringify(inputImages)}`);
    }

    const dimensions = await Promise.all(
      inputImages.map(async (image) => {
        image.thumbnailUrl = isWebPSupported ? image.thumbnailUrl || image.url : image.url;
        if (image.file.width && image.file.height) {
          return { width: image.file.width, height: image.file.height };
        }

        try {
          return await loadImageDimensions(
            image.thumbnailUrl,
            'anonymous',
            { executor: 'addUploadedImages', image },
            false,
          );
        } catch {
          return undefined;
        }
      }),
    );

    const images = inputImages
      .map((imageData, i) => {
        const dim = dimensions[i];
        const uniqColors = new Set([...(imageData.file.colors?.fill ?? []), ...(imageData.file.colors?.stroke ?? [])]);

        return {
          state: dim ? ImageState.UPLOADED : ImageState.FAILED,
          id: imageData.file.id,
          name: imageData.file.name ?? '',
          type: getMimeTypeEnum(imageData.file.type || imageData.mimeType),
          url: dim ? imageData.url : '',
          thumbnailUrl: dim ? imageData.thumbnailUrl : '',
          quantizedUrl: imageData.quantizedUrl,
          width: dim?.width ?? EMPTY_PREVIEW_DIM.width,
          height: dim?.height ?? EMPTY_PREVIEW_DIM.height,
          source: imageData.file.source,
          label: imageData.file.label,
          colors: uniqColors.size > 0 ? [...uniqColors] : undefined,
          quantizedColors: imageData.file.quantizedColors,
          hasAssetDimensions: !!imageData.file.width && !!imageData.file.height,
          hidden: imageData.hidden,
        };
      })
      .filter(notUndefined);

    if (clearExisting) {
      dispatch(setUploadedImagesAction(images));
    } else {
      dispatch(addUploadedImagesAction(images));
    }
  };

export default addUploadedImages;
