import { PayloadAction } from '@reduxjs/toolkit';

import { isNotDefaultTab, isPluginTab, SidebarState, TabInfo } from 'editor/src/store/editorModules/sidebar/types';

function findTabIndex(tabToFind: TabInfo, tabs: TabInfo[]) {
  const customTab = isNotDefaultTab(tabToFind);
  const pluginTab = isPluginTab(tabToFind);
  return tabs.findIndex((tab) => {
    if (isNotDefaultTab(tab)) {
      return customTab && tab.id === tabToFind.id;
    }

    if (isPluginTab(tab)) {
      return pluginTab && tab.id === tabToFind.id;
    }

    return tab === tabToFind;
  });
}

const updateSidebarTabsReducer = (
  state: SidebarState,
  action: PayloadAction<{ tabs: TabInfo[]; isMobile: boolean }>,
) => {
  // consider sanitizing the iconSVG entry
  const previousActiveTab = state.tabs[state.activeTabIndex];
  const { tabs, isMobile } = action.payload;

  const wasTabsLengthChanged = state.tabs.length !== tabs.length;
  const keepActiveTab = !wasTabsLengthChanged && state.activeTabIndex !== -1;

  state.activeTabIndex = keepActiveTab ? findTabIndex(previousActiveTab, tabs) : -1;
  const hasTabs = state.baseTabs.length || state.pluginTabs.length;
  state.lastNonSystemTabIndex = !isMobile && hasTabs ? 0 : -1;
  if (state.activeTabIndex === -1 && !isMobile && hasTabs) {
    state.activeTabIndex = 0;
  }
  state.tabs = tabs;
};

export default updateSidebarTabsReducer;
