import { useMemo } from 'react';

import { Direction, ImagePattern, MediaAddon, MediaImage, PerspectiveTransform } from 'editor/src/store/design/types';

import BrickFilter, {
  ConstructorOption as BrickFilterOptions,
} from 'editor/src/component/EditorArea/fabricFilters/BrickFilter';
import CropFilter from 'editor/src/component/EditorArea/fabricFilters/CropFilter';
import PerspectiveTransformFilter from 'editor/src/component/EditorArea/fabricFilters/PerspectiveTransformFilter';

import getAdjustmentFilters from './getAdjustmentFilters';

export function getPatternRepeat(
  scale: number,
  scaleBase: ImagePattern['scaleBase'],
  width: number,
  height: number,
  pw: number,
  ph: number,
) {
  let repeat = scale;
  if (scaleBase?.type === 'element') {
    if (scaleBase.value === 'width') {
      repeat *= pw / width;
    } else {
      repeat *= ph / height;
    }
  } else if (scaleBase?.type === 'width') {
    repeat *= pw / scaleBase.value;
  } else if (scaleBase?.type === 'height') {
    repeat *= ph / scaleBase.value;
  }
  return repeat;
}

export function getFilters(
  element: MediaImage | MediaAddon | undefined,
  perspectiveTransform: PerspectiveTransform | undefined,
) {
  const filters: fabric.IBaseFilter[] = [];
  if (!element) {
    return filters;
  }

  const transform = perspectiveTransform ?? element.perspective_transform;
  if (transform) {
    filters.push(
      new CropFilter({
        x: -element.px / element.pw,
        y: -element.py / element.ph,
        width: element.width / element.pw,
        height: element.height / element.ph,
        angle: element.pr,
      }),
    );
    filters.push(new PerspectiveTransformFilter({ transform }));
  }

  if (element.type === 'image' && element.adjustments) {
    getAdjustmentFilters(element.adjustments).forEach((filter) => filters.push(filter));
  }

  if (element.type === 'image' && element.pattern) {
    const { scaleBase, scale } = element.pattern;

    const options: BrickFilterOptions = {
      repeat: getPatternRepeat(scale, scaleBase, element.width, element.height, element.pw, element.ph),
      shiftX: element.pattern.direction === Direction.Horizontal ? element.pattern.offset ?? 0 : 0,
      shiftY: element.pattern.direction === Direction.Vertical ? element.pattern.offset ?? 0 : 0,
      offsetX: 0,
      offsetY: 0,
      mirror: element.pattern.mirror || false,
      spacing: element.pattern.spacing ?? 0,
    };
    filters.push(new BrickFilter(options));
  }

  return filters;
}

function useFilters(element: MediaImage | undefined) {
  const filters = useMemo(
    () => getFilters(element, undefined),
    [
      element?.adjustments,
      element?.pattern,
      element?.width,
      element?.height,
      element?.ph,
      element?.pw,
      element?.pr,
      element?.perspective_transform,
    ],
  );
  return filters;
}

export default useFilters;
