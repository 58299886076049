import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  spin?: boolean;
}

function Icon(props: Props) {
  const { children, className, spin, ...otherProps } = props;
  return (
    <div
      className={cn(styles.icon, className, 'icon', { [styles.spin]: spin })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default Icon;
