import { PayloadAction } from '@reduxjs/toolkit';

import { FontState, FontStatus } from 'editor/src/store/fonts/types';

const updateFontStatusReducer = (
  state: FontState,
  action: PayloadAction<{ fontFile: string; fontStatus: FontStatus }>,
) => {
  const { fontFile, fontStatus } = action.payload;
  const font = state.availableFonts.find((font) => font.metadata.fontFile === fontFile);
  if (font) {
    font.status = fontStatus;
  }
};

export default updateFontStatusReducer;
