import { RootState } from 'editor/src/store/index';

import { PopupData, PopupName } from 'editor/src/component/Popup/popups';

import isPopupOpen from './isPopupOpen';

const getPopupData = <A extends PopupName>(state: RootState, popupName: A) =>
  isPopupOpen(state, popupName) ? (state.app.popup.data as PopupData[A]) : undefined;

export default getPopupData;
