/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconTemperature({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 10V4C9 2.34315 7.65685 1 6 1V1C4.34315 1 3 2.34315 3 4V10"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="6" cy="14" r="1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 14V6" strokeWidth="2" strokeLinejoin="round" />
        <path
          d="M9 10C10.1647 10.9223 11 12.3545 11 13.9627C11 16.7447 8.76142 19 6 19C3.23858 19 1 16.7447 1 13.9627C1 12.3545 1.83527 10.9223 3 10"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconTemperature;
