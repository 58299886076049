import { Unit } from 'editor/src/store/editor/types';

export const convertUnitToMm = (value: number, unit: Unit): number => {
  switch (unit) {
    case 'cm':
      return cmToMm(value);
    case 'inch':
      return inchToMm(value);
    default:
      return value;
  }
};

export const convertMmToUnit = (value: number, unit: Unit): number => {
  switch (unit) {
    case 'cm':
      return mmToCm(value);
    case 'inch':
      return mmToInch(value);
    default:
      return value;
  }
};

export const cmToMm = (value: number): number => value * 10;
export const mmToCm = (value: number): number => value * 0.1;

export const inchToMm = (value: number): number => value * 25.4;
export const mmToInch = (value: number): number => value / 25.4;
