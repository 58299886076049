import { UploadedImageData } from 'editor/src/store/gallery/types';

import { EnvEnum } from 'editor/src/environment';
import { Asset, AssetFileType } from 'product-personalizer/src/types/asset';

const getAssetFileUrl = (asset: Asset, assetFileType: AssetFileType): string | undefined => {
  if (!asset || !asset.files || !assetFileType) {
    return undefined;
  }

  return asset.files.find((file) => file.type === assetFileType)?.url;
};

const getUploadedImageDataFromAssets = (assets: Asset[]): UploadedImageData[] =>
  assets.map((asset) => {
    let url = getAssetFileUrl(asset, AssetFileType.PREVIEW_DEFAULT);
    if (!url) {
      url = getAssetFileUrl(asset, AssetFileType.FILE_ORIGINAL) ?? asset.files[0].url;
    }

    const colors = asset.metadata?.find((m) => m.key === 'fillColors')?.value;
    const quantizedColors = asset.metadata?.find((m) => m.key === 'quantizedColors')?.value;

    return {
      file: {
        id: asset.id,
        width: asset.width,
        height: asset.height,
        source: asset.assetProviderUid,
        colors: colors
          ? {
              fill: colors,
              stroke: [],
            }
          : undefined,
        quantizedColors,
      },
      url,
      thumbnailUrl: getAssetFileUrl(asset, AssetFileType.PREVIEW_THUMBNAIL),
      quantizedUrl: getAssetFileUrl(asset, AssetFileType.PREVIEW_QUANTIZED),
      mimeType: asset.mimeType,
      assetId: asset.id,
    };
  });

export const getAssets = async (url: string, fileId: string, useLocalUrl?: true): Promise<Asset> => {
  if (LOCAL_ENV.env === EnvEnum.dev || useLocalUrl) {
    return Promise.resolve({
      id: fileId,
      assetProviderUid: 'url',
      width: undefined,
      height: undefined,
      files: [
        { type: AssetFileType.PREVIEW_DEFAULT, url },
        { type: AssetFileType.PREVIEW_THUMBNAIL, url },
      ],
      mimeType: 'image/png',
      status: 'created',
    });
  }

  const response = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/assets`, {
    method: 'POST',
    body: JSON.stringify({
      fileUrl: url,
    }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export default getUploadedImageDataFromAssets;
