import { setSpreadForegroundsAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { loadSpreadImages, SpreadGroundImageWODimensions } from './setSpreadBackgroundsOperation';

const setSpreadForegroundsOperation = (data: SpreadGroundImageWODimensions[]) => async (dispatch: ThunkDispatch) => {
  const spreadGroundImages = await loadSpreadImages(data);
  dispatch(setSpreadForegroundsAction(spreadGroundImages));
};

export default setSpreadForegroundsOperation;
