import { styleToCSSStyle, weightToCSSWeight } from '@gelatoas/design-editor-fonts';
import { captureException } from '@sentry/react';
import opentype from 'opentype.js';

import fontManager, { FontInfo } from 'editor/src/store/fonts/fontManager';
import { FontDefinition } from 'editor/src/store/fonts/types';

import setTimeoutPromise from 'editor/src/util/setTimeoutPromise';

import fetchWithRetry from './fetchWithRetry';

const fontStyleDoc = document.createElement('style');
fontStyleDoc.setAttribute('data-editor-fonts', 'true');
document.head.appendChild(fontStyleDoc);

const fontsDiv = document.createElement('div');
fontsDiv.className = 'fonts-list';
fontsDiv.style.position = 'absolute';
fontsDiv.style.transform = 'translate3d(-100%,-100%,0)';
fontsDiv.style.left = '0';
fontsDiv.style.top = '0';
document.body.appendChild(fontsDiv);

class AssetDownloadError extends Error {
  constructor(response: Response, executor: string) {
    super(`${response.status} - ${response.statusText}: ${executor}`);
    this.name = 'AssetDownloadError';
  }
}

async function fetchFont<T extends { executor: string }>(fontDef: FontDefinition, context: T) {
  try {
    const response = await fetchWithRetry(fontDef.metadata.fileUrl);
    if (!response.ok) {
      throw new AssetDownloadError(response, context.executor);
    }

    const buffer = await response.arrayBuffer();
    const blobUrl = URL.createObjectURL(new Blob([buffer]));
    const font = opentype.parse(buffer);

    // save font obj to cache
    const fontCache: FontInfo = {
      obj: font,
      definition: fontDef,
      localUrl: blobUrl,
    };

    fontManager.loadedFontMap[fontDef.metadata.fontFile] = fontCache;
    const fontArrKeys = Object.keys(fontManager.loadedFontMap);

    let style = '';
    fontArrKeys.forEach((fontFile) => {
      const { localUrl, definition } = fontManager.loadedFontMap[fontFile];
      const font = definition.metadata;
      style +=
        '@font-face {\n' +
        `  font-family: "${font.family}";\n` +
        `  font-weight: "${weightToCSSWeight(font.weight)}";\n` +
        `  font-style: "${styleToCSSStyle(font.style)}";\n` +
        `  src: url("${localUrl}");\n` +
        '}\n\n';

      style +=
        '@font-face {\n' +
        `  font-family: "${definition.metadata.fontFile}";\n` +
        `  src: url("${localUrl}");\n` +
        '}\n\n';
    });
    fontStyleDoc.innerHTML = style;

    // ensure it is loaded in the DOM
    const fontDivOriginal = document.createElement('div');
    fontDivOriginal.id = fontDef.metadata.fontFile;
    fontDivOriginal.style.fontFamily = fontDef.metadata.family;
    fontDivOriginal.style.fontWeight = weightToCSSWeight(fontDef.metadata.weight);
    fontDivOriginal.style.fontStyle = styleToCSSStyle(fontDef.metadata.style);
    fontDivOriginal.textContent = fontDef.metadata.fontFile;
    fontsDiv.appendChild(fontDivOriginal);

    await fetch(blobUrl);
    await setTimeoutPromise(0);
    return fontCache;
  } catch (err) {
    captureException(err ?? 'error loading font', {
      extra: { context, fontDef },
    });
    throw new Error('error loading font');
  }
}

const fetching: { [fontFile: string]: Promise<FontInfo> } = {};

async function loadFont<T extends { executor: string }>(fontDef: FontDefinition, context: T): Promise<FontInfo> {
  // if font file already loaded do not load it second time
  const existingCache = fontManager.loadedFontMap[fontDef.metadata.fontFile];
  if (existingCache) {
    return existingCache;
  }

  if (fontDef.metadata.fileUrl in fetching) {
    return fetching[fontDef.metadata.fileUrl];
  }

  const promise = fetchFont(fontDef, context);
  fetching[fontDef.metadata.fileUrl] = promise;

  const fontInfo = await promise;
  delete fetching[fontDef.metadata.fileUrl];
  return fontInfo;
}

export default loadFont;
