import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import applyImageToElementOperation from './applyImageToElementOperation';
import getNewElementDisplay from './getNewElementDisplay';
import getNewImageDisplaySize from './getNewImageDisplaySize';

const replaceImageAfterUploadOperation =
  (
    previousImageId: string,
    newImageId: string,
    url: string,
    dimensions?: Dimensions,
    previousDimensions?: Dimensions,
  ) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const pageIndex = 0;

    state.design.designData?.spreads.forEach((spread, spreadIndex) => {
      spread.pages[pageIndex].groups.media?.forEach((element, elementIndex) => {
        if (element.type !== 'image' || element.imageId !== previousImageId) {
          return;
        }

        const elementAddress = { spreadIndex, pageIndex, elementIndex };
        const aspectRatioChanged =
          dimensions &&
          previousDimensions &&
          dimensions.width / dimensions.height !== previousDimensions.width / previousDimensions.height;
        if (!aspectRatioChanged) {
          dispatch(
            updateMediaElementAction({
              elementAddress,
              elementUpdate: { imageId: newImageId, url },
            }),
          );
          return;
        }

        if (!element.createdByLayout) {
          dispatch(
            updateMediaElementAction({
              elementAddress,
              elementUpdate: getElementUpdate(newImageId, url, element, state, spreadIndex, dimensions),
            }),
          );
          return;
        }

        const galleryImage = getImageById(state, newImageId);
        if (galleryImage) {
          dispatch(
            applyImageToElementOperation(element, newImageId, {
              width: galleryImage.width,
              height: galleryImage.height,
            }),
          );
        }
      });
    });
  };

const getElementUpdate = (
  newImageId: string,
  url: string,
  imageElement: MediaImage,
  state: RootState,
  spreadIndex: number,
  dimensions: Dimensions,
): Partial<MediaImage> => {
  const elementUpdate: Partial<MediaImage> = { imageId: newImageId, url };
  if (dimensions) {
    const display = getNewElementDisplay(state, spreadIndex, (area) =>
      getNewImageDisplaySize(area, dimensions.width, dimensions.height),
    );

    if (!imageElement.createdByLayout && display) {
      const centerDiff = getPointPositionRotatedOnPoint(
        (display.width - imageElement.width) / 2,
        (display.height - imageElement.height) / 2,
        0,
        0,
        imageElement.r,
      );
      elementUpdate.width = display.width;
      elementUpdate.height = display.height;
      elementUpdate.pw = display.width;
      elementUpdate.ph = display.height;
      elementUpdate.x = imageElement.x - centerDiff[0];
      elementUpdate.y = imageElement.y - centerDiff[1];
      elementUpdate.px = 0;
      elementUpdate.py = 0;
      elementUpdate.r = 0;
    }
  }

  return elementUpdate;
};

export default replaceImageAfterUploadOperation;
