import { Pair, Polygon, difference, intersection } from 'polygon-clipping';

import { Content, Coords, FoldingLine, Page, Rect, SpreadGroup } from 'editor/src/store/design/types';

import getGroupedSpreadMultiplier from 'editor/src/util/getGroupedSpreadMultiplier';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { rectToPolygon, rotatePolygon, sortPoints } from 'editor/src/component/EditorArea/utils/polygonsUtils';

type Segment = { x1: number; y1: number; x2: number; y2: number };

// returns 0 if point is on the line, <0 or >0 otherwise
// https://math.stackexchange.com/questions/274712/calculate-on-which-side-of-a-straight-line-is-a-given-point-located
function getPointSideRelativeToSegment(segment: Segment, x: number, y: number) {
  return (segment.x2 - segment.x1) * (y - segment.y1) - (segment.y2 - segment.y1) * (x - segment.x1);
}

function getContentCorners(content: Rect): [Pair, Pair, Pair, Pair] {
  const tl: Pair = [content.x, content.y];
  const tr: Pair = [content.x + content.width, content.y];
  const br: Pair = [content.x + content.width, content.y + content.height];
  const bl: Pair = [content.x, content.y + content.height];

  return [tl, tr, br, bl];
}

function getRectPositionRelativeToSegment(rect: Rect, segment: Segment) {
  const corners = getContentCorners(rect);
  // eslint-disable-next-line no-restricted-syntax
  for (const corner of corners) {
    const position = getPointSideRelativeToSegment(segment, corner[0], corner[1]);
    if (position !== 0) {
      // look for a point not on the line
      return position;
    }
  }
  return 0;
}

function isOnSameSide(pos1: number, pos2: number) {
  return pos1 >= 0 === pos2 >= 0;
}

export function getVisibleAreaAsPolygons(
  pages: Page[],
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  canvasRotation: CanvasRotation,
  currentSpreadGroup?: SpreadGroup,
  mediaBoxOffset?: Coords,
) {
  const bleedPolygons: Polygon[] = [];
  const contentPolygons: Polygon[] = [];
  const mediaBoxPolygons: Polygon[] = [];

  pages.forEach((page) => {
    const pageCoords: Coords = {
      left: spreadCoords.left + mm2px(page.x),
      top: spreadCoords.top + mm2px(page.y),
    };
    const elements = getPageElements(page, pageCoords, mm2px, currentSpreadGroup, mediaBoxOffset);
    elements.bleedPolygons.forEach((polygon) => bleedPolygons.push(polygon));
    elements.contentPolygons.forEach((polygon) => contentPolygons.push(polygon));
    elements.mediaBoxPolygons.forEach((polygon) => mediaBoxPolygons.push(polygon));
  });

  return {
    fullArea: difference(mediaBoxPolygons, bleedPolygons).map((polygon) => rotatePolygon(polygon, canvasRotation)),
    contentArea: difference(intersection(mediaBoxPolygons, contentPolygons), bleedPolygons).map((polygon) =>
      rotatePolygon(polygon, canvasRotation),
    ),
  };
}

export function getFocusedVisibleAreaAsPolygons(
  page: Page,
  focusedContent: Content,
  spreadCoords: Coords,
  mm2px: (size: number) => number,
  canvasRotation: CanvasRotation,
  canvasWidth: number,
  canvasHeight: number,
  currentSpreadGroup?: SpreadGroup,
  mediaBoxOffset?: Coords,
) {
  const pageCoords: Coords = {
    left: spreadCoords.left + mm2px(page.x),
    top: spreadCoords.top + mm2px(page.y),
  };
  const { bleedPolygons, mediaBoxPolygons } = getPageElements(
    page,
    pageCoords,
    mm2px,
    currentSpreadGroup,
    mediaBoxOffset,
  );

  if (!page.groups.foldingline?.length) {
    return {
      bleedPolygons,
      contentArea: difference([rectToPolygon(focusedContent, pageCoords, mm2px)], bleedPolygons).map((polygon) =>
        rotatePolygon(polygon, canvasRotation),
      ),
      fullArea: mediaBoxPolygons.map((polygon) => rotatePolygon(polygon, canvasRotation)),
    };
  }

  const foldingLine = page.groups.foldingline[0];
  const foldingLineClipPolygon = getFoldineLineToCanvasBorderArea(
    foldingLine,
    focusedContent,
    pageCoords,
    canvasWidth,
    canvasHeight,
    mm2px,
  );

  return {
    contentArea: difference([rectToPolygon(focusedContent, pageCoords, mm2px)], bleedPolygons).map((polygon) =>
      rotatePolygon(polygon, canvasRotation),
    ),
    fullArea: difference(intersection(mediaBoxPolygons, foldingLineClipPolygon), bleedPolygons).map((polygon) =>
      rotatePolygon(polygon, canvasRotation),
    ),
  };
}

function getFoldineLineToCanvasBorderArea(
  foldingLine: FoldingLine,
  focusedContent: Content,
  pageCoords: Coords,
  canvasWidth: number,
  canvasHeight: number,
  mm2px: (x: number) => number,
): Polygon {
  const segment: Segment = {
    x1: pageCoords.left + mm2px(foldingLine.x1),
    y1: pageCoords.top + mm2px(foldingLine.y1),
    x2: pageCoords.left + mm2px(foldingLine.x2),
    y2: pageCoords.top + mm2px(foldingLine.y2),
  };

  const focusedRect: Rect = {
    x: pageCoords.left + mm2px(focusedContent.x),
    y: pageCoords.top + mm2px(focusedContent.y),
    width: mm2px(focusedContent.width),
    height: mm2px(focusedContent.height),
  };

  // find the position of the focused content in relation to the folding line
  const position = getRectPositionRelativeToSegment(focusedRect, segment);
  const canvasCorners: Pair[] = [
    [0, 0],
    [canvasWidth, 0],
    [canvasWidth, canvasHeight],
    [0, canvasHeight],
  ];
  const filteredCanvasCorners = canvasCorners.filter(([x, y]) =>
    isOnSameSide(position, getPointSideRelativeToSegment(segment, x, y)),
  );
  return [sortPoints([[segment.x1, segment.y1], [segment.x2, segment.y2], ...filteredCanvasCorners])];
}

export function getFocusedFoldingAreaAsPolygon(
  foldingLine: FoldingLine | undefined,
  focusedContent: Content,
  pageCoords: Coords,
  canvasWidth: number,
  canvasHeight: number,
  mm2px: (x: number) => number,
  canvasRotation: CanvasRotation,
): Polygon {
  if (foldingLine) {
    const polygon = getFoldineLineToCanvasBorderArea(
      foldingLine,
      focusedContent,
      pageCoords,
      canvasWidth,
      canvasHeight,
      mm2px,
    );
    return rotatePolygon(polygon, canvasRotation);
  }
  return rotatePolygon(rectToPolygon(focusedContent, pageCoords, mm2px), canvasRotation);
}

export function getPageElements(
  page: Page,
  pageCoords: Coords,
  mm2px: (size: number) => number,
  currentSpreadGroup?: SpreadGroup,
  mediaBoxOffset?: Coords,
) {
  const { widthMultiplier, heightMultiplier } = getGroupedSpreadMultiplier(
    currentSpreadGroup?.spreadIndexes?.length ?? 1,
    currentSpreadGroup?.position,
  );

  const bleedPolygons = (page.groups.bleed || [])
    .filter((bleed) => bleed.type === 'area')
    .map((bleed) => rectToPolygon(bleed, pageCoords, mm2px));

  const contentPolygons = (page.groups.content || [])
    .filter((content) => content.name !== 'panel' && content.type !== 'sample')
    .map((content) => rectToPolygon(content, pageCoords, mm2px));

  const mediaBoxPolygons = ((contentPolygons.length && page.groups.mediabox) || []).map((mediaBox) =>
    rectToPolygon(
      {
        ...mediaBox,
        height: mediaBox.height * heightMultiplier,
        width: mediaBox.width * widthMultiplier,
        y: mediaBox.y + (mediaBoxOffset?.top ?? 0),
        x: mediaBox.x + (mediaBoxOffset?.left ?? 0),
      },
      pageCoords,
      mm2px,
    ),
  );

  return { bleedPolygons, contentPolygons, mediaBoxPolygons };
}
