import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import DropDown from 'editor/src/component/DropDown';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';

import styles from './index.module.scss';

interface Props {
  options: Array<{ imageUrl?: string; label: string }>;
  activeOptionIndex: number;
  selectOption: (index: number) => void;
  className?: string;
}

function PersonalisationDropDown({ options, activeOptionIndex, selectOption, className }: Props) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const close = useCallback(() => setDropdownVisible(false), []);
  const activeOption = options[activeOptionIndex];

  function onSelect(index: number) {
    selectOption(index);
    close();
  }

  return (
    <>
      <div className={cn(styles.PersonalisationDropDownBtn, className)} onClick={() => setDropdownVisible(true)}>
        {activeOption && (
          <>
            {activeOption.imageUrl && (
              <img
                crossOrigin="anonymous"
                src={activeOption.imageUrl}
                alt={activeOption.label}
                className={styles.image}
              />
            )}
            <div className={styles.label}>{activeOption.label}</div>
          </>
        )}
        <IconArrowDown />
      </div>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-left"
        className={cn(styles.PersonalisationDropDown, 'cy-personalization-condition-dropdown')}
        matchWidth
      >
        {options.map((option, i) => (
          <div
            key={i}
            className={cn(styles.dropdownOption, 'cy-dropdown-option', {
              [styles.active]: i === activeOptionIndex,
            })}
            onClick={() => onSelect(i)}
          >
            {option.imageUrl && (
              <img crossOrigin="anonymous" src={option.imageUrl} alt={option.label} className={styles.image} />
            )}
            <div className={styles.label}>{option.label}</div>
          </div>
        ))}
      </DropDown>
    </>
  );
}
export default React.memo(PersonalisationDropDown);
