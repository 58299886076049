import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { MediaImage, MediaAddon } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import IconEdit from 'editor/src/component/Icon/IconEdit';

import styles from './index.module.scss';

interface Props {
  element: MediaImage | MediaAddon;
  editImageCrop: () => void;
  uploadImage: () => void;
}

function PersonalizationSimpleImage({ element, editImageCrop, uploadImage }: Props) {
  const { imageUrl, crossOrigin } = useSelector((state) => getImageElementUrl(state, element, true), shallowEqual);
  const { t } = useTranslation();

  return (
    <div className={cn('g-selector-container', 'g-selector-image')}>
      <div className={cn(styles.label, 'g-selector-label')}>{element.name}</div>
      <div className={cn(styles.imageContainer, 'imageContainer')}>
        <img
          className={cn(styles.imgPreviewStyle)}
          key={(imageUrl || '') + (crossOrigin || '')} // needed to avoid some CORS issue with safari
          crossOrigin={crossOrigin}
          draggable={false}
          src={imageUrl}
          alt="Adjust"
          onClick={editImageCrop}
        />
        <IconEdit className={styles.imgEditIcon} />
        <button className={cn(styles.uploadBtn, 'cy-image-upload-btn')} onClick={uploadImage} type="button">
          {t('Replace image')}
        </button>
      </div>
    </div>
  );
}

export default React.memo(PersonalizationSimpleImage);
