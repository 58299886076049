import { StoreEnhancer } from '@reduxjs/toolkit';

/**
 * This is a custom enhancer that allows you to wrap dispatch calls into a batch function.
 * It will only notify subscribers once the batch is complete instead of each individual dispatch call.
 * The system just uses a simple counter to keep track of the batch depth. And it will not notify
 * subscribers until the batch depth is 0, meaning we are out of a batch call.
 * this is based on https://github.com/tappleby/redux-batched-subscribe
 */
function createEnhancer() {
  let batching = 0;
  let notified = false;
  const currentListeners: Array<() => void> = [];
  function notifyListeners() {
    if (batching !== 0) {
      return;
    }

    notified = true;
    for (let i = 0; i < currentListeners.length; i += 1) {
      currentListeners[i]();
    }
  }

  function batch<T>(cb: () => T) {
    batching += 1;
    const res = cb();
    batching -= 1;

    notifyListeners();
    return res;
  }

  function subscribe(listener: () => void) {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function.');
    }

    let isSubscribed = true;
    currentListeners.push(listener);

    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;

      const index = currentListeners.indexOf(listener);
      currentListeners.splice(index, 1);
    };
  }

  function batchedSubscribeEnhancer(): StoreEnhancer {
    return (next: (...any: any[]) => any) =>
      (...args: any[]) => {
        const store = next(...args);
        const subscribeImmediate = store.subscribe;

        function dispatch(...dispatchArgs: [any, any]) {
          notified = false;
          const res = store.dispatch(...dispatchArgs);
          if (!(res instanceof Promise) && !notified) {
            notifyListeners();
          }
          return res;
        }

        return {
          ...store,
          dispatch,
          subscribe,
          subscribeImmediate,
        };
      };
  }

  return { batch, batchedSubscribeEnhancer };
}

const { batch, batchedSubscribeEnhancer } = createEnhancer();

export { batch };

export default batchedSubscribeEnhancer;
