import {
  ExistingVariant,
  MultiOptions,
  ProductControlMulti,
  ProductControlSingle,
  ProductVariation,
  VariationProductControl,
} from 'editor/src/store/variants/types';

import extractOptionsFromVariations from './extractOptionsFromVariations';

function getInitialMultiOptions(
  productControls: VariationProductControl[],
  productVariations: ProductVariation[],
  defaultProductUids: string[],
  existingVariants: ExistingVariant[],
  singleMode: boolean,
) {
  const multiControls: ProductControlMulti[] = [];
  const singleControls: ProductControlSingle[] = [];

  productControls.forEach((control) => {
    if (control.type === 'single') {
      singleControls.push(control);
    } else if (control.type === 'multi') {
      multiControls.push(control);
    }
  });

  const multiOptions = multiControls.reduce<MultiOptions>((options, control) => {
    options[control.key] = [];
    return options;
  }, {});

  if (existingVariants.length) {
    const selectedVariations = existingVariants.reduce<ProductVariation[]>((variations, variant) => {
      const variation = productVariations.find((variation) => variation.productUid === variant.productUid);
      if (variation) {
        variations.push(variation);
      }
      return variations;
    }, []);
    extractOptionsFromVariations(multiOptions, selectedVariations, multiControls, singleControls, singleMode);
  } else if (defaultProductUids.length) {
    const productUidSet = new Set(defaultProductUids);
    const selectedVariations = productVariations.filter((variation) => productUidSet.has(variation.productUid));
    extractOptionsFromVariations(multiOptions, selectedVariations, multiControls, singleControls, singleMode);
  }

  return multiOptions;
}

export default getInitialMultiOptions;
