import { THUMBNAILS_HEIGHT_DESKTOP, THUMBNAILS_HEIGHT_MOBILE } from './BottomBarHeightProvider';

const getDefaultHeight = (isMobile: boolean, hasMultipleSpreads: boolean) => {
  if (!hasMultipleSpreads) {
    return 0;
  }

  return isMobile ? THUMBNAILS_HEIGHT_MOBILE : THUMBNAILS_HEIGHT_DESKTOP;
};

export default getDefaultHeight;
