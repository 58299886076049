import { PayloadAction } from '@reduxjs/toolkit';

import { AppState } from 'editor/src/store/app/types';

const setActiveAddLayerDropdownReducer = (
  state: AppState,
  { payload: activeAddLayerDropdown }: PayloadAction<string | undefined>,
) => {
  state.dropdowns.activeAddLayerDropdown = activeAddLayerDropdown;
};

export default setActiveAddLayerDropdownReducer;
