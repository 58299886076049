import React from 'react';
import { useTranslation } from 'react-i18next';

import { ECommerceVariant } from 'editor/src/store/variants/types';

import styles from './index.module.scss';

interface Props {
  variant: ECommerceVariant;
}
function CartItemDescription({ variant }: Props) {
  const { t } = useTranslation();

  return <span className={styles.itemDescription}>{variant.optionValues.map((value) => t(value)).join(' / ')}</span>;
}

export default React.memo(CartItemDescription);
