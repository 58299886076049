import { RootState } from '..';

import shouldDigitizeSpread from './shouldDigitizeSpread';

const shouldDigitizeSpreadIndex = (state: RootState, spreadIndex: number): boolean => {
  const spread = state.design.designData?.spreads[spreadIndex];
  return spread ? shouldDigitizeSpread(spread) : false;
};

export default shouldDigitizeSpreadIndex;
