import { ElementAddress, MediaElement } from 'editor/src/store/design/types';
import { SavedState } from 'editor/src/store/editorModules/undoRedo/types';
import { RootState } from 'editor/src/store/index';

const getMediaElement = <T extends MediaElement>(
  state: RootState | SavedState,
  { spreadIndex, pageIndex, elementIndex }: ElementAddress,
) => state.design.designData?.spreads[spreadIndex]?.pages[pageIndex]?.groups.media?.[elementIndex] as T | undefined;

export default getMediaElement;
