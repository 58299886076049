import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';

const getFullSizeImagePosition = (
  imageElement: MediaImage,
  dimensions: Dimensions,
  galleryImage: GalleryImage | undefined,
): (Dimensions & { px: number; py: number; pw: number; ph: number }) | undefined => {
  if (!imageElement.sourceFrame) {
    return undefined;
  }

  const { sourceFrame } = imageElement;
  let newFramePosition: Dimensions;
  if (sourceFrame.width / sourceFrame.height > dimensions.width / dimensions.height) {
    const width = (sourceFrame.height * dimensions.width) / dimensions.height;
    const { height } = sourceFrame;

    newFramePosition = { width, height };
  } else {
    const height = (sourceFrame.width * dimensions.height) / dimensions.width;
    const { width } = sourceFrame;

    newFramePosition = { width, height };
  }

  let imageElementPosition = {
    px: 0,
    py: 0,
    pw: newFramePosition.width,
    ph: newFramePosition.height,
  };
  if (galleryImage) {
    imageElementPosition = getCenteredImagePosition({ ...imageElement, ...newFramePosition }, galleryImage);
  }

  return { ...newFramePosition, ...imageElementPosition };
};

export default getFullSizeImagePosition;
