/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconEdit({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="20" height="20" fill="transparent" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.93259 18.4519L1.54807 14.0674M5.93259 18.4519L14.7502 9.63436L10.3656 5.24984L1.54807 14.0674M5.93259 18.4519L1 19L1.54807 14.0674M19 5.38453L14.6155 1L10.3994 5.21606L14.7839 9.60059L19 5.38453Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconEdit;
