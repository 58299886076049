import { fabric } from 'fabric';

interface FabricPath extends fabric.Path {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricPath = fabric.util.createClass(fabric.Path, {
  zIndex: 0,
}) as { new (...args: ConstructorParameters<typeof fabric.Path>): FabricPath };

export default FabricPath;
