import { PayloadAction } from '@reduxjs/toolkit';

import { PreviewState, SpreadPreview } from 'editor/src/store/editorModules/preview/types';

import { BACK_PREVIEW_KEY } from 'editor/src/component/Preview/FlatPreview';

const setSpreadPreviewReducer = (state: PreviewState, action: PayloadAction<SpreadPreview>) => {
  let previewKey = `${action.payload.spreadIndex}`;
  if (action.payload.isContentBack) {
    previewKey = BACK_PREVIEW_KEY;
  }
  state.spreadPreview[previewKey] = { url: action.payload.url, name: '' };
};

export default setSpreadPreviewReducer;
