import { createSlice } from '@reduxjs/toolkit';

import addVariantReducer from './reducer/addVariantReducer';
import saveMockupStructureReducer from './reducer/saveMockupStructureReducer';
import saveSceneStructureReducer from './reducer/saveSceneStructureReducer';
import setActiveProductReducer from './reducer/setActiveProductReducer';
import setMockDataReducer from './reducer/setMockDataReducer';
import setMockupDownloadingReducer from './reducer/setMockupDownloadingReducer';
import setMockupPreviewReducer from './reducer/setMockupPreviewReducer';
import setMultiOptionReducer from './reducer/setMultiOptionReducer';
import setPlaceholderImageReducer from './reducer/setPlaceholderProductImageReducer';
import setProductScenesReducer from './reducer/setProductScenesReducer';
import setScenePlaceholderMockDataReducer from './reducer/setScenePlaceholderMockDataReducer';
import setSelectedSceneIdReducer from './reducer/setSelectedSceneIdReducer';
import setSingleOptionReducer from './reducer/setSingleOptionReducer';
import setStoreProductsReducer from './reducer/setStoreProductsReducer';
import setStoreProductVariantsReducer from './reducer/setStoreProductVariantsReducer';
import setStoresReducer from './reducer/setStoresReducer';
import setVariantProductImageReducer from './reducer/setVariantProductImageReducer';
import updateMockupDataWithVariantReducer from './reducer/updateMockupDataWithVariantReducer';
import { MockupState } from './types';

export const initialState: MockupState = {
  scenes: {
    categories: [],
    structure: {},
    selectedSceneId: undefined,
  },
  mockup: {
    sizes: [],
    categories: [],
    structure: {},
  },
  productPlaceholder: {
    productImages: {},
    variantProductImages: {},
    variants: {},
    activeProductUid: '',
    variations: [],
    controls: [],
    singleOptions: {},
    multiOptions: {},
    sceneImages: {},
  },
  scenePlaceholder: {
    scenes: {},
    sceneCategories: {},
    productCategories: [],
  },
  preview: {
    downloading: false,
    appliedVariant: undefined,
    stores: { hasMore: true, entries: undefined },
    products: {},
    variants: {},
  },
};

export const slice = createSlice({
  name: 'mockup',
  initialState,
  reducers: {
    setMockDataAction: setMockDataReducer,
    setSingleOptionAction: setSingleOptionReducer,
    setMultiOptionAction: setMultiOptionReducer,
    addVariantAction: addVariantReducer,
    updateMockupDataWithVariantAction: updateMockupDataWithVariantReducer,
    setSelectedSceneIdAction: setSelectedSceneIdReducer,
    saveSceneStructureAction: saveSceneStructureReducer,
    saveMockupStructureAction: saveMockupStructureReducer,
    setStoresAction: setStoresReducer,
    setStoreProductsAction: setStoreProductsReducer,
    setStoreProductVariantsAction: setStoreProductVariantsReducer,
    setMockupPreviewAction: setMockupPreviewReducer,
    setMockupDownloadingAction: setMockupDownloadingReducer,
    setPlaceholderImageAction: setPlaceholderImageReducer,
    setProductScenesAction: setProductScenesReducer,
    setActiveProductAction: setActiveProductReducer,
    setVariantProductImageAction: setVariantProductImageReducer,
    setScenePlaceholderMockDataAction: setScenePlaceholderMockDataReducer,
  },
});

export const {
  setMockDataAction,
  setSingleOptionAction,
  setMultiOptionAction,
  addVariantAction,
  updateMockupDataWithVariantAction,
  setSelectedSceneIdAction,
  saveSceneStructureAction,
  saveMockupStructureAction,
  setStoresAction,
  setStoreProductsAction,
  setStoreProductVariantsAction,
  setMockupPreviewAction,
  setMockupDownloadingAction,
  setPlaceholderImageAction,
  setProductScenesAction,
  setActiveProductAction,
  setVariantProductImageAction,
  setScenePlaceholderMockDataAction,
} = slice.actions;

const mockupReducer = slice.reducer;

export default mockupReducer;
