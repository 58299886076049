import { GridDesign } from '@gelatoas/design-editor-calendar';

import { SpreadGroup } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import { FontDefinition } from 'editor/src/store/fonts/types';

import { RootState } from 'editor/src/store';

export type ReflectContext = {
  layouts: LayoutSchema[];
  groupedSpreadsPerProductUids: { [productUid: string]: SpreadGroup[] } | undefined;
  fonts?: FontDefinition[];
  gridDesigns?: GridDesign[];
};

function getReflectContext(
  state: RootState,
  groupedSpreadsPerProductUids = state.variants.groupedSpreadsPerProductUids,
) {
  const fonts = state.fonts.availableFonts;
  const gridDesigns = state.gridDesigns.grids;
  const layouts = state.editorModules.layouts.schemas;

  return {
    layouts,
    groupedSpreadsPerProductUids,
    fonts,
    gridDesigns,
  };
}

export default getReflectContext;
