import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Draft, VariantInfo } from 'product-personalizer/src/store/drafts/slice';

import getPreviewUrl from 'product-personalizer/src/utils/getPreviewUrl';

import Button from 'editor/src/component/Button';
import IconLoading from 'editor/src/component/Icon/IconLoading';
import IconPen from 'editor/src/component/Icon/IconPen';
import IconRemove from 'editor/src/component/Icon/IconRemove';

import styles from './index.module.scss';

interface Props {
  draft: Draft;
  onDelete: (draft: Draft) => void;
  onEdit: (draft: Draft, variant: VariantInfo) => void;
  variant: VariantInfo;
}

function DraftItem({ draft, onEdit, onDelete, variant }: Props) {
  const { t } = useTranslation();
  const [loadedUrl, setLoadedUrl] = useState('');

  useEffect(() => {
    const img = new Image();
    const url = getPreviewUrl(draft.designId);
    img.onload = () => setLoadedUrl(url);
    img.src = url;

    return () => {
      img.onload = null;
    };
  }, [draft.designId]);

  useEffect(() => {
    if (loadedUrl) {
      return undefined;
    }

    const imgDefault = new Image();
    imgDefault.onload = () => setLoadedUrl(variant.productExternalPreview);
    imgDefault.src = variant.productExternalPreview;

    return () => {
      imgDefault.onload = null;
    };
  }, [loadedUrl]);

  return (
    <div className={cn(styles.DraftItem, 'cy-draft-item')}>
      {loadedUrl ? (
        <img className={styles.image} src={loadedUrl} alt={variant.productTitle} />
      ) : (
        <div className={styles.loader}>
          <IconLoading />
        </div>
      )}

      <div className={cn(styles.content, 'ml-4')}>
        <div className={styles.title}>{variant.productTitle}</div>
        <div className={styles.options}>
          {variant.variantOptions.map((option) => `${option.name}: ${option.value}`).join(' - ')}
        </div>

        <div className={styles.buttons}>
          <Button secondary className={cn(styles.edit, 'cy-draft-item-edit')} onClick={() => onEdit(draft, variant)}>
            <IconPen />
            {t('editor-edit')}
          </Button>
          <Button secondary className={cn(styles.delete, 'cy-draft-item-delete')} onClick={() => onDelete(draft)}>
            <IconRemove />
            {t('Delete')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DraftItem);
