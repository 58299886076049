// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpreadControls--YLK_L{margin-top:24px}.label--HGxkL{font-size:14px;line-height:24px;color:#6b6b6b;display:block}.list--itJFO{display:flex;flex-wrap:wrap}.SpreadButton--Nhe91{margin-bottom:8px}.SpreadButton--Nhe91+.SpreadButton--Nhe91{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/component/SpreadControls/index.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CAGF,cACE,cAAA,CACA,gBAAA,CACA,aAAA,CACA,aAAA,CAGF,aACE,YAAA,CACA,cAAA,CAGF,qBACE,iBAAA,CAGF,0CACE,eAAA","sourcesContent":[".SpreadControls {\n  margin-top: 24px;\n}\n\n.label {\n  font-size: 14px;\n  line-height: 24px;\n  color: #6b6b6b;\n  display: block;\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.SpreadButton {\n  margin-bottom: 8px;\n}\n\n.SpreadButton + .SpreadButton {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpreadControls": `SpreadControls--YLK_L`,
	"label": `label--HGxkL`,
	"list": `list--itJFO`,
	"SpreadButton": `SpreadButton--Nhe91`
};
export default ___CSS_LOADER_EXPORT___;
