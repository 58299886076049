import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { addDigitizedAssetAction, updateDigitizedElementsAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { updateDigitizedElementsAction as updateDigitizedElementsVariantsAction } from 'editor/src/store/variants/slice';

import { SetDigitizedAssetData } from 'editor/src/util/postMessages/messages';

import { currentRequests } from './requestDigitizedAssetOperation';

const addDigitizedAssetOperation = (digitizedAssetData: SetDigitizedAssetData) => (dispatch: ThunkDispatch) => {
  currentRequests.delete(digitizedAssetData.elementId);

  batch(() => {
    dispatch(addDigitizedAssetAction(digitizedAssetData));
    dispatch(updateDigitizedElementsVariantsAction(digitizedAssetData));
    dispatch(updateDigitizedElementsAction(digitizedAssetData));
  });
};

export default addDigitizedAssetOperation;
