import { fabric } from 'fabric';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import getGroupRectangleRect from './getGroupRectangleRect';

const getGroupInternalStrokeClipPath = (objRect: ObjectRect, rounding: number) => {
  const groupMargin = 0.5;
  return new fabric.Rect({
    ...getGroupRectangleRect(objRect, groupMargin),
    rx: rounding,
    ry: rounding,
  });
};

export default getGroupInternalStrokeClipPath;
