import cn from 'classnames';
import React, { useCallback } from 'react';

import { CollectionAsset } from 'editor/src/store/collections/types';
import useCollectionFolder from 'editor/src/store/collections/utils/useCollectionFolder';
import updateCollectionImageOperation from 'editor/src/store/design/operation/updateCollectionImageOperation';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import {
  AdvancedPersonalisationElementData,
  CollectionDisplayOption,
} from 'editor/src/store/plugins/utils/advPersoUtils';
import { useDispatch } from 'product-personalizer/src/store/hooks';

import trackInteraction from 'product-personalizer/src/utils/trackInteraction';

import PersonalisationCollectionDropdown from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationImage/PersonalisationCollection/PersonalisationCollectionDropdown';

import PersonalisationCollectionThumbnails from './PersonalizationCollectionThumbnails';

import styles from './index.module.scss';

interface Props {
  image: MediaImage;
  collectionInfo: AdvancedPersonalisationElementData;
  address: ElementAddress;
  trackUserInteraction: boolean | undefined;
}

function PersonalisationCollection({ image, collectionInfo, address, trackUserInteraction }: Props) {
  const folder = useCollectionFolder(collectionInfo.folderId);

  const dispatch = useDispatch();

  const selectAsset = useCallback(
    (assetId: string) => {
      trackUserInteraction && trackInteraction();
      const asset = folder?.assets.find((asset) => asset.id === assetId) as CollectionAsset | undefined;
      if (address && asset) {
        dispatch(updateCollectionImageOperation(address, assetId, asset.url, asset.width, asset.height));
      }
    },
    [address, folder, trackUserInteraction],
  );

  let assetsElement: JSX.Element | null = null;
  if (folder) {
    switch (collectionInfo.displayOption) {
      case CollectionDisplayOption.Color:
        assetsElement = (
          <PersonalisationCollectionThumbnails
            display="color"
            image={image}
            folder={folder}
            selectAsset={selectAsset}
          />
        );
        break;
      case CollectionDisplayOption.Dropdown:
        assetsElement = <PersonalisationCollectionDropdown image={image} folder={folder} selectAsset={selectAsset} />;
        break;
      default:
        assetsElement = (
          <PersonalisationCollectionThumbnails
            display="thumbnail"
            image={image}
            folder={folder}
            selectAsset={selectAsset}
          />
        );
        break;
    }
  }

  return (
    <div className={cn(styles.PersonalisationCollection, 'g-selector-container', 'g-selector-collection')}>
      <div className={cn(styles.label, 'g-selector-label')}>{image.name}</div>
      {assetsElement}
    </div>
  );
}

export default React.memo(PersonalisationCollection);
