import { PayloadAction } from '@reduxjs/toolkit';

import { MockupPreviewVariant, MockupState } from 'editor/src/store/mockup/types';

const setStoreProductVariantsReducer = (
  state: MockupState,
  action: PayloadAction<{
    productId: string;
    variants: MockupPreviewVariant[];
    limit: number;
    offset: number;
  }>,
) => {
  const { productId, variants, limit, offset } = action.payload;
  const variant = state.preview.variants[productId];
  if (!variant.entries) {
    variant.entries = [];
  }
  variant.entries.splice(offset, 0, ...variants);
  variant.hasMore = variants.length === limit;
};

export default setStoreProductVariantsReducer;
