import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import canImageBeEmptied from 'editor/src/store/design/util/canImageBeEmptied';
import isLockedImageElementDeletionAllowed from 'editor/src/store/editor/selector/isLockedImageElementDeletionAllowed';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import getDeleteFrameWithImage from 'editor/src/store/hostSettings/selector/getDeleteFrameWithImage';
import { RootState } from 'editor/src/store/index';

import { DIMENSIONS } from 'editor/src/util/imagePlaceholder';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applyImageToElementOperation from './applyImageToElementOperation';
import removeMediaElementOperation from './removeMediaElementOperation';

const removeImagesPerImageIdOperation = (imageId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const design = state.design.designData;
  if (!design) {
    return;
  }

  const deleteFrameWithImage = getDeleteFrameWithImage(state);
  const canDeleteLockedImageElement = isLockedImageElementDeletionAllowed(state);

  batch(() => {
    design.spreads.forEach((spread) => {
      spread.pages[0].groups.media?.forEach((element) => {
        if (element.type !== 'image' || element.imageId !== imageId) {
          return;
        }

        if (canImageBeEmptied(element) && !deleteFrameWithImage) {
          dispatch(applyImageToElementOperation(element, '', DIMENSIONS));
        } else if (!element.locked || canDeleteLockedImageElement) {
          // index will have changed when removing more than one element in the same spread
          const address = getStructureIndexByElementUuid(getState().design.designData, element.uuid);
          if (address) {
            dispatch(removeMediaElementOperation(address, false));
          }

          if (element.imageId) {
            sendPostMessage('log.removeImageFrame', undefined);
          }
        }
      });
    });
  });
};

export default removeImagesPerImageIdOperation;
