import { fabric } from 'fabric';

import { Coords, MediaElement, MediaLine } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import { ObjectRect } from './Image/types';

function getElementRect(
  elementData: Exclude<MediaElement, MediaLine>,
  offset: Coords,
  canvasRotation: CanvasRotation,
  mm2px: (n: number) => number,
): ObjectRect {
  const coords = fabric.util.rotatePoint(
    new fabric.Point(offset.left + mm2px(elementData.x), offset.top + mm2px(elementData.y)),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const angle = elementData.r + canvasRotation.angleDeg;

  return {
    left: coords.x,
    top: coords.y,
    width: mm2px(elementData.width),
    height: mm2px(elementData.height),
    angle,
  };
}

export default getElementRect;
