import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const removeAllMediaElementsFromPageReducer = (
  state: DesignState,
  action: PayloadAction<{ spreadIndex: number; pageIndex: number }>,
) => {
  const { spreadIndex, pageIndex } = action.payload;
  const page = state.designData?.spreads[spreadIndex].pages[pageIndex];
  if (page) {
    page.groups.media = [];
  }
};

export default removeAllMediaElementsFromPageReducer;
