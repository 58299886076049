import { captureException } from '@sentry/react';

import { lastActions } from 'editor/src/store/actionLogListener';
import {
  ConditionGroup,
  conditionGroupChildenKey,
  DesignData,
  DropShadow,
  ImageBrushData,
  MediaElement,
  PrintTechnology,
  SpreadGroup,
} from 'editor/src/store/design/types';

import { getClosest1stOfTheMonth } from 'editor/src/util/dateUtils';
import handleSpreadGroupsForExistingProducts from 'editor/src/util/design/handleSpreadGroupsForExistingProducts';
import getSpreadDate from 'editor/src/util/getSpreadDate';
import getGridDefaults from 'editor/src/util/layouts/getGridDefault';
import { elementUuids } from 'editor/src/util/uuids';

import type { i18n } from 'i18next';

const HOURS_3 = 1000 * 60 * 60 * 3; // ms

export type ColorOverride = {
  imageId: string;
  elementUuid: number;
  overrides: ImageBrushData;
  quantized: boolean;
};

type LegacyShadow = Omit<DropShadow, 'type'> & { type?: 'drop-shadow' };

interface ControlDesignOptions {
  designData: DesignData;
  i18n: i18n;
  spreadGroups: SpreadGroup[] | undefined;
}

function isElementInGroup(element: MediaElement, conditionGroup: ConditionGroup): boolean {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const parentId in conditionGroup.children) {
    const child = conditionGroup.children[parentId];
    if (child.some((elt) => elt.id === element.uuid)) {
      return true;
    }
  }
  return false;
}

function controlDesignDataAndGetColorOverrides({ designData, i18n, spreadGroups }: ControlDesignOptions) {
  const colorOverrides: ColorOverride[] = [];
  const THREE_HOURS_AGO = Date.now() - HOURS_3;

  if (!designData.spread_groups && spreadGroups) {
    handleSpreadGroupsForExistingProducts(designData, spreadGroups);
  }

  const { page_count_limit, page_count } = designData;
  if (page_count_limit && page_count && (page_count < page_count_limit.min || page_count > page_count_limit.max)) {
    // eslint-disable-next-line no-console
    console.error('incorrect page count');
    captureException('Incorrect page count', {
      extra: {
        lastActions,
      },
    });
  }
  designData.spreads.forEach((spread) => {
    if (!spread.conditionGroup) {
      spread.conditionGroup = {
        children: { [conditionGroupChildenKey]: [] },
        conditions: {},
      };
    }

    spread.pages[0].groups.media?.forEach((mediaElement) => {
      // we expect the design data elements to either have uuids or not at all
      if (!mediaElement.uuid) {
        mediaElement.uuid = elementUuids.generate();
      }

      if (mediaElement.type === 'image') {
        if (mediaElement.colorOverrides) {
          if (
            mediaElement.colorOverrides.url &&
            mediaElement.colorOverrides.urlTimestamp &&
            mediaElement.colorOverrides.urlTimestamp >= THREE_HOURS_AGO
          ) {
            mediaElement.url = mediaElement.colorOverrides.url;
          } else {
            mediaElement.colorOverrides.urlTimestamp = Date.now(); // avoid multiple updates
            mediaElement.colorOverrides.url = undefined; // make sure no one use the existing url
            colorOverrides.push({
              elementUuid: mediaElement.uuid,
              imageId: mediaElement.imageId,
              overrides: mediaElement.colorOverrides,
              quantized: spread.technology === PrintTechnology.Embroidery,
            });
          }
        }

        // shadow didn't have a type before
        if (mediaElement.shadow && !(mediaElement.shadow as LegacyShadow).type) {
          mediaElement.shadow.type = 'drop-shadow';
        }

        if (mediaElement.shadow && mediaElement.shadow.blur > 1) {
          // deal with legacy blur values
          mediaElement.shadow.blur = mediaElement.shadow.blur > 100 ? 0 : mediaElement.shadow.blur / 100;
        }

        // if the host saved the design while uploading an image, we consider it empty and we clean the flags
        delete mediaElement.loading;
        delete mediaElement.uploading;
      }

      if (mediaElement.type === 'text' && mediaElement.html) {
        mediaElement.html = undefined;
      }

      if (mediaElement.type === 'grid') {
        // for legacy, grid info used to be on the spread
        if (!mediaElement.grid) {
          mediaElement.grid = (spread as any).grid ?? getGridDefaults(i18n, spread.name);
        }

        // legacy, we used to have a timestamp date
        if (!mediaElement.grid.gridDate) {
          const defaultDate = getSpreadDate(spread.name, undefined);
          const timestamp = (mediaElement.grid as any).date ?? new Date(defaultDate.year, defaultDate.month, 1);
          // temporarily dealing with time set with different timezones
          mediaElement.grid.gridDate = getClosest1stOfTheMonth(timestamp);
        }
      }
      delete (spread as any).grid;

      // legacy when condition group was optional, now we add it if it's missing
      if (
        !mediaElement.personalizationLocked &&
        spread.conditionGroup &&
        !isElementInGroup(mediaElement, spread.conditionGroup)
      ) {
        spread.conditionGroup.children[conditionGroupChildenKey].push({
          type: mediaElement.type,
          id: mediaElement.uuid,
        });
      }
    });
  });

  if (spreadGroups) {
    designData.spread_groups = spreadGroups;
  }

  if (!designData.lifecycle_metadata) {
    designData.lifecycle_metadata = {
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      edit_session_count: 0,
      edit_duration_seconds: 0,
    };
  } else {
    designData.lifecycle_metadata.edit_session_count += 1;
    designData.lifecycle_metadata.updated_at = new Date().toISOString();
  }

  return colorOverrides;
}

export default controlDesignDataAndGetColorOverrides;
