import { captureException } from '@sentry/react';

import getFontDefinition from 'editor/src/store/fonts/selector/getFontDefinition';
import { FontStatus } from 'editor/src/store/fonts/types';
import loadFont from 'editor/src/store/fonts/utils/loadFont';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import updateFontStatusOperation from './updateFontStatusOperation';

export const loadingPromise: { [fontFile: string]: Promise<any> } = {};

const loadFontOperation = (fontFile: string) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const fontDefinition = getFontDefinition(state, fontFile);
  if (!fontDefinition) {
    captureException(`unknown font: ${fontFile}`);
    return;
  }

  if (fontDefinition.status === FontStatus.ready) {
    return;
  }

  const url = fontDefinition.metadata.fileUrl;
  if (fontDefinition.status === FontStatus.loading && url in loadingPromise) {
    try {
      await loadingPromise[url];
    } catch {
      // we already catch and log this
    }
    return;
  }

  dispatch(updateFontStatusOperation({ fontFile, fontStatus: FontStatus.loading }));

  try {
    const promise = loadFont(fontDefinition, { executor: 'loadFontOperation', fontDefinition });
    loadingPromise[url] = promise;
    await promise;
    dispatch(updateFontStatusOperation({ fontFile, fontStatus: FontStatus.ready }));
  } catch {
    const def = getFontDefinition(getState(), fontFile);
    if (def?.status === FontStatus.loading) {
      dispatch(updateFontStatusOperation({ fontFile, fontStatus: FontStatus.failed }));
    }
  } finally {
    delete loadingPromise[url];
  }
};

export default loadFontOperation;
