import { ElementAddress } from 'editor/src/store/design/types';

export interface WarningsState {
  list: Warning[];
  variantWarnings: { [key: string]: Warning[] };
}

export type ElementWarning = DPIWarning | NotVisibleWarning | OutOfRangeWarning | SampleElementNotReplacedWarning;
export type GlobalWarning = EmptyDesignWarning;
export type SpreadWarning = MaxStitchCountWarning | MaxColorCountWarning | EmptyPageWarning | EmptyImageBoxWarning;
export type Warning = GlobalWarning | ElementWarning | SpreadWarning;

export function isElementWarning(warning: Warning): warning is ElementWarning {
  return elementWarningTypeSet.has(warning.type);
}

export function isGlobalWarning(warning: Warning): warning is EmptyDesignWarning {
  return globalWarningTypeSet.has(warning.type);
}

export function isSpreadWarning(warning: Warning): warning is SpreadWarning {
  return spreadWarningTypeSet.has(warning.type);
}

export enum WarningLevel {
  Medium = 'Medium',
  High = 'High',
}

interface ElementWarningBase {
  type: WarningType;
  uuid: number;
  spreadIndex: number;
  level: WarningLevel;
  isProceedBlocked?: boolean;
}

interface SpreadWarningBase {
  type: WarningType;
  spreadIndex: number;
  level: WarningLevel;
  isProceedBlocked?: boolean;
}

export interface DPIWarning extends ElementWarningBase {
  type: WarningType.LowDPI;
  dpi: number;
}

export interface NotVisibleWarning extends ElementWarningBase {
  type: WarningType.NotVisible;
}

export interface OutOfRangeWarning extends ElementWarningBase {
  type: WarningType.OutOfRange;
}

export interface SampleElementNotReplacedWarning extends ElementWarningBase {
  type: WarningType.SampleElementNotReplaced;
}

export interface MaxStitchCountWarning extends SpreadWarningBase {
  type: WarningType.MaxStitchCount;
  count: number;
}
export interface MaxColorCountWarning extends SpreadWarningBase {
  type: WarningType.MaxColorCount;
  count: number;
}

export interface EmptyPageWarning extends SpreadWarningBase {
  type: WarningType.EmptyPage;
}

export interface EmptyDesignWarning {
  type: WarningType.EmptyDesign;
  level: WarningLevel;
  isProceedBlocked?: boolean;
}

export interface EmptyImageBoxWarning extends SpreadWarningBase {
  type: WarningType.EmptyImageBox;
}

export enum WarningType {
  LowDPI = 'LowDPI',
  NotVisible = 'NotVisible',
  OutOfRange = 'OutOfRange',
  MaxStitchCount = 'MaxStitchCount',
  MaxColorCount = 'MaxColorCount',
  SampleElementNotReplaced = 'SampleElementNotReplaced',
  EmptyPage = 'EmptyPage',
  EmptyDesign = 'EmptyDesign',
  EmptyImageBox = 'EmptyImageBox',
}

interface ExportedElementWarningBase {
  type: WarningType;
  elementAddress: ElementAddress;
}

interface ExportedSpreadWarningBase {
  type: WarningType;
  spreadIndex: number;
}

export interface ExportedDPIWarning extends ExportedElementWarningBase {
  type: WarningType.LowDPI;
  dpi: number;
}

export interface ExportedNotVisibleWarning extends ExportedElementWarningBase {
  type: WarningType.NotVisible;
}

export interface ExportedOutOfRangeWarning extends ExportedElementWarningBase {
  type: WarningType.OutOfRange;
}

export interface ExportedSampleElementNotReplacedWarning extends ExportedElementWarningBase {
  type: WarningType.SampleElementNotReplaced;
}

export interface ExportedMaxStitchCountWarning extends ExportedSpreadWarningBase {
  type: WarningType.MaxStitchCount;
}

export interface ExportedMaxColorCountWarning extends ExportedSpreadWarningBase {
  type: WarningType.MaxColorCount;
}

export interface ExportedEmptyPageWarning extends ExportedSpreadWarningBase {
  type: WarningType.EmptyPage;
}

export interface ExportedEmptyDesignWarning {
  type: WarningType.EmptyDesign;
}

export interface ExportedEmptyImageBoxWarning extends ExportedSpreadWarningBase {
  type: WarningType.EmptyImageBox;
}

export type ExportedWarning =
  | ExportedDPIWarning
  | ExportedNotVisibleWarning
  | ExportedOutOfRangeWarning
  | ExportedMaxStitchCountWarning
  | ExportedMaxColorCountWarning
  | ExportedSampleElementNotReplacedWarning
  | ExportedEmptyPageWarning
  | ExportedEmptyDesignWarning
  | ExportedEmptyImageBoxWarning;

export type ExtendedWarning =
  | (DPIWarning & ExportedDPIWarning)
  | (NotVisibleWarning & ExportedNotVisibleWarning)
  | (OutOfRangeWarning & ExportedOutOfRangeWarning)
  | (SampleElementNotReplacedWarning & ExportedSampleElementNotReplacedWarning)
  | (MaxStitchCountWarning & ExportedMaxStitchCountWarning)
  | (MaxColorCountWarning & ExportedMaxColorCountWarning)
  | (EmptyPageWarning & ExportedEmptyPageWarning)
  | (EmptyDesignWarning & ExportedEmptyDesignWarning)
  | (EmptyImageBoxWarning & ExportedEmptyImageBoxWarning);

const elementWarningTypeSet = new Set([
  WarningType.LowDPI,
  WarningType.NotVisible,
  WarningType.OutOfRange,
  WarningType.SampleElementNotReplaced,
]);
const spreadWarningTypeSet = new Set([
  WarningType.MaxColorCount,
  WarningType.MaxStitchCount,
  WarningType.EmptyPage,
  WarningType.EmptyImageBox,
]);
const globalWarningTypeSet = new Set([WarningType.EmptyDesign]);
