import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import hideLoaderOperation from 'editor/src/store/app/operation/hideLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { useDispatch } from 'editor/src/store/hooks';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';
import { useSelector } from 'product-personalizer/src/store/hooks';

import useSetupColorConversion from 'editor/src/util/color/useSetupColorConversion';

import { DIGITIZATION_PREVIEW_SIZE } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useDigitizedImage';
import useCanvasRendering from 'editor/src/component/SpreadPreview/useCanvasRendering';
import useDetectDeviceType, { DeviceTypeContext, mobileDevices } from 'editor/src/component/useDetectDeviceType';
import IconLoading from 'product-personalizer/src/component/IconLoading';

import DesktopCarousel from './Carousel/DesktopCarousel';
import MobileCarousel from './Carousel/MobileCarousel';
import PreviewDialog from './PreviewDialog';
import useDigitizeElements from './useDigitizedElements';
import useHandlePostMessage from './useHandlePostMessage';
import useSpreadPreviews from './useSpreadPreviews';

import styles from './index.module.scss';

interface Props {
  loadResource: boolean;
  hideImageSelector?: true;
  useFirstSpreadIfNoPersonalization?: true;
  hidePreviewScrollBar?: boolean;
}

function PreviewView({
  loadResource,
  hideImageSelector,
  useFirstSpreadIfNoPersonalization,
  hidePreviewScrollBar,
}: Props) {
  const deviceType = useDetectDeviceType();
  useSetupColorConversion();
  const dispatch = useDispatch();
  const isMobile = mobileDevices.has(deviceType);
  const { requestRender } = useCanvasRendering();
  const imageUrls = useSelector((state) => state.productPersonalizer.productImages) ?? [];
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);
  const designIsLoading = useSelector((state) => loadResource && state.productPersonalizer.designIsLoading);

  const { isLoading, designKey, designData, currentSpreadIndex } = useSelector(
    (state) => ({
      designKey: state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
      isLoading: state.app.activeLoaders[LoaderType.ProductPersonalizerPreview],
      designData: state.design.designData,
      currentSpreadIndex: state.editor.currentSpreadIndex,
    }),
    shallowEqual,
  );

  const spreadsWithPersonalization = useMemo(() => {
    if (!designData) {
      return [];
    }

    const personalizableSpreadIndexes = designData.spreads.reduce<number[]>((acc, spread, spreadIndex) => {
      if (spread.conditionGroup && Object.keys(spread.conditionGroup.conditions).length > 0) {
        acc.push(spreadIndex);
      } else if (spread.pages[0].groups.media?.some((media) => !media.personalizationLocked)) {
        acc.push(spreadIndex);
      }

      return acc;
    }, []);

    // if no spreads selected for live preview take the first one
    if (!personalizableSpreadIndexes.length && useFirstSpreadIfNoPersonalization) {
      return [0];
    }

    return personalizableSpreadIndexes;
  }, [designKey, useFirstSpreadIfNoPersonalization]);

  // if first spread doesn't contain any personalization we should  select the next one that has personalization
  useEffect(() => {
    if (spreadsWithPersonalization[0] > 0) {
      dispatch(setCurrentSpreadIndexOperation(spreadsWithPersonalization[0]));
    }
  }, [spreadsWithPersonalization]);

  useEffect(() => {
    const index = spreadsWithPersonalization.indexOf(currentSpreadIndex);
    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, [currentSpreadIndex]);

  const onSelectedPreviewChange = useCallback(
    (index: number) => {
      if (index < spreadsWithPersonalization.length) {
        dispatch(setCurrentSpreadIndexOperation(spreadsWithPersonalization[index]));
      }
      setSelectedIndex(index);
    },
    [spreadsWithPersonalization],
  );

  useHandlePostMessage();

  const calculatePreviewSize = useCallback(
    (isDigitizationPreview: boolean): number => {
      let previewSize = 100;

      if (isMobile) {
        previewSize = document.documentElement.clientWidth;
      } else if (isDigitizationPreview) {
        previewSize = DIGITIZATION_PREVIEW_SIZE;
      }
      if (showPreviewDialog) {
        previewSize = Math.min(document.documentElement.clientWidth, 2000);
        if (isMobile) {
          previewSize *= 2;
        }
      }
      return previewSize;
    },
    [isMobile, showPreviewDialog],
  );

  useDigitizeElements(designData, currentSpreadIndex, calculatePreviewSize(true));

  const { previews } = useSpreadPreviews(
    requestRender,
    spreadsWithPersonalization,
    `${designKey}-${calculatePreviewSize(false)}`,
    {
      dimension: 'both',
      value: calculatePreviewSize(false),
    },
  );

  useEffect(() => {
    if (previews.every(Boolean)) {
      dispatch(hideLoaderOperation(LoaderType.ProductPersonalizerPreview));
    }
  }, [previews]);

  const previewUrls = useMemo(() => [...previews.map((p) => p?.dataURL ?? '')], [previews]);

  const images = useMemo(() => [...previewUrls, ...imageUrls], [previewUrls, imageUrls]);

  useEffect(() => {
    dispatch(hideLoaderOperation(LoaderType.ProductPersonalizerPreview));
  });

  const openPreviewDialog = useCallback(() => {
    if (!hideImageSelector) {
      setShowPreviewDialog(true);
    }
  }, [hideImageSelector]);

  const hidePreviewDialog = useCallback(() => {
    setShowPreviewDialog(false);
  }, []);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      <div
        className={cn(styles.PreviewView, {
          [styles.dialogOpened]: showPreviewDialog,
          [styles.hideImageSelector]: hideImageSelector,
        })}
      >
        {isMobile ? (
          <MobileCarousel
            images={images}
            showPreviewDialog={openPreviewDialog}
            selectedIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
            hideImageSelector={hideImageSelector}
          />
        ) : (
          <DesktopCarousel
            spreadIndexes={spreadsWithPersonalization}
            images={images}
            selectedImageIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
            showPreviewDialog={openPreviewDialog}
            isLoading={designIsLoading}
            hideImageSelector={hideImageSelector}
            hidePreviewScrollBar={hidePreviewScrollBar}
          />
        )}
        <div className={cn(styles.loader, { [styles.loaded]: !isLoading })}>{isLoading && <IconLoading />}</div>
        {showPreviewDialog && (
          <PreviewDialog
            isMobile={isMobile}
            images={images}
            selectedIndex={selectedIndex}
            onIndexChanged={onSelectedPreviewChange}
            onClose={hidePreviewDialog}
          />
        )}
      </div>
    </DeviceTypeContext.Provider>
  );
}

export default PreviewView;
