import getFocusArea from 'editor/src/store/design/operation/getFocusArea';
import getSpreadContentElementsWithPossibleMirroring from 'editor/src/store/design/selector/getSpreadContentElementsWithPossibleMirroring';
import { Content, Spread } from 'editor/src/store/design/types';

function getContentAreaFromSpread(spread: Spread | undefined, focusedContent?: Content | undefined) {
  const contentElements = spread ? getSpreadContentElementsWithPossibleMirroring(spread) : undefined;

  if (!contentElements?.length) {
    return undefined;
  }

  return getFocusArea(contentElements, focusedContent);
}

export default getContentAreaFromSpread;
