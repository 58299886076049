import { Dimensions, MediaImage } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';

import { getPatternRepeat } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';

function getCenteredImagePosition(element: MediaImage, { width, height }: Dimensions) {
  const elementWidth = Math.max(element.minDimensions?.width ?? 0, element.width);
  const elementHeight = Math.max(element.minDimensions?.height ?? 0, element.height);

  const scale = element.pattern
    ? getPatternRepeat(
        element.pattern.scale,
        element.pattern.scaleBase,
        element.width,
        element.height,
        elementWidth,
        elementHeight,
      )
    : 1;

  let imageElementWidth;
  let imageElementHeight;
  if (elementWidth / elementHeight > width / height) {
    imageElementWidth = Math.max(elementWidth / scale, element.width);
    imageElementHeight = (imageElementWidth * height) / width;
  } else {
    imageElementHeight = Math.max(elementHeight / scale, element.height);
    imageElementWidth = (imageElementHeight * width) / height;
  }

  const px = (element.width - imageElementWidth) / 2;
  const py = (element.height - imageElementHeight) / 2;

  return {
    px: limitPrecision(px),
    py: limitPrecision(py),
    pw: limitPrecision(imageElementWidth),
    ph: limitPrecision(imageElementHeight),
  };
}

export default getCenteredImagePosition;
