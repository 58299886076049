import { fabric } from 'fabric';
import { MultiPolygon } from 'polygon-clipping';

import { Coords, Page } from 'editor/src/store/design/types';

import FabricCircle from 'editor/src/fabric/FabricCircle';

import holeElementDataToFabricProps from 'editor/src/component/EditorArea/Spread/Page/Hole/holeElementDataToFabricProps';
import { getGradient } from 'editor/src/component/EditorArea/Spread/Page/Hole/utils';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

const addHoles = (
  fabricCanvas: fabric.StaticCanvas,
  page: Page,
  offset: Coords,
  mm2px: (size: number) => number,
  rotation: CanvasRotation,
  contentAreaPolygons: MultiPolygon,
  contentClipPath: fabric.Object | undefined,
) => {
  const holes = page.groups.hole;
  if (!holes?.length) {
    return;
  }

  holes.forEach((hole) => {
    const { left, top, radius } = holeElementDataToFabricProps(hole, offset, mm2px, rotation);

    const boundingRect: ObjectRect = {
      left,
      top,
      width: radius * 2,
      height: radius * 2,
      angle: 0,
    };

    const clipPath = getClipPath(boundingRect, contentAreaPolygons, false, contentClipPath);

    const circle = new FabricCircle({
      top,
      left,
      radius,
      strokeWidth: 0,
      fill: getGradient(radius, 0, 1),
      clipPath,
      zIndex: zIndex.HOLE,
    });
    fabricCanvas.add(circle);
  });
};

export default addHoles;
