import { MediaAddon, MediaImage } from 'editor/src/store/design/types';

import CropFilter from 'editor/src/component/EditorArea/fabricFilters/CropFilter';
import GaussianBlurFilter from 'editor/src/component/EditorArea/fabricFilters/GaussianBlurFilter';
import ShadowFilter from 'editor/src/component/EditorArea/fabricFilters/ShadowFilter';

import { SHADOW_OFFSET_SCALE } from './index';

const getImageShadowFilters = (element: MediaImage | MediaAddon, color: string, blur: number) => [
  new CropFilter({
    inside: true,
    x: -element.px / element.pw,
    y: -element.py / element.ph,
    width: element.width / element.pw,
    height: element.height / element.ph,
    angle: element.pr,
  }),
  new ShadowFilter({
    shadowColor: color,
    scale: SHADOW_OFFSET_SCALE,
  }),
  new GaussianBlurFilter({ blur }),
];

export default getImageShadowFilters;
