import { captureException } from '@sentry/react';

import { CrossOrigin } from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';

const RETRIES = 2;
export class AssetDownloadError extends Error {
  constructor(src: string, executor: string) {
    super(`${src}: ${executor}`);
    this.name = 'AssetDownloadError';
  }
}

function loadImageDimensionLoop<T extends { executor: string }>(
  src: string,
  crossOrigin: CrossOrigin,
  context: T,
  logError: boolean,
  retry = RETRIES,
): Promise<{ width: number; height: number }> {
  return new Promise((res, rej) => {
    const img = new Image();

    function onError() {
      img.onerror = null;
      img.onload = null;
      if (retry <= 0) {
        if (!logError) {
          rej();
          return;
        }

        captureException(new AssetDownloadError(src, context.executor), {
          extra: { context, src, crossOrigin },
        });
        rej();
      } else {
        window.setTimeout(
          () => {
            loadImageDimensionLoop(src, crossOrigin, context, logError, retry - 1).then(res, rej);
          },
          (RETRIES + 1 - retry) * 1000,
        );
      }
    }

    img.crossOrigin = crossOrigin || null;

    // hack to get the img dimensions ASAP. As soon as the header is downloaded, the browser can give us the dimensions
    const handle = window.setInterval(() => {
      if (img.width !== 0 && img.height !== 0) {
        onDimensionAvailable();
      }
    }, 20);

    const onDimensionAvailable = () => {
      window.clearInterval(handle);
      res({ width: img.width || 1, height: img.height || 1 });
      img.onload = null;
      img.onerror = null;
      img.src = '';
    };

    img.onload = () => {
      if (!img.width || !img.height) {
        onError();
      } else {
        onDimensionAvailable();
      }
    };
    img.onerror = onError;
    img.src = src;

    if (img.complete && img.width && img.height) {
      onDimensionAvailable();
    }
  });
}

function loadImageDimensions<T extends { executor: string }>(
  src: string,
  crossOrigin: CrossOrigin,
  context: T,
  logError = true,
): Promise<{ width: number; height: number }> {
  return loadImageDimensionLoop(src, crossOrigin, context, logError);
}

export default loadImageDimensions;
