import { fabric } from 'fabric';

import { Coords, Hole } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

const holeElementDataToFabricProps = (
  hole: Hole,
  pageCoords: Coords,
  mm2px: (n: number) => number,
  canvasRotation: CanvasRotation,
) => {
  const { x: left, y: top } = fabric.util.rotatePoint(
    new fabric.Point(mm2px(hole.x) + pageCoords.left, mm2px(hole.y) + pageCoords.top),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );

  return {
    radius: mm2px(hole.diameter / 2),
    top,
    left,
  };
};

export default holeElementDataToFabricProps;
