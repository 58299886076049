import omit from 'lodash/omit';
import { shallowEqual } from 'react-redux';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCurrentState from 'editor/src/store/editorModules/undoRedo/selector/getCurrentState';
import getPreviousStates from 'editor/src/store/editorModules/undoRedo/selector/getPreviousStates';
import getPreviousStatesCount from 'editor/src/store/editorModules/undoRedo/selector/getPreviousStatesCount';
import { pushPreviousStateAction, clearNextStatesAction } from 'editor/src/store/editorModules/undoRedo/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const saveUndoRedoStateOperation = (key: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.editorModules.undoRedo.saveSteps) {
    return;
  }

  const stepsCount = getPreviousStatesCount(state);
  const steps = getPreviousStates(state);
  const stateToSave = getCurrentState(state);
  const lastStep = steps[stepsCount - 1];

  if (!lastStep || !shallowEqual(omit(lastStep, ['key']), stateToSave)) {
    batch(() => {
      dispatch(clearNextStatesAction());
      dispatch(pushPreviousStateAction({ key, ...stateToSave }));
    });
  }
};

export default saveUndoRedoStateOperation;
