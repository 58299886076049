import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { Dimensions } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import getNewElementDisplay from './getNewElementDisplay';
import getNewImageDisplaySize from './getNewImageDisplaySize';

const updateImagesAfterDropOperation =
  (imageId: string, dimensions: Dimensions) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    batch(() => {
      state.design.designData?.spreads.forEach((spread, spreadIndex) => {
        spread.pages[0].groups.media?.forEach((media, elementIndex) => {
          if (media.type === 'image' && media.imageId === imageId) {
            const display = getNewElementDisplay(state, spreadIndex, (area) =>
              getNewImageDisplaySize(area, dimensions.width, dimensions.height),
            );

            if (display) {
              const centerDiff = getPointPositionRotatedOnPoint(
                (display.width - media.width) / 2,
                (display.height - media.height) / 2,
                0,
                0,
                media.r,
              );
              dispatch(
                updateMediaElementAction({
                  elementAddress: { spreadIndex, pageIndex: 0, elementIndex },
                  elementUpdate: {
                    width: display.width,
                    height: display.height,
                    pw: display.width,
                    ph: display.height,
                    x: media.x - centerDiff[0],
                    y: media.y - centerDiff[1],
                    px: 0,
                    py: 0,
                    pr: 0,
                  },
                }),
              );
            }
          }
        });
      });
    });
  };

export default updateImagesAfterDropOperation;
