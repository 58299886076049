import { Metric } from 'web-vitals';

export enum ImageSource {
  Graphic = 'graphic',
  Addon = 'addon',
  Collection = 'collection',
  Maps = 'map',
  Unknown = 'unknown',
}

export type DesignDataStats = {
  isEmpty: boolean;
  pluginUsed: string[];
  imageMimeTypes: string[];
  conditionCount: number;
  usedFontFamilies: string[];
  usedFilters: string[];
  variants: {
    enabled: boolean;
    productCount: number;
    productUids: string[];
    allLinked: boolean;
  };
  elements: { [type: string]: { count: number; personalizable: number } };
  lowDPIImageCount: number;
  mediumDPIImageCount: number;
  textWithNotDefaultLetterSpacingCount: number;
  textWithNotDefaultShadowsCount: number;
  layouts: string[];
};

type FileUpload = {
  sizeKb: number;
  type: string;
  totalTime: number;
  totalSpeedKbs: number;
  uploadTime: number;
  uploadSpeedKbs: number;
  processTime: number;
  processSpeedKbs: number;
  source: string | undefined;
  status: 'upload-failed' | 'processing-failed' | 'success';
};

export type AmplitudeEvents = {
  'element added': { type: 'line' } | { type: 'rectangle' } | { type: 'collection' };
  'element modified':
    | { type: 'image'; property: 'filter'; value: string }
    | { type: 'image'; property: 'colorOverrides'; colorChangedCount: number }
    | { type: 'image'; property: 'shadow'; value: boolean }
    | { type: 'text'; property: 'size'; value: number }
    | { type: 'text'; property: 'letter-spacing'; value: number }
    | { type: 'text'; property: 'shadow'; value: boolean }
    | { type: 'text'; property: 'stroke'; value: boolean };
  'condtion created': undefined;
  'condtion deleted': undefined;
  'apply layout': { layoutName: string };
  'editor closed': DesignDataStats;
  'design loaded': DesignDataStats;
  'design changed': DesignDataStats;
  'tab opened': { name: string };
  'preview opened': { type: 'flat' | '3d' | 'mockup' | 'unknown' };
  'fonts searched': string[];
  'file dropped': { count: number; types: string[] };
  'web vitals: CLS': Metric;
  'web vitals: FID': Metric;
  'web vitals: LCP': Metric;
  'file uploaded': FileUpload;
};
