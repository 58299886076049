import { PayloadAction } from '@reduxjs/toolkit';

import { AppState, TopMenuDropdown } from 'editor/src/store/app/types';

const setActiveTopMenuDropdownReducer = (
  state: AppState,
  { payload: activeTopMenuDropdown }: PayloadAction<TopMenuDropdown | undefined>,
) => {
  state.dropdowns.activeTopMenuDropdown = activeTopMenuDropdown;
};

export default setActiveTopMenuDropdownReducer;
