import { PayloadAction } from '@reduxjs/toolkit';

import { ProductSizeOption, VariantsState } from 'editor/src/store/variants/types';

const resetControlWithOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{ controlKey: string; option: ProductSizeOption }>,
) => {
  const { controlKey, option } = action.payload;

  const control = state.product.externalProductControls.find((control) => control.key === controlKey);
  if (control) {
    control.options = [option];
  }
};

export default resetControlWithOptionReducer;
