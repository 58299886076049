import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryImage, GalleryState, ImageState } from 'editor/src/store/gallery/types';

export type UpdateImageReducerPayload = Partial<GalleryImage> & {
  id: string;
};

const updateImageReducer = (state: GalleryState, action: PayloadAction<UpdateImageReducerPayload>) => {
  const image = state.images.find((image) => image.id === action.payload.id);
  if (image) {
    Object.assign(image, action.payload);

    if (image.previewData && image.state === ImageState.UPLOADED) {
      URL.revokeObjectURL(image.previewData);
      image.previewData = undefined;
    }
  }
};

export default updateImageReducer;
