// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploaderMobileMenu--dkbpP{height:75vh;display:flex;flex-direction:column}.header--a3Byo{display:flex;align-items:center;justify-content:space-between}.title--o94gF{font-family:"Gelato Sans",sans-serif;font-size:22px;margin:0}.uploaderPlaceholder--zc2m5{overflow:auto}`, "",{"version":3,"sources":["webpack://./../editor/src/component/BottomBarMobile/ActionBarSection/UploaderMobileMenu/index.module.scss"],"names":[],"mappings":"AAEA,2BACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,eACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,cACE,oCAAA,CACA,cAAA,CACA,QAAA,CAGF,4BACE,aAAA","sourcesContent":["@import 'editor/src/comonStyles/variables';\n\n.UploaderMobileMenu {\n  height: 75vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.title {\n  font-family: \"Gelato Sans\", sans-serif;\n  font-size: 22px;\n  margin: 0;\n}\n\n.uploaderPlaceholder {\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UploaderMobileMenu": `UploaderMobileMenu--dkbpP`,
	"header": `header--a3Byo`,
	"title": `title--o94gF`,
	"uploaderPlaceholder": `uploaderPlaceholder--zc2m5`
};
export default ___CSS_LOADER_EXPORT___;
