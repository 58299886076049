import { PreviewAssetAPI, PreviewConfigList } from './PreviewAssetAPI';

const previewAssetAPIEmbedded: PreviewAssetAPI = {
  fetchAssets: (productUid: string) => {
    return fetch(`${LOCAL_ENV.ecomProxyEndpoint}/product/preview/assets?productUid=${productUid}`).then(
      (response) => response.json() as Promise<PreviewConfigList>,
    );
  },
};

export default previewAssetAPIEmbedded;
