import { fabric } from 'fabric';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import CustomControl from './FabricPathText/FabricControl';

interface FabricImage extends fabric.Image {
  zIndex: number;
  isLoaded: boolean;
  frameRect?: ObjectRect;
  uuid?: number;
}

interface IFabricImageOptions extends fabric.IImageOptions {
  zIndex?: number;
}

export function isFabricImage(object: fabric.Object): object is FabricImage {
  return object.type === 'gelato-image';
}

type FabricImageClass = {
  new (img?: ConstructorParameters<typeof fabric.Image>[0], options?: IFabricImageOptions): FabricImage;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricImage = fabric.util.createClass(fabric.Image, {
  type: 'gelato-image',
  zIndex: 0,

  initialize(element: HTMLImageElement | string, options?: IFabricImageOptions) {
    this.callSuper('initialize', element, options);
    const commonControlOptions = {
      cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    };
    this.controls.ml = new CustomControl({
      x: -0.5,
      y: 0,
      orientation: 'vertical',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      ...commonControlOptions,
    });

    this.controls.mr = new CustomControl({
      x: 0.5,
      y: 0,
      orientation: 'vertical',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      ...commonControlOptions,
    });

    this.controls.mt = new CustomControl({
      x: 0,
      y: -0.5,
      orientation: 'horizontal',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      ...commonControlOptions,
    });

    this.controls.mb = new CustomControl({
      x: 0,
      y: 0.5,
      orientation: 'horizontal',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      ...commonControlOptions,
    });
  },

  _createCacheCanvas() {
    this.callSuper('_createCacheCanvas');
    this._cacheCanvas.id = `fabric-cache-${this.type}-${this.uuid ?? ''}`;
  },
}) as FabricImageClass;

export default FabricImage;
