import { Plugin, PluginName } from 'editor/src/store/plugins/types';

import { GenericPluginTabInfo, PluginTabInfo, TAB_NAMES } from './types';

const tabPluginMap: { [tab in TAB_NAMES]?: Set<PluginName> } = {
  // [TAB_NAMES.ADDONS]: PluginName.Graphics, // i removed it because on OP it's managed manually
  [TAB_NAMES.SHUTTERSTOCK]: new Set([PluginName.Shutterstock]),
  [TAB_NAMES.PERSONALIZE]: new Set([PluginName.AdvancedPersonalisation, PluginName.personalizationStudio]),
};

export function isTabManagedByPlugin(tab: TAB_NAMES): boolean {
  return !!tabPluginMap[tab];
}

export const pluginOrder: string[] = [
  PluginName.Graphics,
  PluginName.Shutterstock,
  PluginName.AdvancedPersonalisation,
  PluginName.personalizationStudio,
];

const getPluginTabs = (plugins: Plugin[]): Array<PluginTabInfo | GenericPluginTabInfo> => {
  return plugins.reduce((tabs: Array<PluginTabInfo | GenericPluginTabInfo>, plugin: Plugin) => {
    switch (plugin.name) {
      case PluginName.Graphics:
        tabs.push({
          type: 'plugin',
          id: TAB_NAMES.ADDONS,
          translationKey: 'editor-clipart',
          iconName: 'addons',
          title: plugin.displayName || plugin.title,
          plugin: plugin.name,
        });
        break;
      case PluginName.Shutterstock:
        tabs.push({
          type: 'plugin',
          id: TAB_NAMES.SHUTTERSTOCK,
          translationKey: 'editor-shutterstock',
          iconName: 'shutterstock',
          title: plugin.displayName || plugin.title,
          plugin: plugin.name,
        });
        break;
      case PluginName.AdvancedPersonalisation:
      case PluginName.personalizationStudio:
        tabs.push({
          type: 'plugin',
          id: TAB_NAMES.PERSONALIZE,
          translationKey: 'Personalize',
          iconName: 'personalization',
          title: plugin.displayName || plugin.title,
          plugin: plugin.name,
        });

        if (!plugin.extra?.hideCollections) {
          tabs.push({
            type: 'plugin',
            id: TAB_NAMES.COLLECTIONS,
            translationKey: 'editor-collections',
            iconName: 'collections',
            title: plugin.displayName || plugin.title,
            plugin: plugin.name,
          });
        }
        break;
      default:
        break;
    }

    if (plugin.tab) {
      tabs.push({
        type: 'generic-plugin',
        id: `plugin-${plugin.name}`,
        iconUrl: plugin.icon || '',
        title: plugin.displayName || plugin.title || plugin.name,
        plugin: plugin.name,
      });
    }

    return tabs;
  }, []);
};

export default getPluginTabs;
