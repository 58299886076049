import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';
import { CollectionDisplayOption, getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

const setCollectionDisplayOptionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ElementAddress;
    displayOption: CollectionDisplayOption;
  }>,
) => {
  const { address, displayOption } = action.payload;
  const element =
    state.designData?.spreads[address.spreadIndex].pages[address.pageIndex].groups.media?.[address.elementIndex];
  const collectionPlugin = element && getAdvPersoDataFromElement(element);
  if (collectionPlugin) {
    collectionPlugin.displayOption = displayOption;
  }
};

export default setCollectionDisplayOptionReducer;
