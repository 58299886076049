/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconVibrance({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0005 16L1.00049 1L19.0005 1L10.0005 16Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle r="2.5" cx="10" cy="6" strokeWidth="2" />
      </svg>
    </Icon>
  );
}

export default IconVibrance;
