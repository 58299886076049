import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const removeUnlockSizeRatioMediaElementReducer = (state: EditorState, action: PayloadAction<number>) => {
  const index = state.unlockSizeRatioElementUuids.indexOf(action.payload);
  if (index !== -1) {
    state.unlockSizeRatioElementUuids.splice(index, 1);
  }
};

export default removeUnlockSizeRatioMediaElementReducer;
