import { PayloadAction } from '@reduxjs/toolkit';

import { ContentAddress, DesignState, MirroringMode } from 'editor/src/store/design/types';

const setMirroringModeReducer = (
  state: DesignState,
  action: PayloadAction<{
    contentAddress: ContentAddress;
    mirroringMode: MirroringMode | undefined;
  }>,
) => {
  const { contentAddress, mirroringMode } = action.payload;
  const { spreadIndex, pageIndex, contentIndex } = contentAddress;

  const content = state?.designData?.spreads[spreadIndex].pages[pageIndex].groups?.content?.[contentIndex];
  if (content) {
    if (!content.extra) {
      content.extra = {};
    }
    content.extra.mirroring = mirroringMode;
  }
};

export default setMirroringModeReducer;
