import limitPrecision from '../limitPrecision';

import RGBA from './rgbaUtils';

class HSVA {
  h: number;
  s: number;
  v: number;
  a: number;

  constructor({ h, s, v, a }: { h: number; s: number; v: number; a: number }) {
    this.h = h;
    this.s = s;
    this.v = v;
    this.a = a;
  }

  private static hsvaRegExp =
    /^hsva\(\s*(\d+(\.\d+)?)\s*,\s*(\d+(\.\d+)?)%\s*,\s*(\d+(\.\d+)?)%\s*,\s*(\d+(\.\d+)?)\s*\)$/i;

  static fromString(color: string): HSVA | undefined {
    const match = color.match(HSVA.hsvaRegExp);
    if (!match) {
      return undefined;
    }

    const h = parseFloat(match[1]);
    const s = parseFloat(match[3]);
    const v = parseFloat(match[5]);
    const a = parseFloat(match[7]);

    return new HSVA({
      h: limitPrecision(h, 2),
      s: limitPrecision(s, 2),
      v: limitPrecision(v, 2),
      a: limitPrecision(a, 2),
    });
  }

  toString(): string {
    return `hsva(${this.h},${this.s}%,${this.v}%,${this.a})`;
  }

  toRGB(): RGBA {
    const s = this.s / 100;
    const v = this.v / 100;

    // eslint-disable-next-line no-bitwise
    const i = ~~(this.h / 60);
    const f = this.h / 60 - i;
    const p = v * (1 - s);
    const q = v * (1 - s * f);
    const t = v * (1 - s * (1 - f));
    const index = i % 6;

    const r = Math.round([v, q, p, p, t, v][index] * 255);
    const g = Math.round([t, v, v, q, p, p][index] * 255);
    const b = Math.round([p, p, t, v, v, q][index] * 255);

    return new RGBA({ r, g, b, a: this.a });
  }

  static fromRGB(rgba: RGBA): HSVA {
    const r = rgba.r / 255;
    const g = rgba.g / 255;
    const b = rgba.b / 255;

    const max = Math.max(r, g, b);
    const d = max - Math.min(r, g, b);

    // eslint-disable-next-line no-nested-ternary
    const h = d ? (max === r ? (g - b) / d + (g < b ? 6 : 0) : max === g ? 2 + (b - r) / d : 4 + (r - g) / d) * 60 : 0;
    const s = max ? (d / max) * 100 : 0;
    const v = max * 100;

    return new HSVA({ h: limitPrecision(h, 2), s: limitPrecision(s, 2), v: limitPrecision(v, 2), a: rgba.a });
  }
}

export const DEFAULT_HSVA = new HSVA({ h: 0, s: 0, v: 0, a: 1 });

export default HSVA;
