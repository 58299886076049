import { RootState } from 'editor/src/store/index';

import { isEmbedded } from '../../watchers/useDisplayMode';

const isRemoveElementsAllowed = (state: RootState) =>
  isEmbedded
    ? !!state.design?.designData?.personalizationSettings?.allowAddElements
    : state.hostSettings.allowRemoveElements;

export default isRemoveElementsAllowed;
