import { openPopupAction } from 'editor/src/store/app/slice';
import type { Thunk } from 'editor/src/store/hooks';

import { PopupData, PopupName } from 'editor/src/component/Popup/popups';

const openPopupOperation =
  <A extends PopupName>(open: A, data?: PopupData[A]): Thunk =>
  (dispatch) => {
    dispatch(openPopupAction({ open, data }));
  };

export default openPopupOperation;
