import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Kodachrome = createClass(filters.ColorMatrix, {
  type: 'GelatoKodachrome',
  value: 0,
  mainParameter: 'value',

  calculateMatrix() {
    const coloMatrix = [
      1.12855, -0.39673, -0.03992, 0, 0.24991, -0.16404, 1.08352, -0.05498, 0, 0.09698, -0.16786, -0.56034, 1.60148, 0,
      0.13972, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal));
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      value: this.value,
    });
  },
});

Kodachrome.fromObject = (fabric.Image.filters.ColorMatrix as any).fromObject;
(fabric.Image.filters as any).GelatoKodachrome = Kodachrome;

export default Kodachrome;
