import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { SystemTabNames, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import removeAllSelectedMediaElementsOperation from './removeAllSelectedMediaElementsOperation';
import selectFirstEmptyImageFrameOnCurrentPageOperation from './selectFirstEmptyImageFrameOnCurrentPage';
import stopCropModeOperation from './stopCropModeOperation';

const resetActiveElementsOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    dispatch(setActiveTopMenuDropdownOperation(undefined));
    dispatch(removeAllSelectedMediaElementsOperation());
    dispatch(stopCropModeOperation());

    const state = getState();
    const isGalleryActive = isSidebarTabActive(state, TAB_NAMES.GALLERY);
    const activeTab = getSidebarActiveTab(state);
    if (isGalleryActive) {
      dispatch(selectFirstEmptyImageFrameOnCurrentPageOperation(true));
    } else if (SystemTabNames.indexOf(activeTab as TAB_NAMES) !== -1) {
      dispatch(setSidebarActiveTabOperation(0));
    }
  });
};

export default resetActiveElementsOperation;
