/* eslint-disable max-len */
import React, { SVGProps } from 'react';

function IconPen(svgProps: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58361 5.60356L12.3239 8.34389M6.4581 14.2821L3.71777 11.5418L9.5625 5.62466L12.3028 8.36499L6.4581 14.2821Z"
        stroke="#212121"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5625 5.625L12.3028 8.36533M14.6248 6.11533L11.8844 3.375L9.58361 5.60389L12.3239 8.34422L14.6248 6.11533Z"
        stroke="#212121"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71754 11.543L6.45787 14.2833L3.375 14.6258L3.71754 11.543Z"
        fill="#212121"
        stroke="#212121"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconPen);
