import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

import { EditorState } from 'editor/src/store/editor/types';

interface setMobileMenuActionPayload {
  isOpen: boolean;
}

const setMobileMenuReducer = (state: EditorState, action: PayloadAction<setMobileMenuActionPayload>) => {
  state.mobileMenu = action.payload;
};

export default setMobileMenuReducer;
