import { createSlice } from '@reduxjs/toolkit';

import setCanvasSizeReducer from './reducer/setCanvasSizeReducer';
import { CanvasState } from './types';

export const canvasInitialState: CanvasState = {
  width: 0,
  height: 0,
};

export const slice = createSlice({
  name: 'canvas',
  initialState: canvasInitialState,
  reducers: {
    setCanvasSizeAction: setCanvasSizeReducer,
  },
});

export const { setCanvasSizeAction } = slice.actions;

export default slice.reducer;
