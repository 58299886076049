import { PayloadAction } from '@reduxjs/toolkit';

import { DisabledSpread, EditorState } from 'editor/src/store/editor/types';

const disableSpreadReducer = (state: EditorState, action: PayloadAction<DisabledSpread>) => {
  const spreadInfo = action.payload;
  const idx = state.disabledSpreads.findIndex((spread) => spread.spreadIndex === spreadInfo.spreadIndex);
  if (idx === -1) {
    state.disabledSpreads.push(spreadInfo);
  } else {
    // avoid creating a new object each time
    state.disabledSpreads[idx].information = spreadInfo.information;
  }
};

export default disableSpreadReducer;
