import React from 'react';

import IconDraft from '../IconDraft';

import styles from './index.module.scss';

interface Props {
  onClick: () => void;
}

function OpenDraftsItem({ onClick }: Props) {
  return (
    <button type="button" className={styles.button} aria-label="Open Drafts" onClick={onClick}>
      <IconDraft />
    </button>
  );
}

export default React.memo(OpenDraftsItem);
