import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryImage, GalleryState } from 'editor/src/store/gallery/types';

const replaceImageReducer = (
  state: GalleryState,
  action: PayloadAction<{
    prevImageId: string;
    image: Omit<GalleryImage, 'uuid'>;
  }>,
) => {
  const { prevImageId, image } = action.payload;
  // important thing here is to keep the previous indexes
  const imageIndex = state.images.findIndex((image) => image.id === prevImageId);
  if (imageIndex !== -1) {
    state.images[imageIndex] = {
      ...image,
      uuid: state.images[imageIndex].uuid,
    };
  }
};

export default replaceImageReducer;
