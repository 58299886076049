import { ImageManipulationPluginData, MediaImage } from 'editor/src/store/design/types';
import { Plugin } from 'editor/src/store/plugins/types';
import containsImageManipulationPlugin from 'editor/src/store/watchers/checkPluginUse/containsImageManipulationPlugin';

// eslint-disable-next-line import/prefer-default-export
export function getImageManipulationPluginData(
  pluginName: string,
  mediaImage: Partial<MediaImage> | undefined,
): ImageManipulationPluginData | undefined {
  if (!mediaImage?.plugins || !mediaImage.plugins[pluginName]) {
    return undefined;
  }

  return mediaImage.plugins[pluginName];
}

export function getAppliedManipulationPluginNames(mediaImage: Partial<MediaImage>, plugins: Plugin[]): string[] {
  return plugins.filter((plugin) => containsImageManipulationPlugin(mediaImage, plugin)).map((plugin) => plugin.name);
}
