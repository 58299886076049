import { PayloadAction } from '@reduxjs/toolkit';

import { ProductImage } from 'editor/src/store/design/types';
import { MockupState } from 'editor/src/store/mockup/types';

const setPlaceholderImageReducer = (
  state: MockupState,
  action: PayloadAction<{ productUid: string; image: ProductImage }>,
) => {
  const { productUid, image } = action.payload;
  state.productPlaceholder.productImages[productUid] = image;
};

export default setPlaceholderImageReducer;
