function getBoxesMinMax(boxes: Array<{ x: number; y: number; width: number; height: number }>) {
  let left = boxes[0]?.x ?? 0;
  let right = boxes[0] ? boxes[0].x + boxes[0].width : 0;
  let top = boxes[0]?.y ?? 0;
  let bottom = boxes[0] ? boxes[0].y + boxes[0].height : 0;
  boxes.forEach((box) => {
    if (box.x < left) {
      left = box.x;
    }
    if (box.x + box.width > right) {
      right = box.x + box.width;
    }
    if (box.y < top) {
      top = box.y;
    }
    if (box.y + box.height > bottom) {
      bottom = box.y + box.height;
    }
  });
  return {
    left,
    right,
    top,
    bottom,
    width: right - left,
    height: bottom - top,
  };
}

export default getBoxesMinMax;
