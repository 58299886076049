import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setCurrentSpreadIndexAction } from 'editor/src/store/editor/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import resetActiveElementsOperation from './resetActiveElementsOperation';

const setCurrentSpreadIndexOperation = (spreadIndex: number) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setCurrentSpreadIndexAction(spreadIndex));
    dispatch(resetActiveElementsOperation());
  });
};

export default setCurrentSpreadIndexOperation;
