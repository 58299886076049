import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

import { DesignData } from '../../design/types';

export function updateDesign(designData: DesignData, digitizing_hash: string, stitchCount: number) {
  designData.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      if (element.digitizing_hash === digitizing_hash) {
        element.digitizing = undefined;
        element.stitch_count = stitchCount;
      }
    });
  });
}

const updateDigitizedElementsReducer = (
  state: VariantsState,
  action: PayloadAction<{ elementId: string; stitchCount: number }>,
) => {
  const { elementId, stitchCount } = action.payload;
  state.variationGroups.forEach((variationGroup) => {
    variationGroup.variationsInfo.forEach((variationInfo) => {
      if (variationInfo.designData) {
        updateDesign(variationInfo.designData, elementId, stitchCount);
      }
    });
  });
};

export default updateDigitizedElementsReducer;
