import { fabric } from 'fabric';

import { Coords, MediaAddon, MediaImage } from 'editor/src/store/design/types';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { POINT_0_0 } from 'editor/src/component/EditorArea/useCanvasRotation';

import { ObjectRect } from './types';

function getImageRect(
  elementData: MediaImage | MediaAddon,
  offset: Coords,
  canvasRotation: CanvasRotation,
  mm2px: (n: number) => number,
): ObjectRect {
  const imageInsideCoords = fabric.util.rotatePoint(
    new fabric.Point(elementData.px, elementData.py),
    POINT_0_0,
    fabric.util.degreesToRadians(elementData.r),
  );
  const imageCoords = fabric.util.rotatePoint(
    new fabric.Point(
      offset.left + mm2px(elementData.x + imageInsideCoords.x),
      offset.top + mm2px(elementData.y + imageInsideCoords.y),
    ),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );
  const imageAngle = elementData.r + canvasRotation.angleDeg + elementData.pr;

  const imageRect: ObjectRect = {
    left: imageCoords.x,
    top: imageCoords.y,
    width: mm2px(elementData.pw),
    height: mm2px(elementData.ph),
    angle: imageAngle,
  };

  return imageRect;
}

export default getImageRect;
