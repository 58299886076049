import getNewImageDisplaySize from 'editor/src/store/design/operation/getNewImageDisplaySize';
import { MediaMockupPlaceholder } from 'editor/src/store/design/types';
import { ImageLayoutFrameArea, LayoutFrame } from 'editor/src/store/editorModules/layouts/types';
import { MockupRole, MockupState } from 'editor/src/store/mockup/types';
import findFirstMockupProduct from 'editor/src/store/mockup/utils/findFirstMockupProduct';

import createImageMediaElement from './createImageMediaElement';

const createMockupPlaceholderElement = (
  frame: LayoutFrame,
  frameArea: ImageLayoutFrameArea,
  defaultPersonalizationLock: boolean,
  elementName: string,
  mockupState: MockupState,
  spreadWidth: number,
  spreadHeight: number,
): MediaMockupPlaceholder | undefined => {
  const { variants, activeProductUid } = mockupState.productPlaceholder;
  const productUid =
    activeProductUid || findFirstMockupProduct(mockupState.scenePlaceholder.productCategories)?.productUid;
  if (!productUid) {
    return undefined;
  }

  const variantDimensions = variants[productUid] ?? { width: 50, height: 50 };

  const { width, height } = getNewImageDisplaySize(
    { width: spreadWidth, height: spreadHeight },
    variantDimensions.width,
    variantDimensions.height,
    0.5,
  );

  const placeholder: MediaMockupPlaceholder = {
    ...createImageMediaElement(frame, frameArea, defaultPersonalizationLock, elementName),
    role: MockupRole.Placeholder,
    static: undefined,
    locked: undefined,
    variant: {
      productUid,
      width: variantDimensions.width,
      height: variantDimensions.height,
    },
    // TODO make a good initial ratio here
    width,
    height,
    pw: width,
    ph: height,
    name: 'default',
  };

  placeholder.x -= placeholder.width / 2;
  placeholder.y -= placeholder.height / 2;

  return placeholder;
};

export default createMockupPlaceholderElement;
