import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import clearImagesOrRemoveElementsOperation from 'editor/src/store/design/operation/clearImagesOrRemoveElementsOperation';
import getImageStructureIndexes from 'editor/src/store/design/util/getImageStructureIndexes';
import removeHistoryImageOperation from 'editor/src/store/editorModules/undoRedo/operation/removeHistoryImageOperation';
import { removeImageAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { PluginEventName } from 'editor/src/store/plugins/types';
import dispatchPluginOperations from 'editor/src/store/plugins/utils/dispatchPluginOperations';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const removeImageOperation =
  (imageId: string, noPlugin = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const structureIndexes = getImageStructureIndexes(state.design, imageId);

    batch(() => {
      if (!noPlugin) {
        dispatchPluginOperations(PluginEventName.BeforeElementAssetRemoved, dispatch, [imageId]);
      }

      dispatch(clearImagesOrRemoveElementsOperation(structureIndexes));
      dispatch(removeImageAction(imageId));
      dispatch(removeHistoryImageOperation(imageId));
      sendPostMessage('uploader.removeFile', { imageId });
    });
  };

export default removeImageOperation;
