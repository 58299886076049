import { createSlice } from '@reduxjs/toolkit';

import addDraftReducer from './reducer/addDraftReducer';
import deleteDraftReducer from './reducer/deleteDraftReducer';
import setDraftsReducer from './reducer/setDraftsReducer';
import setVariantsReducer from './reducer/setVariantsReducer';
import toggleSidebarReducer from './reducer/toggleSidebarReducer';
import updateDraftReducer from './reducer/updateDraftReducer';
import { getStoredVariantData } from './utils/variantStorage';

export interface VariantInfo {
  productId: string; // shopify product id,
  variantId: string; // shopify variant id,
  externalVariantId: string; // variant id stored on gelato side,
  productTitle: string; // Title of the product,
  productHandle: string; // URL of the product,
  productExternalPreview: string; // URL to the product image,
  variantOptions: { name: string; value: string }[]; // Array of variant options,
}
export interface Draft {
  designId: string;
  externalVariantId: string; // e-com variant id
  variantId: string | undefined; // gelato id if exist
}

export interface DraftState {
  drafts: Draft[];
  variants: { [variantId: string]: VariantInfo };
  sidebarOpen: boolean;
}

export const initialState: DraftState = {
  drafts: [],
  variants: getStoredVariantData(),
  sidebarOpen: false,
};

const slice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    setVariantsAction: setVariantsReducer,
    setDraftsAction: setDraftsReducer,
    addDraftAction: addDraftReducer,
    updateDraftAction: updateDraftReducer,
    deleteDraftAction: deleteDraftReducer,
    toggleSidebarAction: toggleSidebarReducer,
  },
});

export const {
  addDraftAction,
  deleteDraftAction,
  setVariantsAction,
  setDraftsAction,
  toggleSidebarAction,
  updateDraftAction,
} = slice.actions;

const { reducer } = slice;

export default reducer;
