// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section--eeCFc{margin-bottom:10px}.section--eeCFc:last-child{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Popup/AbstractPopup/Section/index.module.scss"],"names":[],"mappings":"AAEA,gBACE,kBAAA,CAEA,2BACE,eAAA","sourcesContent":["@import 'editor/src/comonStyles/variables';\n\n.section {\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `section--eeCFc`
};
export default ___CSS_LOADER_EXPORT___;
