/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

function IconEdit({ className }: Props) {
  return (
    <div className={cn(className)}>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.40906 15.9091H6.47156L14.2841 8.09659L13.2216 7.03409L5.40906 14.8466V15.9091ZM4.66285 17.4091C4.45199 17.4091 4.27364 17.3376 4.12781 17.1946C3.98197 17.0515 3.90906 16.8743 3.90906 16.6629V14.8534C3.90906 14.6544 3.94378 14.4646 4.01322 14.2841C4.08267 14.1035 4.19378 13.9369 4.34656 13.7841L14.2841 3.84659C14.4368 3.69381 14.6024 3.5827 14.7806 3.51325C14.9589 3.44381 15.1464 3.40909 15.3431 3.40909C15.5399 3.40909 15.7285 3.44381 15.9091 3.51325C16.0896 3.5827 16.2563 3.69381 16.4091 3.84659L17.4716 4.90909C17.6243 5.06187 17.7354 5.22853 17.8049 5.40909C17.8743 5.58964 17.9091 5.77396 17.9091 5.96205C17.9091 6.16266 17.8742 6.35387 17.8044 6.53567C17.7347 6.71749 17.6237 6.88363 17.4716 7.03409L7.53406 16.9716C7.38128 17.1244 7.21496 17.2355 7.0351 17.3049C6.85525 17.3744 6.66609 17.4091 6.46762 17.4091H4.66285ZM13.7435 7.57465L13.2216 7.03409L14.2841 8.09659L13.7435 7.57465Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
}

export default IconEdit;
