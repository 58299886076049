import { Polygon } from 'polygon-clipping';
import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react';

import { MediaAddon, MediaImage, DropShadow } from 'editor/src/store/design/types';

import FabricImage from 'editor/src/fabric/FabricImage';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import FabricImageComponent, {
  CrossOrigin,
} from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import getImageShadowFilters from './getImageShadowFilters';
import getShadowRect from './getShadowRect';

interface Props {
  source: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement;
  crossOrigin: CrossOrigin;
  element: MediaImage | MediaAddon;
  zIndex: number;
  frameRect: ObjectRect;
  shadow: DropShadow;
  contentClipPolygons?: Polygon[];
  contentClipPath: fabric.Object | undefined;
}

export interface Ref {
  udpate: (rect: ObjectRect) => void;
  sourceUpdate: (source: HTMLCanvasElement) => void;
}

export const SHADOW_OFFSET_SCALE = 1.2;

const ImageShadow = React.forwardRef(
  (
    { element, source, crossOrigin, zIndex, contentClipPolygons, frameRect, shadow, contentClipPath }: Props,
    ref: React.Ref<Ref>,
  ) => {
    const fabricCanvas = useFabricCanvas();
    const imageRef = useRef<FabricImage>(null);
    const shadowRect = useMemo(() => getShadowRect(frameRect, shadow), [frameRect, shadow]);
    const clipPath = useMemo(
      () =>
        contentClipPolygons || contentClipPath
          ? getClipPath(shadowRect, contentClipPolygons || [], false, contentClipPath)
          : undefined,
      [shadowRect, contentClipPolygons, contentClipPath],
    );

    const getShadowFabricOptionsOnUpdate = useCallback(
      (image: FabricImage) => {
        const img = image.getElement();
        const scaleX = (frameRect.width / img.width) * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1);
        const scaleY = (frameRect.height / img.height) * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1);
        return { scaleX, scaleY };
      },
      [frameRect.width, frameRect.height, shadow.scale],
    );

    const filters = useMemo(
      () => getImageShadowFilters(element, shadow.color, shadow.blur),
      [element, shadow.color, shadow.blur],
    );

    useImperativeHandle(
      ref,
      () => ({
        udpate: (rect: ObjectRect) => {
          if (!imageRef.current) {
            return;
          }

          const shadowRect = getShadowRect(rect, shadow);
          const clipPath = contentClipPolygons ? getClipPath(shadowRect, contentClipPolygons, false) : undefined;
          const { left, top, angle } = shadowRect;
          const img = imageRef.current.getElement();
          const scaleX = (rect.width / img.width) * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1);
          const scaleY = (rect.height / img.height) * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1);
          imageRef.current.set({
            left,
            top,
            angle,
            clipPath,
            scaleX,
            scaleY,
          });
        },
        sourceUpdate: (source: HTMLCanvasElement) => {
          if (!imageRef.current) {
            return;
          }
          imageRef.current.setElement(source as any);
          imageRef.current.set(getShadowFabricOptionsOnUpdate(imageRef.current));
          imageRef.current.applyFilters();
          fabricCanvas.requestRenderAll();
        },
      }),
      [shadow, contentClipPolygons, getShadowFabricOptionsOnUpdate],
    );

    return (
      <FabricImageComponent
        ref={imageRef}
        source={source}
        crossOrigin={crossOrigin}
        angle={shadowRect.angle}
        left={shadowRect.left}
        top={shadowRect.top}
        evented={false}
        zIndex={zIndex}
        opacity={element.hidden ? 0 : 1}
        getFabricOptionsOnUpdate={getShadowFabricOptionsOnUpdate}
        filters={filters}
        flipX={element.flipX}
        flipY={element.flipY}
        clipPath={clipPath}
      />
    );
  },
);

export default React.memo(ImageShadow);
