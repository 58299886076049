import { LayoutFrame, LayoutOffset, LayoutPadding, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import limitPrecision from 'editor/src/util/limitPrecision';

import numberToOffset from './numberToOffset';
import numberToPadding from './numberToPadding';

const generateLayout = (
  schema: LayoutSchema,
  pageOffsetX: number,
  pageOffsetY: number,
  pageWidth: number,
  pageHeight: number,
  overridePadding?: number,
  overrideOffset?: number,
  overrideSpacing?: number,
): LayoutFrame[] => {
  const padding = overridePadding ? numberToPadding(overridePadding, schema.params.padding) : schema.params.padding;
  const offset = overrideOffset ? numberToPadding(overrideOffset, schema.params.offset) : schema.params.offset;
  const spacing = overrideSpacing ? numberToOffset(overrideSpacing) : schema.params.spacing;

  return generateFrames(schema, pageOffsetX, pageOffsetY, pageWidth, pageHeight, padding, offset, spacing);
};

const getAreaSize = (size: number, areaSize: number, spaceBetween: number) =>
  size * areaSize + spaceBetween * (size - 1);
const getAreaOffset = (offset: number, areaWidth: number, spaceBetween: number) =>
  offset * areaWidth + spaceBetween * offset;

const generateFrames = (
  schema: LayoutSchema,
  pageOffsetX: number,
  pageOffsetY: number,
  pageWidth: number,
  pageHeight: number,
  padding: LayoutPadding,
  offset: LayoutPadding,
  spacing: LayoutOffset,
): LayoutFrame[] => {
  const frames: LayoutFrame[] = [];
  const paddingLeft = padding.left + offset.left;
  const paddingRight = padding.right + offset.right;
  const paddingTop = padding.top + offset.top;
  const paddingBottom = padding.bottom + offset.bottom;

  const defaultAreaWidth =
    (pageWidth - paddingLeft - paddingRight - (schema.params.cols - 1) * spacing.horizontal) / schema.params.cols;
  const defaultAreaHeight =
    (pageHeight - paddingTop - paddingBottom - (schema.params.rows - 1) * spacing.vertical) / schema.params.rows;

  const shiftStepX = defaultAreaWidth / schema.params.shiftSteps;
  const shiftStepY = defaultAreaHeight / schema.params.shiftSteps;

  let rowOffset = 0;

  schema.frameRows.forEach((row, rowIndex) => {
    let areaOffset = 0;
    row.forEach((area, colIndex) => {
      const {
        shiftX = 0,
        shiftY = 0,
        shiftWidth = 0,
        shiftHeight = 0,
        padding = {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
      } = area;

      const type = area.type || 'image';
      const width =
        getAreaSize(area.width ?? 0, defaultAreaWidth, spacing.horizontal) +
        shiftStepX * (shiftWidth - shiftX) -
        (padding.left + padding.right);
      const height =
        getAreaSize(area.height ?? 0, defaultAreaHeight, spacing.vertical) +
        shiftStepY * (shiftHeight - shiftY) -
        (padding.top + padding.bottom);

      if (area.freePlacement) {
        frames.push({
          frameArea: area,
          x: (pageWidth - width) / 2,
          y: (pageHeight - height) / 2,
          width,
          height,
          id: area.id || `${schema.name}-${type}-${rowIndex}-${colIndex}`,
        });
        return;
      }

      if (!area.width || !area.height) {
        areaOffset += 1;
        return;
      }

      frames.push({
        frameArea: area,
        x: limitPrecision(
          pageOffsetX +
            paddingLeft +
            getAreaOffset(areaOffset, defaultAreaWidth, spacing.horizontal) +
            shiftStepX * shiftX +
            padding.left,
        ),
        y: limitPrecision(
          pageOffsetY +
            paddingTop +
            getAreaOffset(rowOffset, defaultAreaHeight, spacing.vertical) +
            shiftStepY * shiftY +
            padding.top,
        ),
        width: Math.max(0, limitPrecision(width)),
        height: Math.max(0, limitPrecision(height)),
        id: area.id || `${schema.name}-${type}-${rowIndex}-${colIndex}`,
      });
      areaOffset += area.width;
    });
    rowOffset += 1;
  });

  return frames;
};

export default generateLayout;
