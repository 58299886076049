import React from 'react';

import LocalConfirmationPopup from 'editor/src/component/Popup/LocalConfirmationPopup';

import styles from './index.module.scss';

function PersonalizerConfirmationPopup() {
  return (
    <LocalConfirmationPopup className={styles.confirmationPopup} fadeMaskClassName={styles.confirmationPopupFadeMask} />
  );
}

export default React.memo(PersonalizerConfirmationPopup);
