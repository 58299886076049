import type { ThunkDispatch } from 'editor/src/store/hooks';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

import { updateImageAction } from '../../gallery/slice';

const setQuantizedAssetOperation =
  (
    imageData: InboundPostMessage['design.setQuantizedAsset'] & {
      assetId?: string;
    },
  ) =>
  (dispatch: ThunkDispatch) => {
    dispatch(
      updateImageAction({
        id: imageData.assetId ?? imageData.file.id,
        quantizedUrl: imageData.quantizedUrl,
        quantizedColors: imageData.file.quantizedColors,
      }),
    );
  };

export default setQuantizedAssetOperation;
