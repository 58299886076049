import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { updateDigitizedElementsAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { updateDigitizedElementsAction as updateDigitizedElementsVariantsAction } from 'editor/src/store/variants/slice';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

import { currentRequests } from './requestDigitizedAssetOperation';

const setDigitizedAssetErrorOperation =
  (digitizedAssetData: InboundPostMessage['design.setDigitizedAssetError']) => (dispatch: ThunkDispatch) => {
    currentRequests.delete(digitizedAssetData.elementId);

    batch(() => {
      dispatch(
        updateDigitizedElementsVariantsAction({
          elementId: digitizedAssetData.elementId,
          stitchCount: -1,
        }),
      );
      dispatch(
        updateDigitizedElementsAction({
          elementId: digitizedAssetData.elementId,
          stitchCount: -1,
        }),
      );
    });
  };

export default setDigitizedAssetErrorOperation;
