import { VariationProductControl, ProductVariation, SingleOptions } from 'editor/src/store/variants/types';

function getInitialSingleOptions(
  productControls: VariationProductControl[],
  defaultVariation: ProductVariation,
): SingleOptions {
  return productControls.reduce<SingleOptions>((options, control) => {
    const value = defaultVariation[control.key];
    if (control.type === 'single' && value !== undefined) {
      options[control.key] = value;
    }
    return options;
  }, {});
}

export default getInitialSingleOptions;
