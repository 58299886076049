import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { addConditionGroupAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const addConditionGroupOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  batch(() => {
    state.design.designData?.spreads.forEach((spread, spreadIndex) => {
      if (!spread.conditionGroup) {
        dispatch(addConditionGroupAction({ spreadIndex }));
      }
    });
  });
};

export default addConditionGroupOperation;
