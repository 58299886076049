import { MockupPreviewVariant } from 'editor/src/store/mockup/types';

const getVariantProductImageKey = (variant: MockupPreviewVariant) => {
  let key = variant.id;
  if (variant.scene) {
    key = `${key}-${variant.scene}`;
  }
  return key;
};

export default getVariantProductImageKey;
