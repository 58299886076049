import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setInstalledPluginsReducer = (state: DesignState, action: PayloadAction<string[]>) => {
  if (!state.designData) {
    return;
  }
  state.designData.plugins = action.payload;
};

export default setInstalledPluginsReducer;
