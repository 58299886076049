import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Technicolor = createClass(filters.ColorMatrix, {
  type: 'GelatoTechnicolor',
  value: 0,
  mainParameter: 'value',

  calculateMatrix() {
    const coloMatrix = [
      1.91252, -0.85453, -0.09155, 0, 0.04624, -0.30878, 1.76589, -0.10601, 0, -0.27589, -0.2311, -0.75018, 1.84759, 0,
      0.12137, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal));
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      value: this.value,
    });
  },
});

Technicolor.fromObject = (fabric.Image.filters.ColorMatrix as any).fromObject;
(fabric.Image.filters as any).GelatoTechnicolor = Technicolor;

export default Technicolor;
