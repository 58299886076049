import { GridDate } from '@gelatoas/design-editor-calendar';

import { getStartingYear } from './dateUtils';
import isSpreadNameAMonth, { NAME_START } from './isSpreadNameAMonth';

const getSpreadDate = (spreadName: string | undefined, gridDate: GridDate | undefined): GridDate => {
  let monthNumber = 1;
  if (spreadName && isSpreadNameAMonth(spreadName)) {
    const numberString = spreadName.slice(NAME_START.length);
    monthNumber = parseInt(numberString, 10);
  }

  const date = gridDate
    ? new Date(gridDate.year, monthNumber - 1 + gridDate.month, 1)
    : new Date(getStartingYear(), monthNumber - 1, 1);

  return { year: date.getFullYear(), month: date.getMonth() };
};

export default getSpreadDate;
