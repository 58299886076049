import React, { Suspense } from 'react';

import { Condition, ConditionDisplayOption } from 'editor/src/store/design/types';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import ConditionOptionsButton from './ConditionOptionsButton';
import ConditionOptionsThumbnail from './ConditionOptionsThumbnail';

const ConditionOptionsDropDown = React.lazy(retryPromiseFn(() => import('./ConditionOptionsDropDown')));

interface Props {
  condition: Condition;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptions({ condition, onSelectOption }: Props) {
  switch (condition.displayOption) {
    case ConditionDisplayOption.Button:
      return <ConditionOptionsButton condition={condition} onSelectOption={onSelectOption} />;
    case ConditionDisplayOption.Dropdown:
      return (
        <Suspense fallback="">
          <ConditionOptionsDropDown condition={condition} onSelectOption={onSelectOption} />
        </Suspense>
      );
    case ConditionDisplayOption.Thumbnail:
      return <ConditionOptionsThumbnail condition={condition} onSelectOption={onSelectOption} />;
    default:
      return null;
  }
}

export default React.memo(ConditionOptions);
