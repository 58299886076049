import { useCallback, useState } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateImageIdByUuidOperation from 'editor/src/store/design/operation/updateImageIdByUuidOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import { ImagePos } from 'product-personalizer/src/component/ImageCropTool/Image';

function useCropTool() {
  const [activeCrop, setActiveCrop] = useState<{
    imageUrl: string | undefined;
    assetId: string | undefined;
    elementAddress: ElementAddress;
    elementUuid: number;
  }>();

  const store = useStore();
  const dispatch = useDispatch();

  const cropElement = useSelector((state) =>
    activeCrop ? getMediaElement<MediaImage>(state, activeCrop.elementAddress) : undefined,
  );

  const editImageCrop = useCallback((elementAddress: ElementAddress) => {
    const state = store.getState();
    const element = getMediaElement<MediaImage>(state, elementAddress);
    const image = state.gallery.images.find((image) => image.id === element?.imageId);
    if (element && image?.url) {
      setActiveCrop({ imageUrl: image.url, assetId: element.imageId, elementAddress, elementUuid: element.uuid });
    }
  }, []);

  const onCancel = useCallback(() => setActiveCrop(undefined), []);
  const onSave = useCallback(
    (imagePos: ImagePos) => {
      batch(() => {
        if (activeCrop?.assetId) {
          dispatch(updateImageIdByUuidOperation(activeCrop.elementUuid, activeCrop.assetId, true));
          dispatch(
            updateMediaElementOperation(
              activeCrop.elementAddress,
              {
                ...imagePos,
              },
              MediaUpdateActionName.CROPPED,
              true,
            ),
          );
        }
      });
      setActiveCrop(undefined);
    },
    [activeCrop],
  );

  return { onCancel, onSave, activeCrop, setActiveCrop, cropElement, editImageCrop };
}

export default useCropTool;
