// this file exists for typings purpose
import {
  type TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch as useDispatchRedux,
  // eslint-disable-next-line no-restricted-imports
  useSelector as useSelectorRedux,
  // eslint-disable-next-line no-restricted-imports
  useStore as useStoreRedux,
} from 'react-redux';

import type { RootState, ThunkArg } from '.';
import type {
  ThunkAction as ReduxThunkAction,
  ThunkDispatch as ReduxThunkDispatch,
  UnknownAction,
  Store,
} from '@reduxjs/toolkit';

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux;

export type ThunkDispatch = ReduxThunkDispatch<RootState, ThunkArg, UnknownAction>;
export type ThunkAction<ReturnType = void> = ReduxThunkAction<ReturnType, RootState, ThunkArg, UnknownAction>;

export type Thunk = (dispatch: ThunkDispatch, getState: () => RootState, extra: ThunkArg) => any;
export type AsyncThunk = (dispatch: ThunkDispatch, getState: () => RootState, extra: ThunkArg) => Promise<any>;

export const useDispatch: () => ThunkDispatch = useDispatchRedux;

export const useStore: () => Store<RootState> = useStoreRedux;
