import { fabric } from 'fabric';

export interface ICircleOptions extends fabric.ICircleOptions {
  zIndex?: number;
}
interface FabricCircle extends fabric.Circle {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricCircle = fabric.util.createClass(fabric.Circle, {
  zIndex: 0,
}) as { new (options?: ICircleOptions): FabricCircle };

export default FabricCircle;
