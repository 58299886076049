import { PayloadAction } from '@reduxjs/toolkit';

import { removeMediaElement } from 'editor/src/store/design/reducer/removeMediaElementReducer';
import canImageBeEmptied from 'editor/src/store/design/util/canImageBeEmptied';
import { SavedState, UndoRedoState } from 'editor/src/store/editorModules/undoRedo/types';

function removeImageFromSavedState(savedState: SavedState, imageId: string, deleteFrameWithImage: boolean) {
  const { designData } = savedState.design;
  designData?.spreads.forEach((spread, spreadIndex) => {
    const { media } = spread.pages[0].groups;
    if (!media) {
      return;
    }

    let i = media.length;
    while (i > 0) {
      i -= 1;
      const element = media[i];
      if (element.type !== 'image' || element.imageId !== imageId) {
        continue;
      }

      if (!deleteFrameWithImage && canImageBeEmptied(element)) {
        element.imageId = '';
        element.url = undefined;
      } else {
        removeMediaElement(designData, spreadIndex, 0, i);
      }
    }
  });
}

const removeImageFromHistoryReducer = (
  state: UndoRedoState,
  action: PayloadAction<{ imageId: string; deleteFrameWithImage: boolean }>,
) => {
  const { imageId, deleteFrameWithImage } = action.payload;

  state.previous.forEach((savedState) => removeImageFromSavedState(savedState, imageId, deleteFrameWithImage));
  state.next.forEach((savedState) => removeImageFromSavedState(savedState, imageId, deleteFrameWithImage));
};

export default removeImageFromHistoryReducer;
