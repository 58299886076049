import { PayloadAction } from '@reduxjs/toolkit';

import { EditorState } from 'editor/src/store/editor/types';

const setImagePatternScaleBasesReducer = (
  state: EditorState,
  action: PayloadAction<Array<{ value: number; dimension: 'width' | 'height'; label: string }>>,
) => {
  state.imagePatternScaleBases = action.payload;
};

export default setImagePatternScaleBasesReducer;
