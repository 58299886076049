import { PayloadAction } from '@reduxjs/toolkit';

import { AppState } from 'editor/src/store/app/types';

const setSimpleDropdownReducer = (
  state: AppState,
  { payload }: PayloadAction<{ name?: string | null; data?: any } | null | undefined>,
) => {
  state.simpleDropdown = payload || {};
};

export default setSimpleDropdownReducer;
