import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';
import getConditionParentKeys from './getConditionParentKeys';

const deleteConditionReducer = (state: DesignState, action: PayloadAction<ConditionAddress>) => {
  const address = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  const parentKeys = getConditionParentKeys(conditionGroup, conditionId);

  const topLevelChildren = conditionGroup.children[conditionGroupChildenKey];

  condition.options.forEach((value) => {
    const childrenKey = getConditionChildrenKey(condition.id, value.id);
    const children = conditionGroup.children[childrenKey];
    children.forEach((child) => {
      if (child.type === 'condition') {
        const childCondition = conditionGroup.conditions[child.id];
        childCondition.parent = undefined;
        if (!topLevelChildren.find((tChild) => tChild.type === 'condition' && tChild.id === child.id)) {
          topLevelChildren.push(child);
        }
      } else {
        const element = state.designData?.spreads[spreadIndex].pages[0].groups.media?.find(
          (element) => element.uuid === child.id,
        );
        if (
          element &&
          !element.personalizationLocked &&
          !topLevelChildren.find((tChild) => tChild.type !== 'condition' && tChild.id === child.id)
        ) {
          topLevelChildren.push(child);
        }
      }
    });
    delete conditionGroup.children[childrenKey];
  });
  delete conditionGroup.conditions[condition.id];

  if (parentKeys.length) {
    parentKeys.forEach((parentKey) => {
      conditionGroup.children[parentKey] = conditionGroup.children[parentKey].filter(
        (element) => element.type !== 'condition' || element.id !== conditionId,
      );
    });
  }
};

export default deleteConditionReducer;
