// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon--P3JTQ svg>[fill=none]{fill:none !important}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Icon/Adjustments/IconBrightness/index.module.scss"],"names":[],"mappings":"AAEA,6BACE,oBAAA","sourcesContent":["@import 'editor/src/comonStyles/variables';\n\n.icon svg > [fill='none'] {\n  fill: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon--P3JTQ`
};
export default ___CSS_LOADER_EXPORT___;
