import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

export const UNSELECTED_PAGE_COUNT = -1;

const setPageCountReducer = (state: VariantsState, action: PayloadAction<number>) => {
  state.selectedPageCount = action.payload;
};

export default setPageCountReducer;
