import { PayloadAction } from '@reduxjs/toolkit';

import updateDesignPagesUtil from 'editor/src/store/design/util/updateDesignPagesUtil';

import { VariantsState } from '../../types';

const removeSpreadReducer = (state: VariantsState, action: PayloadAction<number>) => {
  const spreadIndex = action.payload;
  state.variationGroups.forEach((variationGroup) => {
    variationGroup.variationsInfo.forEach((variationInfo) => {
      if (!variationInfo.designData) {
        return;
      }

      variationInfo.designData.spreads.splice(spreadIndex, 1);
      updateDesignPagesUtil(variationInfo.designData);
    });
  });
};

export default removeSpreadReducer;
