import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData, DesignState, ElementAddress } from 'editor/src/store/design/types';

export function removeMediaElement(
  designData: DesignData,
  spreadIndex: number,
  pageIndex: number,
  elementIndex: number,
) {
  const spread = designData.spreads[spreadIndex];
  const media = spread?.pages[pageIndex].groups.media;
  if (!media) {
    return;
  }

  const [element] = media.splice(elementIndex, 1);

  if (spread.conditionGroup) {
    const { children } = spread.conditionGroup;
    Object.keys(children).forEach((key) => {
      const eltIndex = children[key].findIndex((child) => child.type !== 'condition' && child.id === element.uuid);
      if (eltIndex !== -1) {
        children[key].splice(eltIndex, 1);
      }
    });
  }
}

const removeMediaElementReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  if (state.designData) {
    removeMediaElement(state.designData, spreadIndex, pageIndex, elementIndex);
  }
};

export default removeMediaElementReducer;
