import { PayloadAction } from '@reduxjs/toolkit';

import { CollectionFolder, CollectionsState } from 'editor/src/store/collections/types';

const setCollectionFolderReducer = (state: CollectionsState, action: PayloadAction<CollectionFolder>) => {
  const folder = action.payload;
  state.folders[action.payload.id] = folder;

  folder.assets.forEach((asset) => {
    state.assets[asset.id] = asset;
  });

  if (!folder.parentFolderId) {
    state.topFolderId = folder.id;
  }
};

export default setCollectionFolderReducer;
