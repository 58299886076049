import { PayloadAction } from '@reduxjs/toolkit';

import { ProductImage, SpreadGroundImage } from 'editor/src/store/design/types';
import { MockupState, MockupVariant } from 'editor/src/store/mockup/types';

const addVariantReducer = (
  state: MockupState,
  action: PayloadAction<{
    variant: MockupVariant;
    foregrounds: SpreadGroundImage[];
    backgrounds: SpreadGroundImage[];
    productImage: ProductImage | undefined;
  }>,
) => {
  const { backgrounds, foregrounds, variant, productImage } = action.payload;
  state.productPlaceholder.variants[variant.productUid] = variant;
  state.productPlaceholder.sceneImages[variant.productUid] = {
    foregrounds,
    backgrounds,
  };
  if (productImage) {
    state.productPlaceholder.productImages[variant.productUid + (variant.scene ?? '')] = productImage;
  }
};

export default addVariantReducer;
