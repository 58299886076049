import getSpreadPage from 'editor/src/store/design/selector/getSpreadPage';
import { RootState } from 'editor/src/store/index';

const getFocusedContent = (state: RootState) => {
  const { focusedContentAddress } = state.editor;
  if (!focusedContentAddress) {
    return undefined;
  }

  const page = getSpreadPage(state, focusedContentAddress.spreadIndex, focusedContentAddress.pageIndex);
  return page?.groups.content?.[focusedContentAddress.contentIndex];
};

export default getFocusedContent;
