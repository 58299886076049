import { fabric } from 'fabric';
import { useRef } from 'react';

import { removeCacheCanvas } from './fabricUtils';

function useObjectProps<T extends fabric.IObjectOptions>(element: fabric.Object, props: T) {
  const prevProps = useRef<T | undefined>();

  if ('clipPath' in props) {
    removeCacheCanvas(element.clipPath);
  }

  const propsToSet: { [prop: string]: any } = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const p in props) {
    if (element.get(p as keyof fabric.Object) !== props[p]) {
      propsToSet[p] = props[p];
    }
  }

  if (Object.keys(propsToSet).length) {
    element.set(propsToSet);
  }

  if (prevProps.current) {
    // eslint-disable-next-line no-restricted-syntax
    for (const p in prevProps.current) {
      if (!(p in props)) {
        element.set(p as any, undefined);
      }
    }
  }
  prevProps.current = props;
}

export default useObjectProps;
