import setTimeoutPromise from './setTimeoutPromise';

function retryFn<T>(fn: () => Promise<T>, retryTotal: number, retry = retryTotal): Promise<T> {
  return fn().catch((e) => {
    if (retry === 1) {
      throw e;
    }
    return setTimeoutPromise((retryTotal - retry) * 1000).then(() => retryFn(fn, retryTotal, retry - 1));
  });
}
function retryPromiseFn<T>(fn: () => Promise<T>): () => Promise<T> {
  return () => retryFn(fn, 3);
}

export default retryPromiseFn;
