import * as Sentry from '@sentry/react';

import { lastActions } from 'editor/src/store/actionLogListener';

import environment, { EnvEnum } from 'editor/src/environment';
import formatDesignDataForLogging from 'editor/src/util/formatDesignDataForLogging';

import store from './store';

let pageUnloaded = false;
window.addEventListener('beforeunload', () => {
  pageUnloaded = true;
});
window.addEventListener('pagehide', () => {
  pageUnloaded = true;
});
window.addEventListener('unload', () => {
  pageUnloaded = true;
});

function initSentry(urlParams: URLSearchParams) {
  Sentry.init({
    dsn: 'https://0e0bfbcb00b3229a23c7b75aa115d42f@o297899.ingest.sentry.io/4505980917383168',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment.env === EnvEnum.live ? 0.1 : 0,
    allowUrls: [/product-personalizer\.gelato\.com/],
    release: `product-personalizer@${process.env.REACT_APP_VERSION}`,
    ignoreErrors: ['Non-Error exception captured', 'Object captured as exception with keys: _isScalar, _subscribe'],
    beforeSend(event, hint) {
      // eslint-disable-next-line no-console
      console.error(hint?.originalException || hint?.syntheticException || event);

      if (pageUnloaded) {
        return null;
      }

      if (!event.extra) {
        event.extra = { lastActions, designData: formatDesignDataForLogging(store.getState().design.designData) };
      }

      return environment.env !== EnvEnum.live ? null : event;
    },
  });
  if (environment.env === EnvEnum.live) {
    Sentry.setTag('variantId', urlParams.get('variant'));
  }
}

export default initSentry;
