import { isNotDefaultTab, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import setSidebarActiveTabOperation from './setSidebarActiveTabOperation';

const setSidebarActiveTabByNameOperation =
  (tabName: TAB_NAMES, onlyIfVisible = false, payload?: any) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { tabs, isVisible } = state.editorModules.sidebar;

    if (onlyIfVisible && !isVisible) {
      return;
    }

    const tabIndex = tabs.findIndex((tab) => (isNotDefaultTab(tab) ? tab.id === tabName : tab === tabName));
    dispatch(setSidebarActiveTabOperation(tabIndex, payload));
  };

export default setSidebarActiveTabByNameOperation;
