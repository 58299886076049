// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShippingPrice--g8jNY{margin-top:24px;padding:8px 12px;color:var(--neutral-neutral90, #383838);font-size:14px;font-style:italic;font-weight:400;background:#f7f7f7;display:flex;align-items:center;border-radius:8px}.ShippingPrice--g8jNY .icon--D5cc7{margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/component/ShippingPrice/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,uCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CAEA,mCACE,iBAAA","sourcesContent":[".ShippingPrice {\n  margin-top: 24px;\n  padding: 8px 12px;\n  color: var(--neutral-neutral90, #383838);\n  font-size: 14px;\n  font-style: italic;\n  font-weight: 400;\n  background: #F7F7F7;\n  display: flex;\n  align-items: center;\n  border-radius: 8px;\n\n  .icon {\n    margin-right: 12px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ShippingPrice": `ShippingPrice--g8jNY`,
	"icon": `icon--D5cc7`
};
export default ___CSS_LOADER_EXPORT___;
