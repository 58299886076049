import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';
import { GridEvent } from 'editor/src/store/gridConfig/types';

import createCalendarEventId from 'editor/src/util/reflectDesignData/createCalendarEventId';

const addCalendarPublicEventsReducer = (state: DesignState, action: PayloadAction<GridEvent[]>) => {
  if (!state.designData) {
    return;
  }

  if (!state.designData.calendar) {
    state.designData.calendar = {};
  }

  const newPublicHolidays = action.payload.map((event) => {
    const eventDate = new Date(event.date);
    return {
      ...event,
      day: eventDate.getUTCDate(),
      month: eventDate.getUTCMonth(),
      year: eventDate.getUTCFullYear(),
    };
  });

  // get all events except public holidays
  const eventsList = state.designData.calendar?.events || [];
  const publicEventsList = eventsList.filter(({ merchant, custom }) => !merchant && !custom);

  // if the public holidays list got updated then reset all
  const isPublicHolidaysListUpdated = !!newPublicHolidays.find(({ day, month, title }) => {
    return !publicEventsList.find((event) => event.day === day && event.month === month && event.label === title);
  });

  if (!isPublicHolidaysListUpdated) {
    return;
  }

  const newEventsList = eventsList.filter(({ merchant, custom }) => merchant || custom);
  newPublicHolidays.forEach(({ day, month, year, title }) => {
    const eventId = createCalendarEventId(false, false);
    newEventsList.push({
      id: eventId,
      day,
      month,
      year,
      label: title,
      checked: true,
      custom: false,
      merchant: false,
    });
  });
  state.designData.calendar.events = newEventsList;
};

export default addCalendarPublicEventsReducer;
