import UuidGenerator from './UuidGenerator';

class StringUuidGenerator extends UuidGenerator<string> {
  override generate() {
    const uuid = super.getNext();
    return `${uuid}`;
  }
}

export default StringUuidGenerator;
