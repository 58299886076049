import cn from 'classnames';
import React, { useMemo } from 'react';

import { Product, Variant } from 'product-personalizer/src/utils/productTypes';

import {
  getFormattedVariantOptions,
  getSelectedVariant,
} from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';
import ChipsFields from 'product-personalizer/src/component/ChipsFields';

import SelectorDropDown from '../SelectorDropDown';

import styles from './index.module.scss';

interface Props {
  product: Product;
  selectedVariant: Variant;
  onVariantChange: (variant: Variant) => void;
  overrides: VariantUIOverrides | undefined;
}

export type VariantUIOverrides = { [selectorName: string]: SelectorUI };

interface SelectorUI {
  type: 'button' | 'dropdown';
}

function VariantSelectors({ product, selectedVariant, onVariantChange, overrides }: Props) {
  function onSelect(index: number, value: string) {
    const variant = getSelectedVariant(product.variants, selectedVariant, index, value);
    if (variant) {
      onVariantChange(variant);
    }
  }

  const updatedOptions = useMemo(() => {
    const filteredOptions = product.options.filter((option) => !option.hidden);
    return getFormattedVariantOptions(filteredOptions, product.variants, selectedVariant);
  }, [product.options, product.variants, selectedVariant]);

  return (
    <div className={styles.VariantSelectors}>
      {updatedOptions.map((option, index) => {
        const uiType = overrides?.[option.name]?.type;
        return (
          <div key={index} className={cn(styles.selector, 'g-selector-container', 'g-selector-variants')}>
            <div className={cn(styles.label, 'g-selector-label')}>{option.name}</div>
            {uiType === 'dropdown' ? (
              <SelectorDropDown
                values={option.values}
                selectedValue={selectedVariant.optionValues[index]}
                onChange={(value) => onSelect(index, value)}
              />
            ) : (
              <ChipsFields
                values={option.values}
                selectedValue={selectedVariant.optionValues[index]}
                onChange={(value) => onSelect(index, value)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(VariantSelectors);
