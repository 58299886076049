import cloneDeep from 'lodash/cloneDeep';

import { DesignData } from 'editor/src/store/design/types';
import getLayoutSchemaByName from 'editor/src/store/editor/selector/getLayoutSchemaByName';
import { RootState } from 'editor/src/store/index';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import {
  Product,
  VariationGroup,
  VariationProductControl,
  ExistingVariant,
  Variant,
  ProductVariation,
  VariantInfo,
  AdditionalInfo,
} from 'editor/src/store/variants/types';

import applyResizableProductToDesign from 'editor/src/util/design/applyResizableProductToDesign';
import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import getDesignKey from './getDesignKey';
import getProductVariationSpecificTitle from './getProductVariationSpecificTitle';

import type { i18n } from 'i18next';

export function makeGroupKey(variant: VariantInfo, groupingKeys: string[]): string {
  if (!groupingKeys.length) {
    return getDesignKey(variant.variation.productUid, variant);
  }

  return groupingKeys.reduce((groupKey, key) => {
    switch (key) {
      case 'product-size':
        return groupKey + (variant.dimensions ? `${variant.dimensions.width}x${variant.dimensions.height}` : '');
      default:
        return groupKey + (variant.variation[key] || '');
    }
  }, '');
}

function compareVariations(
  v1: ProductVariation,
  v2: ProductVariation,
  productControls: VariationProductControl[],
  controlValuesIndexMap: Map<string, Map<string, number>>,
) {
  for (let i = 0; i < productControls.length; i += 1) {
    const { key } = productControls[i];
    const valuesIndex = controlValuesIndexMap.get(key);
    const indexValue1 = valuesIndex?.get(v1[key]) ?? -1;
    const indexValue2 = valuesIndex?.get(v2[key]) ?? -1;
    if (indexValue1 !== indexValue2) {
      return indexValue1 - indexValue2;
    }
  }

  return 0;
}

export function sortGroups(groups: VariationGroup[], productControls: VariationProductControl[]): VariationGroup[] {
  const controlValuesIndexMap = new Map<string, Map<string, number>>();
  productControls.forEach((control) => {
    const indexes = new Map<string, number>();
    controlValuesIndexMap.set(control.key, indexes);
    control.options.forEach((option, i) => indexes.set(option.value, i));
  });

  groups.forEach((group) =>
    group.variationsInfo.sort((v1, v2) =>
      compareVariations(v1.variation, v2.variation, productControls, controlValuesIndexMap),
    ),
  );

  return groups.sort((group1, group2) =>
    compareVariations(
      group1.variationsInfo[0].variation,
      group2.variationsInfo[0].variation,
      productControls,
      controlValuesIndexMap,
    ),
  );
}

export function getGroupedVariations(variants: VariantInfo[], groupingKeys: string[]) {
  return variants.reduce<{ [groupKey: string]: VariantInfo[] }>((group, variant) => {
    const key = makeGroupKey(variant, groupingKeys);
    if (!group[key]) {
      group[key] = [];
    }
    group[key].push(variant);
    return group;
  }, {});
}

const EMPTY_ADDTIONAL_INFO: AdditionalInfo = {
  dimensions: undefined,
  pageCount: undefined,
};

export function areAdditionalInfoEquals(v1: AdditionalInfo, v2: AdditionalInfo) {
  return (
    v1.dimensions?.width === v2.dimensions?.width &&
    v1.dimensions?.height === v2.dimensions?.height &&
    v1.pageCount === v2.pageCount
  );
}

type SourceVariantInfo = {
  designData: DesignData | undefined;
  linked: boolean;
  additionalInfo: AdditionalInfo;
};

export function getVariantInfoFromVariant(
  variants: VariantInfo[],
  existingVariants: ExistingVariant[],
): SourceVariantInfo {
  let existingVariant: ExistingVariant | undefined;
  let existingDesign: DesignData | undefined;

  // eslint-disable-next-line no-restricted-syntax
  for (const variant of variants) {
    const foundVariant = existingVariants.find((v) => {
      const dimensions = v.designData?.related_dimensions || v.designData?.dimensions;
      return (
        v.productUid === variant.variation.productUid &&
        areAdditionalInfoEquals({ dimensions, pageCount: undefined }, variant)
      );
    });

    if (foundVariant && !existingVariant) {
      existingVariant = foundVariant;
    }

    if (foundVariant?.designData && !existingDesign) {
      existingDesign = foundVariant.designData;
      existingVariant = foundVariant;
    }

    if (!!existingVariant && !!existingDesign) {
      break;
    }
  }

  const designDimensions = existingDesign?.related_dimensions || existingDesign?.dimensions;
  return {
    designData: existingDesign,
    linked: existingVariant?.linked ?? true,
    additionalInfo: existingDesign
      ? {
          dimensions: designDimensions ? { width: designDimensions.width, height: designDimensions.height } : undefined,
          pageCount: undefined,
        }
      : EMPTY_ADDTIONAL_INFO,
  };
}

export function getVariantInfoFromGroup(
  variants: VariantInfo[],
  groupKey: string,
  previousGroups: VariationGroup[] | undefined,
): SourceVariantInfo {
  const previousGroup = previousGroups?.find((group) => group.key === groupKey);

  let existingVariant: Variant | undefined;

  // eslint-disable-next-line no-restricted-syntax
  for (const variant of variants) {
    const previousVariant = previousGroup?.variationsInfo.find(
      (v) => v.variation.productUid === variant.variation.productUid && areAdditionalInfoEquals(v, variant),
    );
    if (previousVariant?.designData && !existingVariant) {
      existingVariant = previousVariant;
      break;
    }
  }

  return {
    designData: existingVariant?.designData,
    linked: previousGroup?.linked ?? true,
    additionalInfo: existingVariant || EMPTY_ADDTIONAL_INFO,
  };
}

function groupVariations(
  variants: VariantInfo[],
  product: Product,
  designTemplates: { [designKey: string]: DesignData },
  getVariantSourceInfo: (variants: VariantInfo[], groupKey: string) => SourceVariantInfo,
  state: RootState,
  i18n: i18n,
  layoutPerProductUids = state.variants.layoutPerProductUids,
  groupedSpreadsPerProductUids = state.variants.groupedSpreadsPerProductUids,
  forceLayout = state.variants.configuration.forceLayout,
): VariationGroup[] {
  const currentDesign = state.design.designData;
  const unit = state.editor.settings.units;
  const reflectContext = getReflectContext(state, groupedSpreadsPerProductUids);

  const groupedVariations = getGroupedVariations(variants, product.groupBy);
  const groups: VariationGroup[] = [];
  Object.keys(groupedVariations).forEach((groupKey) => {
    const variants = groupedVariations[groupKey];
    const firstVariant = variants[0];

    const { designData, linked, additionalInfo } = getVariantSourceInfo(variants, groupKey);

    const variationsInfo: Variant[] = variants.map((variant) => {
      let variantDesignData = designData;
      if (
        !variantDesignData ||
        variantDesignData.product_uid !== variant.variation.productUid ||
        !areAdditionalInfoEquals(variant, additionalInfo)
      ) {
        const template = designTemplates[getDesignKey(variant.variation.productUid, variant)];
        if (template) {
          // we have a template, this is a new variant, we can reflect the design
          const sourceDesign = variantDesignData || currentDesign;
          const newDesignData = sourceDesign
            ? reflectDesignData(sourceDesign, template, reflectContext)
            : cloneDeep(template);
          variantDesignData = applyLayoutPerProductUid(
            newDesignData,
            state,
            true,
            i18n,
            layoutPerProductUids,
            groupedSpreadsPerProductUids,
            forceLayout,
          );

          const resizableElement = getResizableProductForGivenVariant(state, variantDesignData.product_uid);
          const layoutName = variantDesignData?.spreads[0].layoutSchemaName;
          const layout = layoutName ? getLayoutSchemaByName(state, layoutName) : undefined;

          if (resizableElement && layout && variantDesignData.related_dimensions) {
            variantDesignData = applyResizableProductToDesign(state, variantDesignData, resizableElement, layout, i18n);
          }
        } else {
          // reset the design to undefined until the host sends us the template
          variantDesignData = undefined;
        }
      }

      return {
        ...variant,
        designData: variantDesignData,
      };
    });
    const group = {
      key: groupKey,
      title: getProductVariationSpecificTitle(product, firstVariant, unit),
      linked,
      outOfStock: variants.reduce<boolean>(
        (acc, variant) => acc || !!product.outOfStock[variant.variation.productUid],
        false,
      ),
      variationsInfo,
      linkingDisabled: false,
    };
    groups.push(group);
  });

  const newGroups: VariationGroup[] = [];
  sortGroups(groups, product.productControls).forEach((group) => {
    const designOptionControl = state.variants.designOptionsControl?.[0];
    const variationGroupsWithDesignOptions = state.variants.variationGroups.filter((group) => !!group.designOptions);
    if (designOptionControl) {
      designOptionControl.options.forEach((option) => {
        const groupKey = `${group.key}-${option.value}`;
        const existingGroup = variationGroupsWithDesignOptions.find(
          (groupVariation) => groupVariation.key === groupKey,
        );
        const sameDesignGroup = variationGroupsWithDesignOptions.find(
          (groupVariation) => groupVariation.key.includes(option.value) && group.linked,
        );
        if (existingGroup) {
          newGroups.push(existingGroup);
        } else if (sameDesignGroup) {
          const newGroup = {
            ...group,
            designOptions: [
              {
                optionKey: option.value,
                designOptionControlSubKey: designOptionControl.subtype,
              },
            ],
            key: `${group.key}-${option.value}`,
          };
          newGroup.variationsInfo = group.variationsInfo.map((variationInfo, i) => {
            const spreads = sameDesignGroup.variationsInfo[i].designData?.spreads;
            if (variationInfo.designData && spreads) {
              variationInfo.designData.spreads = spreads;
            }
            return variationInfo;
          });

          newGroups.push(newGroup);
        } else {
          newGroups.push({
            ...group,
            designOptions: [
              {
                optionKey: option.value,
                designOptionControlSubKey: designOptionControl.subtype,
              },
            ],
            key: `${group.key}-${option.value}`,
          });
        }
      });
    } else {
      newGroups.push(group);
    }
  });
  return newGroups;
}

export default groupVariations;
