/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconChevronRight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M7.20799 14.375C7.02799 14.1943 6.93799 13.986 6.93799 13.75C6.93799 13.514 7.02799 13.3057 7.20799 13.125L10.333 10L7.20799 6.875C7.02799 6.69434 6.93799 6.486 6.93799 6.25C6.93799 6.014 7.02799 5.80567 7.20799 5.625C7.38865 5.44434 7.59699 5.354 7.83299 5.354C8.06965 5.354 8.27799 5.44434 8.45799 5.625L12.208 9.375C12.3053 9.47234 12.375 9.573 12.417 9.677C12.4583 9.781 12.479 9.88867 12.479 10C12.479 10.1113 12.4583 10.219 12.417 10.323C12.375 10.427 12.3053 10.5277 12.208 10.625L8.45799 14.375C8.27799 14.5557 8.06965 14.646 7.83299 14.646C7.59699 14.646 7.38865 14.5557 7.20799 14.375Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconChevronRight;
