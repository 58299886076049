import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useIsMounted from 'editor/src/util/useIsMounted';

import getSpreadGroupInfoFromSpreads from 'editor/src/component/Preview/getSpreadGroupInfoFromSpreads';
import { createSpreadPreview, SpreadPreviewBlob, useCanvasRendering } from 'editor/src/component/SpreadPreview';

import { useIsMobile } from '../../useDetectDeviceType';

const PREVIEW_HEIGHT = Math.min(document.documentElement.clientHeight, 1600);

function useFlatPreviews() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [spreadPreviews, setSpreadPreviews] = useState<Array<SpreadPreviewBlob | null>>([]);

  function loadFont(fontFamily: string) {
    return dispatch(loadFontOperation(fontFamily));
  }

  const { noFlatPreviews, firstSpreadOnly, includeBackground, includeForegound } = useSelector(
    (state) => state.hostSettings.previews,
  );

  const {
    images,
    addons,
    focusedContentAddress,
    designData,
    spreadBackgrounds,
    spreadForegrounds,
    digitizedAssets,
    gridDesigns,
  } = useSelector(
    (state) => ({
      images: state.gallery.images,
      addons: state.editorModules.addons.inUse,
      focusedContentAddress: state.editor.focusedContentAddress,
      designData: state.design.designData,
      spreadBackgrounds: state.design.spreadBackgrounds,
      spreadForegrounds: state.design.spreadForegrounds,
      digitizedAssets: state.design.digitizedAssets,
      gridDesigns: state.gridDesigns.grids,
    }),
    shallowEqual,
  );

  const spreadGroups = useSelector(getSpreadGroups);

  const { requestRender } = useCanvasRendering(designData && !noFlatPreviews);

  useEffect(() => {
    if (!designData || noFlatPreviews) {
      return;
    }
    const spreads = firstSpreadOnly ? [designData.spreads[0]] : designData.spreads;
    let previews: Array<SpreadPreviewBlob | null> = spreads.map(() => ({
      type: 'blob',
      blob: null,
      width: 100,
      height: 100,
    }));
    setSpreadPreviews(previews);

    spreads.forEach(async (spread, index) => {
      if (spreadGroups && !spreadGroups.some((spreadGroup) => spreadGroup.spreadIndexes[0] === index)) {
        return;
      }
      const { currSpreadGroup, groupedSpreadInfo } = getSpreadGroupInfoFromSpreads(
        index,
        designData.spreads,
        spreadGroups,
        spreadBackgrounds,
        spreadForegrounds,
      );
      const foregroundImage = includeForegound
        ? spreadForegrounds?.find((image) => image.name === spread.name)
        : undefined;
      const backgroundImage = includeBackground
        ? spreadBackgrounds?.find((image) => image.name === spread.name)
        : undefined;
      const preview = await createSpreadPreview(
        designData.product_uid,
        spread,
        {
          backgroundImage,
          foregroundImage,
          gridDesigns,
          images,
          addons,
        },
        index,
        focusedContentAddress,
        { dimension: 'height', value: PREVIEW_HEIGHT },
        requestRender,
        digitizedAssets,
        loadFont,
        isMobile,
        t,
        { quality: 5, strongerShadow: true, output: 'blob', showBlankAreaShadowOnly: true },
        currSpreadGroup,
        groupedSpreadInfo,
        designData?.calendar,
      );

      if (isMounted()) {
        previews = previews.filter((preview) => preview?.blob);
        previews[index] = preview;
        setSpreadPreviews(previews);
      }
    });
  }, [noFlatPreviews, firstSpreadOnly, includeBackground, includeForegound, spreadGroups]);

  return spreadPreviews;
}

export default useFlatPreviews;
