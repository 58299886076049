import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setDesignAutoZoomReducer = (state: DesignState, action: PayloadAction<boolean>) => {
  const autoZoom = action.payload;
  if (state.designData) {
    state.designData.auto_zoom = autoZoom || undefined;
  }
};

export default setDesignAutoZoomReducer;
