import getSpreadPageMediaElements from 'editor/src/store/design/selector/getSpreadPageMediaElements';
import { MediaElement, MediaImage } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import isPersonalizationLockIgnored from './isPersonalizationLockIgnored';

const getFirstEmptyImageFrameOnPage = (state: RootState, spreadIndex: number, pageIndex: number) => {
  const ignorePersonalizationLock = isPersonalizationLockIgnored(state);
  const emptyElement = (getSpreadPageMediaElements(state, spreadIndex, pageIndex) || []).find(
    (el: MediaElement) =>
      el.type === 'image' && !(el as MediaImage).imageId && (!el.personalizationLocked || ignorePersonalizationLock),
  );
  return emptyElement?.uuid;
};

export default getFirstEmptyImageFrameOnPage;
