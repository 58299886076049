import React from 'react';

import IconZoomIn from 'editor/src/component/Icon/IconZoomIn';

import styles from './index.module.scss';

function ZoomInCartItemPreviewButton() {
  return (
    <div className={styles.zoomInIconBlock}>
      <IconZoomIn className={styles.zoomInIcon} />
    </div>
  );
}

export default React.memo(ZoomInCartItemPreviewButton);
