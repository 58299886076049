import { fabric } from 'fabric';
import { Pair, Polygon } from 'polygon-clipping';

import { Coords, Rect } from 'editor/src/store/design/types';

import FabricPolygon from 'editor/src/fabric/FabricPolygon';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

export function rotatedRectToPolygon(rect: ObjectRect): Polygon {
  return [
    [
      [rect.left, rect.top],
      getPointPositionRotatedOnPoint(rect.left + rect.width, rect.top, rect.left, rect.top, rect.angle),
      getPointPositionRotatedOnPoint(rect.left + rect.width, rect.top + rect.height, rect.left, rect.top, rect.angle),
      getPointPositionRotatedOnPoint(rect.left, rect.top + rect.height, rect.left, rect.top, rect.angle),
      [rect.left, rect.top],
    ],
  ];
}

export function rectToPolygon(
  rect: Rect,
  offset: Coords = { left: 0, top: 0 },
  mm2px: (size: number) => number = (s) => s,
): Polygon {
  return [
    [
      [offset.left + mm2px(rect.x), offset.top + mm2px(rect.y)],
      [offset.left + mm2px(rect.x + rect.width), offset.top + mm2px(rect.y)],
      [offset.left + mm2px(rect.x + rect.width), offset.top + mm2px(rect.y + rect.height)],
      [offset.left + mm2px(rect.x), offset.top + mm2px(rect.y + rect.height)],
      [offset.left + mm2px(rect.x), offset.top + mm2px(rect.y)],
    ],
  ];
}

export function rotatePolygon(polygon: Polygon, canvasRotation: CanvasRotation) {
  polygon[0].forEach((point) => {
    const rotatedPoint = fabric.util.rotatePoint(
      new fabric.Point(point[0], point[1]),
      canvasRotation.canvasCenter,
      canvasRotation.angleRad,
    );
    point[0] = rotatedPoint.x;
    point[1] = rotatedPoint.y;
  });
  return polygon;
}

// sort point to form a convex polygon
export function sortPoints(points: Pair[]) {
  // Find min max to get center
  // Sort from top to bottom
  points.sort((a, b) => a[1] - b[1]);
  const cy = (points[0][1] + points[points.length - 1][1]) / 2;

  // Sort from right to left
  points.sort((a, b) => b[0] - a[0]);
  const cx = (points[0][0] + points[points.length - 1][0]) / 2;

  const center = { x: cx, y: cy };

  // Starting angle used to reference other angles
  let startAng: number;
  const pointAngle = new Map<Pair, number>();
  points.forEach((point) => {
    let ang = Math.atan2(point[1] - center.y, point[0] - center.x);
    if (!startAng) {
      startAng = ang;
    } else if (ang < startAng) {
      ang += Math.PI * 2;
    }
    pointAngle.set(point, ang);
  });

  points.sort((a, b) => (pointAngle.get(a) || 0) - (pointAngle.get(b) || 0));

  return points;
}

export function polygonToFabricPolygon(polygon: Polygon, options?: fabric.IPolylineOptions) {
  return new FabricPolygon(
    polygon[0].map((point) => ({ x: point[0], y: point[1] })),
    options,
  );
}

export function arePolygonsEqual(p1: Polygon[], p2: Polygon[]): boolean {
  if (p1.length !== p2.length) {
    return false;
  }

  for (let i = 0; i < p1.length; i += 1) {
    const p1p = p1[i];
    const p2p = p2[i];

    if (p1p.length !== p2p.length) {
      return false;
    }

    for (let j = 0; j < p1p.length; j += 1) {
      const p1pp = p1p[j];
      const p2pp = p2p[j];

      if (p1pp.length !== p2pp.length) {
        return false;
      }

      for (let k = 0; k < p1pp.length; k += 1) {
        if (p1pp[k][0] !== p2pp[k][0] || p1pp[k][1] !== p2pp[k][1]) {
          return false;
        }
      }
    }
  }

  return true;
}
