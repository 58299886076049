import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';

import isElementWithSpotFinishingType from 'editor/src/util/design/isElementWithSpotFinishingType';

const moveMediaElementZindexUpReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  const media = state.designData?.spreads[spreadIndex].pages[pageIndex].groups.media;
  if (!media || elementIndex === media.length - 1) {
    return;
  } // nothing to do, already at the top position

  const element = media[elementIndex];
  const nextElement = media[elementIndex + 1];

  if (isElementWithSpotFinishingType(nextElement)) {
    return; // nothing to do, next element is a foil element
  }

  // swap element with the next element
  media[elementIndex + 1] = element;
  media[elementIndex] = nextElement;
};

export default moveMediaElementZindexUpReducer;
