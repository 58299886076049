import cloneDeep from 'lodash/cloneDeep';

import { DesignData } from 'editor/src/store/design/types';

function formatDesignDataForLogging(designData: DesignData | undefined) {
  if (!designData) {
    return undefined;
  }
  const designCopy = cloneDeep(designData);
  designCopy.spreads.forEach((spread) => {
    spread.pages = [spread.pages[0]];
    spread.pages[0].groups = { media: spread.pages[0].groups.media };
  });
  return designCopy;
}

export default formatDesignDataForLogging;
