import { fabric } from 'fabric';
import { UAParser } from 'ua-parser-js';

interface AppGlobalVars {
  isTouchEnabled: boolean;
  userAgent: UAParser.IResult;
}

const userAgent = UAParser();

const globalVars: AppGlobalVars = {
  // on new Ipad OS, Safari request the desktop version of website and returns a desktop user agent. so we use maxTouchPoints..
  isTouchEnabled:
    userAgent.device.type === 'tablet' || userAgent.device.type === 'mobile' || navigator.maxTouchPoints > 1,
  userAgent,
};

// some fabric setup
(fabric as any).minCacheSideLimit = 16;
(fabric as any).maxCacheSideLimit = globalVars.userAgent.os.name === 'iOS' ? 1024 : 2048;

export default globalVars;
