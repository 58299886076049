import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { removeMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const removeMediaElementOperation =
  (elementAddress: ElementAddress, saveStep = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      dispatch(setActiveTopMenuDropdownOperation(undefined));
      if (saveStep) {
        dispatch(saveUndoRedoStateOperation('Element remove'));
      }
      const state = getState();
      const element = getMediaElement(state, elementAddress);

      dispatch(removeMediaElementAction(elementAddress));

      switch (element?.type) {
        case 'addon':
          sendPostMessage('log.addonRemoved', element);
          break;
        case 'image':
          sendPostMessage('log.imageRemoved', element);
          break;
        case 'text':
          sendPostMessage('log.textRemoved', element);
          break;
        case 'line':
          sendPostMessage('log.lineRemoved', element);
          break;
        default:
          break;
      }
    });
  };

export default removeMediaElementOperation;
