import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaImage } from 'editor/src/store/design/types';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

function getScaleAndCenterImageUpdates(element: MediaImage, scale: number, area: Area) {
  const elementUpdate: Partial<MediaImage> = {};
  elementUpdate.height = element.height * scale;
  elementUpdate.width = element.width * scale;
  elementUpdate.pw = element.pw * scale;
  elementUpdate.ph = element.ph * scale;
  elementUpdate.px = element.px * scale;
  elementUpdate.py = element.py * scale;

  const elementCenter = getPointPositionRotatedOnPoint(
    elementUpdate.width / 2,
    elementUpdate.height / 2,
    0,
    0,
    element.r,
  );
  elementUpdate.x = area.left + area.width / 2 - elementCenter[0];
  elementUpdate.y = area.top + area.height / 2 - elementCenter[1];

  return elementUpdate;
}

export default getScaleAndCenterImageUpdates;
