import { MediaElement, MediaImage } from 'editor/src/store/design/types';

export interface AssetProviderElementData {
  assetId: string;
}

export function getAssetProviderDataFromElement(
  element: MediaElement,
  pluginName: string,
): AssetProviderElementData | undefined {
  return element.type === 'image' && element.plugins?.[pluginName];
}

export function setAssetProviderDataOnElement(element: MediaImage, pluginName: string, assetId: string) {
  if (!element.plugins) {
    element.plugins = {};
  }
  const data: AssetProviderElementData = { assetId };
  element.plugins[pluginName] = data;
}
