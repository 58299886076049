import { PayloadAction } from '@reduxjs/toolkit';

import { ColorSpace, DesignState } from 'editor/src/store/design/types';

const setDesignColorSpaceReducer = (state: DesignState, action: PayloadAction<ColorSpace>) => {
  const colorSpace = action.payload;
  if (state.designData) {
    state.designData.color_space = colorSpace;
  }
};

export default setDesignColorSpaceReducer;
