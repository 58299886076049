import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';

import isElementWithSpotFinishingType from 'editor/src/util/design/isElementWithSpotFinishingType';

const moveMediaElementZindexTopReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  const media = state.designData?.spreads[spreadIndex].pages[pageIndex].groups.media;
  if (!media) {
    return;
  }

  const element = media.splice(elementIndex, 1)[0]; // remove element from its current position
  if (isElementWithSpotFinishingType(element)) {
    media.push(element); // insert element at the last position
  } else {
    const firstFoilElementIndex = media.findIndex(isElementWithSpotFinishingType);
    if (firstFoilElementIndex === -1) {
      media.push(element); // insert element at the last position
    } else {
      media.splice(firstFoilElementIndex, 0, element); // insert element at the first foil element position
    }
  }
};

export default moveMediaElementZindexTopReducer;
