import React, { useState, useRef, useEffect } from 'react';

function useDragging(divRef: React.RefObject<HTMLDivElement>, close: () => void) {
  const [dragging, setDragging] = useState(false);
  function onHandleUp(e: PointerEvent) {
    if (!e.isPrimary) {
      return;
    }

    setDragging(false);

    if (!dragStarted.current || currentAcc.current > 1) {
      close();
    } else if (divRef.current) {
      divRef.current.style.transform = '';
    }
  }

  const lastEventtime = useRef(0);
  const currentAcc = useRef(0);
  function onHandleMove(e: PointerEvent) {
    if (!e.isPrimary) {
      return;
    }
    const dY = e.clientY - handlePos.current;
    if (!dragStarted.current) {
      if (Math.abs(dY) < 5) {
        return;
      }
      dragStarted.current = true;
    }

    if (divRef.current) {
      const y = dY > 0 ? dY : dY / 4;
      divRef.current.style.transform = `translate3d(0,${y}px,0)`;
    }

    currentAcc.current = dY / (e.timeStamp - lastEventtime.current);
    lastEventtime.current = e.timeStamp;
  }

  const handlePos = useRef(0);
  const dragStarted = useRef(false);
  function onHandleDown(e: React.PointerEvent) {
    if (!e.isPrimary) {
      return;
    }
    handlePos.current = e.clientY;
    lastEventtime.current = e.timeStamp;
    dragStarted.current = false;

    setDragging(true);
  }

  useEffect(() => {
    if (!dragging) {
      return undefined;
    }

    window.addEventListener('pointerup', onHandleUp);
    window.addEventListener('pointermove', onHandleMove);

    return () => {
      window.removeEventListener('pointerup', onHandleUp);
      window.removeEventListener('pointermove', onHandleMove);
    };
  }, [dragging]);

  return { dragging, onHandleDown };
}

export default useDragging;
