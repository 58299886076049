import { Dimensions } from 'editor/src/store/design/types';

export type LimitBasedOn = 'width' | 'height' | 'both';

export const getMinScaleLimit = (basedOn: LimitBasedOn, { width, height }: Dimensions, strokeWidth: number) => {
  const maxSize = strokeWidth + 1;

  if (basedOn === 'both') {
    return maxSize / Math.min(width, height);
  }

  if (basedOn === 'height') {
    return maxSize / height;
  }

  return maxSize / width;
};

export const validateCornerRounding = ({
  width,
  height,
  cornerRounding,
}: Dimensions & { cornerRounding?: number | undefined }): number => {
  if (!cornerRounding) {
    return 0;
  }

  return Math.min(width / 2, height / 2, cornerRounding);
};

export const validateStrokeWidth = ({
  width,
  height,
  strokeWidth,
}: Dimensions & { strokeWidth?: number | undefined }): number => {
  if (!strokeWidth) {
    return 0;
  }

  const minDimensionSize = 1;
  return Math.min(width - minDimensionSize, height - minDimensionSize, strokeWidth);
};
