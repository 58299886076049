import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

const removeControlOptionReducer = (
  state: VariantsState,
  action: PayloadAction<{ controlKey: string; index: number }>,
) => {
  const { controlKey, index } = action.payload;

  const control = state.product.externalProductControls.find((control) => control.key === controlKey);
  if (!control) {
    return;
  }

  control.options.splice(index, 1);
};

export default removeControlOptionReducer;
