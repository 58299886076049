// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonalizationElements--wq3j2{width:100%}.PersonalizationElements--wq3j2.notEmpty--cQNPd{padding-top:24px}.PersonalizationElements--wq3j2>div+div{margin-top:24px}`, "",{"version":3,"sources":["webpack://./src/component/PersonalizationContent/PersonalizationElements/index.module.scss"],"names":[],"mappings":"AAAA,gCACE,UAAA,CAEA,gDACE,gBAAA,CAGF,wCACE,eAAA","sourcesContent":[".PersonalizationElements {\n  width: 100%;\n\n  &.notEmpty {\n    padding-top: 24px;\n  }\n\n  > div + div {\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PersonalizationElements": `PersonalizationElements--wq3j2`,
	"notEmpty": `notEmpty--cQNPd`
};
export default ___CSS_LOADER_EXPORT___;
