import React from 'react';

import { conditionGroupChildenKey, ElementAddress } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import 'editor/src/comonStyles/spacers.scss';
import PersonalizationElements from './PersonalizationElements';

interface Props {
  uploadImage: (address: ElementAddress) => void;
  editImageCrop: (address: ElementAddress) => void;
  trackUserInteraction: boolean | undefined;
  noTopBorder?: boolean;
}

function PersonalizationContent({ editImageCrop, uploadImage, noTopBorder, trackUserInteraction }: Props) {
  const { conditionGroup, spreadIndex } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return {
      conditionGroup: state.design.designData?.spreads[spreadIndex]?.conditionGroup,
      spreadIndex,
    };
  });

  if (!conditionGroup) {
    return null;
  }

  const elements = conditionGroup.children[conditionGroupChildenKey] || [];

  return (
    <PersonalizationElements
      elements={elements}
      conditionGroup={conditionGroup}
      spreadIndex={spreadIndex}
      editImageCrop={editImageCrop}
      uploadImage={uploadImage}
      noTopBorder={noTopBorder}
      trackUserInteraction={trackUserInteraction}
    />
  );
}

export default React.memo(PersonalizationContent);
