import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, ElementAddress } from 'editor/src/store/design/types';

const moveMediaElementZindexDownReducer = (state: DesignState, action: PayloadAction<ElementAddress>) => {
  const { spreadIndex, pageIndex, elementIndex } = action.payload;
  const media = state.designData?.spreads[spreadIndex].pages[pageIndex].groups.media;
  if (!media || elementIndex === 0) {
    return;
  } // nothing to do, already at the lowest position

  // swap element with the previous element
  const element = media[elementIndex];
  const previousElement = media[elementIndex - 1];
  media[elementIndex - 1] = element;
  media[elementIndex] = previousElement;
};

export default moveMediaElementZindexDownReducer;
