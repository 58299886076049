import { GalleryImage } from 'editor/src/store/gallery/types';
import { RootState } from 'editor/src/store/index';

import getGalleryImages from './getGalleryImages';

const getImageById = (state: RootState, imageId: string) => {
  if (!imageId) {
    return undefined;
  }
  return getGalleryImages(state).find((img: GalleryImage) => img.id === imageId);
};

export default getImageById;
