import { updateHiddenElementsAction } from 'editor/src/store/design/slice';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getHiddenMediaElementUuids from './getHiddenMediaElementUuids';

const updateHiddenElementsOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const spreadIndex = getCurrentSpreadIndex(state);
  const conditionGroup = state.design.designData?.spreads[spreadIndex].conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const hiddenElementIds = getHiddenMediaElementUuids(conditionGroup);
  dispatch(
    updateHiddenElementsAction({
      spreadIndex,
      hiddenElementIds: [...hiddenElementIds],
    }),
  );

  if (state.editor.selectedElementUuids.some((uuid) => hiddenElementIds.has(uuid))) {
    dispatch(removeAllSelectedMediaElementsOperation());
  }
};

export default updateHiddenElementsOperation;
