/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconHue({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon)}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 7L16 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5581 7.6842L13.323 9.53215" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.63623 9.63599L11.0504 11.0502" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.68604 12.5537L9.53361 13.3195" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 16L9 16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.68408 19.4421L9.53203 18.6772" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.63623 22.364L11.0504 20.9498" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5542 24.3141L13.32 22.4665" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 23L16 25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.6772 22.4678L19.4422 24.3157" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9497 20.9497L22.3639 22.3639" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.4663 18.6803L24.3139 19.4461" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23 16L25 16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.4678 13.3228L24.3157 12.5578" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9497 11.0503L22.3639 9.63608" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.6802 9.53357L19.446 7.686" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconHue;
