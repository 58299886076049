import type { AsyncThunk } from 'product-personalizer/src/store/hooks';

import { deleteDraftAction } from '../slice';

const deleteDraftOperation =
  (designId: string): AsyncThunk =>
  async (dispatch) => {
    await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs/${designId}`, {
      method: 'delete',
      headers: { 'content-type': 'application/json' },
    });
    dispatch(deleteDraftAction(designId));
  };

export default deleteDraftOperation;
