import { captureException } from '@sentry/react';
import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';

import { Coords, DigitizedAssetImage, MediaAddon, MediaImage } from 'editor/src/store/design/types';
import { Addon, AddonType } from 'editor/src/store/editorModules/addons/types';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';

import FabricImage from 'editor/src/fabric/FabricImage';
import imagePlaceholder from 'editor/src/util/imagePlaceholder';
import loadImage from 'editor/src/util/loadImage';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import getImageClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/getImageClipPath';
import getImageRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/getImageRect';
import { SHADOW_OFFSET_SCALE } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow';
import getImageShadow from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/getImageShadow';
import getImageShadowFilters from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/getImageShadowFilters';
import getShadowRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/getShadowRect';
import isImageEmpty from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/isImageEmpty';
import { getFilters } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import imageElementToFabricProps from './imageElementToFabricProps';

const addImage = async (
  fabricCanvas: fabric.StaticCanvas,
  element: MediaAddon | MediaImage,
  elementIndex: number,
  offset: Coords,
  clipPathPolygons: Polygon[],
  contentClipPath: fabric.Object | undefined,
  mm2px: (size: number) => number,
  rotation: CanvasRotation,
  showEmptyImages: boolean,
  useThumbnails: boolean,
  dataSources: { images: GalleryImage[]; addons: Addon[] },
  digitizedAsset: DigitizedAssetImage | undefined,
) => {
  if (!showEmptyImages && isImageEmpty(element)) {
    return;
  }

  let imageUrl: string;
  if (element.type === 'image') {
    const image = dataSources.images.find((image) => image.id === element.imageId);
    // if we have color overrides present, we want to use that elements image url as it would contain the overridden colors.
    // in case we don't have, datasource images should be used.
    const elementUrl = element.colorOverrides ? element.url : '';
    const imageUploaded = image && image.state === ImageState.UPLOADED;
    if (!imageUploaded) {
      if (!showEmptyImages) {
        return;
      }

      imageUrl = imagePlaceholder;
    } else {
      imageUrl = elementUrl || ((useThumbnails ? image.thumbnailUrl : image.url) ?? imagePlaceholder);
    }
  } else {
    const addon = dataSources.addons.find((addon) => addon.id === element.addonId);
    if (addon?.type !== AddonType.ADDON_BITMAP) {
      return;
    }
    imageUrl = addon.preview ?? imagePlaceholder;
  }

  if (digitizedAsset?.url && imageUrl !== imagePlaceholder) {
    imageUrl = digitizedAsset.url;
  }

  let img;
  try {
    img = await loadImage(imageUrl, 'anonymous', {
      executor: 'addImage',
      element,
    });
  } catch {
    return;
  }

  try {
    const px2mm = (size: number) => size / mm2px(1);
    const fabricElementProperties = imageElementToFabricProps(
      element,
      elementIndex,
      px2mm,
      mm2px,
      img,
      offset,
      false,
      rotation,
    );

    const frameRect = getElementRect(element, offset, rotation, mm2px);
    const imageRect = getImageRect(element, offset, rotation, mm2px);

    const fabricImg = new FabricImage(img, {
      ...fabricElementProperties,
      crossOrigin: 'anonymous',
      clipPath: getImageClipPath(frameRect, imageRect, clipPathPolygons, false, contentClipPath),
      objectCaching: false,
    });

    if (element.type === 'image') {
      const filters = getFilters(element, undefined);
      fabricImg.applyFilters(filters);
    }

    fabricCanvas.add(fabricImg);

    const shadow = element.type === 'image' ? getImageShadow(element.shadow, mm2px) : undefined;
    if (shadow) {
      const shadowRect = getShadowRect(frameRect, shadow);

      const imageShadow = new FabricImage(img, {
        ...fabricElementProperties,
        crossOrigin: 'anonymous',
        angle: shadowRect.angle,
        left: shadowRect.left,
        top: shadowRect.top,
        scaleX: fabricElementProperties.scaleX * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1),
        scaleY: fabricElementProperties.scaleY * SHADOW_OFFSET_SCALE * (shadow.scale ?? 1),
        zIndex: fabricElementProperties.zIndex - 0.01,
        clipPath: getClipPath(shadowRect, clipPathPolygons, false, contentClipPath),
        objectCaching: false,
        filters: getImageShadowFilters(element, shadow.color, shadow.blur),
      });
      fabricCanvas.add(imageShadow);
    }
  } catch (e) {
    captureException(new Error(e || 'addImage: Error adding image to fabric'), {
      extra: { imageUrl },
    });
  }
};

export default addImage;
