import { IMAGE_CORRUPTED, MediaAddon, MediaImage } from 'editor/src/store/design/types';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import getAddonURL from 'editor/src/store/editorModules/addons/selector/getAddonURL';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';
import { RootState } from 'editor/src/store/index';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import imagePlaceholder from 'editor/src/util/imagePlaceholder';

import { CrossOrigin } from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';

export function getImageUrl(image: GalleryImage | undefined, thumbnail = false, quantized = false) {
  if (!image || !image.id || image.id === IMAGE_CORRUPTED || image.state === ImageState.LOCAL_DROP) {
    return imagePlaceholder;
  }

  if (image.state === ImageState.LOCAL_PREVIEW || image.state === ImageState.FAILED) {
    return image.previewData;
  }

  if (thumbnail) {
    return image.thumbnailUrl || image.url;
  }

  if (quantized) {
    return image.quantizedUrl ?? image.url;
  }

  return image.url;
}

export function getElementURL(
  element: MediaImage | MediaAddon,
  images: GalleryImage[],
  addons: Addon[],
  thumbnail = false,
  isPreviewMode = false,
  quantized = false,
): { imageUrl: string | undefined; crossOrigin: CrossOrigin } {
  if (element.type === 'addon') {
    const addon = addons.find((addon) => addon.id === element.addonId);
    return {
      imageUrl: addon ? getAddonURL(addon) : undefined,
      crossOrigin: 'anonymous',
    };
  }

  if ((element.colorOverrides || isPreviewMode) && element.imageId && element.url) {
    return { imageUrl: element.url, crossOrigin: 'anonymous' };
  }

  const image = images.find((image) => image.id === element.imageId);
  return {
    imageUrl: getImageUrl(image, thumbnail, quantized),
    crossOrigin: image?.state === ImageState.UPLOADED ? 'anonymous' : undefined,
  };
}

const getImageElementUrl = (state: RootState, element: MediaImage | MediaAddon, thumbnail = false) => {
  const addons = state.editorModules.addons.inUse;
  const { images } = state.gallery;
  const isPreviewMode = !!getPreviewModePluginName(state, element.uuid);
  const digitizeCurrentSpread = shouldDigitizeCurrentSpread(state);
  return getElementURL(element, images, addons, thumbnail, isPreviewMode, digitizeCurrentSpread);
};

export default getImageElementUrl;
