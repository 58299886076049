import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getLayoutFrameById from './getLayoutFrameById';

import type { TFunction } from 'i18next';

function getFrameTextTemplate(frameId: string, layout: LayoutSchema, t: TFunction): string | undefined {
  const fullElementLabelFrame = getLayoutFrameById(frameId, layout);
  const textTemplates: { [key: string]: string } = {
    'full-wall-label': `${t('Full image')} ({{width}}x{{height}} {{unit}})`,
    'sample-label': `${t('Full scale fragment')} ({{width}}x{{height}} {{unit}})`,
  };
  if (fullElementLabelFrame?.type !== 'text' || !textTemplates[frameId]) {
    return undefined;
  }

  return textTemplates[frameId];
}

export default getFrameTextTemplate;
