export interface State {
  editorModules: {
    preview: PreviewState;
  };
}

export interface PreviewState {
  flatPreviewAvailable: boolean;
  the3dPreviewAvailable: boolean;
  activePreview?: PreviewType;
  status: PreviewStatus;
  the3dPreview?: The3dPreview;
  flatPreviews: FlatPreview[];
  spreadPreview: {
    [key: string]: FlatPreview;
  };
}

export enum PreviewType {
  PREVIEW_3D = 'PREVIEW_3D',
  PREVIEW_FLAT = 'PREVIEW_FLAT',
  MOCKUP = 'MOCKUP',
}

export enum PreviewStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export interface FlatPreview {
  url: string;
  name: string;
  loadingFailed?: boolean;
}

export interface The3dPreview {
  url: string;
}

export interface SpreadPreview {
  url: string;
  spreadIndex: number;
  isContentBack: boolean;
}
