import { popularFontList } from '@gelatoas/design-editor-fonts';

import { FontDefinition, FontStatus } from 'editor/src/store/fonts/types';

import type { FontMetaData } from '@gelatoas/design-editor-fonts';

function fontMetadataToFontDefinition(metadata: FontMetaData): FontDefinition {
  const popularIndex = popularFontList.indexOf(metadata.fontFile);
  const [isPopular, popularOrder] = popularIndex === -1 ? [false, undefined] : [true, popularIndex + 1];
  return {
    status: FontStatus.new,
    metadata,
    isPopular,
    popularOrder,
  };
}

export default fontMetadataToFontDefinition;
