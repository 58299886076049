import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const TrueBlackAndWhite = createClass(filters.ColorMatrix, {
  type: 'GelatoTrueBlackAndWhite',
  value: 0,
  mainParameter: 'value',

  calculateMatrix() {
    const coloMatrix = [1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 0, 0, 0, 1, 0];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal));
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      value: this.value,
    });
  },
});

TrueBlackAndWhite.fromObject = (fabric.Image.filters.ColorMatrix as any).fromObject;
(fabric.Image.filters as any).GelatoTrueBlackAndWhite = TrueBlackAndWhite;

export default TrueBlackAndWhite;
