import { PayloadAction } from '@reduxjs/toolkit';

import { PluginsState, Plugin } from 'editor/src/store/plugins/types';

const setPluginsReducer = (state: PluginsState, action: PayloadAction<Plugin[]>) => {
  action.payload.forEach((plugin) => {
    const elementIndex = state.list.findIndex((element) => element.name === plugin.name);
    if (elementIndex !== -1) {
      state.list[elementIndex] = plugin;
    } else {
      state.list.push(plugin);
    }
  });
};

export default setPluginsReducer;
