import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  value: string;
  active: boolean;
  onClick: (value: string) => void;
  className?: string;
  disabled?: boolean;
}

function ChipsField({ value, className, active, onClick, disabled }: Props) {
  return (
    <div
      className={cn(styles.ChipsField, className, 'g-selector-button', {
        'g-selector-button-active': active,
        [styles.active]: active,
        'g-selector-button-disabled': disabled,
        [styles.disabled]: disabled,
      })}
      onClick={() => onClick(value)}
    >
      {value}
    </div>
  );
}

export default React.memo(ChipsField);
