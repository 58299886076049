// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConditionOptionsThumbnail--jPvwf{display:flex;gap:12px;flex-wrap:wrap}.option--hv4ei{padding:5px;display:flex;transition:border-color 100ms linear;cursor:pointer;border:1px solid rgba(0,0,0,0);width:40px;height:40px}.option--hv4ei:hover{border-color:#212121}.option--hv4ei.active--HnhQL{border:2px solid #212121}.option--hv4ei+.option--hv4ei{margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/component/PersonalizationContent/PersonalizationCondition/ConditionOptions/ConditionOptionsThumbnail/index.module.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,QAAA,CACA,cAAA,CAGF,eACE,WAAA,CACA,YAAA,CACA,oCAAA,CACA,cAAA,CACA,8BAAA,CACA,UAAA,CACA,WAAA,CAEA,qBACE,oBAAA,CAGF,6BACE,wBAAA,CAIJ,8BACE,gBAAA","sourcesContent":[".ConditionOptionsThumbnail {\n  display: flex;\n  gap: 12px;\n  flex-wrap: wrap;\n}\n\n.option {\n  padding: 5px;\n  display: flex;\n  transition: border-color 100ms linear;\n  cursor: pointer;\n  border: 1px solid transparent;\n  width: 40px;\n  height: 40px;\n\n  &:hover {\n    border-color: #212121;\n  }\n\n  &.active {\n    border: 2px solid #212121;\n  }\n}\n\n.option + .option {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConditionOptionsThumbnail": `ConditionOptionsThumbnail--jPvwf`,
	"option": `option--hv4ei`,
	"active": `active--HnhQL`
};
export default ___CSS_LOADER_EXPORT___;
