import { MediaText } from 'editor/src/store/design/types';
import { TextAlign } from 'editor/src/store/fonts/types';

import FabricPathText from 'editor/src/fabric/FabricPathText';
import { convFn } from 'editor/src/util/convFn';
import limitPrecision from 'editor/src/util/limitPrecision';
import mm2pt from 'editor/src/util/mm2pt';

import textElementDataToFabricProps from './textElementDataToFabricProps';

const identity: convFn = (x) => x;

export function getRenderedTextObject(textElement: MediaText) {
  return new FabricPathText(textElement.extra.text, {
    ...textElementDataToFabricProps(textElement, 0, identity, false, false, textElement.extra.fontFamily),
    width: textElement.width,
    height: textElement.height,
    left: 0,
    top: 0,
    angle: 0,
    objectCaching: false,
  });
}

export function getFlexbileTextMaxWidth(
  eltWidth: number,
  eltX: number,
  textAlign: TextAlign | undefined,
  pageLeft: number,
  areaWidth: number,
) {
  let maxWidth = areaWidth;
  const elementX = eltX - pageLeft;
  if (textAlign === 'left') {
    maxWidth = areaWidth - elementX;
  } else if (textAlign === 'right') {
    maxWidth = elementX + eltWidth;
  } else {
    maxWidth = Math.min(areaWidth - elementX + eltWidth / 2, elementX + eltWidth / 2) * 2;
  }
  return Math.max(maxWidth, eltWidth);
}

export function extractTextProperties(
  textElement: MediaText,
  container: { x: number; width: number } | undefined,
  recalculateTextWidth?: boolean,
) {
  const { flexibleWidth, fontSize } = textElement.extra;
  const fabricObject = new FabricPathText(textElement.extra.text, {
    ...textElementDataToFabricProps(textElement, 0, identity, false, false, textElement.extra.fontFamily),
    width: textElement.width,
    height: textElement.height,
    left: 0,
    top: 0,
    angle: 0,
    objectCaching: false,
    flexibleWidth,
    maxWidth: flexibleWidth
      ? getFlexbileTextMaxWidth(
          textElement.width,
          textElement.x,
          textElement.extra.textAlign,
          container?.x ?? 0,
          container?.width ?? textElement.width * 2,
        )
      : undefined,
    isEditing: flexibleWidth,
    recalculateTextWidth,
  });

  // fabric has 14 digits precision internally
  const width = limitPrecision(fabricObject.width ?? 0, 13);
  const height = limitPrecision(fabricObject.height ?? 0, 13);

  const offsetLeft = fabricObject.left || 0;
  const offsetTop = fabricObject.top || 0;

  return {
    width,
    height,
    sx: 1,
    sy: 1,
    fontSize: fabricObject.fontSize !== undefined ? mm2pt(fabricObject.fontSize) : fontSize,
    x: (textElement.x || 0) + offsetLeft,
    y: (textElement.y || 0) + offsetTop,
  };
}

function updateTextElementWithoutRender(textElement: MediaText, container: { x: number; width: number } | undefined) {
  const { width, height, sx, sy, fontSize } = extractTextProperties(textElement, container);
  textElement.width = width;
  textElement.height = height;
  textElement.sx = sx;
  textElement.sy = sy;
  textElement.extra.fontSize = fontSize;
}

export default updateTextElementWithoutRender;
