import { GridDesign } from '@gelatoas/design-editor-calendar';
import { PayloadAction } from '@reduxjs/toolkit';

import { GridConfigState } from 'editor/src/store/gridConfig/types';

const setGridDesignsReducer = (state: GridConfigState, action: PayloadAction<GridDesign[]>) => {
  state.grids = action.payload;
};

export default setGridDesignsReducer;
