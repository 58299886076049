import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState, PersonalizationSettings } from 'editor/src/store/design/types';

const updatePersonalizationSettingsReducer = (
  state: DesignState,
  action: PayloadAction<Partial<PersonalizationSettings>>,
) => {
  if (!state.designData) {
    return;
  }

  const settings = state.designData.personalizationSettings ?? {};
  state.designData.personalizationSettings = { ...settings, ...action.payload };
};

export default updatePersonalizationSettingsReducer;
