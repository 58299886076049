import { Coords } from 'editor/src/store/design/types';

const TEXT_WIDTH_RATIO = 0.8;
const TEXT_LEFT_OFFSET_RATIO = 0.1;
const DEFAULT_FONT_SIZE_RATIO = 0.08;
const CENTER_OFFSET_DIVISOR = 2;

export const calculateTopPosition = (
  pageCoords: Coords,
  blankAreaHeight: number,
  textHeight: number,
  mm2px: (number: number) => number,
) => {
  return pageCoords.top + mm2px(blankAreaHeight) / CENTER_OFFSET_DIVISOR - textHeight / CENTER_OFFSET_DIVISOR;
};

export const calculateWidth = (blankAreaWidth: number, mm2px: (number: number) => number) => {
  return mm2px(blankAreaWidth) * TEXT_WIDTH_RATIO;
};

export const calculateFontSize = (blankAreaSize: number, ratio = DEFAULT_FONT_SIZE_RATIO) => {
  return blankAreaSize * ratio; // Adjust ratio as needed for smaller or larger font
};

export const calculateLeftPosition = (
  pageCoords: Coords,
  blankAreaX: number,
  blankAreaWidth: number,
  mm2px: (number: number) => number,
) => {
  return pageCoords.left + mm2px(blankAreaX) + mm2px(blankAreaWidth) * TEXT_LEFT_OFFSET_RATIO;
};
