// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmationText--TB5l0{font-size:1em;margin:0;font-weight:300}.confirmationButtons--G58pI{display:flex;flex-direction:row;justify-content:flex-end;width:100%}.confirmationButtons--G58pI .button{margin-right:12px}.confirmationButtons--G58pI .button:last-of-type{margin-right:0}.confirmationButtons--G58pI button{margin-left:16px;border-radius:24px}@media only screen and (max-width: 960px){.confirmationText--TB5l0{text-align:center}.confirmationButtons--G58pI{flex-direction:column-reverse;align-items:stretch;gap:8px;margin-left:0}.confirmationButtons--G58pI button{margin-left:0}}`, "",{"version":3,"sources":["webpack://./../editor/src/component/Popup/ConfirmationPopup/index.module.scss"],"names":[],"mappings":"AAEA,yBACE,aAAA,CACA,QAAA,CACA,eAAA,CAGF,4BACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CAEA,oCACE,iBAAA,CAEA,iDACE,cAAA,CAIJ,mCACE,gBAAA,CACA,kBAAA,CAIJ,0CACE,yBACE,iBAAA,CAGF,4BACE,6BAAA,CACA,mBAAA,CACA,OAAA,CACA,aAAA,CAEA,mCACE,aAAA,CAAA","sourcesContent":["@import 'editor/src/comonStyles/variables';\n\n.confirmationText {\n  font-size: 1em;\n  margin: 0;\n  font-weight: 300;\n}\n\n.confirmationButtons {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n\n  :global(.button) {\n    margin-right: 12px;\n\n    &:last-of-type {\n      margin-right: 0;\n    }\n  }\n\n  button {\n    margin-left: 16px;\n    border-radius: 24px;\n  }\n}\n\n@media only screen and (max-width: $maxMobileWidth) {\n  .confirmationText {\n    text-align: center;\n  }\n\n  .confirmationButtons {\n    flex-direction: column-reverse;\n    align-items: stretch;\n    gap: 8px;\n    margin-left: 0;\n\n    button {\n      margin-left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationText": `confirmationText--TB5l0`,
	"confirmationButtons": `confirmationButtons--G58pI`
};
export default ___CSS_LOADER_EXPORT___;
