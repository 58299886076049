import debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import isRightBarVisible from 'editor/src/store/app/selector/isRightBarVisible';
import setCanvasSizeOperation from 'editor/src/store/canvas/operation/setCanvasSizeOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import isSidebarVisible from 'editor/src/store/editorModules/sidebar/selector/isSidebarVisible';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

export const KEYBOARD_ELEMENT = new Set(['TEXTAREA', 'INPUT']);

function useCanvasDimensions(
  canvasDiv: React.RefObject<HTMLDivElement>,
  fabricCanvas: fabric.Canvas | undefined,
  isMobile: boolean,
) {
  const dispatch = useDispatch();
  const { showSidebar, hasTabs, showRightBar } = useSelector(
    (state) => ({
      showSidebar: isMobile ? true : isSidebarVisible(state) && !!getSidebarActiveTab(state),
      hasTabs: state.editorModules.sidebar.tabs.length > 1,
      showRightBar: isMobile ? false : isRightBarVisible(state),
    }),
    shallowEqual,
  );

  const recalculateCanvasSize = useCallback(() => {
    if (!canvasDiv.current || !fabricCanvas) {
      return false;
    }
    const { clientHeight, clientWidth } = canvasDiv.current;
    if (clientWidth === 0 || clientHeight === 0) {
      return false;
    }

    const tagName = document.activeElement?.tagName;
    if (
      clientWidth === fabricCanvas.getWidth() &&
      // no resize on mobile when opening the keyboard
      (clientHeight === fabricCanvas.getHeight() || (tagName && KEYBOARD_ELEMENT.has(tagName)))
    ) {
      return true;
    }

    fabricCanvas.setDimensions({ width: clientWidth, height: clientHeight });
    dispatch(setCanvasSizeOperation(clientWidth, clientHeight));
    return true;
  }, [fabricCanvas]);

  useEffect(() => {
    let updateHandle = -1;
    function update() {
      updateHandle = window.setTimeout(() => {
        if (!recalculateCanvasSize()) {
          update();
        }
      }, 50);
    }
    update();
    return () => window.clearTimeout(updateHandle);
  }, [showSidebar, hasTabs, showRightBar, !!fabricCanvas, recalculateCanvasSize]);

  useEffect(() => {
    const recalculateCanvasSizeDebounced = debounce(recalculateCanvasSize, 100, { trailing: true });
    window.addEventListener('resize', recalculateCanvasSizeDebounced);
    window.addEventListener('resizeCanvas', recalculateCanvasSizeDebounced);
    return () => {
      window.removeEventListener('resize', recalculateCanvasSizeDebounced);
      window.removeEventListener('resizeCanvas', recalculateCanvasSizeDebounced);
    };
  }, [recalculateCanvasSize]);
}

export default useCanvasDimensions;
