import cn from 'classnames';
import Hammer from 'hammerjs';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import getBoxedValue from 'editor/src/util/getBoxedValue';

import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';
import IconCross from 'editor/src/component/Icon/IconCross';
import PreviewPagination from 'product-personalizer/src/component/PreviewPagination';

import styles from './index.module.scss';

interface Props {
  images: string[];
  selectedIndex: number;
  isMobile: boolean;
  onIndexChanged: (index: number) => void;
  onClose: () => void;
}

const PreviewDialog = ({ images, selectedIndex = 0, isMobile, onIndexChanged, onClose }: Props) => {
  const hammer = useRef<HammerManager>();
  const carouselRef = useRef<HTMLImageElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const scale = useRef<number>(1);
  const translate = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (carouselRef.current) {
      hammer.current = new Hammer(carouselRef.current);
      hammer.current.get('pinch').set({ enable: true, threshold: 0.1 });
      hammer.current.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, pointers: 2 });
    }

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (!isMobile || !hammer.current || !carouselRef.current || !scale.current || !contentRef.current) {
      return undefined;
    }

    const currentHammer = hammer.current;
    let isDragging = false;

    const onZoom = (e: WheelEvent) => {
      e.preventDefault();
      const zoomFactor = 0.995;
      updateScale(scale.current * zoomFactor ** e.deltaY);
    };

    contentRef.current.addEventListener('wheel', onZoom, { passive: false });

    let initialX: number;
    let initialY: number;
    let offsetX = 0;
    let offsetY = 0;

    const startDrag = (e: TouchEvent) => {
      isDragging = true;
      const touch = e.touches[0];
      initialX = touch.clientX;
      initialY = touch.clientY;
    };
    const stopDrag = () => {
      isDragging = false;
    };
    const onDrag = (e: TouchEvent) => {
      e.preventDefault();
      if (!isDragging || !carouselRef.current) {
        return;
      }
      const touch = e.touches[0];
      offsetX = touch.clientX - initialX;
      offsetY = touch.clientY - initialY;
      initialX += offsetX;
      initialY += offsetY;
      updateTranslate(offsetX, offsetY);
      transformImage();
    };
    contentRef.current.addEventListener('touchstart', startDrag);
    contentRef.current.addEventListener('touchend', stopDrag);
    contentRef.current.addEventListener('touchmove', onDrag);

    currentHammer.on('pinchstart', () => {
      const initScale = scale.current || 1;
      currentHammer.on('pinch', (e) => updateScale(initScale * e.scale));

      currentHammer.on('pinchend', () => {
        currentHammer.off('pinch');
        currentHammer.off('pinchend');
      });
    });

    currentHammer.on('swipeleft', () => {
      onIndexChanged(Math.min(selectedIndex + 1, images.length - 1));
    });
    currentHammer.on('swiperight', () => {
      onIndexChanged(Math.max(selectedIndex - 1, 0));
    });

    return () => {
      currentHammer.off('pinchstart');
      currentHammer.off('pinch');
      currentHammer.off('pinchend');
      currentHammer.off('swipeleft');
      currentHammer.off('swiperight');
      contentRef.current?.removeEventListener('wheel', onZoom);
      contentRef.current?.removeEventListener('touchstart', startDrag);
      contentRef.current?.removeEventListener('touchend', stopDrag);
      contentRef.current?.removeEventListener('touchmove', onDrag);
    };
  }, [images.length, selectedIndex, onIndexChanged, isMobile]);

  const updateTranslate = (offsetX: number, offsetY: number) => {
    if (!carouselRef.current) {
      return;
    }
    const containedImageSize = getContainedImageSize(carouselRef.current);
    const maxTranslateX = Math.max((containedImageSize.width * scale.current - carouselRef.current.width) / 2, 0);
    const maxTranslateY = Math.max((containedImageSize.height * scale.current - carouselRef.current.height) / 2, 0);
    translate.current = {
      x: getBoxedValue(-maxTranslateX, translate.current.x + offsetX, maxTranslateX),
      y: getBoxedValue(-maxTranslateY, translate.current.y + offsetY, maxTranslateY),
    };
  };

  const onImageChanged = (index: number) => {
    scale.current = 1;
    translate.current = { x: 0, y: 0 };
    transformImage();
    onIndexChanged(index);
  };

  const updateScale = (newScale: number) => {
    scale.current = Math.max(newScale, 1);
    updateTranslate(0, 0);
    transformImage();
  };

  const getContainedImageSize = (img: HTMLImageElement) => {
    const ratio = img.naturalWidth / img.naturalHeight;
    let width = img.height * ratio;
    let { height } = img;

    if (width > img.width) {
      width = img.width;
      height = img.width / ratio;
    }

    return { width, height };
  };

  const transformImage = () => {
    if (!carouselRef.current) {
      return;
    }

    carouselRef.current.style.transform = `translate(${translate.current.x}px, ${translate.current.y}px) scale(${scale.current})`;
  };

  const onContentClick = () => {
    !isMobile && onClose();
  };

  const onPreviousImageClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onIndexChanged((selectedIndex + images.length - 1) % images.length);
  };

  const onNextImageClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onIndexChanged((selectedIndex + 1) % images.length);
  };

  return ReactDOM.createPortal(
    <div className={cn(styles.PreviewDialog, 'preview-dialog', { [styles.isMobile]: isMobile })}>
      <div ref={contentRef} className={styles.content} onClick={onContentClick}>
        <img ref={carouselRef} className={styles.selectedImage} src={images[selectedIndex]} alt="Preview" />
        {!isMobile && (
          <>
            <div className={styles.arrowLeft} onClick={onPreviousImageClick}>
              <IconChevronLeft className={styles.icon} />
            </div>
            <div className={styles.arrowRight} onClick={onNextImageClick}>
              <IconChevronRight className={styles.icon} />
            </div>
          </>
        )}
      </div>
      <div className={styles.closeButton} onClick={onClose}>
        <IconCross className={styles.icon} />
      </div>
      {isMobile && (
        <div className={styles.bottomBar}>
          <PreviewPagination images={images} selectedIndex={selectedIndex} onIndexChanged={onImageChanged} />
        </div>
      )}
    </div>,
    document.body,
  );
};

export default React.memo(PreviewDialog);
