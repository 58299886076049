import { useMemo } from 'react';

import { useSelector, useStore } from 'editor/src/store/hooks';

export type DisplayMode = 'classic' | 'loading';

const urlParams = new URLSearchParams(window.location.search);
export const isEmbedded = urlParams.get('mode') === 'embedded';
export const isFlutter = urlParams.get('mode') === 'flutter';

function useDisplayMode(): DisplayMode {
  const store = useStore();

  const hasDesignData = useSelector((state) => !!state.design.designData);

  return useMemo(() => {
    if (!isEmbedded) {
      return 'classic';
    }

    const { designData } = store.getState().design;

    return designData ? 'classic' : 'loading';
  }, [hasDesignData, isEmbedded]);
}

export default useDisplayMode;
