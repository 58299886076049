import classNames from 'classnames';
import React, { useCallback } from 'react';

import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import { Condition } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useSelector } from 'editor/src/store/hooks';

import ImageThumbnail from 'product-personalizer/src/component/PersonalizationContent/PersonalizationImage/ImageThumbnail';

import ConditionOptionButton from '../ConditionOptionsButton/ConditionOptionButton';

import styles from './index.module.scss';

interface Props {
  condition: Condition;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptionsThumbnail({ condition, onSelectOption }: Props) {
  const images = useSelector((state) =>
    condition.options.map((option) => (option.imageId ? getImageById(state, option.imageId) : undefined)),
  );

  const onSelect = useCallback(
    (optionId: string) => {
      onSelectOption(condition.id, optionId);
    },
    [condition.id],
  );

  return (
    <div className={classNames(styles.ConditionOptionsThumbnail, 'cy-condition-options-thumbnail')}>
      {condition.options.map((option, i) => {
        const imageUrl = images[i] && getImageUrl(images[i], true);

        if (!imageUrl) {
          return (
            <ConditionOptionButton
              key={option.id}
              option={option}
              isActive={option.id === condition.activeOptionId}
              conditionId={condition.id}
              onSelectOption={onSelectOption}
            />
          );
        }

        return (
          <ImageThumbnail
            id={option.id}
            key={option.id}
            url={imageUrl}
            isActive={condition.activeOptionId === option.id}
            onSelect={onSelect}
            className="g-selector-condition-thumbnail"
          />
        );
      })}
    </div>
  );
}

export default React.memo(ConditionOptionsThumbnail);
