import { createSlice } from '@reduxjs/toolkit';

import setDemoProductsReducer from './reducer/setDemoProductsReducer';
import { DemoAppState } from './types';

const initialState: DemoAppState = {
  products: [],
};

const slice = createSlice({
  name: 'demoApp',
  initialState,
  reducers: {
    setDemoProductsAction: setDemoProductsReducer,
  },
});

export const { setDemoProductsAction } = slice.actions;
const { reducer } = slice;

export default reducer;
