import { PayloadAction } from '@reduxjs/toolkit';

import { ConditionAddress, conditionGroupChildenKey, DesignState, MediaElement } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';
import getElementParentKeys from './getElementParentKeys';

const addElementToConditionReducer = (
  state: DesignState,
  action: PayloadAction<{
    address: ConditionAddress;
    optionId: string;
    element: MediaElement;
  }>,
) => {
  const { address, optionId, element } = action.payload;
  const { spreadIndex, conditionId } = address;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  if (conditionGroup.children[conditionGroupChildenKey].find((elt) => elt.id === element.uuid)) {
    conditionGroup.children[conditionGroupChildenKey] = conditionGroup.children[conditionGroupChildenKey].filter(
      (child) => child.type === 'condition' || child.id !== element.uuid,
    );
  } else {
    const currentParents = getElementParentKeys(conditionGroup, element.uuid);
    currentParents.forEach((parent) => {
      if (conditionId !== parent.conditionId) {
        // accept same condition to have layer in multiple values
        const parentKey = getConditionChildrenKey(parent.conditionId, parent.optionId);
        conditionGroup.children[parentKey] = conditionGroup.children[parentKey].filter(
          (child) => child.type === 'condition' || child.id !== element.uuid,
        );
      }
    });
  }

  const childenKey = getConditionChildrenKey(conditionId, optionId);
  conditionGroup.children[childenKey].push({
    type: element.type,
    id: element.uuid,
  });
};

export default addElementToConditionReducer;
