/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconLoading({ className }: Props) {
  return (
    <Icon className={className} spin>
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.5 18.1562C14.1944 18.1562 18 14.3507 18 9.65625C18 4.96183 14.1944 1.15625 9.5 1.15625C4.80558 1.15625 1 4.96183 1 9.65625"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#5991FF"
        />
      </svg>
    </Icon>
  );
}

export default IconLoading;
