/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

function IconDraft({ className }: Props) {
  return (
    <div className={cn(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path
          d="M1.61538 18.4882C1.15513 18.4882 0.770833 18.334 0.4625 18.0257C0.154167 17.7173 0 17.333 0 16.8728V4.10353C0 3.64328 0.154167 3.25899 0.4625 2.95066C0.770833 2.64232 1.15513 2.48816 1.61538 2.48816H10.0019L9.00193 3.48816H1.61538C1.46154 3.48816 1.32052 3.55226 1.1923 3.68046C1.0641 3.80867 1 3.9497 1 4.10353V16.8728C1 17.0266 1.0641 17.1676 1.1923 17.2959C1.32052 17.4241 1.46154 17.4882 1.61538 17.4882H14.3846C14.5385 17.4882 14.6795 17.4241 14.8077 17.2959C14.9359 17.1676 15 17.0266 15 16.8728V9.38431L16 8.38431V16.8728C16 17.333 15.8458 17.7173 15.5375 18.0257C15.2292 18.334 14.8449 18.4882 14.3846 18.4882H1.61538ZM6 12.4882V9.87276L14.9442 0.928531C15.0545 0.818281 15.1705 0.741998 15.2923 0.699681C15.4141 0.657381 15.5423 0.63623 15.6769 0.63623C15.8026 0.63623 15.9253 0.657381 16.0452 0.699681C16.1651 0.741998 16.2737 0.811873 16.3712 0.909306L17.4827 1.98816C17.5891 2.09841 17.6699 2.21988 17.725 2.35258C17.7801 2.48527 17.8077 2.61891 17.8077 2.75353C17.8077 2.88815 17.7875 3.0154 17.7471 3.13528C17.7067 3.25515 17.6314 3.37021 17.5212 3.48048L8.51923 12.4882H6ZM7 11.4882H8.0923L14.7577 4.82278L14.2115 4.27663L13.6019 3.69201L7 10.2939V11.4882Z"
          fill="#1C1B1F"
        />
      </svg>
    </div>
  );
}

export default IconDraft;
