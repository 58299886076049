import { createSlice } from '@reduxjs/toolkit';

import setPluginsReducer from './reducer/setPluginsReducer';
import { PluginsState } from './types';

export const pluginsInitialState: PluginsState = {
  list: [],
};

const slice = createSlice({
  name: 'plugins',
  initialState: pluginsInitialState,
  reducers: {
    setPluginsAction: setPluginsReducer,
  },
});

const { actions, reducer } = slice;

export const { setPluginsAction } = actions;

export default reducer;
