import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { HostSettingsState } from 'editor/src/store/hostSettings/types';

export interface Environment {
  production: boolean;
  env: EnvEnum;
  translationsUrl: string;
  amplitudeKey: string;
  hostSettingsOverrides?: Partial<HostSettingsState>;
  enforceTabs?: TAB_NAMES[];
  shutterstockKeys: ShutterstockKeys;
}

export interface ShutterstockKeys {
  essentials: string;
  full: string;
}

export enum EnvEnum {
  dev = 'dev',
  test = 'test',
  staging = 'staging',
  live = 'live',
}
