import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';

import styles from './index.module.scss';

export const INLINE_UPLOADER_TARGET_CLASS_NAME = 'uploader-placeholder';
interface Props {
  closeMenuCb?: () => void;
}
function UploaderMobileMenu({ closeMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.UploaderMobileMenu, 'cy-tab-gallery')}>
      <div className={cn(styles.header, 'py-1', 'px-2')}>
        <h1 className={styles.title}>{t('Upload files')}</h1>
        <Button className="cy-uploader-close-menu" tertiary stopPropagation onClick={closeMenuCb}>
          {t('Done')}
        </Button>
      </div>
      <div className={cn('px-2', styles.uploaderPlaceholder, INLINE_UPLOADER_TARGET_CLASS_NAME)} />
    </div>
  );
}

export default React.memo(UploaderMobileMenu);
