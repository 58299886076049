import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaElement, MediaImage, MediaText } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const updateMediaElementByUuidOperation =
  (uuid: number, elementUpdate: Partial<MediaElement> | Partial<MediaImage> | Partial<MediaText>) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const elementAddress = getStructureIndexByElementUuid(getState().design.designData, uuid);
    if (elementAddress) {
      dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
    }
  };

export default updateMediaElementByUuidOperation;
