import { GalleryState } from 'editor/src/store/gallery/types';

const selectAllImagesReducer = (state: GalleryState) => {
  state.selectedImagesIds = [];
  state.images
    .filter((image) => !image.hidden)
    .forEach((image) => {
      state.selectedImagesIds.push(image.id);
    });
};

export default selectAllImagesReducer;
