import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

const setECommerceProductReducer = (state: VariantsState, action: PayloadAction<string>) => {
  if (!state.eCommerceProduct) {
    return;
  }

  state.eCommerceProduct.selectedVariantId = action.payload;
};

export default setECommerceProductReducer;
