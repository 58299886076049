import { PayloadAction } from '@reduxjs/toolkit';

import { PreviewState } from 'editor/src/store/editorModules/preview/types';

const setFlatPreviewFailedReducer = (state: PreviewState, action: PayloadAction<{ index: number }>) => {
  state.flatPreviews[action.payload.index] = {
    loadingFailed: true,
    url: '',
    name: '',
  };
};

export default setFlatPreviewFailedReducer;
