import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { DropDownContext } from './DropDownContainer';
import getPosition, { DropdownPosition } from './getPosition';

import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;
  onClickOutside?: (e?: MouseEvent | TouchEvent) => void;
  isVisible: boolean;
  wrapperClassName?: string;
  className?: string;
  fadeMask?: boolean;
  openPosition?: DropdownPosition;
  matchWidth?: boolean;
  margin?: number;

  maxHeight?: number;
}

function DropDown({
  children,
  onClickOutside,
  isVisible,
  wrapperClassName,
  className,
  fadeMask,
  openPosition = 'bottom-left',
  matchWidth,
  maxHeight,
  margin,
}: Props) {
  const containerId = useContext(DropDownContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const clickStartedIn = useRef(false);
  const [position, setPosition] = useState<{
    x: number;
    y: number;
    maxHeight: number;
  } | null>(null);
  const container = document.getElementById(containerId);

  const handleWindowEvent = (e: MouseEvent | WheelEvent | TouchEvent) => {
    if (
      !clickStartedIn.current &&
      dropDownRef.current &&
      e.composedPath &&
      !e.composedPath().includes(dropDownRef.current)
    ) {
      onClickOutside?.(e);
    }
  };

  function positionDropDown() {
    const wrapperBox = wrapperRef.current?.getBoundingClientRect();
    if (matchWidth && wrapperBox && dropDownRef.current) {
      dropDownRef.current.style.width = `${wrapperBox.width}px`;
    }
    const dropBox = dropDownRef.current?.getBoundingClientRect();
    const containerBox = container?.getBoundingClientRect();
    if (!wrapperBox || !dropBox || !containerBox) {
      setPosition(null);
      return;
    }

    const { clientWidth, clientHeight } = document.documentElement;
    const position = getPosition(openPosition, wrapperBox, dropBox, clientWidth, clientHeight, margin);
    position.x -= containerBox.x;
    position.y -= containerBox.y;
    setPosition(position);
  }

  function onMouseDown(e: MouseEvent) {
    clickStartedIn.current =
      (dropDownRef.current && e.composedPath && e.composedPath().includes(dropDownRef.current)) ?? false;
  }

  useEffect(() => {
    if (!isVisible) {
      return undefined;
    }

    const handle = window.setTimeout(() => {
      window.addEventListener('mousedown', onMouseDown);
      window.addEventListener('click', handleWindowEvent, true);
      window.addEventListener('touchstart', handleWindowEvent, true);
      window.addEventListener('wheel', handleWindowEvent, false);
      window.addEventListener('resize', positionDropDown, false);
    }, 0);

    return () => {
      window.clearTimeout(handle);
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('click', handleWindowEvent, true);
      window.removeEventListener('touchstart', handleWindowEvent, true);
      window.removeEventListener('wheel', handleWindowEvent, false);
      window.removeEventListener('resize', positionDropDown, false);
    };
  }, [isVisible]);

  useEffect(() => {
    positionDropDown();
  }, [isVisible]);

  if (!isVisible || !container) {
    return null;
  }

  return (
    <div ref={wrapperRef} className={cn(wrapperClassName, styles.dropdownWrapper)}>
      {fadeMask && <div className={styles.fadeMask} style={{ opacity: position ? 1 : 0 }} />}
      {ReactDOM.createPortal(
        <div
          ref={dropDownRef}
          style={{
            opacity: position ? 1 : 0,
            transform: `translate3d(${position?.x}px,${position?.y}px,0px)`,
            maxHeight: `${maxHeight ?? position?.maxHeight}px`,
          }}
          className={cn(className, styles.dropdown)}
        >
          {children}
        </div>,
        container,
      )}
    </div>
  );
}

export default React.memo(DropDown);
