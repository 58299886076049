import React from 'react';

import styles from './index.module.scss';

export const CONTAINER_ID = 'tooltip-container-id';

function TooltipContainer() {
  return <div id={CONTAINER_ID} className={styles.tooltipContainer} />;
}

export default TooltipContainer;
