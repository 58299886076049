import { PayloadAction } from '@reduxjs/toolkit';

import { conditionGroupChildenKey, DesignState } from 'editor/src/store/design/types';

import getConditionChildrenKey from './getConditionChildrenKey';

const addConditionToConditionReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    conditionId: string;
    parent: { conditionId: string; optionId: string };
  }>,
) => {
  const { spreadIndex, conditionId, parent } = action.payload;
  const conditionGroup = state.designData?.spreads[spreadIndex]?.conditionGroup;
  if (!conditionGroup) {
    return;
  }

  const condition = conditionGroup.conditions[conditionId];
  const topParent = conditionGroup.children[conditionGroupChildenKey];
  const parentChildIndex = topParent.findIndex((child) => child.type === 'condition' && child.id === conditionId);
  if (parentChildIndex !== -1) {
    topParent.splice(parentChildIndex, 1);
  }

  const childenKey = getConditionChildrenKey(parent.conditionId, parent.optionId);
  if (!conditionGroup.children[childenKey]) {
    conditionGroup.children[childenKey] = [];
  }

  conditionGroup.children[childenKey].push({
    type: 'condition',
    id: conditionId,
  });
  if (!condition.parent) {
    condition.parent = { conditionId: parent.conditionId, optionIds: [] };
  }
  condition.parent.optionIds.push(parent.optionId);
};

export default addConditionToConditionReducer;
