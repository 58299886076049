import { PayloadAction } from '@reduxjs/toolkit';

import fontManager from 'editor/src/store/fonts/fontManager';
import { FontDefinition, FontState } from 'editor/src/store/fonts/types';
import getOrderedFallbackFonts from 'editor/src/store/fonts/utils/getOrderedFallbackFonts';

const setAvailableFontsReducer = (state: FontState, action: PayloadAction<FontDefinition[]>) => {
  state.availableFonts = action.payload;
  fontManager.fontDefinitions = getOrderedFallbackFonts(action.payload);
};

export default setAvailableFontsReducer;
