import { UnknownAction, ThunkDispatch as ReduxThunkDispatch } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch as useDispatchRedux,
  // eslint-disable-next-line no-restricted-imports
  useSelector as useSelectorRedux,
  // eslint-disable-next-line no-restricted-imports
  useStore as useStoreRedux,
} from 'react-redux';

import type { PPStore, RootState, ThunkArg } from '.';

export type Thunk = (dispatch: ThunkDispatch, getState: () => RootState, extra: ThunkArg) => any;
export type AsyncThunk = (dispatch: ThunkDispatch, getState: () => RootState, extra: ThunkArg) => Promise<any>;
export type ThunkDispatch = ReduxThunkDispatch<RootState, ThunkArg, UnknownAction>;
const useDispatch: () => ThunkDispatch = useDispatchRedux;
const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux;
const useStore: () => PPStore = useStoreRedux as any;

export { useDispatch, useSelector, useStore };
