import isEmpty from 'lodash/isEmpty';

import { VariantImages } from 'product-personalizer/src/component/PersonalizationView';

import { Product } from './productTypes';

interface Options {
  product: Product;
  selectedVariantId: string;
  selectedGelatoVariantId: string | undefined;
  allVariantImages: VariantImages | undefined;
}

const getVariantImages = ({
  product,
  selectedVariantId,
  selectedGelatoVariantId,
  allVariantImages,
}: Options): string[] => {
  let variantImages = product.imageUrls;
  if (allVariantImages && !isEmpty(allVariantImages)) {
    if (selectedGelatoVariantId && allVariantImages[selectedGelatoVariantId]) {
      variantImages = allVariantImages[selectedGelatoVariantId];
    }

    if (selectedVariantId && allVariantImages[selectedVariantId]) {
      variantImages = allVariantImages[selectedVariantId];
    }
  }

  return variantImages;
};

export default getVariantImages;
