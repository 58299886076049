import cn from 'classnames';
import Hammer from 'hammerjs';
import React, { useEffect, useRef } from 'react';

import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';
import IconLoading from 'editor/src/component/Icon/IconLoading';
import { PreviewItem } from 'editor/src/component/Preview/FlatPreview/FlatPreviewList/ListItem';

import ListItem from './ListItem';

import styles from './index.module.scss';

interface Props {
  isMobile: boolean;
  items: PreviewItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

function Carousel({ items, isMobile, activeIndex, setActiveIndex, onSwipeLeft, onSwipeRight }: Props) {
  const hammer = useRef<HammerManager | undefined>(undefined);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleClickLeft = () => {
    setActiveIndex((activeIndex - 1 + items.length) % items.length);
  };

  const handleClickRight = () => {
    setActiveIndex((activeIndex + 1) % items.length);
  };

  useEffect(() => {
    if (carouselRef.current) {
      hammer.current = new Hammer(carouselRef.current);
    }
  }, []);

  useEffect(() => {
    if (!hammer.current) {
      return undefined;
    }

    hammer.current.on('swipeleft', () => {
      setActiveIndex((activeIndex + 1) % items.length);
      onSwipeLeft?.();
    });
    hammer.current.on('swiperight', () => {
      setActiveIndex((activeIndex - 1 + items.length) % items.length);
      onSwipeRight?.();
    });

    return () => {
      hammer.current?.off('swiperight');
      hammer.current?.off('swipeleft');
    };
  }, [activeIndex, items.length]);

  const isLoaded = items[0]?.url || items[0]?.blob;

  return (
    <div
      className={cn(styles.carousel, 'cy-preview-carousel', {
        [styles.mobile]: isMobile,
        [styles.loaded]: isLoaded,
      })}
    >
      {!isLoaded && (
        <div className={styles.loaderContainer}>
          <IconLoading />
        </div>
      )}
      <div ref={carouselRef} className={styles.images} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {items.map((item, index) => (
          <ListItem url={item.url} blob={item.blob || null} key={index} />
        ))}
      </div>
      {items.length > 1 && (
        <>
          <div className={cn(styles.arrowLeft, 'cy-preview-arrow-left')} onClick={handleClickLeft}>
            <IconChevronLeft />
          </div>
          <div className={cn(styles.arrowRight, 'cy-preview-arrow-right')} onClick={handleClickRight}>
            <IconChevronRight />
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Carousel);
