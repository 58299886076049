import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';

import getSpreadBackgroundImage from 'editor/src/store/design/selector/getSpreadBackgroundImage';
import getSpreadForegroundImage from 'editor/src/store/design/selector/getSpreadForegroundImage';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Coords, SpreadGroundImage } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import limitPrecision from 'editor/src/util/limitPrecision';
import { FabricCanvasContext } from 'editor/src/util/useFabricCanvas';
import { FabricUtilsContext } from 'editor/src/util/useFabricUtils';

import orderObjects from 'editor/src/component/EditorArea/orderObjects';
import { SHADOW } from 'editor/src/component/EditorArea/Spread/Page/SpreadBackgroundColor';
import SpreadGroundImageElement from 'editor/src/component/EditorArea/Spread/SpreadGroundImageElement';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { NO_CANVAS_ROTATION } from 'editor/src/component/EditorArea/useCanvasRotation';
import useRenderCanvasOnPageVisible from 'editor/src/component/EditorArea/useRenderCanvasOnPageVisible';
import IconLoading from 'product-personalizer/src/component/IconLoading';

import PageElement from './PageElement';
import PageMedia from './PageMedia';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
}

const CANVAS_ID = 'preview-canvas';
const SPREAD_COORDS: Coords = { left: 0, top: 0 };

function SpreadRenderer({ spreadIndex, className, onClick, loading }: Props) {
  const [fabricCanvas, setFabricCanvas] = useState<fabric.StaticCanvas>();
  const [pageReady, setPageReady] = useState(false);
  const canvasContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canvas = new fabric.StaticCanvas(CANVAS_ID, {
      selection: false,
      renderOnAddRemove: false,
      preserveObjectStacking: true, // to avoid having the active elements always on top
      uniScaleKey: 'none',
      centeredKey: 'none',
      width: canvasContainerRef.current?.clientWidth ?? 1000,
      height: canvasContainerRef.current?.clientHeight ?? 1000,
    });

    canvas.on('before:render', () => orderObjects(canvas));
    setFabricCanvas(canvas);
  }, []);

  useRenderCanvasOnPageVisible(fabricCanvas);

  const { spreadBackground, spreadForeground, spreadWidth, spreadHeight, pages, designKey, spotFinishingType } =
    useSelector((state) => {
      const spread = state.design.designData?.spreads[spreadIndex];
      return {
        spreadBackground: getSpreadBackgroundImage(state, spread?.name || ''),
        spreadForeground: getSpreadForegroundImage(state, spread?.name || ''),
        spreadWidth: spread ? getSpreadWidthFromSpread(spread) : 1,
        spreadHeight: spread ? getSpreadHeightFromSpread(spread) : 1,
        pages: spread?.pages,
        designKey: state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
        spotFinishingType: spread?.spot_finishing_type,
      };
    }, shallowEqual);

  const [fabricUtils, setFabricUtils] = useState({ mm2px: (x: number) => x, px2mm: (x: number) => x });

  const updateCanvasViewportRef = useRef(() => {});
  updateCanvasViewportRef.current = () => {
    if (!fabricCanvas || !canvasContainerRef.current) {
      return;
    }

    const { clientWidth: canvasWidth, clientHeight: canvasHeight } = canvasContainerRef.current!;
    if (canvasWidth === 0 || canvasHeight === 0) {
      return;
    }
    fabricCanvas.setDimensions({ width: canvasWidth, height: canvasHeight });

    let pxSize = 1;
    let mmSize = 1;
    if (canvasWidth / spreadWidth > canvasHeight / spreadHeight) {
      pxSize = canvasHeight;
      mmSize = spreadHeight;
    } else {
      pxSize = canvasWidth;
      mmSize = spreadWidth;
    }

    const mm2px = (size: number) => limitPrecision(size * (pxSize / mmSize), 13);
    const px2mm = (size: number) => limitPrecision(size * (mmSize / pxSize), 13);

    const shadow = (!spreadBackground && SHADOW) || undefined;
    const shadowSizeX1 = -(shadow?.blur || 0) + (shadow?.offsetX || 0);
    const shadowSizeX2 = (shadow?.blur || 0) + (shadow?.offsetX || 0);
    const shadowSizeY1 = -(shadow?.blur || 0) + (shadow?.offsetY || 0);
    const shadowSizeY2 = (shadow?.blur || 0) + (shadow?.offsetY || 0);

    let minX = SPREAD_COORDS.left + Math.min(shadowSizeX1, 0);
    let minY = SPREAD_COORDS.top + Math.min(shadowSizeY1, 0);
    let maxX = minX + mm2px(spreadWidth + Math.max(shadowSizeX2, 0));
    let maxY = minY + mm2px(spreadHeight + Math.max(shadowSizeY2, 0));

    const handleSpreadImage = (image: SpreadGroundImage) => {
      const imageScaleX = mm2px(spreadWidth) / image.width;
      const imageScaleY = mm2px(spreadHeight) / image.height;
      const width = imageScaleX * image.imgWidth;
      const height = imageScaleY * image.imgHeight;
      const left = SPREAD_COORDS.left - image.left * imageScaleX;
      const top = SPREAD_COORDS.top - image.top * imageScaleY;
      minX = Math.min(minX, left);
      minY = Math.min(minY, top);
      maxX = Math.max(maxX, left + width);
      maxY = Math.max(maxY, top + height);
    };

    if (spreadBackground) {
      handleSpreadImage(spreadBackground);
    }
    if (spreadForeground) {
      handleSpreadImage(spreadForeground);
    }

    const renderedWidth = maxX - minX;
    const renderedHeight = maxY - minY;
    const zoom = Math.min(canvasWidth / renderedWidth, canvasHeight / renderedHeight);
    fabricCanvas.setZoom(zoom);

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const offsetX = centerX * zoom - canvasWidth / 2;
    const offsetY = centerY * zoom - canvasHeight / 2;
    fabricCanvas.absolutePan(new fabric.Point(offsetX, offsetY));

    setFabricUtils({ mm2px, px2mm });
    setPageReady(true);
  };

  useEffect(() => {
    if ('ResizeObserver' in window && canvasContainerRef.current) {
      const resizeObserver = new ResizeObserver(
        debounce(() => updateCanvasViewportRef.current(), 50, { leading: true }),
      );
      resizeObserver.observe(canvasContainerRef.current);
      return () => resizeObserver.disconnect();
    }

    const updateCanvasViewport = () => updateCanvasViewportRef.current();
    window.setTimeout(updateCanvasViewport, 0);
    window.addEventListener('resize', updateCanvasViewport);
    return () => window.removeEventListener('resize', updateCanvasViewport);
  }, []);

  useEffect(() => {
    updateCanvasViewportRef.current();
  }, [spreadWidth, spreadHeight, fabricCanvas, spreadBackground, spreadForeground]);

  const pagesKey = `${designKey}-${spreadIndex}`;

  return (
    <>
      <div
        ref={canvasContainerRef}
        onClick={onClick}
        className={cn(styles.SpreadRenderer, className, { [styles.loaded]: !loading })}
      >
        <canvas id={CANVAS_ID} />
        <div className={styles.loading}>
          <div className={styles.loader}>{loading && <IconLoading />}</div>
        </div>
      </div>
      {fabricCanvas && pageReady && (
        <FabricCanvasContext.Provider value={fabricCanvas as fabric.Canvas}>
          <FabricUtilsContext.Provider value={fabricUtils}>
            {spreadBackground && (
              <SpreadGroundImageElement
                imageData={spreadBackground}
                spreadWidth={spreadWidth}
                spreadHeight={spreadHeight}
                spreadCoords={SPREAD_COORDS}
                zIndex={zIndex.BACKGROUND}
                canvasRotation={NO_CANVAS_ROTATION}
              />
            )}
            {pages?.map((page, index) => (
              <PageElement
                key={index}
                page={page}
                pagesKey={pagesKey}
                hasBackgroundImage={!!spreadBackground}
                backgroundColor={page.backgroundColor || ''}
                spreadCoords={SPREAD_COORDS}
                showBlankAreaShadowOnly
              />
            ))}
            {pages && (
              <PageMedia
                pages={pages}
                pagesKey={pagesKey}
                spreadIndex={spreadIndex}
                spreadCoords={SPREAD_COORDS}
                spotFinishingType={spotFinishingType}
              />
            )}
            {spreadForeground && (
              <SpreadGroundImageElement
                imageData={spreadForeground}
                spreadWidth={spreadWidth}
                spreadHeight={spreadHeight}
                spreadCoords={SPREAD_COORDS}
                zIndex={zIndex.FOREGROUND}
                canvasRotation={NO_CANVAS_ROTATION}
              />
            )}
          </FabricUtilsContext.Provider>
        </FabricCanvasContext.Provider>
      )}
    </>
  );
}

export default React.memo(SpreadRenderer);
