import devEnv from './properties/dev';
import selectedEnv from './properties/selected';
import { EnvEnum } from './types';

export { EnvEnum };

export default {
  ...devEnv,
  ...selectedEnv,
};
