import { MediaElement, MediaLine } from 'editor/src/store/design/types';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import getLineWidth, { getLineAngle } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';

export type Bbox = { x: number; y: number; width: number; height: number };
export type RectMediaElement = Exclude<MediaElement, MediaLine>;
export type Mode = 'adapt' | 'reset';

/**
 * Returns whether the value difference is less than 0.0001
 */
export function areNumberClose(v1: number, v2: number) {
  return Math.abs(v1 - v2) <= 0.0001;
}

/**
 * Returns the rotated rectangle bounding box
 */
export function getRectangleBoundingBox(x: number, y: number, width: number, height: number, angle: number): Bbox {
  const tl = [x, y];
  const tr = getPointPositionRotatedOnPoint(x + width, y, x, y, angle);
  const br = getPointPositionRotatedOnPoint(x + width, y + height, x, y, angle);
  const bl = getPointPositionRotatedOnPoint(x, y + height, x, y, angle);

  const minX = Math.min(tl[0], tr[0], bl[0], br[0]);
  const maxX = Math.max(tl[0], tr[0], bl[0], br[0]);
  const minY = Math.min(tl[1], tr[1], bl[1], br[1]);
  const maxY = Math.max(tl[1], tr[1], bl[1], br[1]);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

/**
 * Returns the bounding box of the element
 */
export function getElementBoundingBox(element: MediaElement) {
  if (element.type === 'line') {
    const width = getLineWidth(element.x1, element.x2, element.y1, element.y2);
    const angle = getLineAngle(element.x1, element.x2, element.y1, element.y2);
    return getRectangleBoundingBox(element.x1, element.x2, width, element.strokeWidth, angle);
  }

  return getRectangleBoundingBox(element.x, element.y, element.width, element.height, element.r);
}
