import { MultiOption, MultiOptions, ProductVariation, SingleOptions } from 'editor/src/store/variants/types';

import isObjectMatchingDepOptions from './isObjectMatchingDepOptions';

function isMatchingOption(controlKey: string, variation: ProductVariation, option: MultiOption, ignoreDeps: boolean) {
  return option.value === variation[controlKey] && (ignoreDeps || isObjectMatchingDepOptions(variation, option));
}

function getAllMatchingVariations(
  variations: ProductVariation[],
  multiOptions: MultiOptions,
  singleOptions: SingleOptions,
  singleMode: boolean,
  replaceControlKeys: string[],
): ProductVariation[] {
  const multiOptionKeys = Object.keys(multiOptions);
  const singleOptionsKeys = Object.keys(singleOptions).filter((key) => singleMode || replaceControlKeys.includes(key));
  return variations.filter(
    (variation) =>
      singleOptionsKeys.every((key) => variation[key] === singleOptions[key]) &&
      multiOptionKeys.every((key) =>
        multiOptions[key].some((option) => isMatchingOption(key, variation, option, singleMode)),
      ),
  );
}

export default getAllMatchingVariations;
