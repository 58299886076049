import { MediaText, TextWrapping } from 'editor/src/store/design/types';
import { LayoutFrame, TextLayoutFrameArea } from 'editor/src/store/editorModules/layouts/types';
import { FontStyles } from 'editor/src/store/fonts/types';

import fontStylesToTextElement from 'editor/src/util/fontStylesToTextElement';
import { elementUuids } from 'editor/src/util/uuids';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

import { getTextElementName } from './layoutElementNameGenerator';

import type { i18n } from 'i18next';

const createTextMediaElement = (
  frame: LayoutFrame,
  defaultText: string,
  defaultFontStyles: FontStyles,
  defaultPersonalizationLock: boolean,
  sample: boolean,
  spreadIndex: number,
  pageElementIndex: number,
  i18n: i18n,
): MediaText => {
  const frameArea = frame.frameArea as TextLayoutFrameArea;
  let fontStyles = { ...defaultFontStyles, wrapping: TextWrapping.Fit };
  if (frameArea.fontStyles) {
    fontStyles = { ...fontStyles, ...frameArea.fontStyles, wrapping: TextWrapping.Fit };
  }

  const textContent = frameArea.textI18nKey ? i18n.t(frameArea.textI18nKey) : defaultText;
  const textElement: MediaText = {
    type: 'text',
    group: '',
    name: getTextElementName(spreadIndex, pageElementIndex),
    x: frame.x,
    y: frame.y,
    width: frame.width,
    height: frame.height,
    sx: 1,
    sy: 1,
    rendered_with: 'opentype.js',
    sample: sample || undefined,
    static: frameArea.static || undefined,
    locked: undefined,
    personalizationLocked: defaultPersonalizationLock || undefined,
    uuid: elementUuids.generate(),
    createdByLayout: true,
    layoutFrameId: frame.id,
    ...fontStylesToTextElement(fontStyles, textContent),
    sourceFrame: { width: frame.width, height: frame.height },
  };

  updateTextElementWithoutRender(textElement, frame);

  return textElement;
};

export default createTextMediaElement;
