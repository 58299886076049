import { FontMetaData } from '@gelatoas/design-editor-fonts';

import { FontDefinition } from 'editor/src/store/fonts/types';

function isFontANotoRegular(font: FontMetaData) {
  return (
    font.family.startsWith('Noto Sans') &&
    (font.style === 'Normal' || !font.style) &&
    (font.weight === 'Regular' || !font.weight)
  );
}

function compareFont(font1: FontDefinition, font2: FontDefinition) {
  const font1IsANotoRegular = isFontANotoRegular(font1.metadata);
  const font2IsANotoRegular = isFontANotoRegular(font2.metadata);

  if (font1IsANotoRegular && font2IsANotoRegular) {
    return font1.metadata.name.localeCompare(font2.metadata.name);
  }

  if (font1IsANotoRegular) {
    return -1;
  }

  if (font2IsANotoRegular) {
    return 1;
  }

  return 0;
}

/**
 * @returns a copy of the array, sorted with Noto Regular fonts first
 */
function getOrderedFallbackFonts(fonts: FontDefinition[]): FontDefinition[] {
  return fonts.concat().sort(compareFont);
}

export default getOrderedFallbackFonts;
