import { Spread } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getCurrentSpreadGroup from './getCurrentSpreadGroup';
import getSpread from './getSpread';

const getSpreadHeight = (state: RootState, spreadIndex: number) => {
  const spread = getSpread(state, spreadIndex);
  const currentSpreadGroup = getCurrentSpreadGroup(state);
  const spreadHeight = spread ? getSpreadHeightFromSpread(spread) : 0;
  // we assume all grouped spreads have the same dimensions
  return currentSpreadGroup?.position === 'vertical'
    ? spreadHeight * currentSpreadGroup.spreadIndexes.length
    : spreadHeight;
};

export const getSpreadHeightFromSpread = (spread: Spread) =>
  spread.pages.reduce((max, page) => Math.max(page.y + page.height, max), 0);

export default getSpreadHeight;
