import { useContext, useLayoutEffect } from 'react';

import { GroupContext } from './FabricGroupComponent';
import { disposeElement } from './fabricUtils';

function useObjectUpdate(fabricCanvas: fabric.Canvas | fabric.StaticCanvas, element: fabric.Object) {
  const groupContext = useContext(GroupContext);

  useLayoutEffect(() => {
    const containerContext = groupContext ?? fabricCanvas;
    containerContext.add(element);

    return () => {
      disposeElement(element);
      containerContext.remove(element);
      fabricCanvas.requestRenderAll();
    };
  }, [element]);

  useLayoutEffect(() => {
    element.setCoords();
    fabricCanvas.requestRenderAll();
  });
}

export default useObjectUpdate;
