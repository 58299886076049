import { captureException } from '@sentry/react';

import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RequestDigitizedAssetData } from 'editor/src/util/postMessages/messages';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { RootState } from '../..';

import setDigitizedAssetErrorOperation from './setDigitizedAssetErrorOperation';
import updateMediaElementByUuidOperation from './updateMediaElementByUuidOperation';

export const currentRequests = new Set<string>();
const MAX_DIGITIZATION_LOADER_TIME = 10000;

const requestDigitizedAssetOperation =
  (request: RequestDigitizedAssetData) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const asset = state.design.digitizedAssets[request.elementId];
    if (request.elementType === 'image' && request.payload.imageUrl.includes('preview_default')) {
      captureException(new Error('preview_default should not be used for digitization'), {
        extra: { imageUrl: request.payload.imageUrl },
      });
    }

    if (!asset) {
      dispatch(
        updateMediaElementByUuidOperation(request.payload.elementUuid, {
          digitizing: true,
          digitizing_hash: request.elementId,
        }),
      );
      if (currentRequests.has(request.elementId)) {
        return;
      }
      currentRequests.add(request.elementId);
      sendPostMessage('design.requestDigitizedAsset', request);

      window.setTimeout(() => {
        if (!currentRequests.has(request.elementId)) {
          return;
        } // it was processed

        dispatch(
          setDigitizedAssetErrorOperation({
            elementId: request.elementId,
            payload: request.payload,
            error: 'timeout',
          }),
        );
      }, MAX_DIGITIZATION_LOADER_TIME);
    } else {
      dispatch(
        updateMediaElementByUuidOperation(request.payload.elementUuid, {
          digitizing: undefined,
          digitizing_hash: request.elementId,
          stitch_count: asset.stitchCount,
        }),
      );
    }
  };

export default requestDigitizedAssetOperation;
