import setSpreadBackgroundsOperation from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import setSpreadForegroundsOperation from 'editor/src/store/design/operation/setSpreadForegroundsOperation';

import store from 'product-personalizer/src/store';

interface SceneImage {
  name: string;
  left: number;
  top: number;
  width: number;
  height: number;
  url: string;
  url_webp: string;
  image_width: number;
  image_height: number;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  type: 'foreground' | 'background';
}

const sceneCache = new Map<string, SceneImage[]>();

async function loadSceneImages(productUid: string) {
  const url = `${LOCAL_ENV.ecomProxyEndpoint}/product/preview/scenes/editor?productUid=${productUid}`;
  let data = sceneCache.get(url);
  if (!data) {
    const res = await fetch(url);
    data = (await res.json()) as SceneImage[];
    sceneCache.set(url, data);
  }

  const backgrounds = data.filter((scene) => scene.type === 'background');
  await store.dispatch(setSpreadBackgroundsOperation(backgrounds));

  const foregrounds = data.filter((scene) => scene.type === 'foreground');
  await store.dispatch(setSpreadForegroundsOperation(foregrounds));
}

export default loadSceneImages;
