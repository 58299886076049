import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { DeviceType } from 'editor/src/store/app/types';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import setUploaderConfigOperation from 'editor/src/store/editor/operation/setUploaderConfigOperation';
import updateImageOnUploadOperation from 'editor/src/store/gallery/operation/updateImageOnUpload';
import { useDispatch, useStore } from 'editor/src/store/hooks';
import { setSelectedPreviewIndexAction } from 'product-personalizer/src/store/productPersonalizer/slice';

import useSetupColorConversion from 'editor/src/util/color/useSetupColorConversion';
import getUploadedImageDataFromAssets, { getAssets } from 'product-personalizer/src/utils/assets';
import { Product, Variant } from 'product-personalizer/src/utils/productTypes';

import useUploader, { SuccessPayload, UploaderContext } from 'editor/src/component/Uploader/useUploader';
import useDetectDeviceType, { DeviceTypeContext, mobileDevices } from 'editor/src/component/useDetectDeviceType';
import MobileMenuContainer from 'editor/src/component/useMobileMenu/MobileMenuContainer';
import ImageCropTool from 'product-personalizer/src/component/ImageCropTool';

import PersonalizationContent from '../PersonalizationContent';
import SpreadControls from '../SpreadControls';
import VariantSelectors, { VariantUIOverrides } from '../VariantSelectors';

import uploadConfig from './uploadConfig';
import useCropTool from './useCropTool';

import styles from './index.module.scss';

interface Props {
  product: Product | undefined;
  initialVariant: Variant | undefined;
  onVariantChange: (variant: Variant) => void;
  overrides: UIOverrides | undefined;
  noTopBorder: boolean;
  updateImage: boolean;
}

export interface UIOverrides {
  selectors: {
    desktop: VariantUIOverrides;
    mobile: VariantUIOverrides;
  };
}

export type VariantImages = {
  [identifier: string]: string[];
};

function PersonalizationView({ product, initialVariant, onVariantChange, overrides, noTopBorder, updateImage }: Props) {
  const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(initialVariant);

  const deviceType = useDetectDeviceType();
  useSetupColorConversion();

  const store = useStore();
  const dispatch = useDispatch();
  const { activeCrop, onCancel, onSave, cropElement, setActiveCrop, editImageCrop } = useCropTool();

  const onVariantChangeHandler = useCallback((variant: Variant) => {
    if (variant.imageUrl && updateImage) {
      const index = product?.imageUrls.findIndex((imageUrl) => imageUrl === variant.imageUrl);
      if (index !== undefined && index !== -1) {
        dispatch(setSelectedPreviewIndexAction(index));
      }
    }
    setSelectedVariant(variant);
    onVariantChange(variant);
  }, []);

  const onUploadSuccess = async (payload: SuccessPayload) => {
    const { url, file } = payload;
    const data = await getAssets(url, file.id);
    const asset = getUploadedImageDataFromAssets([data])[0];
    if (!asset) {
      return;
    }

    asset.file.id = file.id; // put back the it from uppy
    await dispatch(updateImageOnUploadOperation(asset));

    if (activeCrop) {
      setActiveCrop({
        imageUrl: asset.url,
        assetId: data.id,
        elementAddress: activeCrop.elementAddress,
        elementUuid: activeCrop.elementUuid,
      });
    }
  };

  const uploader = useUploader(mobileDevices.has(deviceType), onUploadSuccess);

  const uploadImage = useCallback((elementAddress: ElementAddress) => {
    uploader.open(() => {
      const state = store.getState();
      const element = getMediaElement<MediaImage>(state, elementAddress);
      if (element) {
        if (uploader.isUploading()) {
          setActiveCrop({
            imageUrl: undefined,
            assetId: undefined,
            elementAddress,
            elementUuid: element.uuid,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(setUploaderConfigOperation(uploadConfig));

    // FOR DEBUG PURPOSES
    // onUploadSuccess({
    //   url: 'https://gelato-api-test.s3.eu-west-1.amazonaws.com/preflight/assets/tests/cat.jpeg',
    //   file: { id: '8b25901f-7b5b-419e-9630-8c3f3521d742' },
    // } as any);
  }, []);

  const isNotPersonalizable = product?.isPersonalizable === false;
  const canShowVariantSelectors = !!product?.options.length && !!selectedVariant;

  const variantSelectorOverrides = overrides?.selectors[deviceType === DeviceType.Desktop ? 'desktop' : 'mobile'];

  if (isNotPersonalizable) {
    return canShowVariantSelectors ? (
      <VariantSelectors
        selectedVariant={selectedVariant}
        product={product}
        onVariantChange={onVariantChangeHandler}
        overrides={variantSelectorOverrides}
      />
    ) : null;
  }

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      <UploaderContext.Provider value={uploader}>
        <div className={styles.PersonalizationView}>
          {cropElement && activeCrop && (
            <ImageCropTool
              onCancel={onCancel}
              onSave={onSave}
              element={cropElement}
              imageUrl={activeCrop.imageUrl}
              assetId={activeCrop.assetId}
              trackUserInteraction={false}
            />
          )}
          {canShowVariantSelectors && (
            <VariantSelectors
              selectedVariant={selectedVariant}
              product={product}
              onVariantChange={onVariantChangeHandler}
              overrides={variantSelectorOverrides}
            />
          )}
          <SpreadControls />
          <PersonalizationContent
            editImageCrop={editImageCrop}
            uploadImage={uploadImage}
            noTopBorder={noTopBorder}
            trackUserInteraction={false}
          />
        </div>
        {uploader.renderMenu?.()}
        {createPortal(<MobileMenuContainer />, document.body)}
      </UploaderContext.Provider>
    </DeviceTypeContext.Provider>
  );
}

export default PersonalizationView;
