// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyDraft--qpBAz{flex:1 0 0;display:flex;flex-direction:column;align-items:center;justify-content:center;gap:12px}.EmptyDraft--qpBAz img{height:120px}.EmptyDraft--qpBAz .title--idBqD{color:#212121;text-align:center;font-family:"Gelato Sans",sans-serif;font-size:18px;font-style:normal;font-weight:700;line-height:24px;letter-spacing:.32px}.EmptyDraft--qpBAz .content--e2PMu{color:#383838;text-align:center;font-family:"Gelato Sans",sans-serif;font-size:14px;font-style:normal;font-weight:400;line-height:20px;letter-spacing:.24px}`, "",{"version":3,"sources":["webpack://./src/component/DraftSidebar/EmptyDraft/index.module.scss"],"names":[],"mappings":"AAAA,mBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAEA,uBACE,YAAA,CAGF,iCACE,aAAA,CACA,iBAAA,CACA,oCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CAGF,mCACE,aAAA,CACA,iBAAA,CACA,oCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".EmptyDraft {\n  flex: 1 0 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n\n  img {\n    height: 120px;\n  }\n\n  .title {\n    color: #212121;\n    text-align: center;\n    font-family: 'Gelato Sans', sans-serif;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 24px;\n    letter-spacing: 0.32px;\n  }\n\n  .content {\n    color: #383838;\n    text-align: center;\n    font-family: 'Gelato Sans', sans-serif;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0.24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmptyDraft": `EmptyDraft--qpBAz`,
	"title": `title--idBqD`,
	"content": `content--e2PMu`
};
export default ___CSS_LOADER_EXPORT___;
