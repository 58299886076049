import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { ImageBrushData } from '../types';

const fetching = new Set<string>();

export const createHash = ({
  assetId,
  elementId,
  overrides,
  quantized,
  selectedKeys,
}: {
  assetId: string;
  elementId: number;
  overrides: ImageBrushData;
  quantized: boolean;
  selectedKeys: {
    variantKey: string | undefined;
    designOptionKey: string | undefined;
  };
}) => {
  const { variantKey, designOptionKey } = selectedKeys;
  const fill = overrides.fill?.map((color) => `${color.previous}-${color.new}`).join('-');
  const stroke = overrides.stroke?.map((color) => `${color.previous}-${color.new}`).join('-');
  return [assetId, elementId, fill, stroke, quantized, variantKey, designOptionKey].join('-');
};

export interface ColorReplacement {
  fill: Array<{ from: string; to: string }>;
  stroke: Array<{ from: string; to: string }>;
}

export function overrideColorsToReplacementColors(overrides: ImageBrushData): ColorReplacement {
  const fill = overrides.fill?.map((c) => ({
    from: c.previous,
    to: c.new,
  }));

  const stroke = overrides.stroke?.map((c) => ({
    from: c.previous,
    to: c.new,
  }));

  return { fill: fill ?? [], stroke: stroke ?? [] };
}

const getAssetUrlWithReplacedColors = (
  assetId: string,
  elementId: number,
  overrides: ImageBrushData,
  quantized: boolean,
  variantKey: string | undefined,
  designOptionKey: string | undefined,
) => {
  const hash = createHash({
    assetId,
    elementId,
    overrides,
    quantized,
    selectedKeys: {
      variantKey,
      designOptionKey,
    },
  });
  if (fetching.has(hash)) {
    return;
  }
  fetching.add(hash);
  window.setTimeout(() => fetching.delete(hash), 10000); // allow retrying after 10 seconds

  sendPostMessage('asset.getAssetUrlWithReplacedColors', {
    assetId,
    colors: overrideColorsToReplacementColors(overrides),
    quantized,
    variantKey,
    designOptionKey,
  });
};

export default getAssetUrlWithReplacedColors;
