import { PayloadAction } from '@reduxjs/toolkit';

import { GalleryState } from 'editor/src/store/gallery/types';

const replaceSessionImageReducer = (
  state: GalleryState,
  action: PayloadAction<{ prevImageId: string; currentImageId: string }>,
) => {
  const { prevImageId, currentImageId } = action.payload;
  state.sessionImages.forEach((imageId, i) => {
    if (imageId === prevImageId) {
      state.sessionImages[i] = currentImageId;
    }
  });
};

export default replaceSessionImageReducer;
