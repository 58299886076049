/* eslint-disable no-restricted-syntax */
import { captureException } from '@sentry/react';

import type { DesignData } from 'editor/src/store/design/types';
import { getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import store from 'product-personalizer/src/store';

import getCollectionDataFromAssets from './collections';

const loadCollections = (designData: DesignData) => {
  const { folders } = store.getState().collections;

  const folderIds = new Set();
  for (const spread of designData.spreads) {
    if (spread.conditionGroup) {
      const media = spread.pages?.[0].groups.media ?? [];
      for (const element of media) {
        const collectionInfo = getAdvPersoDataFromElement(element);
        if (collectionInfo && !folders[collectionInfo.folderId]) {
          folderIds.add(collectionInfo.folderId);
        }
      }
    }
  }

  return Promise.all(
    [...folderIds].map(async (folderId) => {
      try {
        const response = await fetch(`${LOCAL_ENV.avdPersoEndpoint}/v1/embedded/folders/${folderId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const collectionData = getCollectionDataFromAssets(data);
        void import('editor/src/store/collections/operations/setCollectionFolderOperation').then(
          (setCollectionFolderOperation) => {
            void store.dispatch(setCollectionFolderOperation.default(collectionData));
          },
        );
      } catch (error) {
        captureException(error);
      }
    }),
  );
};

export default loadCollections;
