import { fabric } from 'fabric';

interface FabricPolygon extends fabric.Polygon {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricPolygon = fabric.util.createClass(fabric.Polygon, {
  zIndex: 0,

  _createCacheCanvas() {
    this.callSuper('_createCacheCanvas');
    this._cacheCanvas.id = 'fabric-cache-polygon';
  },
}) as {
  new (
    points: ConstructorParameters<typeof fabric.Polygon>[0],
    options?: fabric.IPolylineOptions & { zIndex?: number },
  ): FabricPolygon;
};

export default FabricPolygon;
