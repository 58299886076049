import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Hue = createClass(filters.ColorMatrix, {
  type: 'GelatoHue',
  rotation: 0,
  mainParameter: 'rotation',

  calculateMatrix() {
    const rad = (this.rotation / 180) * Math.PI;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const aThird = 1 / 3;
    const aThirdSqtSin = Math.sqrt(aThird) * sin;
    const OneMinusCos = 1 - cos;
    this.matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];
    this.matrix[0] = cos + OneMinusCos / 3;
    this.matrix[1] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[2] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[5] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[6] = cos + aThird * OneMinusCos;
    this.matrix[7] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[10] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[11] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[12] = cos + aThird * OneMinusCos;
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      hue: this.hue,
    });
  },
});

Hue.fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).GelatoHue = Hue;

export default Hue;
