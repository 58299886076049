import React from 'react';

import { Coords, FoldingLine } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricLineComponent from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

interface Props {
  element: FoldingLine;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
}
const STOKE_DASH_ARRAY_GREY = [10, 5];
const STOKE_DASH_ARRAY_WHITE = [5, 10];

function FoldingLineElement({ element, pageCoords, canvasRotation }: Props) {
  const { mm2px } = useFabricUtils();

  const point1 = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(element.x1),
    pageCoords.top + mm2px(element.y1),
  );
  const point2 = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(element.x2),
    pageCoords.top + mm2px(element.y2),
  );

  return (
    <>
      <FabricLineComponent
        x1={point1.x}
        y1={point1.y}
        x2={point2.x}
        y2={point2.y}
        evented={false}
        stroke="rgba(255,255,255,0.4)"
        zIndex={zIndex.FOLDING_LINE}
        fill={undefined}
        strokeUniform
        strokeDashArray={STOKE_DASH_ARRAY_WHITE}
        strokeDashOffset={5}
      />
      <FabricLineComponent
        x1={point1.x}
        y1={point1.y}
        x2={point2.x}
        y2={point2.y}
        evented={false}
        stroke="rgba(51, 51, 51, 0.3)"
        zIndex={zIndex.FOLDING_LINE}
        fill={undefined}
        strokeUniform
        strokeLineCap="round"
        strokeDashArray={STOKE_DASH_ARRAY_GREY}
      />
    </>
  );
}

export default React.memo(FoldingLineElement);
