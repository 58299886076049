import { UnknownAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { RootState } from './index';

const actionLoglistenerMiddleware = createListenerMiddleware<RootState>();

export const lastActions: UnknownAction[] = [];
actionLoglistenerMiddleware.startListening({
  predicate: (action) => !!action.type,
  effect: (action) => {
    if (lastActions.length >= 10) {
      lastActions.splice(0, 1);
    }
    lastActions.push(action);
  },
});

export default actionLoglistenerMiddleware;
