import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { PriceMethod } from 'product-personalizer/src/types/prices';

import IconShippingCar from 'editor/src/component/Icon/IconShippingCar';

import styles from './index.module.scss';

function ShippingPrice({ price, currency, minDays, maxDays }: PriceMethod) {
  const { i18Key, i18nValues } = useMemo(() => {
    let i18Key: string;
    const i18nValues: {
      currency: string;
      price: string;
      days?: number;
      minDays?: number;
      maxDays?: number;
    } = {
      currency,
      price: Number(price).toFixed(2),
    };

    if (minDays === maxDays) {
      i18Key = 'shopify-shipping-price-delivery-day';
      i18nValues.days = minDays;
    } else {
      i18Key = 'shopify-shipping-price';
      i18nValues.minDays = minDays;
      i18nValues.maxDays = maxDays;
    }

    return { i18Key, i18nValues };
  }, [minDays, maxDays]);

  return (
    <div className={styles.ShippingPrice}>
      <IconShippingCar className={styles.icon} />
      <span>
        <Trans
          i18nKey={i18Key}
          values={i18nValues}
          components={{
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            b: <b />,
          }}
        />
      </span>
    </div>
  );
}

export default React.memo(ShippingPrice);
