import { PayloadAction } from '@reduxjs/toolkit';

import { DesignState } from 'editor/src/store/design/types';

const setPageBackgroundColorReducer = (
  state: DesignState,
  action: PayloadAction<{
    spreadIndex: number;
    pageIndex: number | undefined;
    backgroundColor: string | undefined;
  }>,
) => {
  const { spreadIndex, pageIndex, backgroundColor } = action.payload;

  let page = null;
  if (pageIndex !== undefined) {
    page = state.designData?.spreads[spreadIndex].pages[pageIndex];
  }

  if (page) {
    page.backgroundColor = backgroundColor;
  } else {
    state.designData?.spreads[spreadIndex].pages.forEach((page) => {
      page.backgroundColor = backgroundColor;
    });
  }
};

export default setPageBackgroundColorReducer;
