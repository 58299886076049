import { fabric } from 'fabric';

const glsl = (e: TemplateStringsArray) => e;

export type ConstructorOption = {
  blur: number;
};

interface ShadowBlurFilter extends fabric.IBaseFilter {}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ShadowBlurFilter = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  type: 'ShadowBlurFilter',

  fragmentSource: glsl`
    precision highp float;
    
    // Declare uniform variables
    uniform sampler2D uTexture; // 2D texture sampler
    varying vec2 vTexCoord; // Varying texture coordinates
    uniform float uBlur;
    uniform vec2 uTextureSize;
    

    const float pi = 3.14159265359;
    float gaussian(float x, float sigma) {
        return 1.0 / (sigma * sqrt(2.0 * pi)) * exp(-x * x / (2.0 * sigma * sigma));
    }
    void main() {
        vec4 color = vec4(0.0);
        float alpha = 0.0;
        float weight = 0.0;
        for (float i = -4.0; i <= 4.0; i++) {
            for (float j = -4.0; j <= 4.0; j++) {
              vec2 offset = vec2(i, j) * uBlur / uTextureSize;
              vec4 sample = texture2D(uTexture, vTexCoord + offset);
              float d = length(offset);
              float w = gaussian(d, uBlur);
              alpha += sample.a;
              color += sample * w;
              weight += w;
            }
        }
        alpha /= 81.0;
        color /= weight;
        color.a = alpha;
        color.rgb /= color.a;
        gl_FragColor = color;
    }
  `,
  /* eslint-enable max-len */

  /**
   * blur value, in percentage of image dimensions.
   * specific to keep the image blur constant at different resolutions
   * range between 0 and 1.
   * @type Number
   * @default
   */
  blur: 0,

  isNeutralState() {
    return this.blur === 0;
  },

  /**
   * Return WebGL uniform locations for this filter's shader.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {WebGLShaderProgram} program This filter's compiled shader program.
   */
  getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      textureSize: gl.getUniformLocation(program, 'uTextureSize'),
      blur: gl.getUniformLocation(program, 'uBlur'),
    };
  },

  /**
   * Send data from this filter to its shader program's uniforms.
   *
   * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
   * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
   */
  sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform2fv(uniformLocations.textureSize, [this.originalWidth, this.originalHeight]);
    gl.uniform1f(uniformLocations.blur, this.blur * Math.min(this.originalWidth, this.originalHeight) * (1 / 100));
  },

  applyToWebGL(p: { context: WebGLRenderingContext; sourceWidth: number; sourceHeight: number }) {
    this.originalWidth = p.sourceWidth;
    this.originalHeight = p.sourceHeight;

    const gl = p.context;
    gl.clearColor(0, 0, 0, 0);
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    return this.callSuper('applyToWebGL', p);
  },
}) as { new (options: ConstructorOption): ShadowBlurFilter };

(ShadowBlurFilter as any).fromObject = (fabric.Image.filters.BaseFilter as any).fromObject;
(fabric.Image.filters as any).ShadowBlurFilter = ShadowBlurFilter;

export default ShadowBlurFilter;
