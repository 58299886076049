import cn from 'classnames';
import React from 'react';

import { ValueWithDisabled } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';

import ChipsField from './ChipsField';

import styles from './index.module.scss';

interface Props {
  values: ValueWithDisabled[];
  selectedValue: string;
  onChange: (value: string) => void;
}

function ChipsFields({ values, selectedValue, onChange }: Props) {
  return (
    <div className={cn(styles.ChipsFields, 'g-selector-buttons')}>
      {values.map((value, index) => (
        <ChipsField
          key={index}
          value={value.name}
          active={selectedValue === value.name}
          onClick={onChange}
          disabled={value.disabled}
        />
      ))}
    </div>
  );
}

export default React.memo(ChipsFields);
