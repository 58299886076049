import { fabric } from 'fabric';

export interface IGroupOptions extends fabric.IGroupOptions {
  zIndex?: number;
}

interface FabricGroup extends fabric.Group {
  zIndex?: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FabricGroup = fabric.util.createClass(fabric.Group, {
  zIndex: 0,
}) as {
  new (...args: ConstructorParameters<typeof fabric.Group>): FabricGroup;
};

export default FabricGroup;
