import { PayloadAction } from '@reduxjs/toolkit';

import { DesignData } from 'editor/src/store/design/types';
import { MockupState } from 'editor/src/store/mockup/types';

const saveMockupStructureReducer = (
  state: MockupState,
  action: PayloadAction<{ sizeId: string; structure: DesignData }>,
) => {
  const { sizeId, structure } = action.payload;
  state.mockup.structure[sizeId] = structure;
};

export default saveMockupStructureReducer;
