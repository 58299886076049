import { PayloadAction } from '@reduxjs/toolkit';

import getPluginTabs, { pluginOrder } from 'editor/src/store/editorModules/sidebar/pluginsTabs';
import { SidebarState } from 'editor/src/store/editorModules/sidebar/types';
import { Plugin, PluginState } from 'editor/src/store/plugins/types';

const setPluginTabsReducer = (state: SidebarState, action: PayloadAction<Plugin[]>) => {
  const pluginList = action.payload
    .filter((plugin) => plugin.state !== PluginState.NOT_VISIBLE)
    .sort((plugin1, plugin2) => {
      // use our pluginOrder to sort the plugins first
      return pluginOrder.indexOf(plugin1.name) - pluginOrder.indexOf(plugin2.name);
    })
    .sort((plugin1, plugin2) => {
      // sort between apps
      if (plugin1.indexInApps === undefined) {
        return 1;
      }
      if (plugin2.indexInApps === undefined) {
        return -1;
      }
      return plugin1.indexInApps - plugin2.indexInApps;
    });

  state.pluginTabs = getPluginTabs(pluginList);
};

export default setPluginTabsReducer;
