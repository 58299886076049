import { ApplyTemplateData } from './ApplyTemplatePopup';
import { ConfirmationPopupData } from './ConfirmationPopup';
import { LocalConfirmationPopupData } from './LocalConfirmationPopup';
import { WarningPopupData } from './WarningsPopup';

export enum PopupName {
  LOCAL_CONFIRMATION_POPUP = 'local-confirmation-popup',
  CONFIRMATION_POPUP = 'confirmation-popup',
  WARNING_POPUP = 'warning-popup',
  APPLY_TEMPLATE_POPUP = 'apply-template-popup',
  RESET_EFFECTS_POPUP = 'reset-effects-popup',
}

export type PopupData = {
  [PopupName.LOCAL_CONFIRMATION_POPUP]: LocalConfirmationPopupData;
  [PopupName.CONFIRMATION_POPUP]: ConfirmationPopupData;
  [PopupName.WARNING_POPUP]: WarningPopupData;
  [PopupName.APPLY_TEMPLATE_POPUP]: ApplyTemplateData;
  [PopupName.RESET_EFFECTS_POPUP]: undefined;
};
