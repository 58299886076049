import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { MediaImage } from 'editor/src/store/design/types';

import { FabricCanvasContext } from 'editor/src/util/useFabricCanvas';
import { FabricUtilsContext } from 'editor/src/util/useFabricUtils';
import trackInteraction from 'product-personalizer/src/utils/trackInteraction';

import IconLoading from 'product-personalizer/src/component/IconLoading';

import ImageComponent, { ImagePos } from './Image';
import Modal from './Modal';
import useCommonCanvasUtils, { CANVAS_ID } from './useCommonCanvasUtils';

import styles from './index.module.scss';

export interface Props {
  element: MediaImage;
  imageUrl: string | undefined;
  assetId: string | undefined;
  onCancel: () => void;
  onSave: (imagePos: ImagePos) => void;
  trackUserInteraction: boolean | undefined;
}

const ImageCropTool = ({ onSave, onCancel, element, imageUrl, assetId, trackUserInteraction }: Props) => {
  trackUserInteraction && trackInteraction();
  const { canvasDiv, fabricCanvas, fabricUtils } = useCommonCanvasUtils(false, element);
  const [ready, setReady] = useState(false);
  const [imagePos, setImagePos] = useState<ImagePos>({
    px: element.px,
    py: element.py,
    pw: element.pw,
    ph: element.ph,
    pr: element.pr,
  });

  useEffect(() => {
    setImagePos({
      px: element.px,
      py: element.py,
      pw: element.pw,
      ph: element.ph,
      pr: element.pr,
    });
  }, []);

  const onReset = useCallback(() => {
    const image = new Image();
    image.onload = () => {
      let width;
      let height;
      if (image.width / image.height > element.width / element.height) {
        height = element.height;
        width = (image.width * element.height) / image.height;
      } else {
        width = element.width;
        height = (image.height * element.width) / image.width;
      }

      setImagePos({
        px: (element.width - width) / 2,
        py: (element.height - height) / 2,
        pw: width,
        ph: height,
        pr: 0,
      });
    };
    image.src = imageUrl || '';
  }, [imageUrl]);
  const onLocalSave = useCallback(() => onSave(imagePos), [imagePos]);
  const onLoad = useCallback(() => setReady(true), []);

  return ReactDOM.createPortal(
    <Modal onSave={onLocalSave} onClose={onCancel} onReset={onReset} isSaveButtonDisabled={!imageUrl}>
      <div className={styles.ImageCropTool} ref={canvasDiv}>
        <canvas id={CANVAS_ID} />
        {fabricCanvas && fabricUtils.setup && imageUrl && assetId && (
          <FabricUtilsContext.Provider value={fabricUtils}>
            <FabricCanvasContext.Provider value={fabricCanvas}>
              <ImageComponent
                imageUrl={imageUrl}
                assetId={assetId}
                element={element}
                isMobile={false}
                imagePos={imagePos}
                onUpdate={setImagePos}
                onLoad={onLoad}
              />
            </FabricCanvasContext.Provider>
          </FabricUtilsContext.Provider>
        )}
        <div className={cn(styles.loader, { [styles.ready]: ready })}>
          {!ready && (
            <div className={styles.icon}>
              <IconLoading />
            </div>
          )}
        </div>
      </div>
    </Modal>,
    document.body,
  );
};

export default React.memo(ImageCropTool);
