import { useMemo, useEffect } from 'react';

import { Coords, MediaBox } from 'editor/src/store/design/types';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import disposeCanvas from 'editor/src/component/EditorArea/disposeCanvas';

import updateMirrorCanvas from './updateMirrorCanvas';

const RATIO = window.devicePixelRatio;

function useMirrorCanvas(
  pageCoords: Coords,
  mediaBox: MediaBox,
  backgroundColor: string | undefined,
  filteredUids?: Set<number>,
) {
  const fabricCanvas = useFabricCanvas();
  const { mm2px } = useFabricUtils();
  const mirrorCanvas = useMemo(() => {
    const domCanvas = document.createElement('canvas');
    domCanvas.id = 'mirror';
    return new fabric.StaticCanvas(domCanvas, {
      renderOnAddRemove: false,
      backgroundColor,
    });
  }, []);

  useEffect(() => () => disposeCanvas(mirrorCanvas), [mirrorCanvas]);

  const mediaBoxBox = useMemo(
    () => ({
      width: mm2px(mediaBox.width),
      height: mm2px(mediaBox.height),
      x: pageCoords.left + mm2px(mediaBox.x),
      y: pageCoords.top + mm2px(mediaBox.y),
    }),
    [mediaBox, pageCoords, mm2px],
  );

  useEffect(() => {
    mirrorCanvas.setDimensions({
      width: mediaBoxBox.width * RATIO,
      height: mediaBoxBox.height * RATIO,
    });
    updateMirrorCanvas(fabricCanvas, mirrorCanvas, mediaBoxBox, RATIO, filteredUids);
    fabricCanvas.requestRenderAll();

    const beforeRender = () => updateMirrorCanvas(fabricCanvas, mirrorCanvas, mediaBoxBox, RATIO, filteredUids);
    fabricCanvas.on('before:render', beforeRender);

    return () => {
      fabricCanvas.off('before:render', beforeRender);
    };
  }, [mirrorCanvas, mediaBoxBox, filteredUids]);

  return { mirrorCanvas, mediaBoxBox };
}

export default useMirrorCanvas;
