import { PayloadAction } from '@reduxjs/toolkit';

import { VariantsState } from 'editor/src/store/variants/types';

export type Payload = { key: string; value: string };

const selectSingleOptionReducer = (state: VariantsState, action: PayloadAction<Payload>) => {
  const { key, value } = action.payload;
  state.selectedSingleOptions[key] = value;
};

export default selectSingleOptionReducer;
