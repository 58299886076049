import { DesignData } from 'editor/src/store/design/types';

// we only apply if there was not layout or if the current layout is one specified in layoutPerProductUids but is not the correct one
function getApplyableSchemaNames(
  designData: DesignData,
  layoutPerProductUids: { [productUid: string]: string },
  autoApplyLayoutSchemaNames: string[] | undefined,
) {
  const layoutForProductUid = layoutPerProductUids[designData.product_uid];
  const schemaNames = layoutForProductUid ? [layoutForProductUid] : autoApplyLayoutSchemaNames;
  if (!schemaNames?.length) {
    return undefined;
  }

  const existingAppliedLayout = designData.spreads.find((spread) => spread.layoutSchemaName)?.layoutSchemaName;
  if (existingAppliedLayout) {
    if (!layoutForProductUid) {
      return undefined;
    }

    if (!Object.values(layoutPerProductUids).includes(existingAppliedLayout)) {
      return undefined;
    }
  }

  return schemaNames;
}

export default getApplyableSchemaNames;
