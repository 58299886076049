const languageMap: { [language: string]: string } = {
  en: 'en',
  cs: 'cs_CZ',
  da: 'da_DK',
  nl: 'nl_NL',
  fr: 'fr_FR',
  de: 'de_DE',
  it: 'it_IT',
  ja: 'ja_JP',
  nb: 'nb_NO',
  no: 'nb_NO',
  pl: 'pl_PL',
  pt: 'pt_PT',
  es: 'es_ES',
  sv: 'sv_SE',
  vi: 'vi_VN',
};

export default languageMap;
