import { PayloadAction } from '@reduxjs/toolkit';

import { MockupPreviewStore, MockupState } from 'editor/src/store/mockup/types';

const setStoresReducer = (
  state: MockupState,
  action: PayloadAction<{
    limit: number;
    offset: number;
    stores: MockupPreviewStore[];
  }>,
) => {
  const { stores, offset, limit } = action.payload;
  if (!state.preview.stores.entries) {
    state.preview.stores.entries = [];
  }
  state.preview.stores.entries.splice(offset, 0, ...stores);
  state.preview.stores.hasMore = stores.length === limit;
  stores.forEach((store) => {
    state.preview.products[store.id] = { hasMore: true, entries: undefined };
  });
};

export default setStoresReducer;
