import { fabric } from 'fabric';

import { Options } from './types';

const { filters } = fabric.Image;
const { createClass } = fabric.util;

const Vintage = createClass(filters.ColorMatrix, {
  type: 'GelatoVintage',
  value: 0,
  mainParameter: 'value',

  calculateMatrix() {
    const coloMatrix = [
      0.62793, 0.32021, -0.03965, 0, 0.03784, 0.02578, 0.64411, 0.03259, 0, 0.02926, 0.0466, -0.08512, 0.52416, 0,
      0.02023, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal));
  },

  isNeutralState(options: Options) {
    this.calculateMatrix();
    return filters.BaseFilter.prototype.isNeutralState.call(this, options);
  },

  applyTo(options: Options) {
    this.calculateMatrix();
    filters.BaseFilter.prototype.applyTo.call(this, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      value: this.value,
    });
  },
});

Vintage.fromObject = (fabric.Image.filters.ColorMatrix as any).fromObject;
(fabric.Image.filters as any).GelatoVintage = Vintage;

export default Vintage;
